import React from "react";
import { GetBrandLogo } from "../../../assets/utils/IconGenerator";

const MainLogo = ({ small, name, classes, onClick }) => {
  return (
    <div onClick={onClick} className={`main-logo-container ${classes}`}>
      <GetBrandLogo
        name={name}
        classes={`main-logo ${onClick ? "pointer" : ""} ${
          small && "main-logo-small"
        }`}
      />
    </div>
  );
};

export default MainLogo;
