import React from "react";
// Components
import history from "../../../utils/history";
import MainLogo from "../../common/logo/mainLogo";

const LogoIntro = () => {
  const storeClasses = "self-center pointer scale-hover full-width";
  return (
    <div className="single-grid grid-center">
      <MainLogo onClick={() => history.push("/")} />
      {/* TODO: to be changed to riginal text */}
      {/* <TextItem classes="sparag-lit block-hide" content="desc.logo-intro" />
      <TextItem
        classes="parag-hev text-center block-hide top-padd"
        content="title.get-app"
      />
      <div className="double-grid sm-hide">
        <GetImage name="AppStore.png" classes={storeClasses} />
        <GetImage name="GooglePlay.png" classes={storeClasses} />
      </div> */}
    </div>
  );
};

export default LogoIntro;
