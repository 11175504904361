import React from "react";
// Components
import { GetIcon } from "../../../../assets/utils/IconGenerator";
import NormalButton from "../../../common/buttons/NormalButton";
import CustomModal from "../../../common/modals/customModal";
import TextItem from "../../../common/text/TextItem";

const SettingItem = ({
  icon,
  title,
  talk,
  talkValue,
  decorator,
  iconSize,
  modal,
  setModal,
  modalContent,
  setValue,
}) => {
  return (
    <div className="flex vertical-sm-padd list-divider justify-end">
      <div className="flex-nowrap align-start flex-fill">
        <GetIcon name={icon} size={iconSize ?? "tiny2"} />
        <div className="single-grid side-med-padd flex-one grid-sm-gap">
          <div className="flex-nowrap justify-start">
            <TextItem content={title} classes="parag-hev text-start" />
            {decorator}
          </div>
          <TextItem
            content={talkValue ?? talk}
            classes="tiny-lit opacity text-start"
          />
        </div>
      </div>
      <NormalButton
        content="action.changeL"
        onClick={() => setModal(true)}
        classes="top-med-marg"
      />
      <CustomModal
        icon={icon}
        title={title}
        open={modal}
        setOpen={setModal}
        onClose={() => setValue && setValue("")}
      >
        {modalContent && modalContent()}
      </CustomModal>
    </div>
  );
};

export default SettingItem;
