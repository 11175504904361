import React from "react";
// Components
import CancelButton from "./CancelButton";
import NormalButton from "./NormalButton";

const FormButtons = ({
  icon,
  submitContent,
  cancelContent,
  radial,
  flip,
  onSubmit,
  onCancel,
  disabled,
  classes,
  noSubmit,
  second,
}) => {
  return (
    <div className={`flex-nowrap justify-end ${classes}`}>
      {onCancel && (
        <CancelButton
          content={cancelContent ?? "action.cancel"}
          disabled={disabled}
          onClick={onCancel}
        />
      )}
      <NormalButton
        disabled={disabled}
        content={submitContent ?? "action.submit"}
        icon={icon}
        onClick={onSubmit}
        radial={radial}
        flip={flip}
        noSubmit={noSubmit}
        second={second}
      />
    </div>
  );
};

export default FormButtons;
