import React from "react";
// Packages
import { Upload } from "antd";
// Components
import { GetIcon } from "../../../assets/utils/IconGenerator";
import TextItem from "../text/TextItem";

const AlbumUploader = ({ uploadAlbumImage, disabled, minHeight }) => {
  const props = {
    accept: ".jpg, .png, .jpeg",
    showUploadList: false,
    beforeUpload: (file) => {
      uploadAlbumImage(file);
      return false;
    },
  };

  return (
    <Upload className="upload-box" disabled={disabled} {...props}>
      <div
        style={{ minHeight: minHeight ?? 150 }}
        className={`flex flex-column border-grey2 grey1-back ${
          disabled ? "forbid" : "scale-hover pointer"
        } full-width all-med-padd`}
      >
        <GetIcon name="upload" color="dark" classes="all-med-marg" />
        <TextItem content="title.upload-img" classes="micro-med text-center" />
      </div>
    </Upload>
  );
};

export default AlbumUploader;
