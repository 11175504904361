export const genders = [
  {
    value: "Male",
    t: {
      en: "Male",
      ar: "ذكر",
    },
  },
  {
    value: "Female",
    t: {
      en: "Female",
      ar: "أنثي",
    },
  },
];
