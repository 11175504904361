import React from "react";
// Components
import TextItem from "../text/TextItem";

const CancelButton = ({ content, disabled, onClick, classes }) => {
  return (
    <TextItem
      content={content}
      onClick={onClick}
      classes={`cancel-btn sparag-med pointer scale-hover fit-content second-hover box-sm-marg ${
        disabled ? "disabled" : ""
      } ${classes}`}
    />
  );
};

export default CancelButton;
