import React from "react";
// Components
import NavbarActions from "./helpers/navbarActions";
import NavbarNavigations from "./helpers/navbarNavigations";

const NavbarComponent = ({ project, location }) => {
  return (
    <div className="navbar black-back sticky top-zero-abs index">
      <div
        className={`nav-container flex justify-space-between ${
          project ? "side-med-padd" : "not-full-container"
        }`}
      >
        <NavbarNavigations project={project} />
        <NavbarActions project={project} location={location} />
      </div>
    </div>
  );
};

export default NavbarComponent;
