import axios from "axios";
import { reduxActions } from "../redux/actions";
import store from "../redux/store";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const apiHelper = {
  post,
  put,
  get,
};
function post(route, data) {
  axios
    .post(route, data.body, {
      params: data.query,
      headers: data.headers,
    })
    .then((res) => {
      data.success(res.data);
    })
    .catch((err) => {
      if (err.response) data.fail(err.response.data);
      else data.error();
    });
}
function put(route, data) {
  axios
    .put(route, data.body, {
      params: data.query,
      headers: data.headers,
    })
    .then((res) => {
      data.success(res.data);
    })
    .catch((err) => {
      if (err.response) data.fail(err.response.data);
      else data.error();
    });
}
function get(route, data) {
  axios
    .get(route, {
      params: data.query,
      headers: data.headers,
    })
    .then((res) => {
      data.success(res.data);
    })
    .catch((err) => {
      if (err.response) data.fail(err.response.data);
      else data.error();
    });
}

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response?.data?.description === "expired-token") {
      store.dispatch(reduxActions.authActions.logout());
      // TODO: alert for expired token
    }
    return Promise.reject(err);
  }
);
