// Application tab title keys url's
export const titleRouteKeys = {
  SIGN_IN: "/",
  REQUESTS: "/explore",
  SIGN_UP: "/sign-up",
  FORGOT_PASSWORD: "/forgot-password",
  EMAIL_CONFIRM: "/email-confirm",
  RECOVERY_SENT: "/recover-sent",
  DEACTIVATED: "/deactivated",
  ORGANIZATION_DEACTIVATED: "/org-deactivated",
  RESET_PASSWORD: "/reset-pass",
  PASSWORD_CHANGED: "/pass-changed",
  ACCOUNT_ACTIVATED: "/acc-activated",
  ACCOUNT_ALREADY_ACTIVATED: "/already-activated",
  WELCOME_START: "/start/welcome",
  PERSONAL_START: "/start/personal",
  SETTINGS: "/settings",
  PROFILE: "/profile",
  EDIT_PROFILE: "/edit-personal",
  NEW_REQUEST: "/new-request",
  NOTIFICATIONS: "/notifications",
  TEAM: "/team",
  PROJECTS: "/ongoing",
  PROJECT: "/project",
  FULL_GALLERY: "/full-gallery",
  EDIT_CASTING_SHOT: "/edit-casting",
  EDIT_SHOWREEL: "/edit-showreel",
  APPROVE_PROFILE: "/third/approve",
  ORGANIZATION_SIGN_IN: "/organization/auth",
  ORGANIZATION_FIRST_SUB_ACCOUNT_START: "/start/sub-account",
};
