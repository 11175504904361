import React from "react";
import { hist } from "../../../../utils/history";
import EmptyForm from "../../../common/error/empty";

const NotChosenCastingCard = ({ type, projectId, selectType, icon }) => {
  const getEmptyButton = () => {
    if (selectType === "multiple") return "action.add";
    else return "action.choose";
  };

  return (
    <EmptyForm
      classes="card"
      title={`empty.ch-${type}`}
      button={getEmptyButton()}
      emptyIcon={icon}
      onClick={() =>
        hist.push(`/project/${projectId}/casting/explore`, {
          type,
          projectId,
        })
      }
    />
  );
};

export default NotChosenCastingCard;
