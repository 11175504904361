import React from "react";
// Components
import { GetIcon, GetImage } from "../../../../assets/utils/IconGenerator";
// Components
import TextItem from "../../text/TextItem";
// Helpers
import {
  getAvatarLetters,
  mapIconToText,
} from "../../../../utils/helperFunctions";

const ImageEmptyState = ({ size, emptyState }) => {
  if (emptyState) {
    // Desctruct empty state props
    const {
      type,
      color,
      icon,
      scaleSmall,
      image,
      resize,
      name,
      classes,
      text,
    } = emptyState;

    switch (type) {
      case "icon":
        return (
          <div
            className={`absolute flex flex-column full-width full-height scale-down${
              scaleSmall ? "-small" : ""
            } ${classes}`}
          >
            <GetIcon
              name={icon ?? "user"}
              size={size}
              color={color ?? "black"}
            />
            <TextItem
              content={text}
              classes="parag-med text-center top-sm-padd"
            />
          </div>
        );
      case "image":
        return (
          <GetImage
            name={image}
            resize={resize}
            classes="absolute full-width full-height"
          />
        );
      case "name":
        return (
          <TextItem
            classes={`absolute ${mapIconToText(size)}-med`}
            content={name ? getAvatarLetters(name) : "LS"}
            normal
          />
        );
    }
  }

  return (
    <GetIcon
      name="user"
      size={size}
      color="black"
      classes="absolute full-width full-height scale-down"
    />
  );
};

export default ImageEmptyState;
