import React from "react";
import { connect } from "react-redux";
import { GetBrandLogo } from "../../../../assets/utils/IconGenerator";
import { projectNavbarNavigation } from "../../../../utils/constants";
import FullScreenNavigationItem from "../navigation/fullScreenNavigationItem";
import ProjectNavigationItem from "../navigation/projectNavigationItem";

const SideMenuNavigation = ({
  project,
  collapsed,
  setCollapsed,
  dataType,
  type,
}) => {
  return (
    <div className="flex-nowrap flex-column full-height">
      <GetBrandLogo
        name={collapsed ? "logo-sm-blk" : "logo-main"}
        size={collapsed ? "big" : "monster"}
        classes="full-width-imp transition bottom-med-marg"
      />
      {project && (
        <ProjectNavigationItem
          project={project}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      )}
      {projectNavbarNavigation(project._id, dataType ?? type).map(
        (navigationItem) =>
          navigationItem && (
            <FullScreenNavigationItem
              key={navigationItem.url}
              navigationItem={navigationItem}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
          )
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataType: state.auth.dataType,
    type: state.auth.type,
  };
};

export default connect(mapStateToProps)(SideMenuNavigation);
