import { Image } from "antd";
import React, { useState } from "react";
// Packages
import { connect } from "react-redux";
import { reduxActions } from "../../../redux/actions";
// Helpers
import { apiServices } from "../../../services/apiServices";
import { HandleApiHook } from "../../../utils/hooks";
// Components
import FetchUiData from "../../common/layouts/fetchUiData";
import ThirdProfileSkeleton from "../skeleton/thirdProfileSkeleton";
import ApproveCard from "./cards/approveCard";
import CastingShotCard from "./cards/castingShotCard";
import GalleryCard from "./cards/galleryCard";
import IdentificationCard from "./cards/identificationCard";
import MeasurementCard from "./cards/measurementCard";
import ShowreelCard from "./cards/showreelCard";

const ThirdPartyProfile = ({
  token,
  location,
  imageUploadService,
  menuItems,
  identificationCardEditUrl,
  fullGalleryUrl,
  castingShotEditUrl,
  showreelEditUrl,
  changeTab,
  limited,
}) => {
  // Hooks
  const { loading, error, setError, submit } = HandleApiHook(true);
  const [profileData, setProfileData] = useState(null);
  const [otherProfile, setOtherProfile] = useState(
    location.state ? location.state.authId : undefined
  );
  const [currentStage, setCurrentStage] = useState(null);
  const [stageStatus, setStageStatus] = useState("");

  // Fetch profile data
  const fetchProfile = async () => {
    await setOtherProfile(location.state ? location.state.authId : undefined);
    submit({
      service: apiServices.third.getThirdPartyProfile,
      query: {
        token,
        authId: otherProfile,
      },
      onSuccess: (profileDataResult) => {
        changeTab(profileDataResult.username.trim());
        setProfileData(profileDataResult);
      },
    });
  };

  // Update Account Data
  const updateAccountData = (accountData) => {
    let profileDataSample = { ...profileData };
    profileDataSample.data.id.accData.id = accountData;
    setProfileData(profileDataSample);
  };

  return (
    <FetchUiData
      service={fetchProfile}
      observer={location.state}
      loading={loading}
      dataFetched={profileData}
      error={error}
      setError={setError}
      Loader={ThirdProfileSkeleton}
      classes="single-grid bottom-extend top-extend-fill"
      noWait
    >
      <ApproveCard
        profileData={profileData}
        currentStageProp={{ currentStage, setCurrentStage }}
        stageStatusProp={{ stageStatus, setStageStatus }}
      />
      <IdentificationCard
        profileData={profileData}
        imageEmptyIcon="user"
        upload={imageUploadService}
        editUrl={identificationCardEditUrl}
        other={otherProfile}
        menuItems={menuItems}
        limited={limited}
      />
      <Image.PreviewGroup>
        <GalleryCard
          profileData={profileData}
          fullGalleryUrl={fullGalleryUrl}
          other={otherProfile}
          currentStage={currentStage}
          updateAccountData={updateAccountData}
        />
      </Image.PreviewGroup>
      <MeasurementCard
        profileData={profileData}
        currentStage={currentStage}
        other={otherProfile}
        updateAccountData={updateAccountData}
      />
      <CastingShotCard
        profileData={profileData}
        editUrl={castingShotEditUrl}
        other={otherProfile}
      />
      <ShowreelCard
        profileData={profileData}
        editUrl={showreelEditUrl}
        other={otherProfile}
      />
    </FetchUiData>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = {
  changeTab: reduxActions.tabTitleActions.changeTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyProfile);
