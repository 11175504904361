import React, { useState, useEffect } from "react";
// Helpers
import { apiServices } from "../../../services/apiServices";
import { connect } from "react-redux";
import { reduxActions } from "../../../redux/actions";
import history from "../../../utils/history";
import { HandleApiHook, LanguageHook } from "../../../utils/hooks";
// Components
import LinkText from "../../common/buttons/linkText";
import LinkButton from "../../common/buttons/linkButton";
import TopLoader from "../../common/loaders/topLoader";
import HeadTextField from "../../common/fields/headTextField";
import TextItem from "../../common/text/TextItem";
import { GetIcon } from "../../../assets/utils/IconGenerator";
import FormButtons from "../../common/buttons/FormButtons";

const EmailConfirm = ({
  location,
  mail,
  addEmail,
  clearEmail,
  organization,
}) => {
  // Destruct Location Data
  const { token, allowEdit } = location.state ?? {};
  // Hooks
  const { language } = LanguageHook();
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [editMail, setEditMail] = useState(false);
  const [email, setEmail] = useState(mail);
  const [newEmail, setNewEmail] = useState("");

  // Check location data valid
  useEffect(() => {
    if (!location.state || !mail) {
      clearEmail();
      history.goBack();
    }
  }, []);

  // Resend Email confirmation
  const resendConfirmationEmail = () => {
    submit({
      service: apiServices.auth.resendConf,
      query: { email, language },
      onSuccess: (data) => {
        addEmail(data.email);
        setEmail(data.email);
      },
      successMessage: "sent",
      params: { field: "title.activation-email" },
    });
  };

  // Change email
  const changeEmailRegistered = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.auth.changeEmailRegister,
      query: { email: newEmail, language, token },
      onSuccess: () => {
        setEditMail(false);
        setEmail(newEmail);
        addEmail(newEmail);
        setNewEmail("");
      },
      successMessage: "change",
      params: { field: "field.email" },
    });
  };

  return (
    <div className="single-grid">
      {loading && <TopLoader />}
      <TextItem content="title.last-step" classes="shead-hev text-center" />
      <GetIcon name="check-circle" size="giant" classes="margin-auto" />
      <TextItem content="desc.email-conf" classes="micro-lit text-center" />
      <div>
        <TextItem content="desc.ver-email-sent" classes="micro-lit" />
        <TextItem content={email} classes="sparag-hev" normal />
        {!editMail && allowEdit && (
          <LinkText
            text="desc.wrong-mail"
            link="desc.edit-mail"
            onClick={() => setEditMail(true)}
          />
        )}
        {editMail && (
          <form
            onSubmit={changeEmailRegistered}
            className="single-grid top-sm-marg"
          >
            <HeadTextField
              name="field.email"
              innerPlace="field.enter-new-mail"
              value={newEmail}
              error={getErrorItem("email")}
              onChange={setNewEmail}
              type="email"
            />
            <FormButtons
              disabled={loading}
              onCancel={() => setEditMail(false)}
              submitContent="action.changeL"
            />
          </form>
        )}
      </div>
      <div>
        <LinkButton
          content="desc.resend-conf"
          classes="tiny-hev"
          disabled={loading}
          line
          onClick={resendConfirmationEmail}
        />
        <TextItem content="desc.junk-check" classes="micro-lit" />
      </div>
      <LinkButton
        content="title.back-home"
        line
        classes="margin-auto micro-lit"
        onClick={() =>
          history.replace(organization ? "/organization/auth" : "/")
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    mail: state.email.email,
  };
};

const mapDispatchToProps = {
  clearEmail: reduxActions.emailActions.clearEmail,
  addEmail: reduxActions.emailActions.addEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirm);
