import React, { useState, useEffect } from "react";
// Packages
import VideoPlayer from "react-video-js-player";

const VideoPlayerCard = ({ link }) => {
  // Hooks
  const [video, setVideo] = useState(
    link ? `${process.env.REACT_APP_CDN_URL}/${link}` : ""
  );

  // Listen on link change
  useEffect(() => {
    setVideo(link ? `${process.env.REACT_APP_CDN_URL}/${link}` : "");
  }, [link]);

  return (
    <div className="full-width full-height">
      <div className="video-player full-width full-height">
        <VideoPlayer
          controls={true}
          src={video}
          // onReady={this.onPlayerReady.bind(this)}
          // onPlay={this.onVideoPlay.bind(this)}
          // onPause={this.onVideoPause.bind(this)}
          // onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
          // onSeeking={this.onVideoSeeking.bind(this)}
          // onSeeked={this.onVideoSeeked.bind(this)}
          // onEnd={this.onVideoEnd.bind(this)}
        />
      </div>
    </div>
  );
};

export default VideoPlayerCard;
