import React from "react";
// Packages
import { Dialog } from "@material-ui/core";
// Components
import { GetIcon } from "../../../assets/utils/IconGenerator";
import LineDivider from "../dividers/lineDivider";
import TextItem from "../text/TextItem";

const CustomModal = ({
  open,
  setOpen,
  title,
  icon,
  onClose,
  children,
  classes,
  style,
  disableClear,
}) => {
  // Handle close modal
  const handleClose = () => {
    if (onClose) onClose();
    if (!disableClear)
      // clear();
      setOpen(false);
  };

  return (
    <Dialog className="custom-modal" open={open} onClose={handleClose}>
      <div className="flex justify-start top-med-padd side-med-marg">
        <GetIcon name={icon} classes="end-sm-marg" />
        <TextItem content={title} classes="sparag-med" />
      </div>
      <LineDivider classes="top-med-marg gre3-back opacity" />
      <div
        style={style}
        className={`content-box overflow-auto relative ${classes}`}
      >
        {children}
      </div>
    </Dialog>
  );
};

export default CustomModal;
