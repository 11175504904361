import React from "react";
// Helpers
import { apiServices } from "../../../../services/apiServices";
// Components
import AssetDownloader from "../../../common/downloader/assetDownloader";
import ImageComponent from "../../../common/image/image";
import ProjectIdentificationHeader from "../helpers/identificationHeader";
import ProjectOwners from "../helpers/projectOwners";

const ProjectIdentificationCard = ({ projectData, owner }) => {
  // Desctruct profile data
  const { _id, image, brief } = projectData ?? {};

  return (
    <div className="card one-third-grid-resp grid-zero-gap">
      <ImageComponent
        shape="square"
        fill
        minHeight={200}
        preview
        size="big2"
        image={image}
        param={_id}
        resize="cover"
        upload={owner && apiServices.proj.uploadImage}
        emptyState={{
          type: "icon",
          icon: "project",
          color: "black",
          classes: "opacity",
        }}
        name="field.project-picture"
      />
      <div className="single-grid all-med-padd align-self-start">
        <ProjectIdentificationHeader projectData={projectData} owner={owner} />
        <AssetDownloader title="field.brief" asset={brief} />
        <ProjectOwners projectData={projectData} />
      </div>
    </div>
  );
};

export default ProjectIdentificationCard;
