import { tabTitleTypes } from "./tabTitleTypes";

export const tabTitleActions = {
  changeTab,
  clearTab,
};
function changeTab(tabValue) {
  return { type: tabTitleTypes.CHANGE_TAB, tabValue };
}
function clearTab() {
  return { type: tabTitleTypes.CLEAR_TAB };
}
