import React from "react";
// Helpers
import history from "../../../../utils/history";
// Components
import LinkButton from "../../../common/buttons/linkButton";
import EmptyForm from "../../../common/error/empty";
import IconTitle from "../../../common/layouts/IconTitle";
import TextItem from "../../../common/text/TextItem";
import VideoPlayerCard from "../../../common/video/videoPlayerCard";
import CardUtilities from "../../../common/helpers/cardUtilities";

const CastingShotCard = ({ profileData, editUrl, other }) => {
  // Desctruct profile data
  const { id: userData } = profileData.data ?? {};
  const { id: accountData } = userData.accData ?? {};
  const { castDesc, castLinks, castShoot } = accountData ?? {};

  if (userData.type === "cast" && profileData.initial) {
    if (!castDesc && other) return null;
    if (!castDesc)
      return (
        <div className="card">
          <EmptyForm
            title="empty.cast-shot"
            button="action.upload"
            buttonIcon="upload"
            emptyIcon="shot"
            onClick={() => history.push(editUrl)}
          />
        </div>
      );
    return (
      <div className="card all-med-padd single-grid">
        <div className="flex justify-space-between full-width">
          <IconTitle
            content="title.cast-shot"
            icon="shot"
            iconSize="tiny"
            textClasses="sparag-hev text-upper dark"
            classes="align-center"
          />
          <CardUtilities
            editUrl={editUrl}
            edit={!other}
            editData={accountData}
          />
        </div>
        <div className="single-grid">
          <TextItem content={castDesc} classes="micro-lit" />
          <VideoPlayerCard link={castShoot.url} />
          <TextItem content="title.cast-samples" classes="sparag-med" />
          {castLinks.map((link) => {
            return (
              <LinkButton
                key={link}
                content={link}
                line
                classes="tiny-med text-break"
                onClick={() => window.open(link)}
              />
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};

export default CastingShotCard;
