import React, { useEffect, useState } from "react";
import TextItem from "../../../common/text/TextItem";
import ImageComponent from "../../../common/image/image";
import history from "../../../../utils/history";

const ProjectNavigationItem = ({
  project,
  collapsed,
  setActiveMenu,
  setCollapsed,
}) => {
  // Destruct project
  const { _id, image, name } = project ?? {};
  // Hooks
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(window.location.pathname === `/project/${_id}`);
  }, []);
  history.listen(() => {
    setActive(window.location.pathname === `/project/${_id}`);
  });

  return (
    <div
      className={`full-screen-navigation-item pointer full-width vertical-med-padd ${
        active && "black-back"
      }`}
      onClick={() => {
        if (setCollapsed) setCollapsed(true);
        if (setActiveMenu) setActiveMenu(false);
        history.push(`/project/${_id}`);
      }}
    >
      <div className={`flex-nowrap ${!collapsed && "justify-start"}`}>
        <ImageComponent
          image={image}
          size="small"
          emptyState={{
            type: "icon",
            icon: "project",
            color: "black",
          }}
        />
        {!collapsed && (
          <TextItem
            content={name}
            normal
            classes={`side-med-padd text-start parag-${active ? "hev white" : "lit"}`}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectNavigationItem;
