import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const CircleLoader = ({ size, classes, style }) => {
  return (
    <div style={style} className={`circle-loader circle-${size} ${classes}`}>
      <CircularProgress color="inherit" />
    </div>
  );
};

export default CircleLoader;
