import React from "react";
// Helpers
import { hist } from "../../../../utils/history";
import { LanguageHook } from "../../../../utils/hooks";
// Components
import EmptyForm from "../../../common/error/empty";
import IconTitle from "../../../common/layouts/IconTitle";
import TagsRender from "../../../common/tags/tags";
import TextItem from "../../../common/text/TextItem";
import LineDivider from "../../../common/dividers/lineDivider";
import CardUtilities from "../../../common/helpers/cardUtilities";
import SampleCard from "../helpers/sampleCard";

const ShowreelCard = ({ profileData, editUrl, other }) => {
  // Desctruct profile data
  const { id: userData } = profileData.data ?? {};
  const { id: accountData } = userData.accData ?? {};
  const { reelDesc, rate, genre, areas, samples } = accountData ?? {};
  // Hooks
  const { t } = LanguageHook();

  if (profileData && userData.type !== "cast") {
    if (!reelDesc && other) return null;
    if (!reelDesc)
      return (
        <div className="card">
          <EmptyForm
            title="empty.showreel"
            button="action.upload"
            buttonIcon="upload"
            emptyIcon="shot"
            onClick={() =>
              hist.push(editUrl, {
                editData: { ...accountData },
              })
            }
          />
        </div>
      );
    return (
      <div className="card all-med-padd single-grid">
        <div className="flex justify-space-between full-width">
          <IconTitle
            content="title.showreel"
            icon="shot"
            iconSize="tiny"
            textClasses="sparag-hev text-upper dark text-zero-height"
            classes="align-center"
          />
          <CardUtilities
            editUrl={editUrl}
            edit={!other}
            editData={accountData}
          />
        </div>
        <div className="double-grid">
          <IconTitle
            content="field.daily-rate"
            textClasses="tiny-med text-upper dark"
            labelClasses="tiny-med"
            classes="align-center"
            label={`${rate} ${t("money.usd")}`}
          />
          <IconTitle
            content="field.genre"
            textClasses="tiny-med text-upper dark"
            labelClasses="tiny-med text-upper"
            classes="align-center"
            label={`genre.${genre}`}
          />
        </div>
        <TagsRender tags={areas} />
        <TextItem content={reelDesc} classes="tiny-lit" />
        <LineDivider />
        <TextItem content="title.work-samples" classes="tiny-med text-upper" />
        {samples.length === 0 ? (
          <EmptyForm
            title="empty.samples"
            button="action.add"
            buttonIcon="plus"
            onClick={() =>
              hist.push(editUrl, {
                editData: { ...accountData },
              })
            }
            fieldName="title.work-samples"
            noSubmit
          />
        ) : (
          <div className="double-grid">
            {samples.map((sample) => {
              return <SampleCard sample={sample} key={sample._id} />;
            })}
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default ShowreelCard;
