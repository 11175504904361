import React from "react";
// Components
import FloatIconButton from "../buttons/floatIconButton";
import LineDivider from "../dividers/lineDivider";

const SocialMedia = ({ iconSize, mobileApps }) => {
  return (
    <div className="flex not-dir">
      <div className="flex">
        <FloatIconButton
          name="behance"
          color="black"
          classes="all-sm-marg"
          size={iconSize}
          tooltip="social.behance"
          onClick={() => window.open("https://www.behance.net/leapstudios")}
        />
        <FloatIconButton
          name="facebook"
          color="black"
          classes="all-sm-marg"
          size={iconSize}
          tooltip="social.facebook"
          onClick={() => window.open("https://www.facebook.com/LEAPSTUDIOS/")}
        />
        <FloatIconButton
          name="instagram"
          color="black"
          classes="all-sm-marg"
          size={iconSize}
          tooltip="social.instagram"
          onClick={() => window.open("https://www.instagram.com/leap_studios/")}
        />
      </div>
      {/* <div className="flex">
        <LineDivider height={25} classes={`${!mobileApps && "sm-show"}`} />
        <FloatIconButton
          name="apple"
          color="black"
          classes={`all-sm-marg ${!mobileApps && "sm-show"}`}
          size={iconSize}
          tooltip="social.app-store"
        />
        <FloatIconButton
          name="google-play"
          color="black"
          classes={`all-sm-marg ${!mobileApps && "sm-show"}`}
          size={iconSize}
          tooltip="social.play-store"
        />
      </div> */}
    </div>
  );
};

export default SocialMedia;
