import React from "react";
// Helpers
import { apiServices } from "../../../../services/apiServices";
import { hist } from "../../../../utils/history";
// Components
import LinkButton from "../../../common/buttons/linkButton";
import ImageComponent from "../../../common/image/image";
import IconTitle from "../../../common/layouts/IconTitle";

const GalleryCard = ({
  profileData,
  fullGalleryUrl,
  currentStage,
  other,
  updateAccountData,
}) => {
  // Desctruct profile data
  const { id: userData } = profileData.data ?? {};
  const { id: accountData } = userData.accData ?? {};

  // Render image instance
  const ImageRender = ({ param, height }) => {
    return (
      <ImageComponent
        shape="square"
        fill
        minHeight={height ?? 130}
        param={param}
        preview
        size="med2"
        image={accountData.showcase && accountData.showcase[param]}
        classes={param}
        upload={!other && apiServices.cast.addShowcasePic}
        emptyState={{
          type: "icon",
          icon: "images",
          color: "black",
          classes: "opacity",
          text: `${userData.gender}Gallery.${param}`,
        }}
        name="field.showcase-picture"
        exportImage={(imageKey) => {
          if (!accountData.showcase) accountData.showcase = {};
          accountData.showcase[param] = imageKey;
          if (updateAccountData) updateAccountData(accountData);
        }}
      />
    );
  };

  if (profileData && userData.type === "cast")
    return (
      <div className="card all-med-padd single-grid">
        <div className="flex justify-space-between full-width">
          <IconTitle
            content="title.gallery"
            icon="images"
            iconSize="tiny"
            textClasses="sparag-hev text-upper dark"
            classes="align-center"
          />
          {fullGalleryUrl && currentStage >= 2 && (
            <LinkButton
              content="title.full-gallery"
              line
              classes="tiny-med"
              onClick={() =>
                hist.push(fullGalleryUrl, {
                  authId: other,
                })
              }
            />
          )}
        </div>
        <div className="gallery-grid grid grid-sm-gap full-width">
          <ImageRender param="img1" height={250} />
          <ImageRender param="img2" />
          <ImageRender param="img3" />
          <ImageRender param="img4" />
          <ImageRender param="img5" />
          <ImageRender param="img6" />
          <ImageRender param="img7" />
        </div>
      </div>
    );
  return null;
};

export default GalleryCard;
