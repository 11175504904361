import React from "react";
// Components
import { GetIcon, GetLogo } from "../../../assets/utils/IconGenerator";
import { LanguageHook } from "../../../utils/hooks";
import NormalButton from "../buttons/NormalButton";
import TextItem from "../text/TextItem";

const EmptyForm = ({
  title,
  button,
  buttonIcon,
  onClick,
  classes,
  emptyIcon,
  error,
  fieldName,
  noSubmit,
}) => {
  // Hooks
  const { t } = LanguageHook();

  return (
    <div className={`empty-form single-grid all-padd ${classes}`}>
      {emptyIcon ? (
        <GetIcon name={emptyIcon} size="giant" classes="justify-self-center" />
      ) : (
        <GetLogo name="empty" size="giant" classes="justify-self-center" />
      )}
      <TextItem content={title} classes="sparag-lit text-center" />
      {error && (
        <TextItem
          classes="error micro-lit text-center"
          content={`error.${error}`}
          params={{ field: t(fieldName) }}
        />
      )}
      {button && (
        <NormalButton
          icoPos="b"
          icon={buttonIcon}
          content={button}
          classes="justify-self-center"
          onClick={onClick}
          noSubmit={noSubmit}
        />
      )}
    </div>
  );
};

export default EmptyForm;
