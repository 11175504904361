import { apiHelper } from "../../utils/apiHelper";
const endPoint = "/casting";

export const casting = {
  getCasting,
  getCastingExp,
  addCasting,
  removeCasting,
  confirmCasting,
  exploreCastingProjects,
  acceptCasting,
  rejectCasting,
};

// Exec
function execPost(route, data) {
  apiHelper.post(endPoint + route, data);
}
function execPut(route, data) {
  apiHelper.put(endPoint + route, data);
}
function execGet(route, data) {
  apiHelper.get(endPoint + route, data);
}

// Functions
function getCasting(data) {
  execGet("/", data);
}
function getCastingExp(data) {
  execGet("/casting-exp", data);
}
function addCasting(data) {
  execPost("/", data);
}
function removeCasting(data) {
  execPost("/remove-casting", data);
}
function confirmCasting(data) {
  execPost("/confirm-casting", data);
}
function exploreCastingProjects(data) {
  execGet("/explore-proj", data);
}
function acceptCasting(data) {
  execPut("/accept-casting", data);
}
function rejectCasting(data) {
  execPut("/reject-casting", data);
}
