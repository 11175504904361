import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { apiServices } from "../../../../services/apiServices";
import {
  appendToFormData,
  generateFormData,
} from "../../../../utils/helperFunctions";
import { HandleApiHook } from "../../../../utils/hooks";
// Components
import FormButtons from "../../../common/buttons/FormButtons";
import FileField from "../../../common/fields/fileField";
import HeadTextField from "../../../common/fields/headTextField";
import TextAreaField from "../../../common/fields/TextAreaField";
import TopLoader from "../../../common/loaders/topLoader";
import ConfirmModal from "../../../common/modals/confirmModal";
import CustomModal from "../../../common/modals/customModal";

const AddWorkSampleModal = ({
  token,
  addSample,
  setAddSample,
  samples,
  updateLocationWorkSample,
}) => {
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [asset, setAsset] = useState(null);
  const [cancelWorkSample, setcancelWorkSample] = useState(false);

  // Reset form
  const resetForm = () => {
    setTitle("");
    setDesc("");
    setAsset(null);
  };

  // Submit work sample
  const submitWorkSample = (e) => {
    e.preventDefault();
    let formData = generateFormData("asset", asset);
    formData = appendToFormData(formData, "title", title);
    formData = appendToFormData(formData, "desc", desc);
    formData = appendToFormData(
      formData,
      "assetType",
      asset.type.split("/")[0]
    );
    submit({
      service: apiServices.third.uploadWorkSample,
      body: formData,
      query: {
        token,
      },
      onSuccess: (workSample) => {
        resetForm();
        setAddSample(false);
        updateLocationWorkSample([...samples, workSample]);
      },
      successMessage: "upload",
      params: { field: "title.work-sample" },
    });
  };

  return (
    <CustomModal
      icon="plus"
      title="title.add-work-sample"
      open={addSample}
      setOpen={setAddSample}
      onClose={resetForm}
    >
      <form
        onSubmit={submitWorkSample}
        className="single-grid all-med-padd"
        noValidate
      >
        {loading && <TopLoader />}
        <HeadTextField
          place="field.work-title"
          innerPlace="field.enter-work-title"
          value={title}
          error={getErrorItem("title")}
          onChange={setTitle}
        />
        <FileField
          place="title.asset"
          accept="ext.reel"
          type="videoOrImage"
          asset={asset}
          error={getErrorItem("asset")}
          onChange={setAsset}
          maxSize={50}
        />
        <TextAreaField
          place="field.desc"
          innerPlace="field.work-sample-desc"
          value={desc}
          error={getErrorItem("desc")}
          onChange={setDesc}
          rows={3}
          maxLength={300}
        />
        <FormButtons
          disabled={loading}
          classes="top-sm-marg"
          submitContent="action.add"
          icon="plus"
          onSubmit={submitWorkSample}
          onCancel={() => setcancelWorkSample(true)}
        />
        <ConfirmModal
          open={cancelWorkSample}
          setOpen={setcancelWorkSample}
          title="confirm.cancel-work-sample"
          talk="confirm.cancel-work-sample-effect"
          onAgree={() => {
            resetForm();
            setAddSample(false);
          }}
        />
      </form>
    </CustomModal>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(AddWorkSampleModal);
