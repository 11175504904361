import React, { useState } from "react";
// Packages
import { connect } from "react-redux";
// Helpers
import { apiServices } from "../../../../services/apiServices";
import { generateFormData } from "../../../../utils/helperFunctions";
import { HandleApiHook } from "../../../../utils/hooks";
// Components
import IconTitle from "../../../common/layouts/IconTitle";
import TopLoader from "../../../common/loaders/topLoader";
import AlbumUploader from "../../../common/upload/albumUploader";
import ImageComponent from "../../../common/image/image";
import ConfirmModal from "../../../common/modals/confirmModal";
import EmptyForm from "../../../common/error/empty";

const AlbumCard = ({ token, profileData, other }) => {
  // Desctruct profile data
  const { id: userData } = profileData.data;
  const { id: accountData } = userData.accData;
  // Hooks
  const { loading, submit } = HandleApiHook();
  const [album, setAlbum] = useState(
    accountData && accountData.gallery.length !== 0 ? accountData.gallery : []
  );
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [imageToBeRemoved, setImageToBeRemoved] = useState(null);

  // Add image to album
  const addImageToAlbum = (img) => {
    let albumSample = [...album, img];
    setAlbum(albumSample);
  };

  // After removing image
  const afterRemove = () => {
    let albumSample = [...album];
    albumSample = albumSample.filter((obj) => {
      return obj !== imageToBeRemoved;
    });
    setAlbum(albumSample);
    setImageToBeRemoved(null);
  };

  // Upload image
  const uploadAlbumImage = (file) => {
    submit({
      service: apiServices.cast.addGalleryPic,
      body: generateFormData("image", file),
      query: {
        token,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onSuccess: (imageKey) => {
        addImageToAlbum(imageKey);
      },
      successMessage: "upload",
      params: { field: "field.album-picture" },
    });
  };

  // Remove image from album
  const removeAlbumImage = () => {
    submit({
      service: apiServices.cast.removeGalleryPic,
      query: {
        token,
        url: imageToBeRemoved,
      },
      onSuccess: () => afterRemove(),
      successMessage: "removed",
      params: { field: "field.album-picture" },
    });
  };

  if (other && album.length === 0)
    return (
      <div className="card">
        <EmptyForm title="empty.album" />
      </div>
    );
  return (
    <div className="card all-med-padd single-grid">
      {loading && <TopLoader />}
      <IconTitle
        content="title.album"
        icon="images"
        iconSize="tiny"
        textClasses="sparag-hev text-upper dark"
        classes="align-center"
      />
      <div className="fifth-grid grid-sm-gap ">
        <AlbumUploader uploadAlbumImage={uploadAlbumImage} disabled={loading} />
        {album
          .slice(0)
          .reverse()
          .map((albumImage) => {
            return (
              <ImageComponent
                key={albumImage}
                shape="square"
                fill
                minHeight={150}
                preview
                size="med2"
                image={albumImage}
                emptyState={{
                  type: "icon",
                  icon: "images",
                  color: "black",
                  classes: "opacity",
                }}
                name="field.album-picture"
                onDelete={
                  !loading && !other
                    ? () => {
                        setImageToBeRemoved(albumImage);
                        setConfirmRemove(true);
                      }
                    : null
                }
              />
            );
          })}
      </div>
      <ConfirmModal
        open={confirmRemove}
        setOpen={setConfirmRemove}
        title="confirm.img-rem"
        talk="confirm.perm-rem"
        onAgree={removeAlbumImage}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(AlbumCard);
