import React from "react";
// Components
import LinkButton from "../buttons/linkButton";
import TextItem from "../text/TextItem";

const AssetDownloader = ({ title, asset, classes }) => {
  // Destruct asset
  const { name, size, url } = asset ?? {};

  return (
    <div className={`${classes}`}>
      <TextItem content={title} classes="sparag-hev" />
      <div className="flex justify-start">
        <TextItem content={name} classes="micro-med" normal />
        <div className="padd-dot"></div>
        <TextItem content={size} classes="micro-lit opacity" normal />
        <TextItem content="size.mb" classes="micro-lit opacity" />
        <div className="padd-dot"></div>
        {url ? (
          <LinkButton
            content="action.download"
            classes="micro-lit"
            line
            onClick={() =>
              window.open(`${process.env.REACT_APP_CDN_URL}/${url}`)
            }
          />
        ) : (
          <TextItem content="error.fileError" classes="micro-lit second" />
        )}
      </div>
    </div>
  );
};

export default AssetDownloader;
