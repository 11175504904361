import React from "react";
// Components
import IconTitle from "../../../common/layouts/IconTitle";
import CardUtilities from "../../../common/helpers/cardUtilities";

const IdentificationHeader = ({
  userData,
  editUrl,
  menuItems,
  other,
  limited,
}) => {
  return (
    <div className="flex-nowrap justify-space-between full-width">
      <div className="flex justify-start">
        <IconTitle
          content={
            limited
              ? userData.id
              : `${userData.firstName.trim()} ${userData.lastName.trim()}`
          }
          icon={userData.gender.toLowerCase().trim()}
          iconSize="tiny1"
          textClasses="parag-med text-start"
        />
      </div>
      <CardUtilities
        editUrl={editUrl}
        edit={!other}
        editData={userData}
        menuItems={menuItems}
        icon={userData.admin && "admin"}
        iconSize="tiny1"
      />
    </div>
  );
};

export default IdentificationHeader;
