export const genres = [
  {
    value: "Action",
    t: {
      en: "Action",
      ar: "أكشن",
    },
  },
  {
    value: "Adventure",
    t: {
      en: "Adventure",
      ar: "مغامرة",
    },
  },
  {
    value: "Animation",
    t: {
      en: "Animation",
      ar: "رسوم متحركة",
    },
  },
  {
    value: "Biography",
    t: {
      en: "Biography",
      ar: "سيرة شخصية",
    },
  },
  {
    value: "Comedy",
    t: {
      en: "Comedy",
      ar: "كوميديا",
    },
  },
  {
    value: "Crime",
    t: {
      en: "Crime",
      ar: "جريمة",
    },
  },
  {
    value: "Documentary",
    t: {
      en: "Documentary",
      ar: "وثائقي",
    },
  },
  {
    value: "Drama",
    t: {
      en: "Drama",
      ar: "دراما",
    },
  },
  {
    value: "Family",
    t: {
      en: "Family",
      ar: "عائلية",
    },
  },
  {
    value: "Fantasy",
    t: {
      en: "Fantasy",
      ar: "خيال",
    },
  },
  {
    value: "History",
    t: {
      en: "History",
      ar: "تاريخ",
    },
  },
  {
    value: "Horror",
    t: {
      en: "Horror",
      ar: "رعب",
    },
  },
  {
    value: "Musical",
    t: {
      en: "Musical",
      ar: "موسيقي",
    },
  },
  {
    value: "Mystery",
    t: {
      en: "Mystery",
      ar: "لغز",
    },
  },
  {
    value: "Romance",
    t: {
      en: "Romance",
      ar: "رومانسي",
    },
  },
  {
    value: "Sci-fi",
    t: {
      en: "Sci-fi",
      ar: "خيال علمي",
    },
  },
  {
    value: "Sport",
    t: {
      en: "Sport",
      ar: "رياضة",
    },
  },
  {
    value: "Thriller",
    t: {
      en: "Thriller",
      ar: "القصة المثيرة",
    },
  },
  {
    value: "War",
    t: {
      en: "War",
      ar: "حرب",
    },
  },
  {
    value: "Western",
    t: {
      en: "Western",
      ar: "غربي",
    },
  },
];
