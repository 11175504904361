import React from "react";
// Hooks
import { LanguageHook } from "../../../utils/hooks";
// Components
import { GetIcon } from "../../../assets/utils/IconGenerator";
import TextItem from "../text/TextItem";

const NormalButton = ({
  content,
  icon,
  radial,
  flip,
  disabled,
  onClick,
  classes,
  noSubmit,
  second,
}) => {
  // Hooks
  const { isRtl } = LanguageHook();

  // Classes
  const buttonClasses = `flex-nowrap fit-content outline-none box-sm-padd ${
    disabled ? "normal-btn-dis forbid" : "normal-btn scale-hover pointer"
  } ${radial ? "radial-edges" : ""} ${classes} ${
    second ? "white-back border-brand" : "brand-back border-none"
  }`;

  return (
    <button
      disabled={disabled}
      type={!noSubmit ? "submit" : "button"}
      onClick={onClick}
      className={buttonClasses}
    >
      <TextItem
        classes={`${
          disabled ? "disabled" : second ? "black" : "white"
        } side-med-marg sparag-med`}
        content={content}
      />
      {icon && (
        <GetIcon
          name={icon}
          color={second ? "brand" : "white"}
          size="tiny"
          disabled={disabled}
          classes={`${flip && isRtl ? "flip" : ""}`}
        />
      )}
    </button>
  );
};

export default NormalButton;
