import React, { useState, useRef } from "react";
// Components
import LoadingItems from "./helpers/loadingItems";
import SearchBox from "../search/searchBox";
import TagsRender from "../tags/tags";
import TextItem from "../text/TextItem";
import IconTitle from "./IconTitle";
import { getObject, setObject } from "../../../utils/StorageHelpers";

const ExploreItems = ({
  title,
  titleIcon,
  filterGroups,
  service,
  query,
  succ,
  fail,
  err,
  addToPage,
  filterName,
  loader,
  isEmpty,
  emptyTitle,
  result,
  loadMore,
  classes,
  observer,
  wait,
  noWait,
}) => {
  // Hooks
  const [search, setSearch] = useState(
    () => getObject(filterName)?.search || ""
  );
  const [searching, setSearching] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const [filters, setFilters] = useState(
    () => getObject(filterName)?.filters || {}
  );
  const [appliedFilters, setAppliedFilters] = useState([]);
  const firstRender = useRef(true);

  return (
    <div className={`single-grid ${classes}`}>
      <div className="noncard-container double-grid">
        <IconTitle
          content={title}
          icon={titleIcon}
          iconSize="tiny1"
          textClasses="parag-hev text-upper dark"
          classes="align-center"
        />
        <SearchBox
          action={() => {
            if (firstRender.current) firstRender.current = false;
            else setSearching(!searching);
          }}
          value={search}
          onChange={setSearch}
          loading={searchLoad}
          filterGroups={filterGroups}
          filters={filters}
          setFilters={setFilters}
          setAppliedFilters={setAppliedFilters}
          filterName={filterName}
        />
      </div>
      <div className="noncard-container">
        {appliedFilters.length !== 0 && (
          <TagsRender
            dark
            tags={appliedFilters}
            onRemove={(tag) => {
              let filtersSample = { ...filters };
              filtersSample[tag.name] = "";
              setObject(filterName, {
                search,
                filters: filtersSample,
              });
              setFilters(filtersSample);
            }}
            format={(tag) => (
              <TextItem content={tag.label} classes="sparag-med white" />
            )}
          />
        )}
      </div>
      <LoadingItems
        searching={searching}
        setSearchLoad={setSearchLoad}
        service={service}
        observer={observer}
        query={{
          ...query,
          filters,
          search,
        }}
        succ={succ}
        fail={fail}
        err={err}
        loader={loader}
        isEmpty={isEmpty}
        emptyTitle={emptyTitle}
        result={result}
        addToPage={addToPage}
        loadMore={loadMore}
        wait={wait}
        noWait={noWait}
      />
    </div>
  );
};

export default ExploreItems;
