import React from "react";
// Helpers
import history from "../../../utils/history";
// Components
import LinkButton from "../../common/buttons/linkButton";
import TextItem from "../../common/text/TextItem";
import { GetIcon } from "../../../assets/utils/IconGenerator";

const AccountDeactivated = ({ organization }) => {
  return (
    <div className="single-grid">
      <TextItem content="title.acc-deac" classes="shead-hev text-center" />
      <GetIcon name="sad" size="giant2" classes="margin-auto" />
      <TextItem content="desc.deac" classes="sparag-lit text-center" />
      <LinkButton
        content="title.back-home"
        line
        classes="margin-auto micro-lit"
        onClick={() =>
          history.replace(organization ? "/organization/auth" : "/")
        }
      />
    </div>
  );
};

export default AccountDeactivated;
