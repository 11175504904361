import React from "react";
import "./App.scss";
// Components
import Home from "./components";
import HeaderProps from "./components/common/headerProps";
// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
// Themes
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./layout/MUIstyles";
import "./utils/moment";
import { requestPermission } from "./utils/FirebaseHelper";

function App() {
  requestPermission();
  
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <HeaderProps />
        <Home />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
