import React, { useState } from "react";
import IconTitle from "../../../common/layouts/IconTitle";
import ConfirmedCard from "../helpers/confirmedCard";
import NotChosenCastingCard from "../helpers/notChosenCastingCard";
import NotConfirmedCard from "../helpers/notConfirmedCard";

const SingleCastingCard = ({
  castingData,
  type,
  icon,
  confirmCasting,
  confirmLoading,
}) => {
  const getNotConfirmedCasting = () => {
    if (castingData) {
      if (castingData.casting.length === 0) return null;
      return castingData.casting.find(
        (castingItem) => castingItem.type === type
      );
    }
    return null;
  };

  // Hooks
  const [notConfirmed] = useState(getNotConfirmedCasting());

  const CardContainer = ({ children }) => {
    return (
      <div className="single-grid card all-med-padd">
        <IconTitle
          content={`title.${type}`}
          icon={icon}
          textClasses="sparag-hev"
        />
        <div className="hor-div-full zero-marg-imp"></div>
        {children}
      </div>
    );
  };

  if (castingData[type])
    return (
      <CardContainer>
        <ConfirmedCard
          type={type}
          confirmedData={castingData[type]}
          project={castingData.project}
        />
      </CardContainer>
    );
  if (notConfirmed)
    return (
      <CardContainer>
        <NotConfirmedCard
          type={type}
          notConfirmedData={notConfirmed}
          projectId={castingData.project._id}
          confirmCasting={confirmCasting}
          confirmLoading={confirmLoading}
        />
      </CardContainer>
    );

  return (
    <NotChosenCastingCard
      type={type}
      selectType="single"
      projectId={castingData.project._id}
      icon={icon}
    />
  );
};

export default SingleCastingCard;
