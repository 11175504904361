import React, { useState } from "react";
// Packages
import { connect } from "react-redux";
// Components
import NormalButton from "../../common/buttons/NormalButton";
import LineTextField from "../../common/fields/LineTextField";
import TopLoader from "../../common/loaders/topLoader";
import TextItem from "../../common/text/TextItem";
// Helpers
import history from "../../../utils/history";
import { reduxActions } from "../../../redux/actions";
import { apiServices } from "../../../services/apiServices";
import { HandleApiHook, LanguageHook } from "../../../utils/hooks";

const ForgotPassword = ({ addEmail }) => {
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const { language } = LanguageHook();
  // Fields
  const [email, setEmail] = useState("");

  // Submit
  const submitForgot = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.auth.forgotPass,
      query: {
        email,
        language,
      },
      onSuccess: () => {
        addEmail(email);
        history.replace("/recover-sent");
      },
    });
  };

  return (
    <form
      onSubmit={submitForgot}
      className="forgot-pass single-grid"
      noValidate
    >
      {loading && <TopLoader />}
      <TextItem classes="shead-hev text-center" content="title.forgot-pass" />
      <TextItem
        classes="tiny-lit top-med-padd text-start"
        content="desc.forgot-pass"
      />
      <LineTextField
        name="email"
        place="field.enter-mail"
        icon="mail"
        value={email}
        error={getErrorItem("email")}
        autoFocus
        type="email"
        onChange={setEmail}
      />
      <NormalButton
        content="action.reset-pass"
        disabled={loading}
        classes="full-width-imp top-marg bottom-med-marg"
      />
    </form>
  );
};

const mapDispatchToProps = {
  addEmail: reduxActions.emailActions.addEmail,
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
