import { apiHelper } from "../../utils/apiHelper";
const endPoint = "/project";

export const proj = {
  initiateRequest,
  editRequest,
  uploadImage,
  getProjectProfile,
  getIdCard,
  getProfileHandler,
  exploreSubProjects,
  getTabManu,
  getProjectLayout,
};

// Exec
function execPost(route, data) {
  apiHelper.post(endPoint + route, data);
}
function execPut(route, data) {
  apiHelper.put(endPoint + route, data);
}
function execGet(route, data) {
  apiHelper.get(endPoint + route, data);
}

// functions
function initiateRequest(data) {
  execPost("/", data);
}
function editRequest(data) {
  execPut("/", data);
}
function uploadImage(data) {
  execPost("/image", data);
}
function getProjectProfile(data) {
  execGet("/", data);
}
function getIdCard(data) {
  execGet("/id-card", data);
}
function getProfileHandler(data) {
  execGet("/profile-handler", data);
}
function exploreSubProjects(data) {
  execGet("/explore", data);
}
function getProjectLayout(data) {
  execGet("/menu", data);
}
function getTabManu(data) {
  execGet("/tab-menu", data);
}
