import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxActions } from "../../../redux/actions";
import { apiServices } from "../../../services/apiServices";
import { projectRoles } from "../../../utils/constants";
import history from "../../../utils/history";
import { HandleApiHook } from "../../../utils/hooks";
import FetchUiData from "../../common/layouts/fetchUiData";
import TopLoader from "../../common/loaders/topLoader";
import ConfirmModal from "../../common/modals/confirmModal";
import ProjectCastingSkeleton from "../skeleton/projectCastingSkeleton";
import MultipleCastingCard from "./cards/multipleCastingCard";
import SingleCastingCard from "./cards/singleCastingCard";

const ProjectCasting = ({
  token,
  castingId,
  dataType,
  type,
  changeTab,
  accId,
}) => {
  // Hooks
  const { loading, error, setError, submit } = HandleApiHook(true);
  const { loading: confirmLoading, submit: submitConfirm } = HandleApiHook();
  const [castingData, setCastingData] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmQuery, setConfirmQuery] = useState({});

  // Check project id
  useEffect(() => {
    if (!castingId) history.goBack();
  }, [castingId]);

  // Fetch project profile
  const fetchCastingData = () => {
    submit({
      service: apiServices.casting.getCasting,
      query: { token, casting: castingId },
      onSuccess: (castingDataResult) => {
        changeTab(castingDataResult.project.name.trim());
        setCastingData(castingDataResult);
        if (
          projectRoles[dataType ?? type].casting === "none" ||
          (dataType && !castingDataResult[dataType]) ||
          (dataType &&
            castingDataResult[dataType] &&
            castingDataResult[dataType]._id !== accId)
        )
          history.replace(`/project/${castingDataResult.project._id}`);
      },
    });
  };

  // Prepare confirm casting
  const confirmCasting = (query) => {
    setConfirmQuery(query);
    setConfirmOpen(true);
  };

  // Confirm casting
  const confirmCastingApply = () => {
    submitConfirm({
      service: apiServices.casting.confirmCasting,
      body: {
        token,
        proj: castingData.project._id,
        ...confirmQuery,
      },
      onSuccess: (response) => {
        let castingDataSample = { ...castingData };
        if (response.type === "dir") castingDataSample.dir = response.info;
        else if (response.type === "dop") castingDataSample.dop = response.info;
        else {
          castingDataSample.casting = castingDataSample.casting.filter(
            (x) => x._id !== response.castingId
          );
          castingDataSample[response.type] = response.info;
        }
        setCastingData(castingDataSample);
      },
    });
  };

  return (
    <FetchUiData
      service={fetchCastingData}
      observer={castingId}
      loading={loading}
      dataFetched={castingData}
      error={error}
      setError={setError}
      Loader={ProjectCastingSkeleton}
      classes="single-grid bottom-extend top-extend-fill"
      noWait
    >
      {confirmLoading && <TopLoader />}
      {castingData?.isDir && dataType !== "dir" && (
        <SingleCastingCard
          castingData={castingData}
          confirmCasting={confirmCasting}
          confirmLoading={confirmLoading}
          type="dir"
          icon="director"
        />
      )}
      {castingData?.isDop && dataType !== "dop" && (
        <SingleCastingCard
          castingData={castingData}
          confirmCasting={confirmCasting}
          confirmLoading={confirmLoading}
          type="dop"
          icon="user-camera"
        />
      )}
      <MultipleCastingCard
        castingData={castingData}
        confirmCasting={confirmCasting}
        confirmLoading={confirmLoading}
        type="cast"
        icon="user-group"
      />
      <MultipleCastingCard
        castingData={castingData}
        confirmCasting={confirmCasting}
        confirmLoading={confirmLoading}
        type="photographer"
        icon="camera"
      />
      <MultipleCastingCard
        castingData={castingData}
        confirmCasting={confirmCasting}
        confirmLoading={confirmLoading}
        type="artDirector"
        icon="art-director"
      />
      <MultipleCastingCard
        castingData={castingData}
        confirmCasting={confirmCasting}
        confirmLoading={confirmLoading}
        type="food"
        icon="food"
      />
      <MultipleCastingCard
        castingData={castingData}
        confirmCasting={confirmCasting}
        confirmLoading={confirmLoading}
        type="hair"
        icon="hair"
      />
      <MultipleCastingCard
        castingData={castingData}
        confirmCasting={confirmCasting}
        confirmLoading={confirmLoading}
        type="woredrope"
        icon="woredrope"
      />
      <MultipleCastingCard
        castingData={castingData}
        confirmCasting={confirmCasting}
        confirmLoading={confirmLoading}
        type="makup"
        icon="makup"
      />
      <ConfirmModal
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title="confirm.confirm-casting"
        talk="confirm.confirm-casting-talk"
        onAgree={confirmCastingApply}
      />
    </FetchUiData>
  );
};

const mapStateToProps = (state) => {
  return {
    accId: state.auth.accId,
    token: state.auth.token,
    dataType: state.auth.dataType,
    type: state.auth.type,
  };
};

const mapDispatchToProps = {
  changeTab: reduxActions.tabTitleActions.changeTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCasting);
