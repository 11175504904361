import { emailTypes } from "./emailTypes";

export function email(state = {}, action) {
  switch (action.type) {
    case emailTypes.ADD_EMAIL:
      return {
        email: action.email,
      };
    case emailTypes.CLEAR_EMAIL:
      return {};
    default:
      return state;
  }
}
