import React from "react";
import { hist } from "../../../../utils/history";
import FloatIconButton from "../../../common/buttons/floatIconButton";
import IconTitle from "../../../common/layouts/IconTitle";
import ConfirmedCard from "../helpers/confirmedCard";
import NotChosenCastingCard from "../helpers/notChosenCastingCard";
import NotConfirmedCard from "../helpers/notConfirmedCard";
import { isEmpty } from "lodash";

const MultipleCastingCard = ({
  castingData,
  type,
  icon,
  confirmCasting,
  confirmLoading,
}) => {
  const getNotConfirmedCasting = () => {
    if (castingData) {
      if (castingData.casting.length === 0) return null;
      return castingData.casting.find(
        (castingItem) => castingItem.type === type
      );
    }
    return null;
  };

  if (castingData[type].length === 0 && isEmpty(getNotConfirmedCasting()))
    return (
      <NotChosenCastingCard
        type={type}
        selectType="multiple"
        projectId={castingData.project._id}
        icon={icon}
      />
    );

  return (
    <div className="single-grid card all-med-padd">
      <div className="flex-nowrap justify-space-between full-width">
        <IconTitle
          content={`title.${type}`}
          icon={icon}
          textClasses="sparag-hev"
        />
        <FloatIconButton
          name="plus"
          onClick={() =>
            hist.push(`/project/${castingData.project._id}/casting/explore`, {
              type,
              projectId: castingData.project._id,
            })
          }
        />
      </div>
      <div className="hor-div-full zero-marg-imp"></div>
      {castingData[type].map((confirmedCast, index) => (
        <div key={confirmedCast._id}>
          <ConfirmedCard
            type={type}
            confirmedData={confirmedCast}
            project={castingData.project}
          />
          <div className="hor-div-full"></div>
        </div>
      ))}
      {castingData.casting?.map(
        (pendingCast, index) =>
          pendingCast.type === type && (
            <div key={pendingCast._id}>
              <NotConfirmedCard
                type={type}
                notConfirmedData={pendingCast}
                projectId={castingData.project._id}
                confirmCasting={confirmCasting}
                confirmLoading={confirmLoading}
              />
              <div className="hor-div-full zero-marg-imp"></div>
            </div>
          )
      )}
    </div>
  );
};

export default MultipleCastingCard;
