import React, { useEffect } from "react";
// Helpers
import { Switch, Route } from "react-router-dom";
import { reduxActions } from "../../../redux/actions";
import { connect } from "react-redux";
import history from "../../../utils/history";
// Components
import AccountActivated from "../../leap/issue/accActive";
import AccountAlreadyActive from "../../leap/issue/accAlreadyActive";
import MainLogo from "../../common/logo/mainLogo";
import CopyRight from "../../common/footer/CopyRight";

const IssueHome = ({ clearEmail }) => {
  useEffect(() => {
    clearEmail();
  }, []);
  return (
    <div className="single-canvas">
      <div className="single-grid grid-center">
        <MainLogo onClick={() => history.push("/")} />
      </div>
      <div className="padding-card shadow flex">
        <div className="start-content">
          <Switch>
            <Route
              path="/organization/issue/already-activated"
              component={AccountAlreadyActive}
            />
            <Route
              path="/organization/issue/acc-activated"
              component={AccountActivated}
            />
          </Switch>
        </div>
      </div>
      <CopyRight classes="text-center vertical-med-padd" />
    </div>
  );
};

const mapDispathToProps = {
  clearEmail: reduxActions.emailActions.clearEmail,
};

export default connect(null, mapDispathToProps)(IssueHome);
