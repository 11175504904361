import React, { useState } from "react";
// Packages
import { connect } from "react-redux";
// Components
import LinkText from "../../common/buttons/linkText";
import NormalButton from "../../common/buttons/NormalButton";
import LineTextField from "../../common/fields/LineTextField";
import TopLoader from "../../common/loaders/topLoader";
import TextItem from "../../common/text/TextItem";
// Helpers
import history, { hist } from "../../../utils/history";
import { apiServices } from "../../../services/apiServices";
import { reduxActions } from "../../../redux/actions";
// Hooks
import { HandleApiHook } from "../../../utils/hooks";
import { getFirebaseToken } from "../../../utils/FirebaseHelper";

const SignIn = ({ addEmail, login, organization, auth }) => {
  // Hooks
  const { loading, submit } = HandleApiHook();
  // Fields
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Submit
  const submitLogin = async (e) => {
    e.preventDefault();
    const notificationToken = await getFirebaseToken();
    submit({
      service: apiServices.auth[organization ? "loginOrg" : "login"],
      body: {
        email,
        password,
        notificationToken
      },
      query: {
        token: auth ? auth.token : "",
      },
      onSuccess: (data) => login(data.token, data.auth, data.notificationToken),
      onFail: (data) => {
        if (data.target) {
          if (data.target === "activate") {
            addEmail(data.email);
            hist.push(
              organization
                ? "/organization/auth/email-confirm"
                : "/email-confirm",
              {
                allowEdit: false,
                token: data.token,
              }
            );
          }
          if (data.target === "org-deactivate")
            hist.push(
              organization
                ? "/organization/auth/org-deactivated"
                : "/org-deactivated"
            );
          if (data.target === "deactivate")
            hist.push(
              organization ? "/organization/auth/deactivated" : "/deactivated"
            );
        }
      },
    });
  };

  return (
    <form onSubmit={submitLogin} className="single-grid" noValidate>
      {loading && <TopLoader />}
      <TextItem classes="shead-hev text-center" content="title.sign-in" />
      <TextItem classes="tiny-lit text-center" content="desc.sign-in" />
      <LineTextField
        value={email}
        onChange={setEmail}
        autoFocus
        icon="user"
        place="field.email-username"
        type="email"
      />
      <LineTextField
        value={password}
        onChange={setPassword}
        password
        link={!organization ? "title.forgot-pass?" : ""}
        icon="closed-lock"
        place="field.password"
        onLinkPress={
          !organization ? () => history.push("/forgot-password") : null
        }
      />
      <NormalButton
        content="action.log-in"
        disabled={loading}
        classes="full-width-imp top-marg"
      />
      {!organization && (
        <LinkText
          text="desc.sign-up"
          link="title.sign-up"
          classes="top-med-padd text-center"
          onClick={() => history.push("/sign-up")}
        />
      )}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = {
  addEmail: reduxActions.emailActions.addEmail,
  login: reduxActions.authActions.login,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
