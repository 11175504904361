import React from "react";
// Packages
import { Switch } from "react-router-dom";
// Components
import PrivateRoute from "../../routes/privateRoute";
import ThirdApprove from "./approve/approve";
import ExploreThird from "./explore/explore";
import ThirdStart from "./start/thirdStart";

const ThirdHome = () => {
  return (
    <Switch>
      <PrivateRoute
        path="/third/start"
        component={ThirdStart}
        navigationKey="start"
      />
      <PrivateRoute
        path="/third/approve"
        component={ThirdApprove}
        navigationKey="approve"
      />
      <PrivateRoute
        path="/third/explore"
        component={ExploreThird}
        navigationKey="explore"
      />
    </Switch>
  );
};

export default ThirdHome;
