import React from "react";

// Components & Helpers
import { GetIcon, GetLogo } from "../../../assets/utils/IconGenerator";
import { LanguageHook } from "../../../utils/hooks";
import TooltipContainer from "../tooltip/Tooltip";

const FloatIconButton = ({
  name,
  size,
  color,
  logo,
  onClick,
  classes,
  disabled,
  iconClasses,
  tooltip,
  placement,
  flip,
}) => {
  // Hooks
  const { isRtl } = LanguageHook();

  // Float ison button content
  const content = () => {
    return (
      <div
        onClick={!disabled && onClick}
        className={`float-icon-button pointer flex fit-content ${classes}`}
      >
        {logo ? (
          <GetLogo
            name={name}
            size={size ?? "tiny"}
            disabled={disabled}
            classes={`${flip && isRtl ? "flip" : ""} ${iconClasses}`}
          />
        ) : (
          <GetIcon
            name={name}
            size={size ?? "tiny"}
            color={color ?? "brand"}
            disabled={disabled}
            classes={`${flip && isRtl ? "flip" : ""} ${iconClasses}`}
          />
        )}
      </div>
    );
  };

  if (!tooltip) return content();
  return (
    <TooltipContainer
      tooltip={tooltip}
      placement={placement}
      content={content}
    />
  );
};

export default FloatIconButton;
