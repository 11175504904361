import React from "react";
import { GetIcon } from "../../../../assets/utils/IconGenerator";
import TextItem from "../../../common/text/TextItem";

const InviteCardAvatar = ({ castingData }) => {
  // Destruct casting data
  const { chosenAs, type } = castingData ?? {};

  // Is Hero
  const isHero = () => chosenAs === "hero";

  // if Final
  if (type === "final")
    return (
      <GetIcon
        name="check-circle"
        color="brand"
        size="small"
        classes="justify-self-center all-med-marg"
      />
    );

  return (
    <div
      className={`flex flex-column vertical-med-padd ${
        isHero() ? "brand-back" : "orange-back"
      }`}
    >
      <GetIcon
        name={`${isHero() ? "crown" : "refresh"}`}
        color="white"
        size="small"
        classes="bottom-sm-marg"
      />
      <TextItem
        content={`casting.${isHero() ? "hero" : "back"}`}
        classes="sparag-hev white"
      />
    </div>
  );
};

export default InviteCardAvatar;
