import React from "react";
import AvatarSkeleton from "../../common/loaders/skeleton/avatarSkeleton";
import LineSkeleton from "../../common/loaders/skeleton/lineSkeleton";

const OrganizationCardSkeleton = () => {
  return (
    <div className="card one-third-grid-resp">
      <AvatarSkeleton fill shape="square" minHeight={100} />
      <LineSkeleton rows={2} title classes="all-med-padd" />
    </div>
  );
};

export default OrganizationCardSkeleton;
