import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { apiServices } from "../../../../../services/apiServices";
import { hist } from "../../../../../utils/history";
import { HandleApiHook, LanguageHook } from "../../../../../utils/hooks";
// Components
import ImageComponent from "../../../image/image";
import CircleLoader from "../../../loaders/circleLoader";
import TextItem from "../../../text/TextItem";
import IconMenu from "../../../menu/iconMenu";
import TopLoader from "../../../loaders/topLoader";
import ConfirmModal from "../../../modals/confirmModal";

const SubAccountRow = ({ data, accId, isAdmin, token }) => {
  // Destruct Row Data
  const {
    auth,
    _id: subId,
    org,
    admin,
    image,
    firstName,
    lastName,
    title,
    number,
  } = data ?? {};
  const { _id: authId, status, initial, email } = auth ?? {};
  // Hooks
  const { language } = LanguageHook();
  const { loading, submit } = HandleApiHook();
  const [rowAdmin, setRowAdmin] = useState(admin);
  const [rowStatus, setRowStatus] = useState(status);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);

  // Menu Items
  const menuItems = [
    initial && {
      text: `action.${!rowStatus ? "activate" : "deac"}`,
      icon: !rowStatus ? "check-circle" : "close",
      iconColor: !rowStatus ? "brand" : "second",
      onClick: () => {
        setConfirmTitle(!rowStatus ? "activate" : "deactivate");
        setConfirmModal(true);
      },
    },
    initial && {
      text: `action.${!rowAdmin ? "make-admin" : "rem-admin"}`,
      icon: !rowAdmin ? "admin" : "remove-user",
      iconColor: !rowAdmin ? "brand" : "second",
      onClick: () => {
        setConfirmTitle(!rowAdmin ? "make-admin" : "remove-admin");
        setConfirmModal(true);
      },
    },
    !initial && {
      text: "action.resend-email",
      icon: "send",
      iconColor: "brand",
      onClick: () => resendEmail(),
    },
  ];

  // Change admin status
  const changeAdminStatus = () => {
    submit({
      service: apiServices.subAcc.changeAdmin,
      body: {
        subId,
        admin: rowAdmin,
      },
      query: {
        token,
      },
      onSuccess: () => setRowAdmin(!rowAdmin),
    });
  };

  // Change status
  const changeStatus = () => {
    submit({
      service: apiServices.auth.changeStatus,
      body: {
        authId,
        status: rowStatus,
      },
      query: {
        token,
      },
      onSuccess: () => setRowStatus(!rowStatus),
    });
  };

  // Resend email
  const resendEmail = () => {
    submit({
      service: apiServices.subAcc.resendNewSub,
      body: {
        subId,
        language,
      },
      query: {
        token,
      },
    });
  };

  return (
    <div
      className={`sub-row grid side-sm-padd vertical-med-marg radial-edges card ${
        !rowStatus && "border-second"
      }`}
    >
      {loading && <TopLoader />}
      <ImageComponent
        size="big"
        image={image}
        emptyState={{
          type: "icon",
          icon: "user",
          color: "black",
          classes: "opacity",
        }}
        tag={rowAdmin ? { icon: "admin" } : null}
        classes="align-self-center vertical-sm-marg"
      />
      <div className="name vertical-med-padd align-self-center single-grid grid-sm-gap">
        <TextItem
          content={`${firstName} ${lastName}`}
          classes={`sparag-hev fit-content ${
            initial && "text-underline-hover pointer"
          }`}
          onClick={
            initial
              ? () =>
                  hist.push("/sub-account/explore/profile", {
                    authId,
                    subId,
                  })
              : null
          }
          normal
        />
        <div className="flex justify-start">
          <TextItem content={title} classes="micro-lit" normal />
          <div className="padd-dot sm-show"></div>
          <TextItem
            content={`status.${
              !initial ? "pend" : rowStatus ? "active" : "deac"
            }`}
            classes={`micro-hev ${
              !initial ? "pend" : rowStatus ? "active" : "deactivated"
            } align-self-center sm-show`}
          />
        </div>
      </div>
      <div className="phone vertical-med-padd align-self-center">
        <TextItem content={number ?? "_"} classes="not-dir tiny-lit" normal />
      </div>
      <TextItem
        content={email}
        classes="email vertical-med-padd tiny-lit align-self-center"
        normal
      />
      <TextItem
        content={`status.${!initial ? "pend" : rowStatus ? "active" : "deac"}`}
        classes={`micro-hev ${
          !initial ? "pend" : rowStatus ? "active" : "deactivated"
        } status align-self-center`}
      />
      {loading && (
        <CircleLoader
          size="small"
          classes="vertical-med-padd align-self-center"
        />
      )}
      {accId !== subId && isAdmin && !loading && (
        <IconMenu
          icon="dots"
          color="black"
          size="tiny"
          classes="align-self-center"
          menuItems={menuItems}
          placement="right"
        />
      )}
      <ConfirmModal
        open={confirmModal}
        setOpen={setConfirmModal}
        title={`confirm.${confirmTitle}`}
        onAgree={() => {
          if (confirmTitle === "activate" || confirmTitle === "deactivate")
            changeStatus();
          else changeAdminStatus();
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    accId: state.auth.accId,
  };
};

export default connect(mapStateToProps)(SubAccountRow);
