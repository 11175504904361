import React, { useState } from "react";
// Helpers
import { apiServices } from "../../../../services/apiServices";
import { HandleApiHook } from "../../../../utils/hooks";
// Components
import FormButtons from "../../../common/buttons/FormButtons";
import HeadTextField from "../../../common/fields/headTextField";
import TopLoader from "../../../common/loaders/topLoader";
import SettingItem from "../helpers/settingItem";

const UsernameSettingCard = ({ username, changeUsername, token }) => {
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [modal, setModal] = useState(false);
  const [newUsername, setNewUsername] = useState("");

  // Submit username change
  const submitEmailChange = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.auth.changeUsername,
      body: {
        username: newUsername,
      },
      query: {
        token,
      },
      onSuccess: (savedUsername) => {
        changeUsername(savedUsername);
        setNewUsername("");
        setModal(false);
      },
      successMessage: "change",
      params: { field: "field.username" },
    });
  };

  // Modal content
  const modalContent = () => {
    return (
      <form
        onSubmit={submitEmailChange}
        className="single-grid all-med-padd"
        noValidate
      >
        {loading && <TopLoader />}
        <HeadTextField
          name="username"
          place="field.username"
          innerPlace="field.enter-username"
          value={newUsername}
          error={getErrorItem("username")}
          autoFocus
          onChange={setNewUsername}
        />
        <FormButtons
          disabled={loading}
          onCancel={() => {
            setNewUsername("");
            setModal(false);
          }}
          submitContent="action.changeL"
          classes="top-sm-padd"
        />
      </form>
    );
  };

  return (
    <SettingItem
      icon="user"
      title="setting.username"
      talkValue={username}
      modal={modal}
      setModal={setModal}
      modalContent={modalContent}
      setValue={setNewUsername}
    />
  );
};

export default UsernameSettingCard;
