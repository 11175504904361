import React from "react";
// Components
import { GetIcon } from "../../../../assets/utils/IconGenerator";
// Hooks
import { LanguageHook } from "../../../../utils/hooks";

const ImageTag = ({ tag, shape }) => {
  // Hooks
  const { isRtl } = LanguageHook();

  return (
    <div
      className={`absolute ${
        isRtl
          ? `bottom-left-${shape === "circle" ? "zero" : "sm"}-abs`
          : `bottom-right-${shape === "circle" ? "zero" : "sm"}-abs`
      }`}
    >
      {tag && <GetIcon name={tag?.icon} size={tag?.size ?? "tiny"} />}
    </div>
  );
};

export default ImageTag;
