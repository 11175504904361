import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { reduxActions } from "../../../redux/actions";
import { HandleApiHook } from "../../../utils/hooks";
import { apiServices } from "../../../services/apiServices";
import history from "../../../utils/history";
// Components
import TopLoader from "../../common/loaders/topLoader";
import TextItem from "../../common/text/TextItem";
import ImageComponent from "../../common/image/image";
import TextAreaField from "../../common/fields/TextAreaField";
import FormButtons from "../../common/buttons/FormButtons";
import ConfirmModal from "../../common/modals/confirmModal";
import RadioGroup from "../../common/radio/radioGroup";
import { knowledgeTypes } from "../../../utils/constants";
import IconTitle from "../../common/layouts/IconTitle";

const WelcomeStart = ({ type, token, image, addImage, incStage, logout }) => {
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [about, setAbout] = useState("");
  const [knowledge, setKnowledge] = useState("facebook");
  const [logoutModal, setLogoutModal] = useState(false);

  // Submit welcome
  const submitWelcome = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.auth.welcomeUpdate,
      body: {
        about,
        image,
        ...(type === "3rd" ? { knowledge } : {}),
      },
      query: {
        token,
      },
      onSuccess: () => {
        incStage();
        history.replace(
          type === "acc"
            ? "/sub-account/start/personal"
            : "/third/start/personal"
        );
      },
    });
  };

  return (
    <form onSubmit={submitWelcome} className="single-grid">
      {loading && <TopLoader />}
      <TextItem classes="shead-hev" content="title.welcome" />
      <TextItem classes="sparag-lit text-start" content="desc.acc-welc" />
      <div className="double-grid">
        <TextItem
          content="desc.third-pic"
          classes="tiny-lit align-self-center opacity"
        />
        <ImageComponent
          name="field.profile-picture"
          size="monster4"
          image={image}
          upload={
            type === "3rd"
              ? apiServices.third.addProfilePic
              : apiServices.subAcc.addProfilePic
          }
          preview
          emptyState={{
            type: "icon",
            icon: "user",
            color: "black",
            classes: "opacity",
          }}
          exportImage={addImage}
          classes="align-self-center justify-self-center"
        />
      </div>
      {type === "3rd" && (
        <>
          <IconTitle icon="social" content="title.knowledge" />
          <RadioGroup
            onChange={setKnowledge}
            group={knowledgeTypes}
            defVal={knowledge}
          />
        </>
      )}
      <TextAreaField
        place="field.about"
        innerPlace="field.say-about"
        value={about}
        error={getErrorItem("about")}
        onChange={setAbout}
        rows={4}
        maxLength={500}
      />
      <FormButtons
        disabled={loading}
        onCancel={() => setLogoutModal(true)}
        submitContent="action.next"
        cancelContent="action.log-out"
        icon="right-arrow"
        classes="top-med-marg"
        flip
      />
      <ConfirmModal
        open={logoutModal}
        setOpen={setLogoutModal}
        title="confirm.logout"
        onAgree={logout}
      />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.auth.type,
    token: state.auth.token,
    image: state.auth.image,
  };
};

const mapDispathToProps = {
  addImage: reduxActions.authActions.addImage,
  incStage: reduxActions.authActions.incStage,
  logout: reduxActions.authActions.logout,
};

export default connect(mapStateToProps, mapDispathToProps)(WelcomeStart);
