import React from "react";
// Packages
import { Skeleton } from "antd";

const AvatarSkeleton = ({
  fill,
  size,
  shape,
  minHeight,
  minWidth,
  classes,
  disable,
}) => {
  return (
    <div
      className={`${fill ? "full-height full-width" : "fit-content"} icon-${
        fill ? "" : size ? size : "tiny"
      } ${classes} relative`}
      style={{ minHeight: minHeight, minWidth: minWidth }}
    >
      <Skeleton.Avatar
        shape={shape ?? "circle"}
        className="absolute full-width-imp full-height"
        active={!disable}
      />
    </div>
  );
};

export default AvatarSkeleton;
