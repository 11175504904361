import React, { useState, useEffect } from "react";
// Packages
import { Input, Select } from "antd";
// Helpers
import { country } from "../../../assets/const/country";
import { LanguageHook } from "../../../utils/hooks";
import { GetFlag } from "../../../assets/utils/IconGenerator";
// Components
import FieldHeader from "./components/FieldHeader";
import FieldFooter from "./components/FieldFooter";
import { getPhoneDial } from "../../../utils/constHelper";
// SubImports
const { Option } = Select;

const PhoneField = ({
  place,
  label,
  value,
  error,
  disabled,
  onChange,
  autoFocus,
  defaultCode,
}) => {
  // Hooks
  const { t, isRtl } = LanguageHook();
  const [code, setCode] = useState("sa");
  const [number, setNumber] = useState(value ? value.split(" ")[1] : "");

  // Setting default code
  useEffect(async () => {
    await setCode(defaultCode ?? "sa");
    onChange(number ? `${getPhoneDial(defaultCode ?? "sa")} ${number}` : "");
  }, [defaultCode]);

  // Handle changing number
  const handleNumberChange = (numberValue) => {
    setNumber(numberValue);
    onChange(numberValue ? `${getPhoneDial(code)} ${numberValue}` : "");
  };

  // Handle code change
  const handleCodeChange = (codeValue) => {
    setCode(codeValue);
    onChange(number ? `${getPhoneDial(codeValue)} ${number}` : "");
  };

  return (
    <div className="full-width relative">
      {(place || label) && (
        <FieldHeader
          head={place}
          label={label}
          disabled={disabled}
          error={error}
        />
      )}
      <div
        className={`phone-field not-dir ${
          disabled ? "phone-disabled-field" : ""
        } ${error && !disabled ? "phone-error-field" : ""}`}
      >
        <Select
          showSearch
          disabled={disabled}
          onChange={handleCodeChange}
          defaultValue={code}
          value={code}
          style={{ fontFamily: isRtl ? "Almarai" : "Avenir" }}
        >
          {country.map((countryObject) => {
            return (
              <Option key={countryObject.id} value={countryObject.code}>
                <div className="flex-nowrap justify-start not-dir">
                  <GetFlag
                    country={countryObject.code}
                    classes="right-sm-marg"
                  />
                  <div className="tiny-hev right-sm-marg">
                    {countryObject.code.toUpperCase()}
                  </div>
                  <div className="tiny-med">{countryObject.dial}</div>
                </div>
              </Option>
            );
          })}
        </Select>
        <Input
          autoFocus={autoFocus}
          onChange={(e) => handleNumberChange(e.target.value)}
          placeholder={t("field.enter-num")}
          className={`${error && !disabled ? "head-field-error" : ""}`}
          type="number"
          disabled={disabled}
          value={number}
          style={{ fontFamily: isRtl ? "Almarai" : "Avenir" }}
        />
      </div>
      {error && (
        <FieldFooter
          disabled={disabled}
          error={error}
          fieldName={place}
          value={value}
        />
      )}
    </div>
  );
};

export default PhoneField;
