import React from "react";
// Packages
import { Switch, Route } from "react-router-dom";
import { apiServices } from "../../../services/apiServices";
// Components
import Settings from "../../leap/settings/settings";
import NavbarComponent from "../../leap/navbar/navbar";
import ThirdPartyProfile from "../../leap/profile/thirdProfile";
import EditPersonalInformation from "../../leap/profile/editPersonalInformation";
import FullGallery from "../../leap/profile/fullGallery";
import EditCastingShot from "../../leap/profile/editCastingShot";
import EditShowreel from "../../leap/profile/editShowreel";
import Notifications from "../../leap/notifications/notifications";

const ThirdApprove = ({ location }) => {
  return (
    <div className="layout-container">
      <NavbarComponent location={location} />
      <div className="flow-cont">
        <div className="full-container content-container">
          <Switch>
            <Route path="/third/approve/settings" component={Settings} />
            <Route path="/third/approve/full-gallery" component={FullGallery} />
            <Route
              path="/third/approve/edit-casting"
              component={EditCastingShot}
            />
            <Route
              path="/third/approve/edit-showreel"
              component={EditShowreel}
            />
            <Route
              path="/third/approve/edit-personal"
              component={EditPersonalInformation}
            />
            <Route
                path="/third/approve/notifications"
                component={() => (
                  <div className="side-med-padd-fill">
                    <Notifications />
                  </div>
                )}
              />
            <Route
              path="/third/approve"
              component={() => (
                <ThirdPartyProfile
                  location={location}
                  imageUploadService={apiServices.third.addProfilePic}
                  fullGalleryUrl="/third/approve/full-gallery"
                  identificationCardEditUrl="/third/approve/edit-personal"
                  castingShotEditUrl="/third/approve/edit-casting"
                  showreelEditUrl="/third/approve/edit-showreel"
                />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default ThirdApprove;
