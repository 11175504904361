import React from "react";
// Packages
import { connect } from "react-redux";
import { thirdProfileApproveSteps } from "../../../../utils/constants";
// Helpers
import ApproveInstructions from "../helpers/approveInstructions";
import ApproveNotes from "../helpers/approveNotes";
import StepsRender from "../helpers/stepsRender";

const ApproveCard = ({
  profileData,
  type,
  dataType,
  approved,
  currentStageProp,
  stageStatusProp,
}) => {
  // Hooks
  const { currentStage, setCurrentStage } = currentStageProp ?? {};
  const { stageStatus, setStageStatus } = stageStatusProp ?? {};

  // Works only if it's a third party account
  if (type === "3rd" && !approved)
    return (
      <div className="single-grid">
        <StepsRender
          profileData={profileData}
          dataType={dataType}
          steps={thirdProfileApproveSteps(dataType).filter((el) => el)}
          currentStageProp={{ currentStage, setCurrentStage }}
          stageStatusProp={{ stageStatus, setStageStatus }}
        />
        <ApproveInstructions stageStatus={stageStatus} />
        <ApproveNotes profileData={profileData} />
      </div>
    );
  return null;
};

const mapStateToProps = (state) => {
  return {
    type: state.auth.type,
    dataType: state.auth.dataType,
    approved: state.auth.approved,
  };
};

export default connect(mapStateToProps)(ApproveCard);
