const getItem = (key, isSessionStorage = false) => {
    if (isSessionStorage) return sessionStorage.getItem(key);
    return localStorage.getItem(key);
};

const getObject = (key, isSessionStorage = false) => {
    return JSON.parse(getItem(key, isSessionStorage));
};

const setItem = (key, value, isSessionStorage = false) => {
    let val = value;
    if (typeof value === "object") val = JSON.stringify(value);
    isSessionStorage
        ? sessionStorage.setItem(key, val)
        : localStorage.setItem(key, val);
};

const setObject = (key, value, isSessionStorage = false) => {
    setItem(key, JSON.stringify(value), isSessionStorage);
};

const removeItem = (key, isSessionStorage = false) => {
    isSessionStorage
        ? sessionStorage.removeItem(key)
        : localStorage.removeItem(key);
};

const clear = (isSessionStorage = false) => {
    isSessionStorage ? sessionStorage.clear() : localStorage.clear();
};

export { getItem, getObject, setItem, setObject, removeItem, clear };
