import React from "react";
// Hooks
import { LanguageHook } from "../../../../utils/hooks";
// Components
import RadialButton from "../../buttons/radialButton";

const ImageDeleteButton = ({ onDelete, shape }) => {
  // Hooks
  const { isRtl } = LanguageHook();

  return (
    <div
      className={`absolute ${isRtl ? "top-left-sm-abs" : "top-right-sm-abs"}`}
    >
      {onDelete && (
        <RadialButton
          classes={shape === "circle" ? "border-grey" : "shadow"}
          icon="trash"
          color="second"
          onClick={onDelete}
          tooltip="title.delete-image"
        />
      )}
    </div>
  );
};

export default ImageDeleteButton;
