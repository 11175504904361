import { useState } from "react";
import { HandleApiHook } from "../../../../utils/hooks";
import { connect } from "react-redux";
import { apiServices } from "../../../../services/apiServices";
import IconTitle from "../../../common/layouts/IconTitle";
import TagsRender from "../../../common/tags/tags";
import BreakdownSkeleton from "../../skeleton/breakdownSkeleton";
import FetchUiData from "../../../common/layouts/fetchUiData";
import TextItem from "../../../common/text/TextItem";
import ImageComponent from "../../../common/image/image";
import { Badge } from "antd";

const AssignedBreakdowns = ({ token, projectData }) => {
  const { loading, error, setError, submit } = HandleApiHook(true);
  const [props, setProps] = useState(null);

  const fetchProps = () => {
    submit({
      service: apiServices.breakdown.getMyAssigns,
      query: {
        token,
        projectId: projectData._id,
      },
      onSuccess: ({ assigns, breakdowns }) => {
        const formattedAssigns = [];
        if (assigns.length !== 0) {
          assigns.forEach((assign) => {
            const result = {
              category: null,
              breaks: [],
            };
            const breaks = breakdowns.breaks.filter(
              (item) => item.category._id === assign.category
            );
            if (breaks.length > 0) {
              result.category = breaks[0].category;
              result.breaks = breaks;
              formattedAssigns.push(result);
            }
          });
        }
        setProps(formattedAssigns);
      },
    });
  };

  const tagFormat = (tag) => {
    return (
      <div className="flex-nowrap">
        <ImageComponent
          image={tag.image}
          size="small"
          emptyState={{
            type: "icon",
            icon: "layers",
            color: "black",
            classes: "opacity",
          }}
          classes="end-sm-marg"
          preview
        />
        <div className="flex-col end-sm-marg">
          <TextItem content={tag.name} classes="micro-hev" />
          <TextItem content={tag.qty} classes="micro-lit" />
          <TextItem content={tag.description} classes="micro-lit" />
        </div>
      </div>
    );
  };

  return (
    <>
      <IconTitle
        content="navigation.breakdowns"
        icon="layers"
        iconSize="tiny2"
        textClasses="parag-hev text-upper dark"
      />
      <FetchUiData
        service={fetchProps}
        observer={projectData._id}
        loading={loading}
        dataFetched={props}
        error={error}
        setError={setError}
        Loader={BreakdownSkeleton}
        classes="single-grid"
        emptyMessage="empty.breakdown"
        noWait
      >
        {props?.map((item) => {
          return (
            <div
              key={item.category?._id}
              className="card all-med-padd single-grid"
            >
              <div className="flex justify-start">
                <div
                  style={{ backgroundColor: item.category?.color }}
                  className="radial icon-micro"
                ></div>
                <TextItem
                  content={item.category?.name}
                  classes="parag-hev side-med-padd"
                />
                <div className="side-sm-padd">
                  <Badge className="gr-badge" count={item.breaks?.length} />
                </div>
              </div>
              <TagsRender tags={item.breaks} format={tagFormat} />
            </div>
          );
        })}
      </FetchUiData>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(AssignedBreakdowns);
