import React, { useRef } from "react";
// Hooks
import { LanguageHook, AlertHook } from "../../../../utils/hooks";
// Components
import RadialButton from "../../buttons/radialButton";

const ImageUploadButton = ({
  upload,
  setFileName,
  setCroppingImage,
  shape,
}) => {
  // Hooks
  const { isRtl } = LanguageHook();
  const { showAlert } = AlertHook();
  // Upload ref props
  const inputRef = useRef();
  const triggerFileSelectPopup = () => inputRef.current.click();

  // When selecting file from computer
  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      if (
        !["jpeg", "jpg", "png"].includes(
          event.target.files[0].type.split("/")[1]
        )
      ) {
        showAlert({
          message: "not-valid",
          params: {
            field: "title.image",
          },
        });
      } else {
        const reader = new FileReader();
        setFileName(event.target.files[0].name);
        reader.readAsDataURL(event.target.files[0]);
        reader.addEventListener("load", () => {
          setCroppingImage(reader.result);
        });
      }
      inputRef.current.value = "";
    }
  };

  return (
    <div
      className={`absolute ${
        isRtl
          ? `bottom-left-${shape === "circle" ? "zero" : "sm"}-abs`
          : `bottom-right-${shape === "circle" ? "zero" : "sm"}-abs`
      }`}
    >
      <input
        type="file"
        accept="image/jpg, image/jpeg, image/png"
        ref={inputRef}
        onChange={onSelectFile}
        style={{ display: "none" }}
      />
      {upload && (
        <RadialButton
          classes={shape === "circle" ? "border-grey" : "shadow"}
          icon="camera"
          onClick={triggerFileSelectPopup}
          tooltip="title.upload-img"
        />
      )}
    </div>
  );
};

export default ImageUploadButton;
