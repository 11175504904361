import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const ThirdRoute = ({ path, auth }) => (
  <Route
    path={path}
    render={(props) =>
      !auth.initial && auth.type === "3rd" ? (
        <Redirect
          from={props.location.pathname}
          to={`/third/start/${auth.initStage === 1 ? "welcome" : "personal"}`}
        />
      ) : !auth.approved && auth.type === "3rd" ? (
        <Redirect from={props.location.pathname} to="/third/approve" />
      ) : auth.type === "3rd" ? (
        <Redirect from={props.location.pathname} to="/third/explore" />
      ) : (
        <Redirect from={props.location.pathname} to="/" />
      )
    }
  />
);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ThirdRoute);
