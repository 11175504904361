import React from "react";
// Helpers
import { connect } from "react-redux";
// Components
import SubAccountRow from "./rows/subRow";

const TableData = ({ type, data, admin }) => {
  if (type === "sub") {
    return (
      <div>
        {data &&
          data.map((item) => {
            return <SubAccountRow key={item._id} data={item} isAdmin={admin} />;
          })}
      </div>
    );
  } else return null;
};

const mapStateToProps = (state) => {
  return {
    admin: state.auth.admin,
  };
};

export default connect(mapStateToProps)(TableData);
