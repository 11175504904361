import { iconTextMapper, projectRoles } from "./constants";

const arabic = /[\u0600-\u06FF]/;

// Check if text is arabic
export const isArabic = (text) => {
  return arabic.test(text);
};

// Test Arabic Text
export const testArabicText = (text) => {
  let textValues = text.split(" ");
  return textValues.map((txt) => {
    return {
      isArabic: isArabic(txt),
      value: `${txt}`,
    };
  });
};

// Replace string spaces to text
export function spacesToDashes(str) {
  return str.replace(/\s+/g, "-").toLowerCase();
}

// Set app direction according to language
export function setAppDirection(isRtl) {
  var x = document.getElementsByTagName("BODY")[0];
  x.style.direction = isRtl ? "rtl" : "ltr";
}

// Conver name to two capital letters as avatar
export function getAvatarLetters(name) {
  const names = name.split(" ");
  return `${names[0][0].toUpperCase()}${
    names[1] ? names[1][0].toUpperCase() : ""
  }`;
}

// Icon size to text mapper
export function mapIconToText(size) {
  return iconTextMapper[size];
}

// Generate form data
export function generateFormData(name, value) {
  const formData = new FormData();
  formData.append(name, value);
  return formData;
}

// Append to form data
export function appendToFormData(formData, name, value) {
  formData.append(name, value);
  return formData;
}

// Append array to form data
export function appendArrayToFormData(formData, name, array) {
  array.map((arrayValue) => {
    formData.append(name, arrayValue);
  });
  return formData;
}

// Append object to form data
export function appendObjectToFormData(formData, object) {
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
}

// Capitalize first letter
export function capitalizeFirstLetter(text) {
  return `${text[0].toUpperCase()}${text.slice(1)}`;
}

// Calculate profile stage for third party accounts
export function calculateThirdPartyProfileStage(type, profileData) {
  let stage = 1,
    status = "";
  const { showcase, measurements, reelDesc } = profileData.data.id.accData.id;
  if (type === "cast") {
    if (showcase && Object.keys(showcase).length >= 1) stage = 2;
    if (measurements) {
      stage = 3;
      status = "done";
    }
  }
  if (reelDesc) {
    stage = 2;
    status = "done";
  }
  return { stage, status };
}

// Format casting info
export function getCastingInfo(id, casting) {
  // cast/dir/dop accept/reject still/final hero/back role
  let final = {
    id: "",
    projectId: casting.project._id,
    third: "",
    accepted: false,
    type: "",
    chosenAs: "",
    role: "",
  };
  // check still
  let find = casting.casting.find(
    (x) => x.hero.third?._id === id || x.back.third?._id === id
  );
  if (find) {
    final.third = find.type;
    if (find.type === "cast") final.role = find.role;
    if (find.hero.third?._id === id) {
      final.accepted = find.hero.accept;
      final.chosenAs = "hero";
    } else {
      final.accepted = find.back.accept;
      final.chosenAs = "back";
    }
    final.type = "still";
    final.id = find._id;
    return final;
  }
  // check cast
  find = casting.cast.find((castItem) => castItem._id === id);
  if (find) {
    final.third = "cast";
    final.role = find.role;
    final.type = "final";
    return final;
  }
  // check photographer
  find = casting.photographer.find((castItem) => castItem._id === id);
  if (find) {
    final.third = "photographer";
    final.role = find.role;
    final.type = "final";
    return final;
  }
  // check artDirector
  find = casting.artDirector.find((castItem) => castItem._id === id);
  if (find) {
    final.third = "artDirector";
    final.role = find.role;
    final.type = "final";
    return final;
  }
  // check food
  find = casting.food.find((castItem) => castItem._id === id);
  if (find) {
    final.third = "food";
    final.role = find.role;
    final.type = "final";
    return final;
  }
  // check hair
  find = casting.hair.find((castItem) => castItem._id === id);
  if (find) {
    final.third = "hair";
    final.role = find.role;
    final.type = "final";
    return final;
  }
  // check makup
  find = casting.makup.find((castItem) => castItem._id === id);
  if (find) {
    final.third = "makup";
    final.role = find.role;
    final.type = "final";
    return final;
  }
  // check woredrope
  find = casting.woredrope.find((castItem) => castItem._id === id);
  if (find) {
    final.third = "woredrope";
    final.role = find.role;
    final.type = "final";
    return final;
  }
  // check dir
  if (casting?.dir?._id === id) {
    final.third = "dir";
    final.type = "final";
    return final;
  }
  // check dop
  if (casting?.dop?._id === id) {
    final.third = "dop";
    final.type = "final";
    return final;
  }
  return null;
}

// Validate Project roles
export const validateProjectAccessRole = (auth) => {
  return (
    projectRoles[auth.dataType ? auth.dataType : auth.type].project !== "none"
  );
};

// Validate project edit
export const validateProjectEdit = (auth) => {
  return (
    projectRoles[auth.dataType ? auth.dataType : auth.type].project === "edit"
  );
};
