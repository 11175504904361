import React from "react";
// Components
import { GetIcon } from "../../../../assets/utils/IconGenerator";
import TextItem from "../../../common/text/TextItem";

const ApproveInstructions = ({ stageStatus }) => {
  if (stageStatus === "done")
    return (
      <div className="card all-med-padd flex">
        <GetIcon
          name="check-circle"
          color="brand"
          size="med2"
          classes="bottom-med-marg"
        />
        <div className="single-grid grid-sm-gap full-width">
          <TextItem content="desc.wait-approve" classes="tiny-med" />
          <TextItem content="desc.approve-instructions" classes="micro-lit" />
        </div>
      </div>
    );
  return null;
};

export default ApproveInstructions;
