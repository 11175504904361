import React, { useState } from "react";
// Packages
import { Input, FormControl, InputLabel } from "@material-ui/core";
// Components
import { GetIcon } from "../../../assets/utils/IconGenerator";
import TextItem from "../text/TextItem";
import FloatIconButton from "../buttons/floatIconButton";
import FieldFooter from "./components/FieldFooter";
// Hooks
import { LanguageHook } from "../../../utils/hooks";

const LineTextField = ({
  name,
  place,
  innerPlace,
  password,
  icon,
  type,
  value,
  error,
  disabled,
  onChange,
  autoFocus,
  maxLength,
  multiline,
  maxRows,
  link,
  onLinkPress,
  capital,
}) => {
  // Hooks
  const { isRtl, t } = LanguageHook();
  const [showPass, setShowPass] = useState(false);

  return (
    <div className="flex-nowrap align-end">
      {icon && (
        <GetIcon
          name={icon}
          size="tiny2"
          color={error ? "alert" : "brand"}
          classes="end-sm-marg"
          disabled={disabled}
        />
      )}
      <div className="relative full-width">
        <div
          className={`line-txt-field flex-nowrap align-end relative ${
            disabled ? "line-txt-dis" : ""
          }`}
        >
          <FormControl fullWidth>
            <InputLabel
              style={{
                right: "0",
                transformOrigin: isRtl ? "top right" : "top left",
              }}
              className={error ? "error" : ""}
            >
              <TextItem
                classes={`parag-lit ${disabled ? "disabled" : ""} ${
                  error ? "error" : ""
                } text-place-holder text-start`}
                content={place}
              />
            </InputLabel>
            <Input
              autoFocus={autoFocus}
              multiline={multiline}
              rowsMax={maxRows}
              onChange={(e) => onChange(e.target.value)}
              error={disabled ? false : error}
              disabled={disabled}
              placeholder={t(innerPlace)}
              value={value}
              type={password && !showPass ? "password" : type ? type : "text"}
              style={{ fontFamily: isRtl ? "Almarai" : "Avenir" }}
              autoCapitalize={capital ? "sentences" : "none"}
            ></Input>
          </FormControl>
          {password && !disabled && (
            <FloatIconButton
              onClick={() => setShowPass(!showPass)}
              name={showPass ? "eye" : "eye-hide"}
              color="black"
              classes={`${
                isRtl ? "left-zero-abs" : "right-zero-abs"
              } absolute box-sm-marg`}
              tooltip={showPass ? "title.hide-password" : "title.show-password"}
            />
          )}
        </div>
        {(error || maxLength || link) && (
          <FieldFooter
            disabled={disabled}
            error={error}
            fieldName={name ?? place}
            maxLength={maxLength}
            value={value}
            link={link}
            onLinkPress={onLinkPress}
            classes="top-tiny-padd"
          />
        )}
      </div>
    </div>
  );
};

export default LineTextField;
