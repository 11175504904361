import React from "react";
// Helpers
import { connect } from "react-redux";
import { reduxActions } from "../../../redux/actions";
// Components
import Footer from "../../common/footer/Footer";
import EmailSettingCard from "./cards/emailSettingCard";
import LanguageSettingCard from "./cards/languageSettingCard";
import PasswordSettingCard from "./cards/passwordSettingCard";
import UsernameSettingCard from "./cards/usernameSettingCard";

const Settings = ({ auth, changeUsername, logout }) => {
  // Destruct auth
  const { email, username, token } = auth;

  return (
    <div className="single-grid bottom-extend top-extend noncard-container">
      <EmailSettingCard email={email} logout={logout} token={token} />
      <UsernameSettingCard
        username={username}
        changeUsername={changeUsername}
        token={token}
      />
      <PasswordSettingCard token={token} />
      <LanguageSettingCard />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = {
  changeUsername: reduxActions.authActions.changeUsername,
  logout: reduxActions.authActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
