import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { validateProjectAccessRole } from "../utils/helperFunctions";

const PrivateRoute = ({ component: Component, path, auth, navigationKey }) => {
  const { token, type, initial, approved, initStage } = auth ?? {};

  const routeValidator = {
    third: "3rd",
    "sub-account": "acc",
    organization: "org",
  };

  const validateRoute = (route, type) => {
    if (routeValidator[route.split("/")[1]] === type) return true;
    return false;
  };

  const navOptions = (props) => {
    if (navigationKey === "auth")
      return type && validateRoute(props.location.pathname, type);
    if (navigationKey === "project")
      return token && initial && approved && validateProjectAccessRole(auth);
    if (navigationKey === "start") return !initial;
    if (navigationKey === "explore") return initial && approved;
    if (navigationKey === "approve") return !approved && initial;
    if (navigationKey === "startWelcome") return initStage === 1;
    if (navigationKey === "startPersonal") return initStage === 2;
  };

  return (
    <Route
      path={path}
      render={(props) =>
        navOptions(props) ? (
          <Component {...props} />
        ) : props.location.pathname.includes("/organization/issue") ? (
          <Redirect
            from={props.location.pathname}
            to={`/issue/${props.location.pathname.split("/")[3]}`}
          />
        ) : (
          <Redirect from={props.location.pathname} to="/" />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
