import React from "react";
import NormalButton from "../../common/buttons/NormalButton";
import history from "../../../utils/history";
import { GetIcon } from "../../../assets/utils/IconGenerator";
import TextItem from "../../common/text/TextItem";

const PasswordChanged = () => {
  return (
    <div className="single-grid">
      <GetIcon name="check-circle" size="big2" classes="margin-auto" />
      <TextItem classes="shead-hev text-center" content="title.pass-changed" />
      <TextItem classes="sparag-lit text-center" content="desc.pass-changed" />
      <NormalButton
        onClick={() => history.push("/")}
        content="action.log-in"
        classes="margin-auto"
      />
    </div>
  );
};

export default PasswordChanged;
