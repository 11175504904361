import React from "react";
import TextItem from "../../common/text/TextItem";
import ImageComponent from "../../common/image/image";
import { LanguageHook } from "../../../utils/hooks";

const AudiCard = ({ title, items, classes }) => {
  // Hooks
  const { isRtl } = LanguageHook();

  return (
    <div className={`flex justify-start ${classes}`}>
      <TextItem content={title} classes="sparag-hev" />
      <div className="padd-dot"></div>
      <div
        className={`${isRtl ? "audi-imgs-ar" : "audi-imgs"} flex justify-start`}
      >
        {items.map((item) => {
          return (
            <ImageComponent
              key={item._id}
              image={item.image}
              size="tiny1"
              tooltip={`${item.firstName} ${item.lastName}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AudiCard;
