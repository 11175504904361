import React, { useState } from "react";
import { connect } from "react-redux";
import { apiServices } from "../../../../services/apiServices";
import { HandleApiHook, LanguageHook } from "../../../../utils/hooks";
import { momentHelper } from "../../../../utils/momentHelper";
import BoxButton from "../../../common/buttons/boxBtn";
import FetchUiData from "../../../common/layouts/fetchUiData";
import IconTitle from "../../../common/layouts/IconTitle";
import TextItem from "../../../common/text/TextItem";
import BreakdownSkeleton from "../../skeleton/breakdownSkeleton";

const ReceivedCallsheets = ({ projectData, token }) => {
  const { language } = LanguageHook();
  const { loading, error, setError, submit } = HandleApiHook(true);
  const [versions, setVersions] = useState(null);

  const fetchVersions = () => {
    submit({
      service: apiServices.callsheet.receiveCallsheets,
      query: {
        token,
        projectId: projectData._id,
      },
      onSuccess: (versionsResult) => setVersions(versionsResult),
    });
  };

  return (
    <>
      <IconTitle
        content="navigation.callsheets"
        icon="papers"
        iconSize="tiny2"
        textClasses="parag-hev text-upper dark"
      />
      <FetchUiData
        service={fetchVersions}
        observer={projectData._id}
        loading={loading}
        dataFetched={versions}
        error={error}
        setError={setError}
        Loader={BreakdownSkeleton}
        classes="single-grid"
        emptyMessage="empty.noVersions"
        noWait
      >
        {versions?.map((ver) => {
          return (
            <div key={ver._id} className="card box-sm-padd">
              <div className="full-width flex justify-space-between">
                <div>
                  <TextItem
                    normal
                    content={`${ver.callsheet.title} - ${ver.version}`}
                    classes="sparag-hev text-upper"
                  />
                  <TextItem
                    content={momentHelper.getDateTimeFormat(
                      ver.callsheet.dateTime,
                      language
                    )}
                  />
                </div>
                <BoxButton
                  icon="pdf"
                  color="second"
                  onClick={() =>
                    window.open(`${process.env.REACT_APP_CDN_URL}/${ver.url}`)
                  }
                  tooltip="project.pdf"
                  placement="bottom"
                />
              </div>
            </div>
          );
        })}
      </FetchUiData>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(ReceivedCallsheets);
