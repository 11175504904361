import moment from "moment";

// Insert arabic naming to moment properties
moment.updateLocale("ar", {
  months: [
    "يناير",
    "فبراير",
    "مارس",
    "ابريل",
    "مايو",
    "يونيو",
    "يوليو",
    "اغسطس",
    "سبتمبر",
    "اكتوبر",
    "نوفمير",
    "ديسمبر",
  ],
  monthsShort: [
    "يناير",
    "فبراير",
    "مارس",
    "ابريل",
    "مايو",
    "يونيو",
    "يوليو",
    "اغسطس",
    "سبتمبر",
    "اكتوبر",
    "نوفمير",
    "ديسمبر",
  ],
  relativeTime: {
    future: "في غضون %s",
    past: "منذ %s",
    s: "بضع ثوانٍ",
    ss: "%d ثوانٍ",
    m: "دقيقة",
    mm: "%d دقيقة",
    h: "ساعة",
    hh: "%d ساعات",
    d: "يوم",
    dd: "%d ايام",
    w: "اسبوع",
    ww: "%d اسابيع",
    M: "شهر",
    MM: "%d شهور",
    y: "سنة",
    yy: "%d سنين",
  },
  meridiem: function (hour) {
    if (hour >= 12) {
      return "مساءً";
    } else {
      return "صباحاً";
    }
  },
});
