import React from "react";
// Components
import FloatIconButton from "../buttons/floatIconButton";
import TextItem from "../text/TextItem";

const TagsRender = ({ tags, onRemove, format, dark }) => {
  if (tags.length !== 0)
    return (
      <div className="flex justify-start full-width">
        {tags.map((tag) => {
          return (
            <div
              className={`flex-nowrap box-sm-padd end-sm-marg bottom-sm-marg radial-edges ${
                dark ? "black-back" : "grey3-back"
              }`}
              style={{ maxWidth: "280px" }}
              key={format ? tag.name : tag}
            >
              {format ? (
                format(tag)
              ) : (
                <TextItem
                  content={tag}
                  classes={`tiny-hev ellipsis ${dark ? "white" : ""}`}
                />
              )}
              {onRemove && (
                <FloatIconButton
                  name="close"
                  size="micro"
                  color={dark ? "white" : "second"}
                  classes="start-sm-marg"
                  onClick={() => onRemove(tag)}
                  tooltip="action.remove"
                />
              )}
            </div>
          );
        })}
      </div>
    );
  return null;
};

export default TagsRender;
