import React, { useState } from "react";
// Packages
import { Input } from "antd";
// Components
import FloatIconButton from "../buttons/floatIconButton";
import FieldHeader from "./components/FieldHeader";
import FieldFooter from "./components/FieldFooter";
// Hooks
import { LanguageHook } from "../../../utils/hooks";

const HeadTextField = ({
  name,
  place,
  innerPlace,
  label,
  type,
  password,
  value,
  error,
  disabled,
  onChange,
  autoFocus,
  maxLength,
  classes,
  onEnter,
  disableSubmit,
  capital,
}) => {
  // Hooks
  const { isRtl, t } = LanguageHook();
  const [showPass, setShowPass] = useState(false);

  return (
    <div className="full-width relative">
      {(place || label) && (
        <FieldHeader
          head={place}
          label={label}
          disabled={disabled}
          error={error}
        />
      )}
      <div
        className={`input-field relative ${
          disabled ? "input-disabled-field" : ""
        } ${classes}`}
      >
        <Input
          autoFocus={autoFocus}
          onChange={(e) => onChange(e.target.value)}
          placeholder={t(innerPlace)}
          className={`${error && !disabled ? "input-error-field" : ""}`}
          type={password && !showPass ? "password" : type ? type : "text"}
          disabled={disabled}
          value={value}
          maxLength={maxLength}
          autoCapitalize={capital ? "sentences" : "none"}
          onKeyPress={(e) => {
            if (disableSubmit && e.key === "Enter") {
              if (onEnter) onEnter();
              e.preventDefault();
            }
          }}
          style={{ fontFamily: isRtl ? "Almarai" : "Avenir" }}
        />
        {password && !disabled && (
          <FloatIconButton
            onClick={() => setShowPass(!showPass)}
            name={showPass ? "eye" : "eye-hide"}
            color="black"
            classes={`${
              isRtl ? "left-zero-abs" : "right-zero-abs"
            } absolute box-sm-marg top-zero-abs`}
            tooltip={showPass ? "title.hide-password" : "title.show-password"}
          />
        )}
      </div>
      {(error || maxLength) && (
        <FieldFooter
          disabled={disabled}
          error={error}
          fieldName={name ?? place}
          maxLength={maxLength}
          value={value}
        />
      )}
    </div>
  );
};

export default HeadTextField;
