import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { email } from "./stores/email";
import { auth } from "./stores/auth";
import { account } from "./stores/account";
import { tabTitle } from "./stores/tabTitle";

const persistConfig = {
  key: "Leap",
  storage,
  whitelist: ["email", "auth", "tabTitle"],
};
const rootReducer = combineReducers({
  email,
  auth,
  account,
  tabTitle,
});

export default persistReducer(persistConfig, rootReducer);
