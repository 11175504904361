import React from "react";
import ProjectCardSkeleton from "./projectCardSkeleton";

const ProjectsSkeleton = () => {
  return (
    <div className="grid projects-grid">
      <ProjectCardSkeleton />
      <ProjectCardSkeleton />
      <ProjectCardSkeleton />
      <ProjectCardSkeleton />
      <ProjectCardSkeleton />
      <ProjectCardSkeleton />
    </div>
  );
};

export default ProjectsSkeleton;
