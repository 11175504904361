import React from "react";
import LineSkeleton from "../../common/loaders/skeleton/lineSkeleton";

const BreakdownSkeleton = () => {
  return (
    <div className="single-grid">
      <div className="card one-third-grid-resp grid-zero-gap">
        <LineSkeleton rows={1} classes="all-med-padd" />
        <LineSkeleton rows={2} classes="all-med-padd" />
      </div>
      <div className="card one-third-grid-resp grid-zero-gap">
        <LineSkeleton rows={1} classes="all-med-padd" />
        <LineSkeleton rows={2} classes="all-med-padd" />
      </div>
      <div className="card one-third-grid-resp grid-zero-gap">
        <LineSkeleton rows={1} classes="all-med-padd" />
        <LineSkeleton rows={2} classes="all-med-padd" />
      </div>
    </div>
  );
};

export default BreakdownSkeleton;
