import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { apiServices } from "../../../../services/apiServices";
import { HandleApiHook, LanguageHook } from "../../../../utils/hooks";
// Components
import FormButtons from "../../../common/buttons/FormButtons";
import CheckboxItem from "../../../common/checkbox/checkbox";
import HeadTextField from "../../../common/fields/headTextField";
import TopLoader from "../../../common/loaders/topLoader";
import CustomModal from "../../../common/modals/customModal";

const AddSubAccountModal = ({
  addUser,
  setAddUser,
  token,
  organizationId,
  team,
  setTeam,
}) => {
  // Hooks
  const { language } = LanguageHook();
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [adminForm, setAdminForm] = useState(false);

  // Reset form
  const resetForm = () => {
    setAddUser(false);
    setFirstName("");
    setLastName("");
    setUsername("");
    setEmail("");
    setTitle("");
    setAdminForm(false);
  };

  // Submit new Subaccount
  const submitNewSubAccount = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.subAcc.addSubAcc,
      body: {
        firstName,
        lastName,
        title,
        username,
        email,
        adminForm,
        language,
        organizationId,
      },
      query: {
        token,
      },
      onSuccess: (newSubAccount) => {
        resetForm();
        setTeam([newSubAccount, ...team]);
      },
    });
  };

  return (
    <CustomModal
      icon="add-user"
      title="title.new-emp"
      open={addUser}
      setOpen={setAddUser}
    >
      <form
        onSubmit={submitNewSubAccount}
        className="single-grid grid-high-gap all-med-padd"
        noValidate
      >
        {loading && <TopLoader />}
        <HeadTextField
          place="field.firstName"
          value={firstName}
          error={getErrorItem("firstName")}
          onChange={setFirstName}
        />
        <HeadTextField
          place="field.lastName"
          value={lastName}
          error={getErrorItem("lastName")}
          onChange={setLastName}
        />
        <HeadTextField
          place="field.username"
          value={username}
          error={getErrorItem("username")}
          onChange={setUsername}
        />
        <HeadTextField
          place="field.email"
          value={email}
          error={getErrorItem("email")}
          onChange={setEmail}
          type="email"
        />
        <HeadTextField
          place="field.title"
          value={title}
          error={getErrorItem("title")}
          onChange={setTitle}
        />
        <CheckboxItem
          content="title.admin"
          font="sparag-lit"
          onChange={setAdminForm}
          classes="top-med-marg"
        />
        <FormButtons
          disabled={loading}
          onCancel={resetForm}
          submitContent="action.add"
          icon="plus"
          classes="top-sm-marg"
        />
      </form>
    </CustomModal>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    organizationId: state.auth.org,
  };
};

export default connect(mapStateToProps)(AddSubAccountModal);
