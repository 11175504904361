import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import CircleLoader from "../loaders/circleLoader";

const VisSensor = ({ nextPage, pageLoad }) => {
  return (
    <VisibilitySensor onChange={nextPage}>
      <div style={{ height: "1px" }}>
        {pageLoad && <CircleLoader classes="margin-auto all-med-padd" />}
      </div>
    </VisibilitySensor>
  );
};

export default VisSensor;
