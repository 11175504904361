import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { apiServices } from "../../../services/apiServices";
// Components
import FloatIconButton from "../../common/buttons/floatIconButton";
import ExploreTable from "../../common/layouts/exploreTable";
import OrganizationCard from "../cards/organizationCard";
import AddSubAccountModal from "./helpers/addSubModal";

const OrganizationTeam = ({ token, admin, organizationId }) => {
  // Hooks
  const [team, setTeam] = useState([]);
  const [addSubAccountModal, setAddSubAccountModal] = useState(false);

  // Filters
  const filterGroups = [
    {
      title: "title.status",
      defVal: "",
      name: "status",
      values: [
        { value: "", label: "title.all" },
        { value: "act", label: "statusL.active" },
        { value: "deact", label: "statusL.deac" },
        { value: "pending", label: "statusL.pend" },
      ],
    },
    {
      title: "field.gender",
      defVal: "",
      name: "gender",
      values: [
        { value: "", label: "title.all" },
        { value: "Male", label: "gender.Male" },
        { value: "Female", label: "gender.Female" },
      ],
    },
  ];

  // Add new subaccount to organization
  const titleProp = () => {
    if (admin)
      return (
        <FloatIconButton
          name="add-user"
          size="tiny2"
          tooltip="title.add-sub-account"
          onClick={() => setAddSubAccountModal(true)}
        />
      );
    else return null;
  };

  return (
    <div className="single-grid bottom-extend top-extend-fill">
      <OrganizationCard />
      <ExploreTable
        title="title.employees"
        // titleIcon="team"
        filterGroups={filterGroups}
        tableType="sub"
        service={apiServices.subAcc.getOrgTeam}
        query={{
          token,
          org: organizationId,
        }}
        emptyTitle="empty.team"
        titleProp={titleProp}
        info={team}
        setInfo={setTeam}
        filterName="sub-team-filters"
      />
      <AddSubAccountModal
        addUser={addSubAccountModal}
        setAddUser={setAddSubAccountModal}
        team={team}
        setTeam={setTeam}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    organizationId: state.auth.org,
    admin: state.auth.admin,
  };
};

export default connect(mapStateToProps)(OrganizationTeam);
