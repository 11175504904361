import React from "react";
// Components & Helpers
import { GetIcon } from "../../../assets/utils/IconGenerator";
import TextItem from "../text/TextItem";
// Hooks
import { LanguageHook } from "../../../utils/hooks";
import { useTranslation } from "react-i18next";
import TooltipContainer from "../tooltip/Tooltip";

const LanguageSelector = ({ iconSize, classes }) => {
  const { i18n } = useTranslation();
  // Language Hook
  const { language } = LanguageHook();

  // Style Classes
  const languageSelectorClasses = `flex not-dir ${classes}`;

  // Check which language selected
  const checkActive = (lng) => {
    if (language === lng) return true;
    return false;
  };

  // Change language
  const changeAppLanguage = (lng) => {
    if (lng !== language) {
      window.location.reload();
      i18n.changeLanguage(lng);
    }
  };

  return (
    <div className={languageSelectorClasses}>
      <GetIcon
        color="brand"
        name="globe"
        size={iconSize ?? "tiny"}
        classes="side-sm-marg"
      />
      <TooltipContainer tooltip="constants.english">
        <TextItem
          content="constants.en"
          onClick={() => changeAppLanguage("en")}
          classes={`pointer transition sparag-lit side-sm-marg ${
            checkActive("en") && "sparag-hev brand"
          }`}
        />
      </TooltipContainer>
      <TooltipContainer tooltip="constants.arabic">
        <TextItem
          content="constants.ar"
          onClick={() => changeAppLanguage("ar")}
          classes={`pointer transition sparag-lit side-sm-marg ${
            checkActive("ar") && "sparag-hev brand"
          }`}
        />
      </TooltipContainer>
    </div>
  );
};

export default LanguageSelector;
