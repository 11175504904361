import React from "react";
// Packages
import { connect } from "react-redux";
// Components
import AvatarSkeleton from "../../common/loaders/skeleton/avatarSkeleton";
import LineSkeleton from "../../common/loaders/skeleton/lineSkeleton";

const SubAccountProfileSkeleton = () => {
  return (
    <div className="single-grid bottom-extend top-extend-fill">
      <div className="card one-third-grid-resp">
        <AvatarSkeleton fill shape="square" minHeight={200} />
        <LineSkeleton rows={6} title classes="all-med-padd" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.auth.type,
    approved: state.auth.approved,
  };
};

export default connect(mapStateToProps)(SubAccountProfileSkeleton);
