import React from "react";
// Packages
import { Switch, Route } from "react-router-dom";
// Components
import AccountDeactivated from "../../leap/auth/deactivated";
import EmailConfirm from "../../leap/auth/emailConfirm";
import ForgotPassword from "../../leap/auth/forgotPassword";
import AccountOrgDeactivated from "../../leap/auth/orgDeactivated";
import RecoveryPass from "../../leap/auth/recovery";
import SignIn from "../../leap/auth/signIn";
import SignUp from "../../leap/auth/signUp";

const LoginCanvas = () => {
  return (
    <div className="padding-card shadow flex">
      <div className="card-content margin-auto full-width">
        <Switch>
          <Route path="/sign-up" component={SignUp} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/email-confirm" component={EmailConfirm} />
          <Route path="/recover-sent" component={RecoveryPass} />
          <Route path="/deactivated" component={AccountDeactivated} />
          <Route path="/org-deactivated" component={AccountOrgDeactivated} />
          <Route path="/" component={SignIn} />
        </Switch>
      </div>
    </div>
  );
};

export default LoginCanvas;
