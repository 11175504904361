import React from "react";
// Packages
import { Input } from "antd";
// Components
import FieldHeader from "./components/FieldHeader";
import FieldFooter from "./components/FieldFooter";
// Hooks
import { LanguageHook } from "../../../utils/hooks";

const { TextArea } = Input;

const TextAreaField = ({
  place,
  innerPlace,
  label,
  value,
  error,
  disabled,
  onChange,
  autoFocus,
  maxLength,
  rows,
}) => {
  // Hooks
  const { isRtl, t } = LanguageHook();

  return (
    <div
      className={`input-field relative ${
        disabled ? "input-disabled-field" : ""
      }`}
    >
      {(place || label) && (
        <FieldHeader
          head={place}
          label={label}
          disabled={disabled}
          error={error}
        />
      )}
      <TextArea
        autoFocus={autoFocus}
        onChange={(e) => onChange(e.target.value)}
        placeholder={t(innerPlace)}
        rows={rows}
        className={`${error && !disabled ? "input-error-field" : ""}`}
        disabled={disabled}
        value={value}
        maxLength={maxLength}
        style={{ fontFamily: isRtl ? "Almarai" : "Avenir" }}
      />
      {(error || maxLength) && (
        <FieldFooter
          disabled={disabled}
          error={error}
          fieldName={place}
          maxLength={maxLength}
          value={value}
        />
      )}
    </div>
  );
};

export default TextAreaField;
