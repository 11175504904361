import { getToken, onMessage, deleteToken } from "firebase/messaging";
import { messaging } from "../config/firebase.config";

const onMessageListener = (action) => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      if (action) action();
      
      new Notification("New Notification", {
        body: payload.notification.title,
        icon: 'https://leapstudios-public.s3.us-east-2.amazonaws.com/brand-logo.png'
      });
      resolve(payload);
    });
  });
};

const requestPermission = async () => {
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    console.log("push notifications permission granted");
  } else {
    console.log("push notifications permission denyed");
  }
};

const getFirebaseToken = async (setTokenFound = null) => {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return null;
      }
    })
    .catch((error) => {
      console.log(`Failed to retrieve notification token error: ${error}`);
    });
};

export {
  getFirebaseToken,
  onMessageListener,
  requestPermission,
};
