import React from "react";
// Helpers
import { Switch, Route } from "react-router-dom";
import { apiServices } from "../../../services/apiServices";
// Components
import CreateRequest from "../../leap/project/createRequest";
import SubAccountProfile from "../../leap/profile/subAccountProfile";
import EditPersonalInformation from "../../leap/profile/editPersonalInformation";
import ProjectExplore from "../../leap/project/projectExplore";
import Settings from "../../leap/settings/settings";
import NavbarComponent from "../../leap/navbar/navbar";
import OrganizationTeam from "../../leap/team/team";
import Notifications from "../../leap/notifications/notifications";

const ExploreSubAccoount = ({ location }) => {
  return (
    <div className="layout-container">
      <NavbarComponent location={location} />
      <div className="flow-cont">
        <div className="full-container content-container">
          <Switch>
            <Route path="/sub-account/explore/settings" component={Settings} />
            <Route
              path="/sub-account/explore/profile"
              component={() => (
                <SubAccountProfile
                  location={location}
                  imageUploadService={apiServices.subAcc.addProfilePic}
                  identificationCardEditUrl="/sub-account/explore/edit-personal"
                />
              )}
            />
            <Route
              path="/sub-account/explore/edit-personal"
              component={EditPersonalInformation}
            />
            <Route
              path="/sub-account/explore/new-request"
              component={CreateRequest}
            />
            <Route
              path="/sub-account/explore/team"
              component={OrganizationTeam}
            />
            <Route
              path="/sub-account/explore/ongoing"
              component={() => (
                <ProjectExplore
                  filterName="sub-projects-filter"
                  subAccount
                  type="proj"
                />
              )}
            />
            <Route
                path="/sub-account/explore/notifications"
                component={() => (
                  <div className="side-med-padd-fill">
                    <Notifications />
                  </div>
                )}
              />
            <Route
              path="/sub-account/explore/"
              component={() => (
                <ProjectExplore
                  filterName="sub-requests-filter"
                  subAccount
                  type="req"
                />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default ExploreSubAccoount;
