import React from "react";
// Packages
import { Switch, Route } from "react-router-dom";
// Components
import IssueStack from "./issueStack/IssueStack";
import LoginStack from "./loginStack/LoginStack";
import Footer from "../common/footer/Footer";

const AuthHome = () => {
  return (
    <div className="full-container full-height-vh flex top-padd">
      <div className="full-width">
        <Switch>
          <Route path="/issue" component={IssueStack} />
          <Route path="/" component={LoginStack} />
        </Switch>
        <Footer language />
      </div>
    </div>
  );
};

export default AuthHome;
