import React, { useState } from "react";
// Components
import TextAreaField from "../../common/fields/TextAreaField";
import HeadTextField from "../../common/fields/headTextField";
import TopLoader from "../../common/loaders/topLoader";
import TextItem from "../../common/text/TextItem";
import ConfirmModal from "../../common/modals/confirmModal";
// Helpers
import { apiServices } from "../../../services/apiServices";
// Hooks
import { HandleApiHook } from "../../../utils/hooks";
import FormButtons from "../../common/buttons/FormButtons";
import IconTitle from "../../common/layouts/IconTitle";

const ContactUs = ({ closeModal }) => {
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [cancelModal, setCancelModal] = useState(false);

  // Submit Message
  const submitContact = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.contact.sendMessage,
      body: {
        name,
        email,
        subject,
        message,
      },
      successMessage: "sent",
      params: { field: "field.message" },
      onSuccess: closeModal,
    });
  };

  return (
    <form
      onSubmit={submitContact}
      className="single-grid grid-sm-gap all-med-padd"
      noValidate
    >
      {loading && <TopLoader />}
      <TextItem content="desc.love-hear" classes="tiny-lit" />
      <IconTitle
        content="constants.email"
        icon="mail"
        iconSize="tiny1"
        textClasses="sparag-med"
      />
      <HeadTextField
        place="field.name"
        value={name}
        error={getErrorItem("name")}
        onChange={setName}
      />
      <HeadTextField
        place="field.email"
        value={email}
        error={getErrorItem("email")}
        onChange={setEmail}
        type="email"
      />
      <HeadTextField
        place="field.subject"
        value={subject}
        error={getErrorItem("subject")}
        onChange={setSubject}
      />
      <TextAreaField
        place="field.message"
        innerPlace="title.inner-mess"
        value={message}
        error={getErrorItem("message")}
        onChange={setMessage}
        rows={3}
        maxLength={100}
      />
      <FormButtons
        disabled={loading}
        onCancel={() => setCancelModal(true)}
        submitContent="action.send"
        icon="send"
        classes="top-med-marg"
      />
      <ConfirmModal
        open={cancelModal}
        setOpen={setCancelModal}
        title="confirm.cancel-contact"
        talk="confirm.cancel-contact-effect"
        onAgree={closeModal}
      />
    </form>
  );
};

export default ContactUs;
