import React from "react";
// Helpers
import { LanguageHook } from "../../../../utils/hooks";
// Components
import FloatIconButton from "../../../common/buttons/floatIconButton";
import MainLogo from "../../../common/logo/mainLogo";
import FullScreenNavigationItem from "./fullScreenNavigationItem";
import ProjectNavigationItem from "./projectNavigationItem";

const FullScreenNavigation = ({ navigations, active, setActive, project }) => {
  // Hooks
  const { isRtl } = LanguageHook();

  if (active && navigations)
    return (
      <div className="full-screen-navigation absolute full-width full-height-vh flex-nowrap flex-column top-zero-abs left-zero-abs white-back index2 overflow-auto">
        <FloatIconButton
          name="close"
          color="black"
          classes={`absolute ${
            isRtl ? "top-left-med-abs" : "top-right-med-abs"
          }`}
          onClick={() => setActive(false)}
          tooltip="action.close"
          placement="bottom"
        />
        <MainLogo classes="bottom-padd" />
        <div className="flex flex-column full-width">
          {project && (
            <ProjectNavigationItem
              project={project}
              collapsed={false}
              setActiveMenu={setActive}
            />
          )}
          {navigations.map((navigationItem) => (
            <FullScreenNavigationItem
              key={navigationItem.url}
              navigationItem={navigationItem}
              setActiveMenu={setActive}
              collapsed={false}
            />
          ))}
        </div>
      </div>
    );

  return null;
};

export default FullScreenNavigation;
