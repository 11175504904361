export const authTypes = {
  LOGIN: "LOGIN",
  CHANGE_EMAIL: "CHANGE_EMAIL",
  CHANGE_USERNAME: "CHANGE_USERNAME",
  END_INIT: "END_INIT",
  INC_STAGE: "INC_STAGE",
  ADD_IMAGE: "ADD_IMAGE",
  APPROVE_PROF: "APPROVE_PROF",
  LOGOUT: "LOGOUT",
};
