import { apiHelper } from "../../utils/apiHelper";
const endPoint = "/auth";

export const auth = {
  login,
  logout,
  loginOrg,
  changeEmailRegister,
  validateEmail,
  validateUsername,
  getLoggedInAccount,
  getFile,
  forgotPass,
  resetPass,
  changePass,
  nextInitStage,
  getIdCard,
  resendConf,
  welcomeUpdate,
  personalUpdate,
  personalEdit,
  changeStatus,
  changeUsername,
  changeEmail,
};

// Exec
function execPost(route, data) {
  apiHelper.post(endPoint + route, data);
}
function execPut(route, data) {
  apiHelper.put(endPoint + route, data);
}
function execGet(route, data) {
  apiHelper.get(endPoint + route, data);
}

// Functions
function login(data) {
  execPost("/", data);
}
function logout(data) {
  execPost("/logout", data);
}
function loginOrg(data) {
  execPost("/login-org", data);
}
function changeEmailRegister(data) {
  execPut("/change-email-register", data);
}
function validateEmail(data) {
  execGet("/valid-email", data);
}
function validateUsername(data) {
  execGet("/valid-username", data);
}
function getLoggedInAccount(data) {
  execGet("/logged-account", data);
}
function getFile(data) {
  execGet("/get-file", data);
}
function forgotPass(data) {
  execPost("/forgot-pass", data);
}
function resetPass(data) {
  execPut("/reset-pass", data);
}
function changePass(data) {
  execPut("/change-pass", data);
}
function nextInitStage(data) {
  execPut("/next-init-stage", data);
}
function getIdCard(data) {
  execGet("/id-card", data);
}
function resendConf(data) {
  execPost("/resend-conf", data);
}
function welcomeUpdate(data) {
  execPut("/welcome-update", data);
}
function personalUpdate(data) {
  execPut("/personal-update", data);
}
function personalEdit(data) {
  execPut("/personal-edit", data);
}
function changeStatus(data) {
  execPut("/change-status", data);
}
function changeUsername(data) {
  execPut("/change-username", data);
}
function changeEmail(data) {
  execPut("/change-email", data);
}
