import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AccountRoute = ({ path, auth }) => (
  <Route
    path={path}
    render={(props) =>
      !auth.initial && auth.type === "acc" ? (
        <Redirect
          from={props.location.pathname}
          to={`/sub-account/start/${
            auth.initStage === 1 ? "welcome" : "personal"
          }`}
        />
      ) : auth.type === "acc" ? (
        <Redirect from={props.location.pathname} to="/sub-account/explore" />
      ) : (
        <Redirect from={props.location.pathname} to="/" />
      )
    }
  />
);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AccountRoute);
