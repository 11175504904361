import React from "react";
import { connect } from "react-redux";
import { validateProjectEdit } from "../../../../utils/helperFunctions";
import { LanguageHook } from "../../../../utils/hooks";
import { momentHelper } from "../../../../utils/momentHelper";
import CardUtilities from "../../../common/helpers/cardUtilities";
import TextItem from "../../../common/text/TextItem";

const ProjectIdentificationHeader = ({ projectData, owner, auth }) => {
  // Desctruct profile data
  const { _id, name, desc, createdAt, status, modified } = projectData ?? {};
  // Hooks
  const { language } = LanguageHook();

  return (
    <>
      <div>
        <div className="flex-nowrap justify-space-between full-width">
          <TextItem content={name} classes="parag-hev" normal />
          <CardUtilities
            editUrl={`/project/${_id}/edit`}
            edit={owner && validateProjectEdit(auth)}
            editData={projectData}
          />
        </div>
        <div className="flex justify-start top-sm-padd">
          <TextItem
            content={momentHelper.getDateFormat(createdAt, language)}
            classes="micro-med"
            normal
          />
          <div className="padd-dot"></div>
          <TextItem
            content={momentHelper.getFrom(createdAt, language)}
            classes="micro-lit"
            normal
          />
          <div className="padd-dot"></div>
          <TextItem
            content={`status.${status}`}
            classes={`micro-hev ${status}`}
          />
        </div>
        {modified && (
          <div className="flex justify-start">
            <TextItem content="title.modified" classes="micro-hev brand" />
            <div className="padd-dot"></div>
            <TextItem
              content={momentHelper.getFrom(modified, language)}
              classes="micro-lit"
              normal
            />
          </div>
        )}
      </div>
      <TextItem content={desc} classes="tiny-lit" normal />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ProjectIdentificationHeader);
