import React from "react";
// Components
import { GetIcon } from "../../../assets/utils/IconGenerator";
import TextItem from "../text/TextItem";

const IconTitle = ({
  content,
  label,
  icon,
  iconColor,
  iconSize,
  textClasses,
  labelClasses,
  iconClasses,
  onClick,
  classes,
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex-nowrap justify-start align-center ${classes}`}
    >
      {icon && (
        <GetIcon
          name={icon}
          color={iconColor ?? "brand"}
          size={iconSize ?? "tiny"}
          classes={iconClasses}
        />
      )}
      <TextItem content={content} classes={`side-sm-padd ${textClasses}`} />
      <TextItem
        content={label}
        classes={`side-sm-padd opacity ${labelClasses}`}
      />
    </div>
  );
};

export default IconTitle;
