import React from "react";
import UserCardSkeleton from "./userCardSkeleton";

const NotificationsSkeleton = () => {
  return (
    <div className="single-grid">
      <UserCardSkeleton />
      <UserCardSkeleton />
      <UserCardSkeleton />
    </div>
  );
};

export default NotificationsSkeleton;
