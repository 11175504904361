import React, { useState } from "react";
// Packages
import { connect } from "react-redux";
// Helpers
import { apiServices } from "../../../services/apiServices";
import { reduxActions } from "../../../redux/actions";
import history, { hist } from "../../../utils/history";
import { HandleApiHook, LanguageHook } from "../../../utils/hooks";
// Components
import RadioGroup from "../../common/radio/radioGroup";
import LineTextField from "../../common/fields/LineTextField";
import CheckboxItem from "../../common/checkbox/checkbox";
import NormalButton from "../../common/buttons/NormalButton";
import LinkText from "../../common/buttons/linkText";
import TopLoader from "../../common/loaders/topLoader";
import TextItem from "../../common/text/TextItem";
import { thirdPartyTypes } from "../../../utils/constants";
import SelectField from "../../common/fields/selectField";
import HeadTextField from "../../common/fields/headTextField";
import PhoneField from "../../common/fields/phoneField";

const SignUp = ({ addEmail }) => {
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const { language } = LanguageHook();
  // Fields
  const [type, setType] = useState("cast");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [number, setNumber] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePass, setRePass] = useState("");
  const [terms, setTerms] = useState(false);

  // Submit
  const submitSignup = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.third.register,
      body: {
        type,
        firstName,
        lastName,
        number,
        username,
        email,
        password,
        rePass,
        terms,
        language,
      },
      onSuccess: (token) => {
        addEmail(email);
        hist.replace("/email-confirm", {
          token: token,
          allowEdit: true,
        });
      },
    });
  };

  return (
    <form onSubmit={submitSignup} className="sign-up single-grid" noValidate>
      {loading && <TopLoader />}
      <TextItem classes="shead-hev text-center" content="title.sign-up" />
      <div className="single-grid grid-high-gap">
        <SelectField
          place="field.type"
          value={type}
          error={getErrorItem("type")}
          onChange={setType}
          options={thirdPartyTypes}
        />
        <HeadTextField
          place="field.firstName"
          value={firstName}
          error={getErrorItem("firstName")}
          onChange={setFirstName}
        />
        <HeadTextField
          place="field.lastName"
          value={lastName}
          error={getErrorItem("lastName")}
          onChange={setLastName}
        />
        <PhoneField
          place="field.number"
          value={number}
          error={getErrorItem("number")}
          onChange={setNumber}
          defaultCode="sa"
        />
        <HeadTextField
          place="field.username"
          value={username}
          error={getErrorItem("username")}
          onChange={setUsername}
        />
        <HeadTextField
          place="field.email"
          value={email}
          error={getErrorItem("email")}
          onChange={setEmail}
          type="email"
        />
        <HeadTextField
          place="field.password"
          value={password}
          error={getErrorItem("password")}
          onChange={setPassword}
          password
        />
        <HeadTextField
          place="field.re-pass"
          value={rePass}
          error={getErrorItem("re-pass")}
          onChange={setRePass}
          password
        />
      </div>
      <CheckboxItem
        content="desc.agree-use"
        font="sparag-lit"
        onChange={setTerms}
        classes="top-med-marg"
      />
      <NormalButton
        content="action.register"
        disabled={loading}
        classes="full-width-imp top-med-marg"
      />
      <LinkText
        text="desc.have-account"
        link="title.sign-in"
        classes="top-med-padd text-center"
        onClick={() => history.goBack()}
      />
    </form>
  );
};

const mapDispatchToProps = {
  addEmail: reduxActions.emailActions.addEmail,
};

export default connect(null, mapDispatchToProps)(SignUp);
