import { emailTypes } from "./emailTypes";

export const emailActions = {
  addEmail,
  clearEmail,
};
function addEmail(email) {
  return { type: emailTypes.ADD_EMAIL, email };
}
function clearEmail() {
  return { type: emailTypes.CLEAR_EMAIL };
}
