import React, { useState } from "react";
// Components
import FloatIconButton from "../../../common/buttons/floatIconButton";
import LineDivider from "../../../common/dividers/lineDivider";
import EmptyForm from "../../../common/error/empty";
import TextItem from "../../../common/text/TextItem";
import AddWorkSampleModal from "../helpers/addWorkSampleModal";
import WorkSampleCard from "../helpers/workSampleCard";

const WorkSamplesCard = ({
  samples,
  updateLocationWorkSample,
  getErrorItem,
}) => {
  // Hooks
  const [addSample, setAddSample] = useState(false);

  // Remove sample
  const removeSample = (sampleId) => {
    let samplesData = [...samples];
    samplesData = samplesData.filter((sampleObject) => {
      return sampleObject._id !== sampleId;
    });
    updateLocationWorkSample(samplesData);
  };

  if (samples.length === 0)
    return (
      <div className="card">
        <EmptyForm
          title="empty.samples"
          button="action.add"
          buttonIcon="plus"
          onClick={() => setAddSample(true)}
          error={getErrorItem("samples")}
          fieldName="title.work-samples"
          noSubmit
        />
        <AddWorkSampleModal
          addSample={addSample}
          setAddSample={setAddSample}
          samples={samples}
          updateLocationWorkSample={updateLocationWorkSample}
        />
      </div>
    );
  return (
    <div className="card single-grid all-med-padd height-fit-content align-start">
      <div className="flex justify-space-between full-width">
        <TextItem content="title.work-samples" classes="tiny-med text-upper" />
        <FloatIconButton
          name="plus"
          size="tiny1"
          onClick={() => setAddSample(true)}
          tooltip="action.add"
        />
      </div>
      <LineDivider />
      {samples.map((sample, index) => {
        return (
          <>
            <WorkSampleCard
              key={sample._id}
              sample={sample}
              removeSample={removeSample}
            />
            {index !== samples.length - 1 && <LineDivider />}
          </>
        );
      })}
      <AddWorkSampleModal
        addSample={addSample}
        setAddSample={setAddSample}
        samples={samples}
        updateLocationWorkSample={updateLocationWorkSample}
      />
    </div>
  );
};

export default WorkSamplesCard;
