import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { reduxActions } from "../../../redux/actions";
import { Switch, Route } from "react-router-dom";
import { apiServices } from "../../../services/apiServices";
import history from "../../../utils/history";
// Components
import MainLogo from "../../common/logo/mainLogo";
import CancelButton from "../../common/buttons/CancelButton";
import ConfirmModal from "../../common/modals/confirmModal";
import SignIn from "../../leap/auth/signIn";
import AccountDeactivated from "../../leap/auth/deactivated";
import EmailConfirm from "../../leap/auth/emailConfirm";
import ImageComponent from "../../common/image/image";
import TextItem from "../../common/text/TextItem";
import CopyRight from "../../common/footer/CopyRight";

const OrganizationAuth = ({
  image,
  dataType,
  organizationName,
  logout,
  location,
}) => {
  // Hooks
  const [logoutModal, setLogoutModal] = useState(false);

  return (
    <div className="single-canvas full-width org-auth">
      <div className="single-grid grid-center">
        <MainLogo onClick={() => history.push("/")} />
      </div>
      <div className="card all-padd">
        <div className="org-auth-content full-width grid">
          <div className="align-self-center single-grid full-width">
            <ImageComponent
              name="field.profile-picture"
              size="monster"
              image={image}
              upload={apiServices.org.addProfilePic}
              preview
              classes="margin-auto"
            />
            <TextItem
              classes="label-hev text-center"
              content={organizationName}
            />
            <TextItem
              classes="text-center brand sparag-med"
              content={`org.${dataType}`}
            />
            <CancelButton
              content="action.log-out"
              classes="sparag-med margin-auto second"
              onClick={() => setLogoutModal(true)}
            />
          </div>
          <div className="hor-div horizontal-div"></div>
          <div className="vert-div sm-hide"></div>
          <div className="justify-self-center">
            <Switch>
              <Route
                path="/organization/auth/email-confirm"
                component={() => (
                  <EmailConfirm organization location={location} />
                )}
              />
              <Route
                path="/organization/auth/deactivated"
                component={() => <AccountDeactivated organization />}
              />
              <Route
                path="/organization/auth"
                component={() => <SignIn organization />}
              />
            </Switch>
          </div>
        </div>
      </div>
      <CopyRight classes="text-center vertical-med-padd" />
      <ConfirmModal
        open={logoutModal}
        setOpen={setLogoutModal}
        title="confirm.logout"
        onAgree={logout}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    image: state.auth.image,
    dataType: state.auth.dataType,
    organizationName: state.auth.organizationName,
  };
};

const mapDispatchToProps = {
  logout: reduxActions.authActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationAuth);
