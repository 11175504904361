import React, { useRef, useState } from "react";
// Helpers
import { apiServices } from "../../../../services/apiServices";
import { HandleApiHook } from "../../../../utils/hooks";
// Components
import FetchUiData from "../../../common/layouts/fetchUiData";
import IconTitle from "../../../common/layouts/IconTitle";
import SearchBox from "../../../common/search/searchBox";
import TextItem from "../../../common/text/TextItem";
import ImageComponent from "../../../common/image/image";
import CollaboratorsSkeleton from "../../skeleton/collaboratorsSkeleton";
import { connect } from "react-redux";

const CollaboratorsCard = ({
  organizationId,
  token,
  location,
  selected,
  selectCollaborator,
  deSelectCollaborator,
  creator,
  editMode,
  accId,
}) => {
  // Hooks
  const { loading, error, setError, submit } = HandleApiHook(true);
  const [collaborators, setCollaborators] = useState([]);
  const [search, setSearch] = useState("");
  const firstRender = useRef(true);

  // Fetch collaborators
  const fetchCollaborators = async () => {
    submit({
      service: apiServices.subAcc.getCollaborators,
      query: {
        token,
        organizationId,
        search,
      },
      onSuccess: (availableCollaborators) => {
        try {
          console.log(availableCollaborators);
          availableCollaborators = availableCollaborators.filter(
            (collaborator) =>
              !selected.find((x) => x._id === collaborator._id) &&
              (collaborator._id !== accId ||
                (editMode && collaborator._id !== creator._id))
          );
          setCollaborators(availableCollaborators);
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  return (
    <div className="single-grid card all-med-padd">
      <div className="double-grid">
        <IconTitle
          icon="user-group"
          content="title.collab"
          textClasses="parag-hev text-upper"
        />
        <SearchBox
          action={() => {
            if (firstRender.current) firstRender.current = false;
            else fetchCollaborators();
          }}
          value={search}
          onChange={setSearch}
        />
      </div>
      <div className="grey3-back radial-edges all-med-padd single-grid">
        <TextItem
          content="title.sel"
          classes="micro-med text-upper side-med-padd"
        />
        {selected.length === 0 ? (
          <TextItem
            content="title.none-sel"
            classes="micro-med text-upper opacity side-sm-padd full-width text-center"
          />
        ) : (
          <div className="flex justify-start">
            {selected.map((collaborator) => {
              return (
                <ImageComponent
                  key={collaborator._id}
                  onClick={
                    collaborator._id !== accId
                      ? () => {
                          setCollaborators([...collaborators, collaborator]);
                          deSelectCollaborator(collaborator);
                        }
                      : null
                  }
                  size="big2"
                  image={collaborator.image}
                  classes="scale-hover all-sm-marg"
                  tooltip={`${collaborator.firstName} ${collaborator.lastName}`}
                />
              );
            })}
          </div>
        )}
      </div>
      <TextItem
        content="title.available-collaborators"
        classes="micro-med text-upper side-med-padd"
      />
      <FetchUiData
        service={fetchCollaborators}
        observer={location.state}
        loading={loading}
        dataFetched={collaborators}
        emptyMessage={
          selected.length !== 0 && !search ? "empty.team-more" : "empty.team"
        }
        error={error}
        setError={setError}
        Loader={CollaboratorsSkeleton}
        classes=""
        noWait
      >
        <div className="flex justify-start">
          {collaborators.map((collaborator) => {
            return (
              <ImageComponent
                key={collaborator._id}
                onClick={() => {
                  setCollaborators(
                    [...collaborators].filter(
                      (collab) => collab._id !== collaborator._id
                    )
                  );
                  selectCollaborator(collaborator);
                }}
                size="big2"
                image={collaborator.image}
                classes="scale-hover all-sm-marg"
                tooltip={`${collaborator.firstName} ${collaborator.lastName}`}
              />
            );
          })}
        </div>
      </FetchUiData>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    organizationId: state.auth.org,
    accId: state.auth.accId,
  };
};

export default connect(mapStateToProps)(CollaboratorsCard);
