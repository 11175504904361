import React from "react";
// Components
import LanguageSelector from "../languageSelector/languageSelector";
import CorporateInfo from "./CorporateInfo";
import SocialMedia from "./SocialMedia";
import CopyRight from "./CopyRight";

const Footer = ({ language, mobileApps }) => {
  // Classes
  const footerClasses =
    "footer single-grid grid-sm-gap full-width sticky side-sm-padd";

  return (
    <div className={footerClasses}>
      <div className="flex">
        <CorporateInfo />
        <SocialMedia iconSize="tiny" mobileApps={mobileApps} />
      </div>
      {language && <LanguageSelector iconSize="micro2" />}
      <CopyRight classes="text-center vertical-med-padd" />
    </div>
  );
};

export default Footer;
