import React from "react";
// Packages
import { Dialog } from "@material-ui/core";
// Components
import TextItem from "../text/TextItem";
import FormButtons from "../buttons/FormButtons";
import IconTitle from "../layouts/IconTitle";

const ConfirmModal = ({ open, setOpen, title, talk, onAgree, disabled }) => {
  // Handle open modal
  const handleClose = () => {
    setOpen(false);
  };
  const handleAgree = () => {
    setOpen(false);
    onAgree();
  };
  return (
    <Dialog className="confirm-modal" open={open} onClose={handleClose}>
      <div className="content-box all-med-padd overflow-auto">
        <div className="single-grid">
          <IconTitle
            content={title}
            icon="question"
            iconSize="tiny1"
            textClasses="parag-med text-left"
            classes="align-center"
          />
          {talk && <TextItem content={talk} classes="sparag-lit" />}
        </div>
        <FormButtons
          disabled={disabled}
          onSubmit={handleAgree}
          onCancel={handleClose}
          submitContent="action.iam-sure"
          cancelContent="action.iam-not"
          icon="check"
          classes="top-padd"
        />
      </div>
    </Dialog>
  );
};

export default ConfirmModal;
