import React from "react";
import AvatarSkeleton from "../../common/loaders/skeleton/avatarSkeleton";
import LineSkeleton from "../../common/loaders/skeleton/lineSkeleton";

const CastingExploreSkeleton = () => {
  return (
    <div className="double-grid bottom-extend top-extend-fill">
      <div className="card one-third-grid-resp grid-zero-gap">
        <AvatarSkeleton shape="square" fill size="big2" classes="grid-center" />
        <LineSkeleton rows={2} classes="all-padd" />
      </div>
      <div className="card one-third-grid-resp grid-zero-gap">
        <AvatarSkeleton shape="square" fill size="big2" classes="grid-center" />
        <LineSkeleton rows={2} classes="all-padd" />
      </div>
    </div>
  );
};

export default CastingExploreSkeleton;
