import React from "react";
// Helpers
import { connect } from "react-redux";
import { hist } from "../../../../utils/history";
// Components
import TextItem from "../../../common/text/TextItem";
import AudiCard from "../../cards/audiCard";
import UserCard from "../../cards/userCard";

const ProjectOwners = ({ projectData, accId, type }) => {
  // Destruct project data
  const { creator, org, collabs } = projectData ?? {};

  return (
    <div className="single-grid">
      <div className="double-grid">
        <UserCard
          title="title.org"
          name={org.name}
          image={org.image}
          imageIcon="building"
          UtilsRender={() => {
            return (
              <>
                <TextItem
                  content={`org.${org.type}`}
                  classes="tiny-hev brand text-start"
                />
                <TextItem
                  content={org.auth.email}
                  classes="micro-lit opacity text-start"
                  normal
                />
              </>
            );
          }}
        />
        {type === "acc" && (
          <UserCard
            title="title.creator"
            image={creator.image}
            name={`${creator.firstName} ${creator.lastName}`}
            onClick={() => {
              hist.push("/sub-account/explore/profile", {
                authId: accId !== creator._id ? creator.auth._id : null,
              });
            }}
            UtilsRender={() => {
              return (
                <>
                  <TextItem
                    content={creator.title}
                    classes="tiny-hev text-start"
                    normal
                  />
                  <TextItem
                    content={creator.auth.email}
                    classes="micro-lit opacity text-start"
                    normal
                  />
                </>
              );
            }}
          />
        )}
      </div>
      {collabs && collabs.length !== 0 && type === "acc" && (
        <AudiCard title="title.collab" items={collabs} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accId: state.auth.accId,
    type: state.auth.type,
  };
};

export default connect(mapStateToProps)(ProjectOwners);
