import React, { useState, useEffect } from "react";
// Components
import TextItem from "../../../common/text/TextItem";

const ApproveNotes = ({ profileData }) => {
  // Hooks
  const [notes, setNotes] = useState([]);

  // Set notes from profile data
  useEffect(() => {
    setNotes(profileData.data.id.approveNotes);
  }, [profileData]);

  if (notes.length !== 0)
    return (
      <div className="single-grid">
        {notes.map((note, index) => {
          return (
            <div
              key={index}
              className="card all-med-padd alert1-back justify-start"
            >
              <TextItem
                content={note}
                classes="micro-med text-start alert-talk"
              />
            </div>
          );
        })}
      </div>
    );
  return null;
};

export default ApproveNotes;
