import React, { useEffect, useState } from "react";
// Helpers
import { connect } from "react-redux";
import { apiServices } from "../../../services/apiServices";
import {
  appendArrayToFormData,
  appendObjectToFormData,
  appendToFormData,
  generateFormData,
} from "../../../utils/helperFunctions";
import history from "../../../utils/history";
import { HandleApiHook } from "../../../utils/hooks";
// Components
import FormButtons from "../../common/buttons/FormButtons";
import FileField from "../../common/fields/fileField";
import HeadTextField from "../../common/fields/headTextField";
import TextAreaField from "../../common/fields/TextAreaField";
import IconTitle from "../../common/layouts/IconTitle";
import TopLoader from "../../common/loaders/topLoader";
import ChooseDirectorsCard from "./cards/chooseDirectors";
import CollaboratorsCard from "./cards/collaboratorsCard";

const CreateRequest = ({ token, organizationId, location, editMode }) => {
  // Destruct Edit data
  const { editData } = location.state ?? {};
  const {
    _id: editId,
    name: initName,
    brief: initBrief,
    desc: initDesc,
    collabs: initCollabs,
    creator
  } = editData ?? {};
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [name, setName] = useState(initName ?? "");
  const [brief, setBrief] = useState(initBrief ?? null);
  const [desc, setDesc] = useState(initDesc ?? "");
  const [dir, setDir] = useState(false);
  const [dop, setDop] = useState(false);
  const [collaborators, setCollaborators] = useState(initCollabs ?? []);

  // Check to see if authorized to edit
  useEffect(() => {
    if (editMode && !editData) history.goBack();
  }, []);

  // Select Collaborator
  const selectCollaborator = (collaborator) => {
    setCollaborators([...collaborators, collaborator]);
  };

  // deSelect Collaborator
  const deSelectCollaborator = (collaborator) => {
    setCollaborators(
      [...collaborators].filter((collab) => collab._id !== collaborator._id)
    );
  };

  // Submit
  const submitRequest = (e) => {
    e.preventDefault();
    let formData = generateFormData("projectName", name);
    formData = appendToFormData(formData, "id", editId);
    formData = brief?.url
      ? appendObjectToFormData(formData, brief)
      : appendToFormData(formData, "brief", brief);
    formData = appendToFormData(formData, "desc", desc);
    formData = appendToFormData(formData, "isDir", dir);
    formData = appendToFormData(formData, "isDop", dop);
    formData = appendToFormData(formData, "organization", organizationId);
    formData = appendArrayToFormData(
      formData,
      "collaborators",
      collaborators.map((collaborator) => collaborator._id)
    );
    submit({
      service: editMode
        ? apiServices.proj.editRequest
        : apiServices.proj.initiateRequest,
      body: formData,
      query: { token },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onSuccess: (projectId) => history.replace(`/project/${projectId}`),
    });
  };

  return (
    <form
      onSubmit={submitRequest}
      className="single-grid bottom-extend top-extend"
      noValidate
    >
      {loading && <TopLoader />}
      <div className="noncard-container single-grid bottom-med-marg">
        <IconTitle
          content={`title.${editMode ? "edit-request" : "create-request"}`}
          icon="new-request"
          iconSize="tiny1"
          textClasses="parag-hev text-upper text-left"
          classes="align-center"
        />
        <div className="double-grid">
          <HeadTextField
            place="field.project-name"
            value={name}
            error={getErrorItem("projectName")}
            onChange={setName}
          />
          <FileField
            place="field.brief"
            accept="ext.brief"
            type="file"
            asset={brief}
            error={getErrorItem("brief")}
            onChange={setBrief}
            maxSize={50}
          />
        </div>
        <div className={editMode ? "" : "double-grid"}>
          <TextAreaField
            place="field.desc"
            innerPlace="field.desc-request"
            value={desc}
            error={getErrorItem("desc")}
            onChange={setDesc}
            rows={4}
            maxLength={500}
          />
          {!editMode && <ChooseDirectorsCard setDir={setDir} setDop={setDop} />}
        </div>
      </div>
      <CollaboratorsCard
        location={location}
        selected={collaborators}
        selectCollaborator={selectCollaborator}
        deSelectCollaborator={deSelectCollaborator}
        creator={creator}
        editMode={editMode}
      />
      <div className="noncard-container">
        <FormButtons
          disabled={loading}
          submitContent={editMode ? "action.save" : "action.create"}
          onCancel={
            editMode && !loading
              ? () => history.replace(`/project/${editId}`)
              : null
          }
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    organizationId: state.auth.org,
  };
};

export default connect(mapStateToProps)(CreateRequest);
