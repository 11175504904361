import React, { useEffect, useState } from "react";
// Helpers
import { connect } from "react-redux";
import { reduxActions } from "../../../redux/actions";
import { apiServices } from "../../../services/apiServices";
import history from "../../../utils/history";
import { HandleApiHook } from "../../../utils/hooks";
// Components
import FetchUiData from "../../common/layouts/fetchUiData";
import ProjectProfileSkeleton from "../skeleton/projectProfileSkeleton";
import InviteCard from "./cards/inviteCard";
import ProjectIdentificationCard from "./cards/projectIdentificationCard";
import ReceivedCallsheets from "./cards/receivedCallsheets";
import AssignedBreakdowns from "./cards/assignedBreakdowns";

const ProjectProfile = ({ token, match, accId, admin, changeTab, type }) => {
  // Destruct location data
  const { projectId } = match.params ?? {};
  // Hooks
  const { loading, error, setError, submit } = HandleApiHook(true);
  const [projectData, setProjectData] = useState(null);
  const [owner, setOwner] = useState(false);

  // Check project id
  useEffect(() => {
    if (!projectId) history.replace("/");
  }, [projectId]);

  // Check owner
  useEffect(() => {
    if (projectData)
      setOwner(
        admin || accId === projectData.creator._id || checkCollaborator()
      );
  }, [projectData]);

  // Fetch project profile
  const fetchProjectProfile = () => {
    submit({
      service: apiServices.proj.getProjectProfile,
      query: { token, projectId },
      onSuccess: (projectProfileData) => {
        changeTab(projectProfileData.name.trim());
        setProjectData(projectProfileData);
      },
    });
  };

  // Check if collaborator
  const checkCollaborator = () => {
    let collaborator = false;
    if (projectData.collabs && projectData.collabs.length !== 0) {
      collaborator = !!projectData.collabs.find(
        (collab) => collab._id === accId
      );
    }
    return collaborator;
  };

  return (
    <FetchUiData
      service={fetchProjectProfile}
      observer={projectId}
      loading={loading}
      dataFetched={projectData}
      error={error}
      setError={setError}
      Loader={ProjectProfileSkeleton}
      classes="single-grid bottom-extend top-extend-fill"
      noWait
    >
      <ProjectIdentificationCard projectData={projectData} owner={owner} />
      <InviteCard casting={projectData?.casting} />
      <ReceivedCallsheets projectData={projectData} />
      {type === "3rd" && <AssignedBreakdowns projectData={projectData} />}
    </FetchUiData>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    accId: state.auth.accId,
    admin: state.auth.admin,
    type: state.auth.type,
  };
};

const mapDispatchToProps = {
  changeTab: reduxActions.tabTitleActions.changeTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectProfile);
