import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { reduxActions } from "../../../redux/actions";
import { apiServices } from "../../../services/apiServices";
import { hist } from "../../../utils/history";
import { HandleApiHook, LanguageHook } from "../../../utils/hooks";
// components
import FormButtons from "../../common/buttons/FormButtons";
import CheckboxItem from "../../common/checkbox/checkbox";
import LineTextField from "../../common/fields/LineTextField";
import TopLoader from "../../common/loaders/topLoader";
import ConfirmModal from "../../common/modals/confirmModal";
import TextItem from "../../common/text/TextItem";

const OrganizationFirstSubAccount = ({
  organizationName,
  token,
  addEmail,
  logout,
  endStage,
}) => {
  // Hooks
  const { language } = LanguageHook();
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [password, setPassword] = useState("");
  const [rePass, setRePass] = useState("");
  const [terms, setTerms] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  // Submit personal start
  const submitPersonal = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.subAcc.registerFirst,
      body: {
        firstName,
        lastName,
        username,
        email,
        title,
        password,
        rePass,
        terms,
        language,
      },
      query: {
        token,
      },
      onSuccess: (token) => {
        addEmail(email);
        endStage();
        hist.replace(`/organization/auth/email-confirm`, {
          token: token,
          allowEdit: false,
        });
      },
    });
  };

  return (
    <form onSubmit={submitPersonal} className="single-grid">
      {loading && <TopLoader />}
      <TextItem
        content="desc.first-sub"
        classes="parag-lit"
        params={{ orgName: organizationName }}
      />
      <LineTextField
        place="field.firstName"
        value={firstName}
        error={getErrorItem("firstName")}
        autoFocus
        onChange={setFirstName}
      />
      <LineTextField
        place="field.lastName"
        value={lastName}
        error={getErrorItem("lastName")}
        onChange={setLastName}
      />
      <LineTextField
        place="field.username"
        value={username}
        error={getErrorItem("username")}
        onChange={setUsername}
      />
      <LineTextField
        place="field.email"
        value={email}
        error={getErrorItem("email")}
        onChange={setEmail}
        type="email"
      />
      <LineTextField
        place="field.title"
        value={title}
        error={getErrorItem("title")}
        onChange={setTitle}
      />
      <LineTextField
        place="field.password"
        value={password}
        error={getErrorItem("password")}
        password
        onChange={setPassword}
      />
      <LineTextField
        place="field.re-pass"
        value={rePass}
        error={getErrorItem("re-pass")}
        password
        onChange={setRePass}
      />
      <CheckboxItem
        content="desc.agree-use"
        font="sparag-lit"
        onChange={setTerms}
        classes="top-med-marg"
      />
      <FormButtons
        disabled={loading}
        onCancel={() => setLogoutModal(true)}
        submitContent="action.register"
        cancelContent="action.log-out"
      />
      <ConfirmModal
        open={logoutModal}
        setOpen={setLogoutModal}
        title="confirm.logout"
        onAgree={logout}
      />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    organizationName: state.auth.organizationName,
  };
};

const mapDispathToProps = {
  addEmail: reduxActions.emailActions.addEmail,
  logout: reduxActions.authActions.logout,
  endStage: reduxActions.authActions.endInit,
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(OrganizationFirstSubAccount);
