import { apiHelper } from "../../utils/apiHelper";
const endPoint = "/third";

export const third = {
  register,
  addProfilePic,
  getThirdPartyProfile,
  uploadWorkSample,
  removeWorkSample,
  updateShowreel,
  initType,
  approveCheck,
  getCastingGallery,
};

// Exec
function execPost(route, data) {
  apiHelper.post(endPoint + route, data);
}
function execPut(route, data) {
  apiHelper.put(endPoint + route, data);
}
function execGet(route, data) {
  apiHelper.get(endPoint + route, data);
}

// Functions
function register(data) {
  execPost("/", data);
}
function addProfilePic(data) {
  execPost("/profile-pic", data);
}
function uploadWorkSample(data) {
  execPost("/work-sample", data);
}
function removeWorkSample(data) {
  execPut("/work-sample", data);
}
function updateShowreel(data) {
  execPut("/showreel", data);
}
function getThirdPartyProfile(data) {
  execGet("/third-profile", data);
}
function initType(data) {
  execPost("/init-type", data);
}
function approveCheck(data) {
  execGet("/approve-chk", data);
}
function getCastingGallery(data) {
  execGet("/casting-gallery", data);
}
