import React from "react";
// Packages
import { DatePicker } from "antd";
// Components
import FieldHeader from "./components/FieldHeader";
import FieldFooter from "./components/FieldFooter";
// Helpers
import { LanguageHook } from "../../../utils/hooks";
var moment = require("moment");

const DateField = ({
  place,
  label,
  value,
  error,
  disabled,
  onChange,
  allowClear,
  type,
  customDateDisable,
}) => {
  // Hooks
  const { t, isRtl } = LanguageHook();

  // Disabled Date
  const disabledDate = (current) => {
    if (type === "forward") {
      return current && current < moment().subtract(1, "days").endOf("day");
    } else if (type === "backward") {
      return current && current > moment().subtract(1, "days").endOf("day");
    } else return null;
  };

  return (
    <div className="full-width relative">
      {(place || label) && (
        <FieldHeader
          head={place}
          label={label}
          disabled={disabled}
          error={error}
        />
      )}
      <div className={`date-field ${disabled ? "date-disabled-field" : ""}`}>
        <DatePicker
          className={`${error && !disabled ? "date-field-error" : ""}`}
          onChange={(e) => onChange(e ? e._d.toISOString() : "")}
          allowClear={allowClear}
          placeholder={t("field.select-date")}
          format="Do MMM YYYY"
          disabledDate={customDateDisable ?? disabledDate}
          style={{ fontFamily: isRtl ? "Almarai" : "Avenir" }}
          disabled={disabled}
          value={value ? moment(value) : undefined}
        />
      </div>
      {error && (
        <FieldFooter
          disabled={disabled}
          error={error}
          fieldName={place}
          value={value}
        />
      )}
    </div>
  );
};

export default DateField;
