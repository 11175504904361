import { useState } from "react";
import { apiServices } from "../../../services/apiServices";
import ExploreItems from "../../common/layouts/exploreItems";
import { connect } from "react-redux";
import NotificationsSkeleton from "../skeleton/notificationsSkeleton";
import NotificationCard from "./cards/notificationCard";

const Notifications = ({ token }) => {
  const [notifications, setNotifications] = useState([]);

  const filterGroups = [
    {
      title: "field.read",
      defVal: "",
      name: "isRead",
      values: [
        { value: "", label: "title.all" },
        { value: "read", label: "title.read" },
        { value: "not-read", label: "title.not-read" },
      ],
    },
  ];

  const addToPage = (data) => {
    let notificationData = [...notifications, ...data.notifications];
    setNotifications(notificationData);
  };

  const updateNotification = (notification) => {
    let notificationData = [...notifications];
    notificationData.map((contactItem, index) => {
      if (contactItem._id === notification._id)
        notificationData[index] = notification;
      return null;
    });
    setNotifications(notificationData);
  };

  const renderResult = () => {
    return (
      <div className="single-grid">
        {notifications.map((notification) => {
          return (
            <NotificationCard
              notification={notification}
              updateNotification={updateNotification}
            />
          );
        })}
      </div>
    );
  };

  return (
    <ExploreItems
      title="navigation.notification"
      titleIcon="bell"
      filterGroups={filterGroups}
      filterName="notifications-filters"
      service={apiServices.notification.listNotifications}
      query={{
        token,
      }}
      succ={(data) => setNotifications(data.notifications)}
      addToPage={addToPage}
      loader={NotificationsSkeleton}
      isEmpty={notifications.length === 0}
      emptyTitle="empty.notifications"
      result={renderResult}
      classes="top-extend bottom-extend side-extend-fill"
      loadMore
      noWait
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    roles: state.auth.roles,
  };
};

export default connect(mapStateToProps)(Notifications);
