import { tabTitleTypes } from "./tabTitleTypes";

export function tabTitle(state = {}, action) {
  switch (action.type) {
    case tabTitleTypes.CHANGE_TAB:
      return {
        tabValue: action.tabValue,
      };
    case tabTitleTypes.CLEAR_TAB:
      return {};
    default:
      return state;
  }
}
