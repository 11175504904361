import { apiHelper } from "../../utils/apiHelper";
const endPoint = "/callsheet";

export const callsheet = {
  getCallsheets,
  getCallsheet,
  initiateCallsheet,
  getPotentialRecipients,
  updateCallsheet,
  deleteCallsheet,
  sendCallsheet,
  getVersions,
  receiveCallsheets,
};

// Exec
function execPost(route, data) {
  apiHelper.post(endPoint + route, data);
}
function execPut(route, data) {
  apiHelper.put(endPoint + route, data);
}
function execGet(route, data) {
  apiHelper.get(endPoint + route, data);
}

// Functions
function initiateCallsheet(data) {
  execPost("/initiate", data);
}
function sendCallsheet(data) {
  execPost("/send-callsheet", data);
}
function updateCallsheet(data) {
  execPut("/update-callsheet", data);
}
function deleteCallsheet(data) {
  execPut("/delete-callsheet", data);
}
function getCallsheets(data) {
  execGet("/", data);
}
function getVersions(data) {
  execGet("/versions", data);
}
function receiveCallsheets(data) {
  execGet("/receive", data);
}
function getCallsheet(data) {
  execGet("/callsheet", data);
}
function getPotentialRecipients(data) {
  execGet("/potential-recipients", data);
}
