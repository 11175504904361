import React from "react";
// Components
import WideScreenNavigationItem from "./wideScreenNavigationItem";

const WideScreenNavigation = ({ navigations }) => {
  if (navigations)
    return (
      <div className="wide-screen-navigation to-hide flex full-height">
        {navigations.map((navigationItem) => (
          <WideScreenNavigationItem
            key={navigationItem.url}
            navigationItem={navigationItem}
          />
        ))}
      </div>
    );

  return null;
};

export default WideScreenNavigation;
