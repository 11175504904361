import React, { useState, useEffect } from "react";
// Helpers
import { testArabicText } from "../../../utils/helperFunctions";
import { LanguageHook } from "../../../utils/hooks";

const TextItem = ({
  content,
  classes,
  onClick,
  params,
  coloredKey,
  children,
  span,
  style,
  normal,
}) => {
  // Hooks
  const { t } = LanguageHook();
  const [TextValues, setTextValues] = useState([]);

  // Set text item values with arabic check
  useEffect(() => {
    const translatedParams = {};
    if (!normal && params) {
      const paramsKeys = Object.keys(params);
      paramsKeys.forEach((paramKey) => {
        translatedParams[paramKey] = t(params[paramKey]);
      });
    }
    setTextValues(
      testArabicText(normal ? content : t(content, { ...translatedParams }))
    );
  }, [content, normal]);

  // Check which text is colored
  const checkColored = (value) => {
    let coloredArray = coloredKey ? coloredKey.toLowerCase().split(" ") : [];
    return coloredArray.includes(t(value).toLowerCase());
  };

  // Render text content
  const textContent = () => {
    return (
      <>
        {TextValues.length !== 0 &&
          TextValues.map((textValue, index) => {
            return (
              <span
                key={index}
                style={{
                  fontFamily: textValue.isArabic ? "Almarai" : "Avenir",
                  width: "fit-content",
                  ...style,
                }}
                className={`${checkColored(textValue.value) ? "brand" : ""}`}
              >
                {textValue.value}
                {TextValues.length === index + 1 ? "" : " "}
              </span>
            );
          })}
        {children && " "}
        {children}
      </>
    );
  };

  if (span) {
    return (
      <span
        onClick={onClick}
        className={`text-justify ${classes} ${onClick && "line-hover pointer"}`}
      >
        {textContent()}
      </span>
    );
  } else
    return (
      <div
        onClick={onClick}
        className={`text-justify ${classes} ${onClick && "line-hover pointer"}`}
      >
        {textContent()}
      </div>
    );
};

export default TextItem;
