import React from "react";
// Components
import TextItem from "../text/TextItem";

const LinkButton = ({ content, onClick, classes, line, disabled }) => {
  // Classes
  const disabledClasses =
    "disabled decoration-hover-none fit-content forbid dark";
  const lineHover = "brand line-hover";
  const scaleHover = "link-btn brand-hover all-sm-marg scale-hover";

  return (
    <TextItem
      onClick={!disabled && onClick}
      classes={`fit-content pointer ${line ? lineHover : scaleHover} ${
        disabled ? disabledClasses : ""
      } ${classes}`}
      content={content}
    />
  );
};

export default LinkButton;
