import React from "react";
import AvatarSkeleton from "../../common/loaders/skeleton/avatarSkeleton";
import LineSkeleton from "../../common/loaders/skeleton/lineSkeleton";

const NotChosenThirdSkeleton = () => {
  return (
    <div className="flex">
      <AvatarSkeleton
        shape="circle"
        size="big2"
        classes="align-self-center"
        disable
      />
      <LineSkeleton rows={2} classes="all-med-padd flex-one" disable />
    </div>
  );
};

export default NotChosenThirdSkeleton;
