import React from "react";
// Helpers
import history from "../../../utils/history";
// Components
import { GetIcon } from "../../../assets/utils/IconGenerator";
import LinkButton from "../../common/buttons/linkButton";
import TextItem from "../../common/text/TextItem";

const AccountOrgDeactivated = () => {
  return (
    <div className="single-grid">
      <TextItem content="title.acc-org-deac" classes="shead-hev text-center" />
      <GetIcon name="sad" size="giant2" classes="margin-auto" />
      <TextItem content="desc.deac" classes="sparag-lit text-center" />
      <LinkButton
        content="title.back-home"
        line
        classes="margin-auto micro-lit"
        onClick={() => history.replace("/")}
      />
    </div>
  );
};

export default AccountOrgDeactivated;
