import React from "react";
import moment from "moment";
import TextItem from "../text/TextItem";
import { version } from "../../../../package.json";

const CopyRight = ({ classes }) => {
  return (
    <TextItem
      content="title.copy-right"
      params={{ year: moment().format("YYYY"), version }}
      classes={`micro-lit not-dir ${classes}`}
    />
  );
};

export default CopyRight;
