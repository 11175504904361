import React from "react";
// Components
import AvatarSkeleton from "../../common/loaders/skeleton/avatarSkeleton";
import LineSkeleton from "../../common/loaders/skeleton/lineSkeleton";

const ProjectProfileSkeleton = () => {
  return (
    <div className="single-grid bottom-extend top-extend-fill">
      <div className="card one-third-grid-resp grid-zero-gap">
        <AvatarSkeleton fill shape="square" minHeight={200} />
        <LineSkeleton rows={6} title classes="all-med-padd" />
      </div>
    </div>
  );
};

export default ProjectProfileSkeleton;
