export const country = [
  {
    id: 4,
    code: "af",
    codeLng: "afg",
    en: "Afghanistan",
    ar: "أفغانستان",
    dial: "+93",
    cities: [
      { shortCode: "BDS", en: "Badakhshan", ar: "بدخشان" },
      { shortCode: "BDG", en: "Badghis", ar: "بادغيس" },
      { shortCode: "BGL", en: "Baghlan", ar: "باغلان" },
      { shortCode: "BAL", en: "Balkh", ar: "بلخ" },
      { shortCode: "BAM", en: "Bamyan", ar: "باميان" },
      { shortCode: "DAY", en: "Daykundi", ar: "دايكوندي" },
      { shortCode: "FRA", en: "Farah", ar: "فرح" },
      { shortCode: "FYB", en: "Faryab", ar: "فرياب" },
      { shortCode: "GHA", en: "Ghazni", ar: "غزنة" },
      { shortCode: "GHO", en: "Ghor", ar: "غور" },
      { shortCode: "HEL", en: "Helmand", ar: "هلمند" },
      { shortCode: "HER", en: "Herat", ar: "حيرات" },
      { shortCode: "JOW", en: "Jowzjan", ar: "جوزلان" },
      { shortCode: "KAB", en: "Kabul", ar: "كابول" },
      { shortCode: "KAN", en: "Kandahar", ar: "كانداهار" },
      { shortCode: "KAP", en: "Kapisa", ar: "كابيسا" },
      { shortCode: "KHO", en: "Khost", ar: "خست" },
      { shortCode: "KNR", en: "Kunar", ar: "كنار" },
      { shortCode: "KDZ", en: "Kunduz", ar: "كندز" },
      { shortCode: "LAG", en: "Laghman", ar: "لاغمان" },
      { shortCode: "LOW", en: "Logar", ar: "لوجار" },
      { shortCode: "WAR", en: "Maidan Wardak", ar: "ميدان وردك" },
      { shortCode: "NAN", en: "Nangarhar", ar: "نانجارهار" },
      { shortCode: "NIM", en: "Nimruz", ar: "نيمروز" },
      { shortCode: "NUR", en: "Nuristan", ar: "نوريستان" },
      { shortCode: "PIA", en: "Paktia", ar: "باكتيا" },
      { shortCode: "PKA", en: "Paktika", ar: "باكتيكا" },
      { shortCode: "PAN", en: "Panjshir", ar: "بانجشير" },
      { shortCode: "PAR", en: "Parwan", ar: "باروان" },
      { shortCode: "SAM", en: "Samangan", ar: "سامانجان" },
      { shortCode: "SAR", en: "Sar-e Pol", ar: "سريل" },
      { shortCode: "TAK", en: "Takhar", ar: "تاخار" },
      { shortCode: "ORU", en: "Urozgan", ar: "اوروزجان" },
      { shortCode: "ZAB", en: "Zabul", ar: "زابول" },
    ],
  },
  {
    id: 248,
    code: "ax",
    codeLng: "ala",
    en: "Åland Islands",
    ar: "جزر أولاند",
    dial: "+358",
    cities: [
      { shortCode: "BR", en: "Brändö", ar: "براندو" },
      { shortCode: "EC", en: "Eckerö", ar: "ايكيرو" },
      { shortCode: "FN", en: "Finström", ar: "فينستروم" },
      { shortCode: "FG", en: "Föglö", ar: "فوجلو" },
      { shortCode: "GT", en: "Geta", ar: "جيتا" },
      { shortCode: "HM", en: "Hammarland", ar: "همرلاند" },
      { shortCode: "JM", en: "Jomala", ar: "جومالا" },
      { shortCode: "KM", en: "Kumlinge", ar: "كملينجي" },
      { shortCode: "KK", en: "Kökar", ar: "كوكار" },
      { shortCode: "LE", en: "Lemland", ar: "لملاند" },
      { shortCode: "LU", en: "Lumparland", ar: "لمبرلاند" },
      { shortCode: "MH", en: "Mariehamn", ar: "ماريهامن" },
      { shortCode: "SV", en: "Saltvik", ar: "سالتفيك" },
      { shortCode: "ST", en: "Sottunga", ar: "سوتونجا" },
      { shortCode: "SD", en: "Sund", ar: "سوند" },
      { shortCode: "VR", en: "Vårdö", ar: "فاردو" },
    ],
  },
  {
    id: 8,
    code: "al",
    codeLng: "alb",
    en: "Albania",
    ar: "ألبانيا",
    dial: "+355",
    cities: [
      { shortCode: "01", en: "Berat", ar: "بيرات" },
      { shortCode: "09", en: "Dibër", ar: "ديبر" },
      { shortCode: "02", en: "Durrës", ar: "دوريس" },
      { shortCode: "03", en: "Elbasan", ar: "الباسان" },
      { shortCode: "04", en: "Fier", ar: "فير" },
      { shortCode: "05", en: "Gjirokastër", ar: "جيروكاستر" },
      { shortCode: "06", en: "Korçë", ar: "كورسي" },
      { shortCode: "07", en: "Kukës", ar: "كوكيس" },
      { shortCode: "08", en: "Lezhë", ar: "ليزهي" },
      { shortCode: "10", en: "Shkodër", ar: "شكودر" },
      { shortCode: "11", en: "Tirana", ar: "تيرانا" },
      { shortCode: "12", en: "Vlorë", ar: "فلورى" },
    ],
  },
  {
    id: 12,
    code: "dz",
    codeLng: "dza",
    en: "Algeria",
    ar: "الجزائر",
    dial: "+213",
    cities: [
      { shortCode: "01", en: "Adrar", ar: "ادرار" },
      { shortCode: "44", en: "Aïn Defla", ar: "عين ديفله" },
      { shortCode: "46", en: "Aïn Témouchent", ar: "عين تيموشينت" },
      { shortCode: "16", en: "Algiers", ar: "الجيرس" },
      { shortCode: "23", en: "Annaba", ar: "انابا" },
      { shortCode: "05", en: "Batna", ar: "باتنا" },
      { shortCode: "08", en: "Béchar", ar: "بيشار" },
      { shortCode: "06", en: "Béjaïa", ar: "بيجايا" },
      { shortCode: "07", en: "Biskra", ar: "بيسكرا" },
      { shortCode: "09", en: "Blida", ar: "بليدا" },
      {
        shortCode: "34",
        en: "Bordj Bou Arréridj",
        ar: "Bordj Bou Arréridj",
      },
      { shortCode: "10", en: "Bouïra", ar: "بويرا" },
      { shortCode: "35", en: "Boumerdès", ar: "بومرديس" },
      { shortCode: "02", en: "Chlef", ar: "شليف" },
      { shortCode: "25", en: "Constantine", ar: "كونستاتين" },
      { shortCode: "17", en: "Djelfa", ar: "دجيلفا" },
      { shortCode: "32", en: "El Bayadh", ar: "الباديه" },
      { shortCode: "39", en: "El Oued", ar: "العود" },
      { shortCode: "36", en: "El Tarf", ar: "التارف" },
      { shortCode: "47", en: "Ghardaïa", ar: "غاردايا" },
      { shortCode: "24", en: "Guelma", ar: "جويلما" },
      { shortCode: "33", en: "Illizi", ar: "اليزي" },
      { shortCode: "18", en: "Jijel", ar: "جيجل" },
      { shortCode: "40", en: "Khenchela", ar: "خينشيلا" },
      { shortCode: "03", en: "Laghouat", ar: "لاغوات" },
      { shortCode: "29", en: "Mascara", ar: "ماسكارا" },
      { shortCode: "26", en: "Médéa", ar: "ميديا" },
      { shortCode: "43", en: "Mila", ar: "ميلا" },
      { shortCode: "27", en: "Mostaganem", ar: "موستاجانيم" },
      { shortCode: "28", en: "Msila", ar: "مسيلا" },
      { shortCode: "45", en: "Naâma", ar: "ناما" },
      { shortCode: "31", en: "Oran", ar: "اوران" },
      { shortCode: "30", en: "Ouargla", ar: "اورجلا" },
      { shortCode: "04", en: "Oum el Bouaghi", ar: "ام البواغي" },
      { shortCode: "48", en: "Relizane", ar: "ريليزاني" },
      { shortCode: "20", en: "Saïda", ar: "سايدا" },
      { shortCode: "19", en: "Sétif", ar: "سيتيف" },
      { shortCode: "22", en: "Sidi Bel Abbès", ar: "سيدي بيل ابيس" },
      { shortCode: "21", en: "Skikda", ar: "سكيكدا" },
      { shortCode: "41", en: "Souk Ahras", ar: "سوق احراس" },
      { shortCode: "11", en: "Tamanghasset", ar: "تامانغاسيت" },
      { shortCode: "12", en: "Tébessa", ar: "تيبيسا" },
      { shortCode: "14", en: "Tiaret", ar: "تياريت" },
      { shortCode: "37", en: "Tindouf", ar: "تيندوف" },
      { shortCode: "42", en: "Tipaza", ar: "تيبازا" },
      { shortCode: "38", en: "Tissemsilt", ar: "تيسمسيلت" },
      { shortCode: "15", en: "Tizi Ouzou", ar: "تيزي اوزو" },
      { shortCode: "13", en: "Tlemcen", ar: "تليمكين" },
    ],
  },
  {
    id: 16,
    code: "as",
    codeLng: "asm",
    en: "American Samoa",
    ar: "ساموا الأمريكية",
    dial: "+1 684",
    cities: [
      { shortCode: "01", en: "Tutuila", ar: "توتويلا" },
      { shortCode: "02", en: "Aunu'u", ar: "اونوئو" },
      { shortCode: "03", en: "Ta'ū", ar: "تائو" },
      { shortCode: "04", en: "Ofu‑Olosega", ar: "اوفو-اولوسيجا" },
      { shortCode: "21", en: "Rose Atoll", ar: "روز اتول" },
      { shortCode: "22", en: "Swains Island", ar: "جزر السوين" },
    ],
  },
  {
    id: 20,
    code: "ad",
    codeLng: "and",
    en: "Andorra",
    ar: "أندورا",
    dial: "+376",
    cities: [
      { shortCode: "07", en: "Andorra la Vella", ar: "اندورا لا فيلا" },
      { shortCode: "02", en: "Canillo", ar: "كانيلو" },
      { shortCode: "03", en: "Encamp", ar: "اينكامب" },
      {
        shortCode: "08",
        en: "Escaldes-Engordany",
        ar: "ايسكالدز-انجورداني",
      },
      { shortCode: "04", en: "La Massana", ar: "لا ماسانا" },
      { shortCode: "05", en: "Ordino", ar: "اوردينو" },
      {
        shortCode: "06",
        en: "Sant Julià de Lòria",
        ar: "سانت جوليا دي لوريا",
      },
    ],
  },
  {
    id: 24,
    code: "ao",
    codeLng: "ago",
    en: "Angola",
    ar: "أنغولا",
    dial: "+244",
    cities: [
      { shortCode: "BGO", en: "Bengo", ar: "بينجو" },
      { shortCode: "BGU", en: "Benguela", ar: "بينجويلا" },
      { shortCode: "BIE", en: "Bié", ar: "باي" },
      { shortCode: "CAB", en: "Cabinda", ar: "كابيندا" },
      { shortCode: "CCU", en: "Cuando Cubango", ar: "كواندو كوبانجو" },
      { shortCode: "CNO", en: "Cuanza Norte", ar: "كوانزا نورتي" },
      { shortCode: "CUS", en: "Cuanza Sul", ar: "كوانزا سول" },
      { shortCode: "CNN", en: "Cunene", ar: "كويني" },
      { shortCode: "HUA", en: "Huambo", ar: "هوامبو" },
      { shortCode: "HUI", en: "Huíla", ar: "هويلا" },
      { shortCode: "LUA", en: "Luanda", ar: "لواندا" },
      { shortCode: "LNO", en: "Lunda Norte", ar: "لوندا نورتي" },
      { shortCode: "LSU", en: "Lunda Sul", ar: "لوندا سول" },
      { shortCode: "MAL", en: "Malanje", ar: "مالانجي" },
      { shortCode: "MOX", en: "Moxico", ar: "موكسيكو" },
      { shortCode: "NAM", en: "Namibe", ar: "ناميبي" },
      { shortCode: "UIG", en: "Uíge", ar: "اويجي" },
      { shortCode: "ZAI", en: "Zaire", ar: "زايرى" },
    ],
  },
  {
    id: 660,
    code: "ai",
    codeLng: "aia",
    en: "Anguilla",
    ar: "أنغويلا",
    dial: "+1 264",
    cities: [
      { shortCode: "01", en: "Anguilla", ar: "انجويلا" },
      {
        shortCode: "02",
        en: "Anguillita Island",
        ar: "جزيرة انجوليتا",
      },
      { shortCode: "03", en: "Blowing Rock", ar: "بلوينج روك" },
      { shortCode: "04", en: "Cove Cay", ar: "كوف كاي" },
      { shortCode: "05", en: "Crocus Cay", ar: "كروكس كاي" },
      { shortCode: "06", en: "Deadman's Cay", ar: "ديدمان كاي" },
      { shortCode: "07", en: "Dog Island", ar: "جزيره دوج" },
      { shortCode: "08", en: "East Cay", ar: "شرق كاي" },
      { shortCode: "09", en: "Little Island", ar: "الجزيره الصغيره" },
      {
        shortCode: "10",
        en: "Little Scrub Island",
        ar: "جزيرة ليتل سكراب",
      },
      { shortCode: "11", en: "Mid Cay", ar: "منتصف كاي" },
      { shortCode: "12", en: "North Cay", ar: "شمال كاي" },
      {
        shortCode: "13",
        en: "Prickly Pear Cays",
        ar: "بريكلي بير كايز",
      },
      { shortCode: "14", en: "Rabbit Island", ar: "جزيره الارنب" },
      {
        shortCode: "15",
        en: "Sandy Island/Sand Island",
        ar: "جزيرة الرمال",
      },
      { shortCode: "16", en: "Scilly Cay", ar: "سكيلي كاي" },
      { shortCode: "17", en: "Scrub Island", ar: "جزيره سكراب" },
      { shortCode: "18", en: "Seal Island", ar: "جزيره سيل" },
      {
        shortCode: "19",
        en: "Sombrero/Hat Island",
        ar: "جزيرة سومبريرو",
      },
      { shortCode: "20", en: "South Cay", ar: "جنوب كاي" },
      {
        shortCode: "21",
        en: "South Wager Island",
        ar: "جزيرة ساوس واجر",
      },
      { shortCode: "22", en: "West Cay", ar: "غرب كاي" },
    ],
  },
  {
    id: 10,
    code: "aq",
    codeLng: "ata",
    en: "Antarctica",
    ar: "القارة القطبية الجنوبية",
    dial: "+672",
    cities: [{ shortCode: "AQ", en: "Antarctica", ar: "انتارتيكا" }],
  },
  {
    id: 28,
    code: "ag",
    codeLng: "atg",
    en: "Antigua and Barbuda",
    ar: "أنتيغوا وباربودا",
    dial: "+1268",
    cities: [
      { shortCode: "01", en: "Antigua Island", ar: "جزيره انتيجوا" },
      { shortCode: "02", en: "Barbuda Island", ar: "جزيره باربودا" },
      { shortCode: "04", en: "Bird Island", ar: "جزيره بيرد" },
      { shortCode: "05", en: "Bishop Island", ar: "جزيره بيشوب" },
      { shortCode: "06", en: "Blake Island", ar: "جزيره بليك" },
      { shortCode: "09", en: "Crump Island", ar: "جزيره كرامب" },
      { shortCode: "10", en: "Dulcina Island", ar: "جزيره دولكينا" },
      { shortCode: "11", en: "Exchange Island", ar: "جزيره اكسشينج" },
      { shortCode: "12", en: "Five Islands", ar: "الخمس جزر" },
      {
        shortCode: "13",
        en: "Great Bird Island",
        ar: "جزيرة جريت بيرد",
      },
      { shortCode: "14", en: "Green Island", ar: "الجزيره الخضراء" },
      { shortCode: "15", en: "Guiana Island", ar: "جزيره جويانا" },
      { shortCode: "17", en: "Hawes Island", ar: "جزيره هاوس" },
      {
        shortCode: "16",
        en: "Hells Gate Island",
        ar: "جزيرة هيلز جيت",
      },
      { shortCode: "18", en: "Henry Island", ar: "جزيره هندي" },
      { shortCode: "19", en: "Johnson Island", ar: "جزيره جونسون" },
      { shortCode: "20", en: "Kid Island", ar: "جزيره الطفل" },
      { shortCode: "22", en: "Lobster Island", ar: "جزيره لوبستر" },
      { shortCode: "24", en: "Maiden Island", ar: "جزيره مايدين" },
      { shortCode: "25", en: "Moor Island", ar: "جزيره مور" },
      { shortCode: "26", en: "Nanny Island", ar: "جزيره ناني" },
      { shortCode: "27", en: "Pelican Island", ar: "جزيره بيليكان" },
      {
        shortCode: "28",
        en: "Prickly Pear Island",
        ar: "جزيرة بريكلي بير",
      },
      { shortCode: "29", en: "Rabbit Island", ar: "جزيره الارنب" },
      { shortCode: "31", en: "Red Head Island", ar: "جزيره الراس الاحمر" },
      { shortCode: "03", en: "Redonda Island", ar: "جزيره ريدوندا" },
      { shortCode: "32", en: "Sandy Island", ar: "جزيره ساندي" },
      { shortCode: "33", en: "Smith Island", ar: "جزيره سميث" },
      { shortCode: "34", en: "The Sisters", ar: "الشقيقات" },
      { shortCode: "35", en: "Vernon Island", ar: "جزيره فيرنون" },
      {
        shortCode: "36",
        en: "Wicked Will Island",
        ar: "جزيرة ويكد ويل",
      },
      { shortCode: "37", en: "York Island", ar: "جزيره يورك" },
    ],
  },
  {
    id: 32,
    code: "ar",
    codeLng: "arg",
    en: "Argentina",
    ar: "الأرجنتين",
    dial: "+54",
    cities: [
      { shortCode: "B", en: "Buenos Aires", ar: "بوينو اريس" },
      { shortCode: "C", en: "Capital Federal", ar: "العاصمه الفيدراليه" },
      { shortCode: "K", en: "Catamarca", ar: "كاتاماركا" },
      { shortCode: "H", en: "Chaco", ar: "شاكو" },
      { shortCode: "U", en: "Chubut", ar: "شوبوت" },
      { shortCode: "X", en: "Córdoba", ar: "كوردوبا" },
      { shortCode: "W", en: "Corrientes", ar: "كورينتيس" },
      { shortCode: "E", en: "Entre Ríos", ar: "مدخل ريوس" },
      { shortCode: "P", en: "Formosa", ar: "فورموسا" },
      { shortCode: "Y", en: "Jujuy", ar: "جوجوي" },
      { shortCode: "L", en: "La Pampa", ar: "بامبا" },
      { shortCode: "F", en: "La Rioja", ar: "ريوجا" },
      { shortCode: "M", en: "Mendoza", ar: "ميندوزا" },
      { shortCode: "N", en: "Misiones", ar: "ميسيونيس" },
      { shortCode: "Q", en: "Neuquén", ar: "نيوكوين" },
      { shortCode: "R", en: "Río Negro", ar: "ريو نيجرو" },
      { shortCode: "A", en: "Salta", ar: "سالتا" },
      { shortCode: "J", en: "San Juan", ar: "سان وان" },
      { shortCode: "D", en: "San Luis", ar: "سان لويس" },
      { shortCode: "Z", en: "Santa Cruz", ar: "سانتا كروز" },
      { shortCode: "S", en: "Santa Fe", ar: "سانتا في" },
      {
        shortCode: "G",
        en: "Santiago del Estero",
        ar: "سانتياجو ديل ايستيرو",
      },
      { shortCode: "V", en: "Tierra del Fuego", ar: "تييرا ديل فويجو" },
      { shortCode: "T", en: "Tucumán", ar: "توكومان" },
    ],
  },
  {
    id: 51,
    code: "am",
    codeLng: "arm",
    en: "Armenia",
    ar: "أرمينيا",
    dial: "+374",
    cities: [
      { shortCode: "AG", en: "Aragatsotn", ar: "اراجاسوتن" },
      { shortCode: "AR", en: "Ararat", ar: "ارارات" },
      { shortCode: "AV", en: "Armavir", ar: "ارمافير" },
      { shortCode: "GR", en: "Gegharkunik", ar: "جيجاركونيك" },
      { shortCode: "KT", en: "Kotayk", ar: "كوتايك" },
      { shortCode: "LO", en: "Lori", ar: "لوري" },
      { shortCode: "SH", en: "Shirak", ar: "شيراك" },
      { shortCode: "SU", en: "Syunik", ar: "سيونيك" },
      { shortCode: "TV", en: "Tavush", ar: "تافوش" },
      { shortCode: "VD", en: "Vayots Dzor", ar: "فايوتس دزور" },
      { shortCode: "ER", en: "Yerevan", ar: "يريفان" },
    ],
  },
  {
    id: 533,
    code: "aw",
    codeLng: "abw",
    en: "Aruba",
    ar: "أروبا",
    dial: "+297",
    cities: [{ shortCode: "AW", en: "Aruba", ar: "اروبا" }],
  },
  {
    id: 36,
    code: "au",
    codeLng: "aus",
    en: "Australia",
    ar: "أستراليا",
    dial: "+61",
    cities: [
      {
        shortCode: "ACT",
        en: "Australian Capital Territory",
        ar: "Australian Capital Territory",
      },
      { shortCode: "NSW", en: "New South Wales", ar: "جنوب واليس الجديده" },
      {
        shortCode: "NT",
        en: "Northern Territory",
        ar: "Northern Territory",
      },
      { shortCode: "QLD", en: "Queensland", ar: "جزيره كوينز" },
      { shortCode: "SA", en: "South Australia", ar: "جنوب استراليا" },
      { shortCode: "TAS", en: "Tasmania", ar: "تاسمانيا" },
      { shortCode: "VIC", en: "Victoria", ar: "فيكتوريا" },
      {
        shortCode: "WA",
        en: "Western Australia",
        ar: "Western Australia",
      },
    ],
  },
  {
    id: 40,
    code: "at",
    codeLng: "aut",
    en: "Austria",
    ar: "النمسا",
    dial: "+43",
    cities: [
      { shortCode: "1", en: "Burgenland", ar: "بورجين لاند" },
      { shortCode: "2", en: "Kärnten", ar: "كارنتين" },
      { shortCode: "3", en: "Niederösterreich", ar: "نيدروستيريخ" },
      { shortCode: "4", en: "Oberösterreich", ar: "اوبيروستيريخ" },
      { shortCode: "5", en: "Salzburg", ar: "سالزبورج" },
      { shortCode: "6", en: "Steiermark", ar: "ستيرمارك" },
      { shortCode: "7", en: "Tirol", ar: "تيرول" },
      { shortCode: "8", en: "Vorarlberg", ar: "فورارلبيرج" },
      { shortCode: "9", en: "Wien", ar: "وين" },
    ],
  },
  {
    id: 31,
    code: "az",
    codeLng: "aze",
    en: "Azerbaijan",
    ar: "أذربيجان",
    dial: "+994",
    cities: [
      { shortCode: "ABS", en: "Abşeron", ar: "ابسيرون" },
      { shortCode: "AGC", en: "Ağcabədi", ar: "اجكابيدي" },
      { shortCode: "AGM", en: "Ağdam", ar: "اجدام" },
      { shortCode: "AGS", en: "Ağdaş", ar: "اجداس" },
      { shortCode: "AGA", en: "Ağstafa", ar: "اجستافا" },
      { shortCode: "AGU", en: "Ağsu", ar: "اجسو" },
      { shortCode: "AST", en: "Astara", ar: "استارا" },
      { shortCode: "BAK", en: "Bakı", ar: "باكي" },
      { shortCode: "BAB", en: "Babək", ar: "بابيك" },
      { shortCode: "BAL", en: "Balakən", ar: "بالاكين" },
      { shortCode: "BAR", en: "Bərdə", ar: "باردي" },
      { shortCode: "BEY", en: "Beyləqan", ar: "بيليكان" },
      { shortCode: "BIL", en: "Biləsuvar", ar: "بيليسوفار" },
      { shortCode: "CAB", en: "Cəbrayıl", ar: "كابرايل" },
      { shortCode: "CAL", en: "Cəlilabad", ar: "كاليلباد" },
      { shortCode: "CUL", en: "Culfa", ar: "كولفا" },
      { shortCode: "DAS", en: "Daşkəsən", ar: "داسكيسين" },
      { shortCode: "FUZ", en: "Füzuli", ar: "فوزولي" },
      { shortCode: "GAD", en: "Gədəbəy", ar: "جاداباي" },
      { shortCode: "GOR", en: "Goranboy", ar: "جورانبوي" },
      { shortCode: "GOY", en: "Göyçay", ar: "جويكاي" },
      { shortCode: "GYG", en: "Göygöl", ar: "جويجول" },
      { shortCode: "HAC", en: "Hacıqabul", ar: "هاكيكابول" },
      { shortCode: "IMI", en: "İmişli", ar: "ايميسلي" },
      { shortCode: "ISM", en: "İsmayıllı", ar: "اسمايلي" },
      { shortCode: "KAL", en: "Kəlbəcər", ar: "كالباكار" },
      { shortCode: "KAN", en: "Kǝngǝrli", ar: "كانجارلي" },
      { shortCode: "KUR", en: "Kürdəmir", ar: "كورديمير" },
      { shortCode: "LAC", en: "Laçın", ar: "لاكين" },
      { shortCode: "LAN", en: "Lənkəran", ar: "لينكيران" },
      { shortCode: "LER", en: "Lerik", ar: "ليريك" },
      { shortCode: "MAS", en: "Masallı", ar: "ماسالي" },
      { shortCode: "NEF", en: "Neftçala", ar: "نيفتكالا" },
      { shortCode: "OGU", en: "Oğuz", ar: "اوجوز" },
      { shortCode: "ORD", en: "Ordubad", ar: "اوردوباز" },
      { shortCode: "QAB", en: "Qəbələ", ar: "كابالا" },
      { shortCode: "QAX", en: "Qax", ar: "كاكس" },
      { shortCode: "QAZ", en: "Qazax", ar: "كازاكس" },
      { shortCode: "QOB", en: "Qobustan", ar: "كوبوستان" },
      { shortCode: "QBA", en: "Quba", ar: "كوبا" },
      { shortCode: "QBI", en: "Qubadli", ar: "كوبادلي" },
      { shortCode: "QUS", en: "Qusar", ar: "كوسار" },
      { shortCode: "SAT", en: "Saatlı", ar: "ساتلي" },
      { shortCode: "SAB", en: "Sabirabad", ar: "سابيراباد" },
      { shortCode: "SBN", en: "Şabran", ar: "سابران" },
      { shortCode: "SAD", en: "Sədərək", ar: "ساداريك" },
      { shortCode: "SAH", en: "Şahbuz", ar: "سابوز" },
      { shortCode: "SAK", en: "Şəki", ar: "ساكي" },
      { shortCode: "SAL", en: "Salyan", ar: "ساليان" },
      { shortCode: "SMI", en: "Şamaxı", ar: "ساماكسي" },
      { shortCode: "SKR", en: "Şəmkir", ar: "سامكير" },
      { shortCode: "SMX", en: "Samux", ar: "Samساموكسux" },
      { shortCode: "SAR", en: "Şərur", ar: "سارور" },
      { shortCode: "SIY", en: "Siyəzən", ar: "سييازان" },
      { shortCode: "SUS", en: "Şuşa", ar: "سوسا" },
      { shortCode: "TAR", en: "Tərtər", ar: "تارتار" },
      { shortCode: "TOV", en: "Tovuz", ar: "توفوز" },
      { shortCode: "UCA", en: "Ucar", ar: "اوكار" },
      { shortCode: "XAC", en: "Xaçmaz", ar: "اكسمكاز" },
      { shortCode: "XIZ", en: "Xızı", ar: "اكسيزي" },
      { shortCode: "XCI", en: "Xocalı", ar: "اكسوكالي" },
      { shortCode: "XVD", en: "Xocavənd", ar: "اكسوفاند" },
      { shortCode: "YAR", en: "Yardımlı", ar: "يارديميلي" },
      { shortCode: "YEV", en: "Yevlax", ar: "يفلاكس" },
      { shortCode: "ZAN", en: "Zəngilan", ar: "زينجيلان" },
      { shortCode: "ZAQ", en: "Zaqatala", ar: "زاكاتالا" },
      { shortCode: "ZAR", en: "Zərdab", ar: "زارداب" },
    ],
  },
  {
    id: 44,
    code: "bs",
    codeLng: "bhs",
    en: "Bahamas",
    ar: "باهاماس",
    dial: "+1 242",
    cities: [
      { shortCode: "01", en: "Acklins Island", ar: "جزيره اكلينز" },
      { shortCode: "22", en: "Berry Islands", ar: "جزيره بيري" },
      { shortCode: "02", en: "Bimini", ar: "بيميني" },
      { shortCode: "23", en: "Black Point", ar: "النقطه السوداء" },
      { shortCode: "03", en: "Cat Island", ar: "جزيره القط" },
      { shortCode: "24", en: "Central Abaco", ar: "سينترال الباكو" },
      {
        shortCode: "28",
        en: "Crooked Island and Long Cay",
        ar: "Crooked Island and Long Cay",
      },
      {
        shortCode: "29",
        en: "East Grand Bahama",
        ar: "East Grand Bahama",
      },
      { shortCode: "04", en: "Exuma", ar: "ايكسوما" },
      { shortCode: "05", en: "Freeport", ar: "فريبورت" },
      { shortCode: "06", en: "Fresh Creek", ar: "فريش كريك" },
      {
        shortCode: "07",
        en: "Governor's Harbour",
        ar: "Governor's Harbour",
      },
      { shortCode: "08", en: "Green Turtle Cay", ar: "جرين ترتل كاي" },
      { shortCode: "09", en: "Harbour Island", ar: "جزيره هاربور" },
      { shortCode: "10", en: "High Rock", ar: "هاي روك" },
      { shortCode: "11", en: "Inagua", ar: "ايناجوا" },
      { shortCode: "12", en: "Kemps Bay", ar: "كيمبس باي" },
      { shortCode: "13", en: "Long Island", ar: "جزيره لونج" },
      { shortCode: "14", en: "Marsh Harbour", ar: "مارش هاربور" },
      { shortCode: "15", en: "Mayaguana", ar: "ماياجوانا" },
      { shortCode: "40", en: "Moore’s Island", ar: "جزيره مور" },
      { shortCode: "16", en: "New Providence", ar: "بروفينس الجديده" },
      {
        shortCode: "17",
        en: "Nichollstown and Berry Islands",
        ar: "Nichollstown and Berry Islands",
      },
      { shortCode: "42", en: "North Abaco", ar: "شمال اباكو" },
      { shortCode: "41", en: "North Andros", ar: "شامال اندروس" },
      { shortCode: "33", en: "North Eleuthera", ar: "شمال ايليثيرا" },
      { shortCode: "18", en: "Ragged Island", ar: "جزيره راجد" },
      { shortCode: "19", en: "Rock Sound", ar: "روك ساوند" },
      {
        shortCode: "20",
        en: "San Salvador and Rum Cay",
        ar: "San Salvador and Rum Cay",
      },
      { shortCode: "21", en: "Sandy Point", ar: "نقطه ساندي" },
      { shortCode: "35", en: "South Abaco", ar: "جنوب اباكو" },
      { shortCode: "36", en: "South Andros", ar: "جنوب اندرس" },
      { shortCode: "37", en: "South Eleuthera", ar: "جنوب ايليثيرا" },
      {
        shortCode: "39",
        en: "West Grand Bahama",
        ar: "West Grand Bahama",
      },
    ],
  },
  {
    id: 48,
    code: "bh",
    codeLng: "bhr",
    en: "Bahrain",
    ar: "البحرين",
    dial: "+973",
    cities: [
      { shortCode: "14", en: "Al Janūbīyah", ar: "الجنوبيه" },
      { shortCode: "13", en: "Al Manāmah", ar: "المنامه" },
      { shortCode: "15", en: "Al Muḩarraq", ar: "المحراق" },
      { shortCode: "16", en: "Al Wusţá", ar: "الوسطى" },
      { shortCode: "17", en: "Ash Shamālīyah", ar: "الشماليه" },
    ],
  },
  {
    id: 50,
    code: "bd",
    codeLng: "bgd",
    en: "Bangladesh",
    ar: "بنغلاديش",
    dial: "+880",
    cities: [
      { shortCode: "A", en: "Barisal", ar: "باريسال" },
      { shortCode: "B", en: "Chittagong", ar: "شيتاجونج" },
      { shortCode: "C", en: "Dhaka", ar: "داكا" },
      { shortCode: "D", en: "Khulna", ar: "كولنا" },
      { shortCode: "M", en: "Mymensingh", ar: "ميمينسينج" },
      { shortCode: "E", en: "Rajshahi", ar: "راجشاهي" },
      { shortCode: "F", en: "Rangpur", ar: "رانجبور" },
      { shortCode: "G", en: "Sylhet", ar: "سيلهيت" },
    ],
  },
  {
    id: 52,
    code: "bb",
    codeLng: "brb",
    en: "Barbados",
    ar: "باربادوس",
    dial: "+1 246",
    cities: [
      { shortCode: "01", en: "Christ Church", ar: "كنيسه المسيح" },
      { shortCode: "02", en: "Saint Andrew", ar: "سينت اندرو" },
      { shortCode: "03", en: "Saint George", ar: "سينت جورج" },
      { shortCode: "04", en: "Saint James", ar: "سينت جيمس" },
      { shortCode: "05", en: "Saint John", ar: "سينت جون" },
      { shortCode: "06", en: "Saint Joseph", ar: "سينت جوسيف" },
      { shortCode: "07", en: "Saint Lucy", ar: "سينت لوسي" },
      { shortCode: "08", en: "Saint Michael", ar: "سينت مايكيل" },
      { shortCode: "09", en: "Saint Peter", ar: "سينت بيتر" },
      { shortCode: "10", en: "Saint Philip", ar: "سينت فيليب" },
      { shortCode: "11", en: "Saint Thomas", ar: "سينت توماس" },
    ],
  },
  {
    id: 112,
    code: "by",
    codeLng: "blr",
    en: "Belarus",
    ar: "بيلاروس",
    dial: "+375",
    cities: [
      { shortCode: "BR", en: "Brest voblast", ar: "بريست فوبلاس" },
      { shortCode: "HM", en: "Gorod Minsk", ar: "جورود مينسك" },
      { shortCode: "HO", en: "Homiel voblast", ar: "هوميل فوبلاست" },
      { shortCode: "HR", en: "Hrodna voblast", ar: "هيرودنا فوبلاست" },
      { shortCode: "MA", en: "Mahilyow voblast", ar: "ماهيليو فوبلاست" },
      { shortCode: "MI", en: "Minsk voblast", ar: "مينسك فوبلاست" },
      { shortCode: "VI", en: "Vitsebsk voblast", ar: "فيتسيبسك فوبلاست" },
    ],
  },
  {
    id: 56,
    code: "be",
    codeLng: "bel",
    en: "Belgium",
    ar: "بلجيكا",
    dial: "+32",
    cities: [
      { shortCode: "BRU", en: "Brussels", ar: "براسيلس" },
      { shortCode: "VLG", en: "Flanders", ar: "فلاندرز" },
      { shortCode: "WAL", en: "Wallonia", ar: "والونيا" },
    ],
  },
  {
    id: 84,
    code: "bz",
    codeLng: "blz",
    en: "Belize",
    ar: "بليز",
    dial: "+501",
    cities: [
      { shortCode: "BZ", en: "Belize District", ar: "حي بيليز" },
      { shortCode: "CY", en: "Cayo District", ar: "حي كايو" },
      {
        shortCode: "CZL",
        en: "Corozal District",
        ar: "Corozal District",
      },
      {
        shortCode: "OW",
        en: "Orange Walk District",
        ar: "Orange Walk District",
      },
      {
        shortCode: "SC",
        en: "Stann Creek District",
        ar: "Stann Creek District",
      },
      { shortCode: "TOL", en: "Toledo District", ar: "حي توليدو" },
    ],
  },
  {
    id: 204,
    code: "bj",
    codeLng: "ben",
    en: "Benin",
    ar: "بنين",
    dial: "+229",
    cities: [
      { shortCode: "AL", en: "Alibori", ar: "اليبوري" },
      { shortCode: "AK", en: "Atakora", ar: "اتاكورا" },
      { shortCode: "AQ", en: "Atlantique", ar: "اتلاتيك" },
      { shortCode: "BO", en: "Borgou", ar: "بورجو" },
      {
        shortCode: "CO",
        en: "Collines Department",
        ar: "Collines Department",
      },
      { shortCode: "DO", en: "Donga", ar: "دونجا" },
      { shortCode: "KO", en: "Kouffo", ar: "كوفو" },
      {
        shortCode: "LI",
        en: "Littoral Department",
        ar: "Littoral Department",
      },
      { shortCode: "MO", en: "Mono Department", ar: "قسم مونو" },
      { shortCode: "OU", en: "Ouémé", ar: "اويمي" },
      { shortCode: "PL", en: "Plateau", ar: "بلاتو" },
      { shortCode: "ZO", en: "Zou", ar: "زو" },
    ],
  },
  {
    id: 60,
    code: "bm",
    codeLng: "bmu",
    en: "Bermuda",
    ar: "برمودا",
    dial: "+1 441",
    cities: [
      { shortCode: "03", en: "City of Hamilton", ar: "مدينه هاميلتون" },
      {
        shortCode: "01",
        en: "Devonshire Parish",
        ar: "Devonshire Parish",
      },
      { shortCode: "02", en: "Hamilton Parish", ar: "هاميلتون باريش" },
      { shortCode: "04", en: "Paget Parish", ar: "باجيت باريش" },
      { shortCode: "05", en: "Pembroke Parish", ar: "بيمبوركي باريش" },
      { shortCode: "08", en: "Sandys Parish", ar: "سانديز باريش" },
      { shortCode: "09", en: "Smith's Parish", ar: "سميث باريش" },
      {
        shortCode: "10",
        en: "Southampton Parish",
        ar: "Southampton Parish",
      },
      {
        shortCode: "07",
        en: "St. George's Parish",
        ar: "St. George's Parish",
      },
      {
        shortCode: "06",
        en: "Town of St. George",
        ar: "Town of St. George",
      },
      { shortCode: "11", en: "Warwick Parish", ar: "وارويك باريش" },
    ],
  },
  {
    id: 64,
    code: "bt",
    codeLng: "btn",
    en: "Bhutan",
    ar: "بوتان",
    dial: "+975",
    cities: [
      { shortCode: "33", en: "Bumthang", ar: "بومثانج" },
      { shortCode: "12", en: "Chhukha", ar: "تشوكا" },
      { shortCode: "22", en: "Dagana", ar: "داجانا" },
      { shortCode: "GA", en: "Gasa", ar: "جاسا" },
      { shortCode: "13", en: "Haa", ar: "هاا" },
      { shortCode: "44", en: "Lhuntse", ar: "لهونتسي" },
      { shortCode: "42", en: "Mongar", ar: "مونجار" },
      { shortCode: "11", en: "Paro", ar: "بارو" },
      { shortCode: "43", en: "Pemagatshel", ar: "بيماجاتشيل" },
      { shortCode: "23", en: "Punakha", ar: "بونوخا" },
      { shortCode: "45", en: "Samdrup Jongkhar", ar: "جونجكار سامدرب" },
      { shortCode: "14", en: "Samtse", ar: "سامتسي" },
      { shortCode: "31", en: "Sarpang", ar: "ساربانج" },
      { shortCode: "15", en: "Thimphu", ar: "تيمبو" },
      { shortCode: "41", en: "Trashigang", ar: "تراشيجانج" },
      { shortCode: "TY", en: "Trashiyangtse", ar: "تراشييانجتسي" },
      { shortCode: "32", en: "Trongsa", ar: "ترونجسا" },
      { shortCode: "21", en: "Tsirang", ar: "تسيرانج" },
      { shortCode: "24", en: "Wangdue Phodrang", ar: "وانجدو فودرانج" },
      { shortCode: "34", en: "Zhemgang", ar: "زيمجانج" },
    ],
  },
  {
    id: 68,
    code: "bo",
    codeLng: "bol",
    en: "Bolivia (Plurinational State of)",
    ar: "بوليفيا",
    dial: "+591",
    cities: [
      { shortCode: "B", en: "Beni", ar: "بيني" },
      { shortCode: "H", en: "Chuquisaca", ar: "شوكويساكا" },
      { shortCode: "C", en: "Cochabamba", ar: "شوكوبامبا" },
      { shortCode: "L", en: "La Paz", ar: "باز" },
      { shortCode: "O", en: "Oruro", ar: "اورورو" },
      { shortCode: "N", en: "Pando", ar: "باندو" },
      { shortCode: "P", en: "Potosí", ar: "بوتوسي" },
      { shortCode: "S", en: "Santa Cruz", ar: "سانتا كروز" },
      { shortCode: "T", en: "Tarija", ar: "تاريجا" },
    ],
  },
  {
    id: 535,
    code: "bq",
    codeLng: "bes",
    en: "Bonaire, Sint Eustatius and Saba",
    ar: "الجزر الكاريبية الهولندية",
    dial: "+599",
    cities: [
      { shortCode: "BO", en: "Bonaire", ar: "بونير" },
      { shortCode: "SA", en: "Saba Island", ar: "جزيره سابا" },
      { shortCode: "SE", en: "Sint Eustatius", ar: "سينت يوستاتيوس" },
    ],
  },
  {
    id: 70,
    code: "ba",
    codeLng: "bih",
    en: "Bosnia and Herzegovina",
    ar: "البوسنة والهرسك",
    dial: "+387",
    cities: [
      { shortCode: "BRC", en: "Brčko Distrikt", ar: "بركو ديستريكت" },
      {
        shortCode: "BIH",
        en: "Federacija Bosne i Hercegovine",
        ar: "Federacija Bosne i Hercegovine",
      },
      { shortCode: "SRP", en: "Republika Srpska", ar: "ريبابليكا سربسكا" },
    ],
  },
  {
    id: 72,
    code: "bw",
    codeLng: "bwa",
    en: "Botswana",
    ar: "بوتسوانا",
    dial: "+267",
    cities: [
      { shortCode: "CE", en: "Central", ar: "سنترال" },
      { shortCode: "GH", en: "Ghanzi", ar: "غانزي" },
      { shortCode: "KG", en: "Kgalagadi", ar: "كجالاجادي" },
      { shortCode: "KL", en: "Kgatleng", ar: "جاتلينج" },
      { shortCode: "KW", en: "Kweneng", ar: "كوينينج" },
      { shortCode: "NW", en: "North West", ar: "شمال غرب" },
      { shortCode: "NE", en: "North-East", ar: "شمال شرق" },
      { shortCode: "SE", en: "South East", ar: "جنوب شرق" },
      { shortCode: "SO", en: "Southern", ar: "الجنوبيه" },
    ],
  },
  {
    id: 74,
    code: "bv",
    codeLng: "bvt",
    en: "Bouvet Island",
    ar: "جزيرة بوفيه",
    dial: "+47",
    cities: [{ shortCode: "BV", en: "Bouvet Island", ar: "جزيره بوفيت" }],
  },
  {
    id: 76,
    code: "br",
    codeLng: "bra",
    en: "Brazil",
    ar: "البرازيل",
    dial: "+55",
    cities: [
      { shortCode: "AC", en: "Acre", ar: "اكري" },
      { shortCode: "AL", en: "Alagoas", ar: "الاجواس" },
      { shortCode: "AP", en: "Amapá", ar: "امابا" },
      { shortCode: "AM", en: "Amazonas", ar: "امازوناس" },
      { shortCode: "BA", en: "Bahia", ar: "بهيه" },
      { shortCode: "CE", en: "Ceará", ar: "سيارا" },
      { shortCode: "DF", en: "Distrito Federal", ar: "ديستريتو الفيديراليه" },
      { shortCode: "ES", en: "Espírito Santo", ar: "ايسبريتو سانتو" },
      { shortCode: "GO", en: "Goiás", ar: "جوياس" },
      { shortCode: "MA", en: "Maranhão", ar: "مارانهاو" },
      { shortCode: "MT", en: "Mato Grosso", ar: "ماتو جروسو" },
      {
        shortCode: "MS",
        en: "Mato Grosso do Sul",
        ar: "Mato Grosso do Sul",
      },
      { shortCode: "MG", en: "Minas Gerais", ar: "ميناس جيرايس" },
      { shortCode: "PA", en: "Pará", ar: "بارا" },
      { shortCode: "PB", en: "Paraíba", ar: "بارايبا" },
      { shortCode: "PR", en: "Paraná", ar: "بارانا" },
      { shortCode: "PE", en: "Pernambuco", ar: "بيرنامبوكو" },
      { shortCode: "PI", en: "Piauí", ar: "بياوي" },
      { shortCode: "RJ", en: "Rio de Janeiro", ar: "ريو دي جانيرو" },
      {
        shortCode: "RN",
        en: "Rio Grande do Norte",
        ar: "Rio Grande do Norte",
      },
      {
        shortCode: "RS",
        en: "Rio Grande do Sul",
        ar: "Rio Grande do Sul",
      },
      { shortCode: "RO", en: "Rondônia", ar: "روندونيا" },
      { shortCode: "RR", en: "Roraima", ar: "رورايما" },
      { shortCode: "SC", en: "Santa Catarina", ar: "سانتا كاتارينا" },
      { shortCode: "SP", en: "São Paulo", ar: "ساو باولو" },
      { shortCode: "SE", en: "Sergipe", ar: "سيرجيبي" },
      { shortCode: "TO", en: "Tocantins", ar: "توكانتينس" },
    ],
  },
  {
    id: 86,
    code: "io",
    codeLng: "iot",
    en: "British Indian Ocean Territory",
    ar: "إقليم المحيط الهندي البريطاني",
    dial: "+246",
    cities: [
      {
        shortCode: "IO",
        en: "British Indian Ocean Territory",
        ar: "British Indian Ocean Territory",
      },
    ],
  },
  {
    id: 96,
    code: "bn",
    codeLng: "brn",
    en: "Brunei Darussalam",
    ar: "بروناي",
    dial: "+673",
    cities: [
      { shortCode: "BE", en: "Belait", ar: "بيلايت" },
      { shortCode: "BM", en: "Brunei Muara", ar: "بروني موارا" },
      { shortCode: "TE", en: "Temburong", ar: "تيمبورونج" },
      { shortCode: "TU", en: "Tutong", ar: "توتونج" },
    ],
  },
  {
    id: 100,
    code: "bg",
    codeLng: "bgr",
    en: "Bulgaria",
    ar: "بلغاريا",
    dial: "+359",
    cities: [
      { shortCode: "01", en: "Blagoevgrad", ar: "بلاجويفجراد" },
      { shortCode: "02", en: "Burgas", ar: "بورجاس" },
      { shortCode: "08", en: "Dobrich", ar: "دوبريخ" },
      { shortCode: "07", en: "Gabrovo", ar: "جابروفو" },
      { shortCode: "28", en: "Jambol", ar: "جامبول" },
      { shortCode: "26", en: "Khaskovo", ar: "خاسكوفو" },
      { shortCode: "10", en: "Kjustendil", ar: "كجوستينديل" },
      { shortCode: "09", en: "Kurdzhali", ar: "كوردزالي" },
      { shortCode: "11", en: "Lovech", ar: "لوفيك" },
      { shortCode: "12", en: "Montana", ar: "مونتانا" },
      { shortCode: "13", en: "Pazardzhik", ar: "بازاردزيك" },
      { shortCode: "14", en: "Pernik", ar: "بيرنيك" },
      { shortCode: "15", en: "Pleven", ar: "بليفين" },
      { shortCode: "16", en: "Plovdiv", ar: "بلوفديف" },
      { shortCode: "17", en: "Razgrad", ar: "رازجراد" },
      { shortCode: "18", en: "Ruse", ar: "روسي" },
      { shortCode: "27", en: "Shumen", ar: "شومين" },
      { shortCode: "19", en: "Silistra", ar: "سيليسترا" },
      { shortCode: "20", en: "Sliven", ar: "سليفين" },
      { shortCode: "21", en: "Smoljan", ar: "سمولجان" },
      { shortCode: "23", en: "Sofija", ar: "سوفيجا" },
      { shortCode: "22", en: "Sofija-Grad", ar: "سوفيجا-جراد" },
      { shortCode: "24", en: "Stara Zagora", ar: "ستارا زاجورا" },
      { shortCode: "25", en: "Turgovishhe", ar: "تورجوفيشي" },
      { shortCode: "03", en: "Varna", ar: "فارنا" },
      { shortCode: "04", en: "Veliko Turnovo", ar: "فيليكو تورنوفو" },
      { shortCode: "05", en: "Vidin", ar: "فيدين" },
      { shortCode: "06", en: "Vraca", ar: "فراكا" },
    ],
  },
  {
    id: 854,
    code: "bf",
    codeLng: "bfa",
    en: "Burkina Faso",
    ar: "بوركينا فاسو",
    dial: "+226",
    cities: [
      { shortCode: "BAL", en: "Balé", ar: "بالى" },
      { shortCode: "BAM", en: "Bam/Lake Bam", ar: "بام/لاكي بام" },
      { shortCode: "BAN", en: "Banwa Province", ar: "بانوا بروفينس" },
      { shortCode: "BAZ", en: "Bazèga", ar: "بازيجا" },
      { shortCode: "BGR", en: "Bougouriba", ar: "بوجوريبا" },
      {
        shortCode: "BLG",
        en: "Boulgou Province",
        ar: "Boulgou Province",
      },
      { shortCode: "BLK", en: "Boulkiemdé", ar: "بولكيمدى" },
      { shortCode: "COM", en: "Comoé/Komoe", ar: "كومو" },
      {
        shortCode: "GAN",
        en: "Ganzourgou Province",
        ar: "Ganzourgou Province",
      },
      { shortCode: "GNA", en: "Gnagna", ar: "جناجنا" },
      { shortCode: "GOU", en: "Gourma Province", ar: "جورما برفينس" },
      { shortCode: "HOU", en: "Houet", ar: "هويت" },
      { shortCode: "IOB", en: "Ioba", ar: "يوبا" },
      { shortCode: "KAD", en: "Kadiogo", ar: "كاديوجو" },
      { shortCode: "KEN", en: "Kénédougou", ar: "كينيدوجو" },
      { shortCode: "KMD", en: "Komondjari", ar: "كوموندجاري" },
      { shortCode: "KMP", en: "Kompienga", ar: "كومبينجا" },
      { shortCode: "KOS", en: "Kossi Province", ar: "كوسي بروفينس" },
      { shortCode: "KOP", en: "Koulpélogo", ar: "كولبيلوجو" },
      { shortCode: "KOT", en: "Kouritenga", ar: "كوريتينجا" },
      { shortCode: "KOW", en: "Kourwéogo", ar: "كورويوجو" },
      { shortCode: "LER", en: "Léraba", ar: "ليرابا" },
      { shortCode: "LOR", en: "Loroum", ar: "لوروم" },
      { shortCode: "MOU", en: "Mouhoun", ar: "مونهون" },
      { shortCode: "NAM", en: "Namentenga", ar: "نامينتينجا" },
      { shortCode: "NAO", en: "Naouri/Nahouri", ar: "ناوري" },
      { shortCode: "NAY", en: "Nayala", ar: "نايالا" },
      { shortCode: "NOU", en: "Noumbiel", ar: "نومبيل" },
      { shortCode: "OUB", en: "Oubritenga", ar: "اوبريتينجا" },
      { shortCode: "OUD", en: "Oudalan", ar: "اودالان" },
      { shortCode: "PAS", en: "Passoré", ar: "باسوري" },
      { shortCode: "PON", en: "Poni", ar: "بوني" },
      { shortCode: "SNG", en: "Sanguié", ar: "سانجوي" },
      { shortCode: "SMT", en: "Sanmatenga", ar: "سانماتينجا" },
      { shortCode: "SEN", en: "Séno", ar: "سينو" },
      { shortCode: "SIS", en: "Sissili", ar: "سيسيلي" },
      { shortCode: "SOM", en: "Soum", ar: "سوم" },
      { shortCode: "SOR", en: "Sourou", ar: "سورو" },
      { shortCode: "TAP", en: "Tapoa", ar: "تابوا" },
      { shortCode: "TUI", en: "Tui/Tuy", ar: "توي" },
      { shortCode: "YAG", en: "Yagha", ar: "ياغا" },
      { shortCode: "YAT", en: "Yatenga", ar: "ياتينغا" },
      { shortCode: "ZIR", en: "Ziro", ar: "زيرو" },
      { shortCode: "ZON", en: "Zondoma", ar: "زوندوما" },
      { shortCode: "ZOU", en: "Zoundwéogo", ar: "زوندويجو" },
    ],
  },
  {
    id: 108,
    code: "bi",
    codeLng: "bdi",
    en: "Burundi",
    ar: "بوروندي",
    dial: "+257",
    cities: [
      { shortCode: "BB", en: "Bubanza", ar: "بوبانزا" },
      { shortCode: "BM", en: "Bujumbura Mairie", ar: "بوجومبورا" },
      { shortCode: "BL", en: "Bujumbura Rural", ar: "بوجومبورا رورال" },
      { shortCode: "BR", en: "Bururi", ar: "بوروري" },
      { shortCode: "CA", en: "Cankuzo", ar: "كانكوزو" },
      { shortCode: "CI", en: "Cibitoke", ar: "سيبيتوكي" },
      { shortCode: "GI", en: "Gitega", ar: "جيتيجا" },
      { shortCode: "KR", en: "Karuzi", ar: "كاروزي" },
      { shortCode: "KY", en: "Kayanza", ar: "كايانزا" },
      { shortCode: "KI", en: "Kirundo", ar: "كيروندو" },
      { shortCode: "MA", en: "Makamba", ar: "ميكامبا" },
      { shortCode: "MU", en: "Muramvya", ar: "مورامفيا" },
      { shortCode: "MY", en: "Muyinga", ar: "مويينجا" },
      { shortCode: "MW", en: "Mwaro", ar: "موارو" },
      { shortCode: "NG", en: "Ngozi", ar: "نجوزي" },
      { shortCode: "RT", en: "Rutana", ar: "روتانا" },
      { shortCode: "RY", en: "Ruyigi", ar: "رويجي" },
    ],
  },
  {
    id: 132,
    code: "cv",
    codeLng: "cpv",
    en: "Cabo Verde",
    ar: "الرأس الأخضر",
    dial: "+238",
    cities: [
      { shortCode: "BV", en: "Boa Vista", ar: "بوا فيستا" },
      { shortCode: "BR", en: "Brava", ar: "برافا" },
      {
        shortCode: "CS",
        en: "Calheta de São Miguel",
        ar: "Calheta de São Miguel",
      },
      { shortCode: "MA", en: "Maio", ar: "مايو" },
      { shortCode: "MO", en: "Mosteiros", ar: "موستيروس" },
      { shortCode: "PA", en: "Paúl", ar: "بول" },
      { shortCode: "PN", en: "Porto Novo", ar: "بورتو نوفو" },
      { shortCode: "PR", en: "Praia", ar: "برايا" },
      { shortCode: "RB", en: "Ribeira Brava", ar: "ريبيرا برافا" },
      { shortCode: "RG", en: "Ribeira Grande", ar: "ريبيرا جراندي" },
      { shortCode: "SL", en: "Sal", ar: "سال" },
      { shortCode: "CA", en: "Santa Catarina", ar: "سانتا كاتارينا" },
      { shortCode: "CR", en: "Santa Cruz", ar: "سانتا كروز" },
      { shortCode: "SD", en: "São Domingos", ar: "ساو دومينجوز" },
      { shortCode: "SF", en: "São Filipe", ar: "ساو فيليب" },
      { shortCode: "SN", en: "São Nicolau", ar: "ساو نيكولاو" },
      { shortCode: "SV", en: "São Vicente", ar: "ساو فيكينتي" },
      { shortCode: "TA", en: "Tarrafal", ar: "تارافال" },
      {
        shortCode: "TS",
        en: "Tarrafal de São Nicolau",
        ar: "Tarrafal de São Nicolau",
      },
    ],
  },
  {
    id: 116,
    code: "kh",
    codeLng: "khm",
    en: "Cambodia",
    ar: "كمبوديا",
    dial: "+855",
    cities: [
      { shortCode: "2", en: "Baat Dambang", ar: "بات دامبانج" },
      {
        shortCode: "1",
        en: "Banteay Mean Chey",
        ar: "Banteay Mean Chey",
      },
      { shortCode: "3", en: "Kampong Chaam", ar: "كامبونج شام" },
      { shortCode: "4", en: "Kampong Chhnang", ar: "كامبونج شنانج" },
      { shortCode: "5", en: "Kampong Spueu", ar: "كامبونج سبيو" },
      { shortCode: "6", en: "Kampong Thum", ar: "كامبونج ثام" },
      { shortCode: "7", en: "Kampot", ar: "كامبوت" },
      { shortCode: "8", en: "Kandaal", ar: "كاندال" },
      { shortCode: "9", en: "Kaoh Kong", ar: "كاو كونج" },
      { shortCode: "10", en: "Kracheh", ar: "كراشين" },
      { shortCode: "23", en: "Krong Kaeb", ar: "كرونج كايب" },
      { shortCode: "24", en: "Krong Pailin", ar: "كرونج بايلين" },
      {
        shortCode: "18",
        en: "Krong Preah Sihanouk",
        ar: "Krong Preah Sihanouk",
      },
      { shortCode: "11", en: "Mondol Kiri", ar: "موندول كيري" },
      { shortCode: "22", en: "Otdar Mean Chey", ar: "اوتدار مين شيي" },
      { shortCode: "12", en: "Phnom Penh", ar: "فنوم بين" },
      { shortCode: "15", en: "Pousaat", ar: "بوسات" },
      { shortCode: "13", en: "Preah Vihear", ar: "بري فيهيار" },
      { shortCode: "14", en: "Prey Veaeng", ar: "بري فيانج" },
      { shortCode: "16", en: "Rotanah Kiri", ar: "روتانا كيري" },
      { shortCode: "17", en: "Siem Reab", ar: "سيم ريب" },
      { shortCode: "19", en: "Stueng Treng", ar: "ستوينج ترينج" },
      { shortCode: "20", en: "Svaay Rieng", ar: "سفاي رينج" },
      { shortCode: "21", en: "Taakaev", ar: "تاكايف" },
      { shortCode: "25", en: "Tbong Khmum", ar: "تبونج خموم" },
    ],
  },
  {
    id: 120,
    code: "cm",
    codeLng: "cmr",
    en: "Cameroon",
    ar: "الكاميرون",
    dial: "+237",
    cities: [
      { shortCode: "AD", en: "Adamaoua", ar: "اداموا" },
      { shortCode: "CE", en: "Centre", ar: "سينتري" },
      { shortCode: "ES", en: "Est", ar: "ايست" },
      { shortCode: "EN", en: "Extrême-Nord", ar: "اكستريم نورد" },
      { shortCode: "LT", en: "Littoral", ar: "ليتورال" },
      { shortCode: "NO", en: "Nord", ar: "نورد" },
      { shortCode: "NW", en: "Nord-Ouest", ar: "نورد اويست" },
      { shortCode: "OU", en: "Ouest", ar: "اويست" },
      { shortCode: "SU", en: "Sud", ar: "ساد" },
      { shortCode: "SW", en: "Sud-Ouest", ar: "ساد اويست" },
    ],
  },
  {
    id: 124,
    code: "ca",
    codeLng: "can",
    en: "Canada",
    ar: "كندا",
    dial: "+1",
    cities: [
      { shortCode: "AB", en: "Alberta", ar: "البيرتا" },
      { shortCode: "BC", en: "British Columbia", ar: "كولومبيا البريطانيه" },
      { shortCode: "MB", en: "Manitoba", ar: "مانيتوبا" },
      { shortCode: "NB", en: "New Brunswick", ar: "برانسويك الجديده" },
      {
        shortCode: "NL",
        en: "Newfoundland and Labrador",
        ar: "Newfoundland and Labrador",
      },
      {
        shortCode: "NT",
        en: "Northwest Territories",
        ar: "Northwest Territories",
      },
      { shortCode: "NS", en: "Nova Scotia", ar: "نوفا سكوتيا" },
      { shortCode: "NU", en: "Nunavut", ar: "نونافوت" },
      { shortCode: "ON", en: "Ontario", ar: "اونتاريو" },
      {
        shortCode: "PE",
        en: "Prince Edward Island",
        ar: "Prince Edward Island",
      },
      { shortCode: "QC", en: "Quebec", ar: "كويبيك" },
      { shortCode: "SK", en: "Saskatchewan", ar: "ساسكاتشيوان" },
      { shortCode: "YT", en: "Yukon", ar: "يوكون" },
    ],
  },
  {
    id: 136,
    code: "ky",
    codeLng: "cym",
    en: "Cayman Islands",
    ar: "جزر كايمان",
    dial: "+ 345",
    cities: [
      { en: "Creek", ar: "كريك" },
      { en: "Eastern", ar: "الشرقيه" },
      { en: "Midland", ar: "ميدلاند" },
      { en: "South Town", ar: "مدينه الجنوب" },
      { en: "Spot Bay", ar: "سبوت باي" },
      { en: "Stake Bay", ar: "ستيك باي" },
      { en: "West End", ar: "ايند الغرب" },
      { en: "Western", ar: "الغربيه" },
    ],
  },
  {
    id: 140,
    code: "cf",
    codeLng: "caf",
    en: "Central African Republic",
    ar: "جمهورية أفريقيا الوسطى",
    dial: "+236",
    cities: [
      {
        shortCode: "BB",
        en: "Bamingui-Bangoran",
        ar: "Bamingui-Bangoran",
      },
      { shortCode: "BGF", en: "Bangui", ar: "باجوي" },
      { shortCode: "BK", en: "Basse-Kotto", ar: "باسي كوتو" },
      { shortCode: "HK", en: "Haute-Kotto", ar: "هاوتي كوتو" },
      { shortCode: "HM", en: "Haut-Mbomou", ar: "هاوت امبومو" },
      { shortCode: "KG", en: "Kémo", ar: "كيمو" },
      { shortCode: "LB", en: "Lobaye", ar: "لوباي" },
      { shortCode: "HS", en: "Mambéré-Kadéï", ar: "مامبيري كادي" },
      { shortCode: "MB", en: "Mbomou", ar: "امبومو" },
      { shortCode: "10", en: "Nana-Grebizi", ar: "نانا جريبيزي" },
      { shortCode: "NM", en: "Nana-Mambéré", ar: "نانا مامبيري" },
      { shortCode: "MP", en: "Ombella-M'Poko", ar: "اومبيلا ام بوكو" },
      { shortCode: "UK", en: "Ouaka", ar: "اواكا" },
      { shortCode: "AC", en: "Ouham", ar: "اوهام" },
      { shortCode: "OP", en: "Ouham Péndé", ar: "اوهام بيندي" },
      { shortCode: "SE", en: "Sangha-Mbaéré", ar: "سانغا امبايري" },
      { shortCode: "VK", en: "Vakaga", ar: "فاكاجا" },
    ],
  },
  {
    id: 148,
    code: "td",
    codeLng: "tcd",
    en: "Chad",
    ar: "تشاد",
    dial: "+235",
    cities: [
      { shortCode: "BG", en: "Bahr el Ghazal", ar: "بحر الغزال" },
      { shortCode: "BA", en: "Batha", ar: "باتها" },
      { shortCode: "BO", en: "Borkou", ar: "بوركو" },
      { shortCode: "CB", en: "Chari-Baguirmi", ar: "شاري باجويرمي" },
      { shortCode: "EE", en: "Ennedi-Est", ar: "اينيدي ايست" },
      { shortCode: "EO", en: "Ennedi-Ouest", ar: "اينيدي اويست" },
      { shortCode: "GR", en: "Guéra", ar: "جويرا" },
      { shortCode: "HL", en: "Hadjer Lamis", ar: "هادجير لاميس" },
      { shortCode: "KA", en: "Kanem", ar: "كانيم" },
      { shortCode: "LC", en: "Lac", ar: "لاك" },
      {
        shortCode: "LO",
        en: "Logone Occidental",
        ar: "Logone Occidental",
      },
      { shortCode: "LR", en: "Logone Oriental", ar: "لوجون اورينتال" },
      { shortCode: "MA", en: "Mondoul", ar: "موندول" },
      { shortCode: "ME", en: "Mayo-Kébbi-Est", ar: "مايو كيبي ايست" },
      { shortCode: "MC", en: "Moyen-Chari", ar: "موين شاري" },
      { shortCode: "OD", en: "Ouaddai", ar: "اواداي" },
      { shortCode: "SA", en: "Salamat", ar: "سالامات" },
      { shortCode: "SI", en: "Sila", ar: "سيلا" },
      { shortCode: "TA", en: "Tandjilé", ar: "تاندجيلي" },
      { shortCode: "TI", en: "Tibesti", ar: "تيبيستي" },
      {
        shortCode: "ND",
        en: "Ville de Ndjamena",
        ar: "Ville de Ndjamena",
      },
      { shortCode: "WF", en: "Wadi Fira", ar: "وادي فيرا" },
    ],
  },
  {
    id: 152,
    code: "cl",
    codeLng: "chl",
    en: "Chile",
    ar: "تشيلي",
    dial: "+56",
    cities: [
      {
        shortCode: "AI",
        en: "Aisén del General Carlos Ibáñez del Campo",
        ar: "أيسن من الجنرال كارلوس إيبانيز ديل كامبو",
      },
      { shortCode: "AN", en: "Antofagasta", ar: "انتوفاجاستا" },
      { shortCode: "AR", en: "Araucanía", ar: "اراوكانيا" },
      {
        shortCode: "AP",
        en: "Arica y Parinacota",
        ar: "Arica y Parinacota",
      },
      { shortCode: "AT", en: "Atacama", ar: "اتاكاما" },
      { shortCode: "BI", en: "Bío-Bío", ar: "بيو بيو" },
      { shortCode: "CO", en: "Coquimbo", ar: "كوكويمبو" },
      {
        shortCode: "LI",
        en: "Libertador General Bernardo O'Higgins",
        ar: "المحرر العام برناردو أوهيغينز",
      },
      { shortCode: "LL", en: "Los Lagos", ar: "لوس لاجوس" },
      { shortCode: "LR", en: "Los Ríos", ar: "لوس ريوس" },
      {
        shortCode: "MA",
        en: "Magallanes y Antartica Chilena",
        ar: "ماجالانيس و التشيلي انتارتيكا",
      },
      { shortCode: "", en: "Marga-Marga", ar: "مارجا مارجا" },
      { shortCode: "ML", en: "Maule", ar: "ماولي" },
      { shortCode: "NB", en: "Ñuble", ar: "نوبلي" },
      {
        shortCode: "RM",
        en: "Región Metropolitana de Santiago",
        ar: "منطقة سانتياغو متروبوليتان",
      },
      { shortCode: "TA", en: "Tarapacá", ar: "تاراباك" },
      { shortCode: "VS", en: "Valparaíso", ar: "فالبارايسو" },
    ],
  },
  {
    id: 156,
    code: "cn",
    codeLng: "chn",
    en: "China",
    ar: "الصين",
    dial: "+86",
    cities: [
      { shortCode: "34", en: "Anhui", ar: "انهوي" },
      { shortCode: "11", en: "Beijing", ar: "بيجينج" },
      { shortCode: "50", en: "Chongqing", ar: "شونجكينج" },
      { shortCode: "35", en: "Fujian", ar: "فوجيان" },
      { shortCode: "62", en: "Gansu", ar: "جانسو" },
      { shortCode: "44", en: "Guangdong", ar: "جوانجدونج" },
      { shortCode: "45", en: "Guangxi", ar: "جوانجكسي" },
      { shortCode: "52", en: "Guizhou", ar: "جويزهو" },
      { shortCode: "46", en: "Hainan", ar: "هاينان" },
      { shortCode: "13", en: "Hebei", ar: "هيبي" },
      { shortCode: "23", en: "Heilongjiang", ar: "هيلونجيانج" },
      { shortCode: "41", en: "Henan", ar: "هينان" },
      { shortCode: "91", en: "Hong Kong", ar: "هونج كونج" },
      { shortCode: "42", en: "Hubei", ar: "هوبي" },
      { shortCode: "43", en: "Hunan", ar: "هونان" },
      { shortCode: "15", en: "Inner Mongolia", ar: "مونوجوليا الداخليه" },
      { shortCode: "32", en: "Jiangsu", ar: "جيانجسو" },
      { shortCode: "36", en: "Jiangxi", ar: "جيانجكسي" },
      { shortCode: "22", en: "Jilin", ar: "جيلين" },
      { shortCode: "21", en: "Liaoning", ar: "لياونينج" },
      { shortCode: "92", en: "Macau", ar: "ماكاو" },
      { shortCode: "64", en: "Ningxia", ar: "نينجكسيا" },
      { shortCode: "63", en: "Qinghai", ar: "كينجهاي" },
      { shortCode: "61", en: "Shaanxi", ar: "شانكسي" },
      { shortCode: "37", en: "Shandong", ar: "شاندونج" },
      { shortCode: "31", en: "Shanghai", ar: "شانجاي" },
      { shortCode: "14", en: "Shanxi", ar: "شانكسي" },
      { shortCode: "51", en: "Sichuan", ar: "سيشاون" },
      { shortCode: "12", en: "Tianjin", ar: "تيانجين" },
      { shortCode: "54", en: "Tibet", ar: "تيبيت" },
      { shortCode: "65", en: "Xinjiang", ar: "اكسينجيانج" },
      { shortCode: "53", en: "Yunnan", ar: "يونان" },
      { shortCode: "33", en: "Zhejiang", ar: "زيجيانج" },
    ],
  },
  {
    id: 162,
    code: "cx",
    codeLng: "cxr",
    en: "Christmas Island",
    ar: "جزيرة عيد الميلاد",
    dial: "+61",
    cities: [
      { shortCode: "CX", en: "Christmas Island", ar: "جزيره الكريسماس" },
    ],
  },
  {
    id: 166,
    code: "cc",
    codeLng: "cck",
    en: "Cocos (Keeling) Islands",
    ar: "جزر كوكوس",
    dial: "+61",
    cities: [
      { shortCode: "DI", en: "Direction Island", ar: "جزيره الاتجاه" },
      { shortCode: "HM", en: "Home Island", ar: "جزيره هوم" },
      { shortCode: "HR", en: "Horsburgh Island", ar: "جزيره هورسبورج" },
      {
        shortCode: "NK",
        en: "North Keeling Island",
        ar: "North Keeling Island",
      },
      { shortCode: "SI", en: "South Island", ar: "جزيره الجنوب" },
      { shortCode: "WI", en: "West Island", ar: "جزيره الغرب" },
    ],
  },
  {
    id: 170,
    code: "co",
    codeLng: "col",
    en: "Colombia",
    ar: "كولومبيا",
    dial: "+57",
    cities: [
      { shortCode: "AMA", en: "Amazonas", ar: "امازوناس" },
      { shortCode: "ANT", en: "Antioquia", ar: "انتيوكوا" },
      { shortCode: "ARA", en: "Arauca", ar: "اراوكا" },
      {
        shortCode: "SAP",
        en: "Archipiélago de San Andrés",
        ar: "Archipiélago de San Andrés",
      },
      { shortCode: "ATL", en: "Atlántico", ar: "اتلانتيكو" },
      { shortCode: "DC", en: "Bogotá D.C.", ar: "عاصمه بوجوتا" },
      { shortCode: "BOL", en: "Bolívar", ar: "بوليفار" },
      { shortCode: "BOY", en: "Boyacá", ar: "بوياكا" },
      { shortCode: "CAL", en: "Caldas", ar: "كالداس" },
      { shortCode: "CAQ", en: "Caquetá", ar: "كاكويتا" },
      { shortCode: "CAS", en: "Casanare", ar: "كاسانارى" },
      { shortCode: "CAU", en: "Cauca", ar: "كاوكا" },
      { shortCode: "CES", en: "Cesar", ar: "سيزار" },
      { shortCode: "CHO", en: "Chocó", ar: "شوكو" },
      { shortCode: "COR", en: "Córdoba", ar: "كوردوبا" },
      { shortCode: "CUN", en: "Cundinamarca", ar: "كوندينماركا" },
      { shortCode: "GUA", en: "Guainía", ar: "جواينيا" },
      { shortCode: "GUV", en: "Guaviare", ar: "جوافياري" },
      { shortCode: "HUI", en: "Huila", ar: "هويلا" },
      { shortCode: "LAG", en: "La Guajira", ar: "الجواجيرا" },
      { shortCode: "MAG", en: "Magdalena", ar: "ماجدالينا" },
      { shortCode: "MET", en: "Meta", ar: "ميتا" },
      { shortCode: "NAR", en: "Nariño", ar: "نارينو" },
      {
        shortCode: "NSA",
        en: "Norte de Santander",
        ar: "Norte de Santander",
      },
      { shortCode: "PUT", en: "Putumayo", ar: "بوتومايو" },
      { shortCode: "QUI", en: "Quindío", ar: "كوينديو" },
      { shortCode: "RIS", en: "Risaralda", ar: "ريسارالدا" },
      { shortCode: "SAN", en: "Santander", ar: "سانتاندير" },
      { shortCode: "SUC", en: "Sucre", ar: "سوكرى" },
      { shortCode: "TOL", en: "Tolima", ar: "توليما" },
      { shortCode: "VAC", en: "Valle del Cauca", ar: "فالي الكاوكا" },
      { shortCode: "VAU", en: "Vaupés", ar: "فاوبيس" },
      { shortCode: "VID", en: "Vichada", ar: "فيشادا" },
    ],
  },
  {
    id: 174,
    code: "km",
    codeLng: "com",
    en: "Comoros",
    ar: "جزر القمر",
    dial: "+269",
    cities: [
      { shortCode: "G", en: "Andjazîdja", ar: "اندجازيدجا" },
      { shortCode: "A", en: "Andjouân", ar: "اندجوان" },
      { shortCode: "M", en: "Moûhîlî", ar: "موهيلي" },
    ],
  },
  {
    id: 178,
    code: "cg",
    codeLng: "cog",
    en: "Congo",
    ar: "جمهورية الكونغو",
    dial: "+242",
    cities: [
      { shortCode: "11", en: "Bouenza", ar: "بوينزا" },
      { shortCode: "BZV", en: "Brazzaville", ar: "برازافيل" },
      { shortCode: "8", en: "Cuvette", ar: "كوفيت" },
      { shortCode: "15", en: "Cuvette-Ouest", ar: "اويست كوفيت" },
      { shortCode: "5", en: "Kouilou", ar: "كويلو" },
      { shortCode: "2", en: "Lékoumou", ar: "ليكومو" },
      { shortCode: "7", en: "Likouala", ar: "ليكوالا" },
      { shortCode: "9", en: "Niari", ar: "نياري" },
      { shortCode: "14", en: "Plateaux", ar: "بلاتوكس" },
      { shortCode: "16", en: "Pointe-Noire", ar: "بوينت نواري" },
      { shortCode: "12", en: "Pool", ar: "بوول" },
      { shortCode: "13", en: "Sangha", ar: "سانغا" },
    ],
  },
  {
    id: 180,
    code: "cd",
    codeLng: "cod",
    en: "Congo, Democratic Republic of the",
    ar: "جمهورية الكونغو الديمقراطية",
    dial: "+243",
    cities: [
      { shortCode: "BN", en: "Bandundu", ar: "باندوندو" },
      { shortCode: "BC", en: "Bas-Congo", ar: "باس كونجو" },
      { shortCode: "EQ", en: "Équateur", ar: "ايكواتور" },
      { shortCode: "KE", en: "Kasaï-Occidental", ar: "كاساي اوكسيدينتال" },
      { shortCode: "KW", en: "Kasaï-Oriental", ar: "كاساي اورينتال" },
      { shortCode: "KA", en: "Katanga", ar: "كاتانجا" },
      { shortCode: "KN", en: "Kinshasa", ar: "كينسهاسا" },
      { shortCode: "MA", en: "Maniema", ar: "مينيما" },
      { shortCode: "NK", en: "Nord-Kivu", ar: "نورد كيفو" },
      { shortCode: "OR", en: "Orientale", ar: "اورينتالي" },
      { shortCode: "SK", en: "Sud-Kivu", ar: "سود كيفو" },
    ],
  },
  {
    id: 184,
    code: "ck",
    codeLng: "cok",
    en: "Cook Islands",
    ar: "جزر كوك",
    dial: "+682",
    cities: [
      { en: "Aitutaki", ar: "ايتوتاكي" },
      { en: "Atiu", ar: "اتيو" },
      { en: "Avarua", ar: "افاروا" },
      { en: "Mangaia", ar: "مانجايا" },
      { en: "Manihiki", ar: "مانيهيكي" },
      { en: "Ma'uke", ar: "ما اوكي" },
      { en: "Mitiaro", ar: "ميتيارو" },
      { en: "Nassau", ar: "ناساو" },
      { en: "Palmerston", ar: "بالميرستون" },
      { en: "Penrhyn", ar: "بينرهين" },
      { en: "Pukapuka", ar: "بوكا بوكا" },
      { en: "Rakahanga", ar: "راكاهانجا" },
    ],
  },
  {
    id: 188,
    code: "cr",
    codeLng: "cri",
    en: "Costa Rica",
    ar: "كوستاريكا",
    dial: "+506",
    cities: [
      { shortCode: "2", en: "Alajuela", ar: "الاجويلا" },
      { shortCode: "3", en: "Cartago", ar: "كارتاجو" },
      { shortCode: "5", en: "Guanacaste", ar: "جواناكاستي" },
      { shortCode: "4", en: "Heredia", ar: "هيريديا" },
      { shortCode: "7", en: "Limón", ar: "ليمون" },
      { shortCode: "6", en: "Puntarenas", ar: "بونتاريناس" },
      { shortCode: "1", en: "San José", ar: "سان جوسي" },
    ],
  },
  {
    id: 384,
    code: "ci",
    codeLng: "civ",
    en: "Côte d'Ivoire",
    ar: "ساحل العاج",
    dial: "+225",
    cities: [
      { shortCode: "16", en: "Agnéby", ar: "اجنيبي" },
      { shortCode: "17", en: "Bafing", ar: "بافينج" },
      { shortCode: "09", en: "Bas-Sassandra", ar: "باس ساساندرا" },
      { shortCode: "10", en: "Denguélé", ar: "دينجويلي" },
      {
        shortCode: "06",
        en: "Dix-Huit Montagnes",
        ar: "Dix-Huit Montagnes",
      },
      { shortCode: "18", en: "Fromager", ar: "فروماجير" },
      { shortCode: "02", en: "Haut-Sassandra", ar: "هاوت ساساندرا" },
      { shortCode: "07", en: "Lacs", ar: "لاكس" },
      { shortCode: "01", en: "Lagunes", ar: "لاجونيس" },
      { shortCode: "12", en: "Marahoué", ar: "ماراهوي" },
      { shortCode: "19", en: "Moyen-Cavally", ar: "موين كافالي" },
      { shortCode: "05", en: "Moyen-Comoé", ar: "موين كوموي" },
      { shortCode: "11", en: "N'zi-Comoé", ar: "نزي كوموي" },
      { shortCode: "03", en: "Savanes", ar: "سافانيس" },
      { shortCode: "15", en: "Sud-Bandama", ar: "سود بانداما" },
      { shortCode: "13", en: "Sud-Comoé", ar: "سود كوموي" },
      {
        shortCode: "04",
        en: "Vallée du Bandama",
        ar: "Vallée du Bandama",
      },
      { shortCode: "14", en: "Worodougou", ar: "وورودوجو" },
      { shortCode: "08", en: "Zanzan", ar: "زانهان" },
    ],
  },
  {
    id: 191,
    code: "hr",
    codeLng: "hrv",
    en: "Croatia",
    ar: "كرواتيا",
    dial: "+385",
    cities: [
      {
        shortCode: "07",
        en: "Bjelovarsko-Bilogorska Županija",
        ar: "مقاطعة بيلوفار بيلوجورا",
      },
      {
        shortCode: "12",
        en: "Brodsko-Posavska Županija",
        ar: "مقاطعة برود بوسافينا",
      },
      {
        shortCode: "19",
        en: "Dubrovačko-Neretvanska Županija",
        ar: "مقاطعة دوبروفنيك-نيريتفا",
      },
      { shortCode: "21", en: "Grad Zagreb", ar: "Grad Zagreb" },
      {
        shortCode: "18",
        en: "Istarska Županija",
        ar: "مقاطعة استريا",
      },
      {
        shortCode: "04",
        en: "Karlovačka Županija",
        ar: "مقاطعة كارلوفاك",
      },
      {
        shortCode: "06",
        en: "Koprivničko-Krizevačka Županija",
        ar: " مقاطعه كوبريفنيكو كريزيفاكا",
      },
      {
        shortCode: "02",
        en: "Krapinsko-Zagorska Županija",
        ar: "مقاطعة كرابينا زاغوريه",
      },
      {
        shortCode: "09",
        en: "Ličko-Senjska Županija",
        ar: "مقاطعة ليكا سنج",
      },
      {
        shortCode: "20",
        en: "Međimurska Županija",
        ar: "مقاطعة ميدمررسكا",
      },
      {
        shortCode: "14",
        en: "Osječko-Baranjska Županija",
        ar: "مقاطعة أوسييك بارانيا",
      },
      {
        shortCode: "11",
        en: "Požeško-Slavonska Županija",
        ar: "مقاطعه بوزيجا سلافونيا",
      },
      {
        shortCode: "08",
        en: "Primorsko-Goranska Županija",
        ar: "مقاطعة بريمورسكو جورانسكا",
      },
      {
        shortCode: "03",
        en: "Sisačko-Moslavačka Županija",
        ar: "مقاطعة سيساك موسلافينا",
      },
      {
        shortCode: "17",
        en: "Splitsko-Dalmatinska Županija",
        ar: "مقاطعة سبليت دالماتيا",
      },
      {
        shortCode: "15",
        en: "Sibensko-Kninska Županija",
        ar: "مقاطعة سيبينيك كنين",
      },
      {
        shortCode: "05",
        en: "Varaždinska Županija",
        ar: "مقاطعة فارازدين",
      },
      {
        shortCode: "10",
        en: "Virovitičko-Podravska Županija",
        ar: "مقاطعة فيروفيتيكا بودرافينا",
      },
      {
        shortCode: "16",
        en: "Vukovarsko-Srijemska Županija",
        ar: "مقاطعة فوكوفار سريجم",
      },
      {
        shortCode: "13",
        en: "Zadarska Županija",
        ar: "مقاطعة زادار",
      },
      {
        shortCode: "01",
        en: "Zagrebacka Zupanija",
        ar: "مقاطعة زغرب",
      },
    ],
  },
  {
    id: 192,
    code: "cu",
    codeLng: "cub",
    en: "Cuba",
    ar: "كوبا",
    dial: "+53",
    cities: [
      { shortCode: "15", en: "Artemisa", ar: "الميرمية" },
      { shortCode: "09", en: "Camagüey", ar: "كاماغوي" },
      { shortCode: "08", en: "Ciego de Ávila", ar: "سييجو دي افيلا" },
      { shortCode: "06", en: "Cienfuegos", ar: "سينفويجوس" },
      { shortCode: "12", en: "Granma", ar: "جرانما" },
      { shortCode: "14", en: "Guantánamo", ar: "جوانتانامو" },
      { shortCode: "11", en: "Holguín", ar: "هولجوين" },
      {
        shortCode: "99",
        en: "Isla de la Juventud",
        ar: "Isla de la Juventud",
      },
      { shortCode: "03", en: "La Habana", ar: "هابانا" },
      { shortCode: "10", en: "Las Tunas", ar: "توناس" },
      { shortCode: "04", en: "Matanzas", ar: "ماتانزاس" },
      { shortCode: "16", en: "Mayabeque", ar: "مايابيكو" },
      { shortCode: "01", en: "Pinar del Río", ar: "بينار ديل ريو" },
      { shortCode: "07", en: "Sancti Spíritus", ar: "سانكتي سبيريتوس" },
      { shortCode: "13", en: "Santiago de Cuba", ar: "الكوبا سانتياجو" },
      { shortCode: "05", en: "Villa Clara", ar: "فيلا كلارا" },
    ],
  },
  {
    id: 531,
    code: "cw",
    codeLng: "cuw",
    en: "Curaçao",
    ar: "كوراساو",
    dial: "+599",
    cities: [{ shortCode: "CW", en: "Curaçao", ar: "كوراساو" }],
  },
  {
    id: 196,
    code: "cy",
    codeLng: "cyp",
    en: "Cyprus",
    ar: "قبرص",
    dial: "+537",
    cities: [
      { shortCode: "04", en: "Ammochostos", ar: "اموشوتوس" },
      { shortCode: "05", en: "Keryneia", ar: "كيرينيا" },
      { shortCode: "03", en: "Larnaka", ar: "لارناكا" },
      { shortCode: "01", en: "Lefkosia", ar: "ليفكوسيا" },
      { shortCode: "02", en: "Lemesos", ar: "ليميسوس" },
      { shortCode: "05", en: "Pafos", ar: "بافوس" },
    ],
  },
  {
    id: 203,
    code: "cz",
    codeLng: "cze",
    en: "Czechia",
    ar: "جمهورية التشيك",
    dial: "+420",
    cities: [
      {
        shortCode: "PR",
        en: "Hlavní město Praha",
        ar: "عاصمة براغ",
      },
      { shortCode: "JC", en: "Jihočeský kraj", ar: "منطقة جنوب بوهيميا" },
      {
        shortCode: "JM",
        en: "Jihomoravský kraj",
        ar: "منطقة جنوب مورافيا",
      },
      { shortCode: "KA", en: "Karlovarský kraj", ar: "منطقة كارلوفي فاري" },
      {
        shortCode: "KR",
        en: "Královéhradecký kraj",
        ar: "منطقة هراديك كرالوف",
      },
      { shortCode: "LI", en: "Liberecký kraj", ar: "منطقة ليبيريتش" },
      {
        shortCode: "MO",
        en: "Moravskoslezský kraj",
        ar: "منطقة مورافيا-سيليزيا",
      },
      { shortCode: "OL", en: "Olomoucký kraj", ar: "منطقة أولوموك" },
      { shortCode: "PA", en: "Pardubický kraj", ar: "منطقة باردوبيتسه" },
      { shortCode: "PL", en: "Plzeňský kraj", ar: "منطقة بلسن" },
      { shortCode: "ST", en: "Středočeský kraj", ar: "منطقة بوهيميا الوسطى" },
      { shortCode: "US", en: "Ústecký kraj", ar: "منطقة أوستي" },
      { shortCode: "VY", en: "Vysočina", ar: "المرتفعات" },
      { shortCode: "ZL", en: "Zlínský kraj", ar: "منطقة زلين" },
    ],
  },
  {
    id: 208,
    code: "dk",
    codeLng: "dnk",
    en: "Denmark",
    ar: "الدنمارك",
    dial: "+45",
    cities: [
      { shortCode: "84", en: "Hovedstaden", ar: "العاصمة" },
      { shortCode: "GL-KU", en: "Kujalleq", ar: "كوجالق" },
      { shortCode: "82", en: "Midtjylland", ar: "ميدجي لاند" },
      { shortCode: "FO-01", en: "Norderøerne", ar: "نوردريرني" },
      { shortCode: "81", en: "Nordjylland", ar: "نوردجي لاند" },
      { shortCode: "FO-06", en: "Østerø", ar: "اوستيرو" },
      { shortCode: "GL-QA", en: "Qaasuitsup", ar: "كاسوتساب" },
      { shortCode: "GL-QE", en: "Qeqqata", ar: "كيكاتا" },
      { shortCode: "FO-02", en: "Sandø", ar: "ساندو" },
      { shortCode: "GL-SM", en: "Sermersooq", ar: "سيرميرسوق" },
      { shortCode: "85", en: "Sjælland", ar: "سجالاند" },
      { shortCode: "FO-03", en: "Strømø", ar: "ستورمو" },
      { shortCode: "FO-04", en: "Suderø", ar: "سنديرو" },
      { shortCode: "83", en: "Syddanmark", ar: "سيدانمارك" },
      { shortCode: "FO-05", en: "Vågø", ar: "فاجو" },
    ],
  },
  {
    id: 262,
    code: "dj",
    codeLng: "dji",
    en: "Djibouti",
    ar: "جيبوتي",
    dial: "+253",
    cities: [
      { shortCode: "AS", en: "Ali Sabieh", ar: "علي صبيح" },
      { shortCode: "AR", en: "Arta", ar: "ارتا" },
      { shortCode: "DI", en: "Dikhil", ar: "ديخيل" },
      { shortCode: "OB", en: "Obock", ar: "اوبوك" },
      { shortCode: "TA", en: "Tadjourah", ar: "تادجورا" },
    ],
  },
  {
    id: 212,
    code: "dm",
    codeLng: "dma",
    en: "Dominica",
    ar: "دومينيكا",
    dial: "+1 767",
    cities: [
      {
        shortCode: "02",
        en: "Saint Andrew Parish",
        ar: "القديس اندرو باريش",
      },
      {
        shortCode: "03",
        en: "Saint David Parish",
        ar: "القديس ديفيد باريش",
      },
      {
        shortCode: "04",
        en: "Saint George Parish",
        ar: "القديس جورج باريش",
      },
      {
        shortCode: "05",
        en: "Saint John Parish",
        ar: "القديس جون باريش",
      },
      {
        shortCode: "06",
        en: "Saint Joseph Parish",
        ar: "القديس جوسيف باريش",
      },
      {
        shortCode: "07",
        en: "Saint Luke Parish",
        ar: "القديس لوك باريش",
      },
      {
        shortCode: "08",
        en: "Saint Mark Parish",
        ar: "القديس مارك باريش",
      },
      {
        shortCode: "09",
        en: "Saint Patrick Parish",
        ar: "القديس باتريك باريش",
      },
      {
        shortCode: "10",
        en: "Saint Paul Parish",
        ar: "القديس بول باريش",
      },
      {
        shortCode: "11",
        en: "Saint Peter Parish",
        ar: "القديس بيتر باريش",
      },
    ],
  },
  {
    id: 214,
    code: "do",
    codeLng: "dom",
    en: "Dominican Republic",
    ar: "جمهورية الدومينيكان",
    dial: "+1 849",
    cities: [
      { shortCode: "02", en: "Cibao Central", ar: "سيباو المتوسطه" },
      { shortCode: "37", en: "Del Valle", ar: "الفالي" },
      {
        shortCode: "01",
        en: "Distrito Nacional",
        ar: "Distrito Nacional",
      },
      { shortCode: "38", en: "Enriquillo", ar: "انريكيلو" },
      { shortCode: "04", en: "Norcentral", ar: "نورسينترال" },
      { shortCode: "34", en: "Nordeste", ar: "نورديستى" },
      { shortCode: "34", en: "Noroeste", ar: "نورويستى" },
      { shortCode: "35", en: "Norte", ar: "نورتى" },
      { shortCode: "42", en: "Valdesia", ar: "فالديسيا" },
    ],
  },
  {
    id: 218,
    code: "ec",
    codeLng: "ecu",
    en: "Ecuador",
    ar: "الإكوادور",
    dial: "+593",
    cities: [
      { shortCode: "A", en: "Azuay", ar: "ازواي" },
      { shortCode: "B", en: "Bolívar", ar: "بوليفار" },
      { shortCode: "F", en: "Cañar", ar: "كانار" },
      { shortCode: "C", en: "Carchi", ar: "كارشي" },
      { shortCode: "H", en: "Chimborazo", ar: "شيمبورازو" },
      { shortCode: "X", en: "Cotopaxi", ar: "كوتوباكسي" },
      { shortCode: "O", en: "El Oro", ar: "الاورو" },
      { shortCode: "E", en: "Esmeraldas", ar: "ايزميرالداس" },
      { shortCode: "W", en: "Galápagos", ar: "جالاباجوس" },
      { shortCode: "G", en: "Guayas", ar: "جواياس" },
      { shortCode: "I", en: "Imbabura", ar: "ايمبابورا" },
      { shortCode: "L", en: "Loja", ar: "لوجا" },
      { shortCode: "R", en: "Los Ríos", ar: "لوس ريوس" },
      { shortCode: "M", en: "Manabí", ar: "مانابي" },
      { shortCode: "S", en: "Morona-Santiago", ar: "مورونا سانتياجو" },
      { shortCode: "N", en: "Napo", ar: "نابو" },
      { shortCode: "D", en: "Orellana", ar: "اوريلانا" },
      { shortCode: "Y", en: "Pastaza", ar: "باستازا" },
      { shortCode: "P", en: "Pichincha", ar: "بيشينشا" },
      { shortCode: "SE", en: "Santa Elena", ar: "سانتا ايلينا" },
      {
        shortCode: "SD",
        en: "Santo Domingo de los Tsáchilas",
        ar: "سانتو دومينغو دي لوس تساشيلاس",
      },
      { shortCode: "U", en: "Sucumbíos", ar: "سوكومبيوس" },
      { shortCode: "T", en: "Tungurahua", ar: "تونجوارها" },
      { shortCode: "Z", en: "Zamora-Chinchipe", ar: "زامبورا شينشيبي" },
    ],
  },
  {
    id: 818,
    code: "eg",
    codeLng: "egy",
    en: "Egypt",
    ar: "مصر",
    dial: "+20",
    cities: [
      { shortCode: "ALX", en: "Alexandria", ar: "الاسكندريه" },
      { shortCode: "ASN", en: "Aswan", ar: "اسوان" },
      { shortCode: "AST", en: "Asyout", ar: "اسيوط" },
      { shortCode: "BNS", en: "Bani Sueif", ar: "بني سويف" },
      { shortCode: "BH", en: "Beheira", ar: "البحيره" },
      { shortCode: "C", en: "Cairo", ar: "القاهره" },
      { shortCode: "DK", en: "Daqahlia", ar: "الدقهليه" },
      { shortCode: "DT", en: "Dumiat", ar: "دمياط" },
      { shortCode: "BA", en: "El Bahr El Ahmar", ar: "البحر الاحمر" },
      { shortCode: "IS", en: "El Ismailia", ar: "الاسماعيليه" },
      { shortCode: "SUZ", en: "El Suez", ar: "السويس" },
      {
        shortCode: "WAD",
        en: "El Wadi El Gedeed",
        ar: "الوادي الجديد",
      },
      { shortCode: "FYM", en: "Fayoum", ar: "الفيوم" },
      { shortCode: "GH", en: "Gharbia", ar: "الغربيه" },
      { shortCode: "SUZ", en: "Giza", ar: "الجيزه" },
      { shortCode: "HU", en: "Helwan", ar: "حلوان" },
      { shortCode: "KFS", en: "Kafr El Sheikh", ar: "كفر الشيخ" },
      { shortCode: "LX", en: "Luxor", ar: "الاقصر" },
      { shortCode: "MT", en: "Matrouh", ar: "مطروح" },
      { shortCode: "MN", en: "Menia", ar: "المنيا" },
      { shortCode: "MNF", en: "Menofia", ar: "المنوفيه" },
      { shortCode: "SIN", en: "North Sinai", ar: "شمال سيناء" },
      { shortCode: "PTS", en: "Port Said", ar: "بور سعيد" },
      { shortCode: "KB", en: "Qalubia", ar: "القليوبيه" },
      { shortCode: "KN", en: "Qena", ar: "قنا" },
      { shortCode: "SHR", en: "Sharqia", ar: "الشرقيه" },
      { shortCode: "SU", en: "Sixth of October", ar: "السادس من اكتوبر" },
      { shortCode: "SHG", en: "Sohag", ar: "سوهاج" },
      { shortCode: "JS", en: "South Sinai", ar: "جنوب سيناء" },
    ],
  },
  {
    id: 222,
    code: "sv",
    codeLng: "slv",
    en: "El Salvador",
    ar: "السلفادور",
    dial: "+503",
    cities: [
      { shortCode: "AH", en: "Ahuachapán", ar: "اهواشابان" },
      { shortCode: "CA", en: "Cabañas", ar: "كاباناس" },
      { shortCode: "CU", en: "Cuscatlán", ar: "كوسكاتلان" },
      { shortCode: "CH", en: "Chalatenango", ar: "شالاتينانجو" },
      { shortCode: "LI", en: "La Libertad", ar: "ليبيرتاد" },
      { shortCode: "PA", en: "La Paz", ar: "الباز" },
      { shortCode: "UN", en: "La Unión", ar: "الاتحاد" },
      { shortCode: "MO", en: "Morazán", ar: "مورازان" },
      { shortCode: "SM", en: "San Miguel", ar: "سان ميجيل" },
      { shortCode: "SS", en: "San Salvador", ar: "سان سالفادور" },
      { shortCode: "SA", en: "Santa Ana", ar: "سانتا انا" },
      { shortCode: "SV", en: "San Vicente", ar: "سان فيكينتي" },
      { shortCode: "SO", en: "Sonsonate", ar: "سونسوناتي" },
      { shortCode: "US", en: "Usulután", ar: "اسولوتان" },
    ],
  },
  {
    id: 226,
    code: "gq",
    codeLng: "gnq",
    en: "Equatorial Guinea",
    ar: "غينيا الاستوائية",
    dial: "+240",
    cities: [
      { shortCode: "AN", en: "Annobón", ar: "انوبون" },
      { shortCode: "BN", en: "Bioko Norte", ar: "بيوكو نورتي" },
      { shortCode: "BS", en: "Bioko Sur", ar: "بيوكو سور" },
      { shortCode: "CS", en: "Centro Sur", ar: "سور المتوسطه" },
      { shortCode: "KN", en: "Kié-Ntem", ar: "كي نتيم" },
      { shortCode: "LI", en: "Litoral", ar: "ليتورال" },
      { shortCode: "WN", en: "Wele-Nzas", ar: "ويلي نزاس" },
    ],
  },
  {
    id: 232,
    code: "er",
    codeLng: "eri",
    en: "Eritrea",
    ar: "إريتريا",
    dial: "+291",
    cities: [
      { shortCode: "AN", en: "Anseba", ar: "انسيبا" },
      { shortCode: "DU", en: "Debub", ar: "ديبوب" },
      { shortCode: "DK", en: "Debub-Keih-Bahri", ar: "ديبوب كيه بحري" },
      { shortCode: "GB", en: "Gash-Barka", ar: "جاش باركا" },
      { shortCode: "MA", en: "Maekel", ar: "ميكيل" },
      {
        shortCode: "SK",
        en: "Semien-Keih-Bahri",
        ar: "سيمين كيه بحري",
      },
    ],
  },
  {
    id: 233,
    code: "ee",
    codeLng: "est",
    en: "Estonia",
    ar: "إستونيا",
    dial: "+372",
    cities: [
      {
        shortCode: "37",
        en: "Harjumaa (Tallinn)",
        ar: "هارجوما",
      },
      { shortCode: "39", en: "Hiiumaa (Kardla)", ar: "Hiiumaa (Kardla)" },
      {
        shortCode: "44",
        en: "Ida-Virumaa (Johvi)",
        ar: "ايدا فيروما",
      },
      { shortCode: "41", en: "Järvamaa (Paide)", ar: "جارفاما" },
      {
        shortCode: "49",
        en: "Jõgevamaa (Jogeva)",
        ar: "جوجيفاما",
      },
      { shortCode: "57", en: "Läänemaa", ar: "لانيما" },
      {
        shortCode: "59",
        en: "Lääne-Virumaa (Rakvere)",
        ar: "لاني فيروما ",
      },
      { shortCode: "67", en: "Pärnumaa (Parnu)", ar: "بارنوما " },
      { shortCode: "65", en: "Põlvamaa (Polva)", ar: "بولفاما" },
      { shortCode: "70", en: "Raplamaa (Rapla)", ar: "رابلاما" },
      {
        shortCode: "74",
        en: "Saaremaa (Kuessaare)",
        ar: "ساريما ",
      },
      { shortCode: "78", en: "Tartumaa (Tartu)", ar: "تارتوما " },
      { shortCode: "82", en: "Valgamaa (Valga)", ar: "فالجاما " },
      {
        shortCode: "84",
        en: "Viljandimaa (Viljandi)",
        ar: "فيلجانديما ",
      },
      { shortCode: "86", en: "Võrumaa (Voru)", ar: "فوروما " },
    ],
  },
  {
    id: 748,
    code: "sz",
    codeLng: "swz",
    en: "Eswatini",
    ar: "إسواتيني",
    dial: "+268",
    cities: [
      { shortCode: "HH", en: "Hhohho", ar: "هوهو" },
      { shortCode: "LU", en: "Lubombo", ar: "لوبومبو" },
      { shortCode: "MA", en: "Manzini", ar: "مانزيني" },
      { shortCode: "SH", en: "Shiselweni", ar: "شيسيلويني" },
    ],
  },
  {
    id: 231,
    code: "et",
    codeLng: "eth",
    en: "Ethiopia",
    ar: "إثيوبيا",
    dial: "+251",
    cities: [
      { shortCode: "AA", en: "Addis Ababa", ar: "اديس ابابا" },
      { shortCode: "AF", en: "Afar", ar: "افار" },
      { shortCode: "AM", en: "Amhara", ar: "امهارا" },
      { shortCode: "BE", en: "Benshangul-Gumaz", ar: "بينشانجول جوماز" },
      { shortCode: "DD", en: "Dire Dawa", ar: "ديري دوا" },
      { shortCode: "GA", en: "Gambela", ar: "جامبيلا" },
      { shortCode: "HA", en: "Harari", ar: "هاراري" },
      { shortCode: "OR", en: "Oromia", ar: "اروميا" },
      { shortCode: "SO", en: "Somali", ar: "سومالي" },
      {
        shortCode: "SN",
        en: "Southern Nations Nationalities and People's Region",
        ar: "قوميات الأمم الجنوبية ومنطقة الشعوب",
      },
      { shortCode: "TI", en: "Tigray", ar: "تيجراي" },
    ],
  },
  {
    id: 238,
    code: "fk",
    codeLng: "flk",
    en: "Falkland Islands (Malvinas)",
    ar: "جزر فوكلاند",
    dial: "+500",
    cities: [
      {
        en: "Falkland Islands (Islas Malvinas)",
        ar: "جزر الفالكلاند",
      },
    ],
  },
  {
    id: 234,
    code: "fo",
    codeLng: "fro",
    en: "Faroe Islands",
    ar: "جزر فارو",
    dial: "+298",
    cities: [
      { en: "Bordoy", ar: "بوردوي" },
      { en: "Eysturoy", ar: "ايستوروي" },
      { en: "Mykines", ar: "ميكينيس" },
      { en: "Sandoy", ar: "ساندوي" },
      { en: "Skuvoy", ar: "سكوفوي" },
      { en: "Streymoy", ar: "ستريموي" },
      { en: "Suduroy", ar: "سوندوروي" },
      { en: "Tvoroyri", ar: "تفوروي" },
      { en: "Vagar", ar: "فاجار" },
    ],
  },
  {
    id: 242,
    code: "fj",
    codeLng: "fji",
    en: "Fiji",
    ar: "فيجي",
    dial: "+679",
    cities: [
      { shortCode: "01", en: "Ba", ar: "با" },
      { shortCode: "01", en: "Bua", ar: "بوا" },
      { shortCode: "03", en: "Cakaudrove", ar: "كاكاودروف" },
      { shortCode: "04", en: "Kadavu", ar: "كادافو" },
      { shortCode: "05", en: "Lau", ar: "لاو" },
      { shortCode: "06", en: "Lomaiviti", ar: "لومايفيتي" },
      { shortCode: "07", en: "Macuata", ar: "ماكواتا" },
      {
        shortCode: "08",
        en: "Nadroga and Navosa",
        ar: "Nadroga and Navosa",
      },
      { shortCode: "09", en: "Naitasiri", ar: "نايتسيري" },
      { shortCode: "10", en: "Namosi", ar: "ناموسي" },
      { shortCode: "011", en: "Ra", ar: "را" },
      { shortCode: "12", en: "Rewa", ar: "ريوا" },
      { shortCode: "R", en: "Rotuma", ar: "روتوما" },
      { shortCode: "12", en: "Serua", ar: "سيروا" },
      { shortCode: "14", en: "Tailevu", ar: "تيليفو" },
    ],
  },
  {
    id: 246,
    code: "fi",
    codeLng: "fin",
    en: "Finland",
    ar: "فنلندا",
    dial: "+358",
    cities: [
      {
        shortCode: "FI-01",
        en: "Ahvenanmaan maakunta",
        ar: "افينانمان ميكونتا",
      },
      { shortCode: "FI-02", en: "Etelä-Karjala", ar: "ايتيلا كارجالا" },
      {
        shortCode: "FI-03",
        en: "Etelä-Pohjanmaa",
        ar: "ايتيلا بوهجانما",
      },
      { shortCode: "FI-04", en: "Etelä-Savo", ar: "ايتيلا سافو" },
      { shortCode: "FI-05", en: "Kainuu", ar: "كاينو" },
      { shortCode: "FI-06", en: "Kanta-Häme", ar: "كانتا هامي" },
      {
        shortCode: "FI-07",
        en: "Keski-Pohjanmaa",
        ar: "كيسكي بوجانما",
      },
      { shortCode: "FI-08", en: "Keski-Suomi", ar: "كيسكي سومي" },
      { shortCode: "FI-09", en: "Kymenlaakso", ar: "كيمينلاسكو" },
      { shortCode: "FI-10", en: "Lappi", ar: "لابي" },
      { shortCode: "FI-11", en: "Pirkanmaa", ar: "بيركانما" },
      { shortCode: "FI-12", en: "Pohjanmaa", ar: "بوجانما" },
      {
        shortCode: "FI-13",
        en: "Pohjois-Karjala",
        ar: "بوجويس كارجالا",
      },
      {
        shortCode: "FI-14",
        en: "Pohjois-Pohjanmaa",
        ar: "بوجويس بوجانما",
      },
      { shortCode: "FI-15", en: "Pohjois-Savo", ar: "بوجويس سافو" },
      { shortCode: "FI-16", en: "Päijät-Häme", ar: "بايجات هامي" },
      { shortCode: "FI-17", en: "Satakunta", ar: "ساتاكونتا" },
      { shortCode: "FI-18", en: "Uusimaa", ar: "اوسيما" },
      { shortCode: "FI-19", en: "Varsinais-Suomi", ar: "فارسينايس سومي" },
    ],
  },
  {
    id: 250,
    code: "fr",
    codeLng: "fra",
    en: "France",
    ar: "فرنسا",
    dial: "+33",
    cities: [
      {
        shortCode: "ARA",
        en: "Auvergne-Rhône-Alpes",
        ar: "اوفيرجني روني البيس",
      },
      {
        shortCode: "BFC",
        en: "Bourgogne-Franche-Comté",
        ar: "بورجوجني فرانشي كومتي",
      },
      { shortCode: "BRE", en: "Bretagne", ar: "بريتانجي" },
      {
        shortCode: "CVL",
        en: "Centre-Val de Loire",
        ar: "سينترال فال دي لويري",
      },
      { shortCode: "COR", en: "Corse", ar: "كورسي" },
      { shortCode: "GES", en: "Grand Est", ar: "جراند ايست" },
      { shortCode: "HDF", en: "Hauts-de-France", ar: "هاوتس فرانسا" },
      { shortCode: "IDF", en: "Île-de-France", ar: "ايلي فرانسا" },
      { shortCode: "NOR", en: "Normandie", ar: "نورماندي" },
      {
        shortCode: "NAQ",
        en: "Nouvelle-Aquitaine",
        ar: "نويفيلي اكويتايني",
      },
      { shortCode: "OCC", en: "Occitanie", ar: "اوكيتاني" },
      {
        shortCode: "PDL",
        en: "Pays de la Loire",
        ar: "بايس دي لويري",
      },
      {
        shortCode: "PAC",
        en: "Provence-Alpes-Cote d'Azur",
        ar: "بروفينس البيس كوتي ازور",
      },
      { shortCode: "CP", en: "Clipperton", ar: "كليبرتون" },
      { shortCode: "GP", en: "Guadeloupe", ar: "كواديلوبي" },
      { shortCode: "GF", en: "Guyane", ar: "جوياني" },
      { shortCode: "MQ", en: "Martinique", ar: "مارتينيك" },
      { shortCode: "YT", en: "Mayotte", ar: "مايوتي" },
      {
        shortCode: "NC",
        en: "Novelle-Calédonie",
        ar: "نوفيلي كاليدوني",
      },
      { shortCode: "PF", en: "Polynésie", ar: "بولينيسي" },
      {
        shortCode: "PM",
        en: "Saint-Pierre-et-Miquelon",
        ar: "القديس بيري ميكيولون",
      },
      { shortCode: "BL", en: "Saint Barthélemy", ar: "القديس بارتيليمي" },
      { shortCode: "MF", en: "Saint Martin", ar: "القديس مارتن" },
      { shortCode: "RE", en: "Réunion", ar: "الاتحاد" },
      {
        shortCode: "TF",
        en: "Terres Australes Françaises",
        ar: "تيريس اوستراليس الفرنسيه",
      },
      { shortCode: "WF", en: "Wallis-et-Futuna", ar: "واليس فاتونا" },
    ],
  },
  {
    id: 254,
    code: "gf",
    codeLng: "guf",
    en: "French Guiana",
    ar: "غويانا الفرنسية",
    dial: "+594",
    cities: [{ en: "French Guiana", ar: "جويانا الفرنسيه" }],
  },
  {
    id: 258,
    code: "pf",
    codeLng: "pyf",
    en: "French Polynesia",
    ar: "بولينزيا الفرنسية",
    dial: "+689",
    cities: [
      { en: "Archipel des Marquises", ar: "ارشيبيل الماركويسيس" },
      { en: "Archipel des Tuamotu", ar: "ارشيبل التاوموتو" },
      { en: "Archipel des Tubuai", ar: "ارشيبل التوبواي" },
      { en: "Iles du Vent", ar: "ايليس دو فينت" },
      { en: "Iles Sous-le-Vent", ar: "اليس سوس لي فينت" },
    ],
  },
  {
    id: 260,
    code: "tf",
    codeLng: "atf",
    en: "French Southern Territories",
    ar: "أراض فرنسية جنوبية وأنتارتيكية",
    dial: "+262",
    cities: [
      { en: "Adelie Land", ar: "اديلي لاند" },
      { en: "Ile Crozet", ar: "ايلي كروزيت" },
      { en: "Iles Kerguelen", ar: "ايليس كيرجويليت" },
      {
        en: "Iles Saint-Paul et Amsterdam",
        ar: "جزر سانت بول وأمستردام",
      },
    ],
  },
  {
    id: 266,
    code: "ga",
    codeLng: "gab",
    en: "Gabon",
    ar: "الغابون",
    dial: "+241",
    cities: [
      { shortCode: "1", en: "Estuaire", ar: "ايستوايري" },
      { shortCode: "2", en: "Haut-Ogooué", ar: "هاوت اجوي" },
      { shortCode: "3", en: "Moyen-Ogooué", ar: "موين اجوي" },
      { shortCode: "4", en: "Ngounié", ar: "انجويني" },
      { shortCode: "5", en: "Nyanga", ar: "نيانجا" },
      { shortCode: "6", en: "Ogooué-Ivindo", ar: "اجوي ايفيندو" },
      { shortCode: "7", en: "Ogooué-Lolo", ar: "اجوي لولو" },
      { shortCode: "8", en: "Ogooué-Maritime", ar: "اجوي ماريتيمي" },
      { shortCode: "9", en: "Woleu-Ntem", ar: "فولو انتيم" },
    ],
  },
  {
    id: 270,
    code: "gm",
    codeLng: "gmb",
    en: "Gambia",
    ar: "غامبيا",
    dial: "+220",
    cities: [
      { shortCode: "B", en: "Banjul", ar: "بانجول" },
      { shortCode: "M", en: "Central River", ar: "النهر المتوسط" },
      { shortCode: "L", en: "Lower River", ar: "النهر المنخفض" },
      { shortCode: "N", en: "North Bank", ar: "البنك الشمالي" },
      { shortCode: "U", en: "Upper River", ar: "النهر العلوي" },
      { shortCode: "W", en: "Western", ar: "الغربيه" },
    ],
  },
  {
    id: 268,
    code: "ge",
    codeLng: "geo",
    en: "Georgia",
    ar: "جورجيا",
    dial: "+995",
    cities: [
      {
        shortCode: "AB",
        en: "Abkhazia (Sokhumi)",
        ar: "Abkhazia (Sokhumi)",
      },
      { shortCode: "AJ", en: "Ajaria (Bat'umi)", ar: "اجاريا" },
      { shortCode: "GU", en: "Guria", ar: "جوريا" },
      { shortCode: "IM", en: "Imereti", ar: "ايميريتي" },
      { shortCode: "KA", en: "K'akheti", ar: "اخيتي" },
      { shortCode: "KK", en: "Kvemo Kartli", ar: "كفينمو كارتلي" },
      {
        shortCode: "MM",
        en: "Mtshkheta-Mtianeti",
        ar: "متشكيتا متيانيتي",
      },
      {
        shortCode: "RL",
        en: "Rach'a-Lexhkumi-KvemoSvaneti",
        ar: "رخا ليكسكومي كفيمو سفانيتي",
      },
      {
        shortCode: "SZ",
        en: "Samegrelo-Zemo Svaneti",
        ar: "ساميجريلو زيمو سفانيتي",
      },
      {
        shortCode: "SJ",
        en: "Samtskhe-Javakheti",
        ar: "سامتسي جافاخيتي",
      },
      { shortCode: "SK", en: "Shida Kartli", ar: "شيدا كارتلي" },
      { shortCode: "TB", en: "Tbilisi", ar: "تبيليشي" },
    ],
  },
  {
    id: 276,
    code: "de",
    codeLng: "deu",
    en: "Germany",
    ar: "ألمانيا",
    dial: "+49",
    cities: [
      {
        shortCode: "BW",
        en: "Baden-Württemberg",
        ar: "بادين ورتيمبيرج",
      },
      { shortCode: "BY", en: "Bayern", ar: "بايرن" },
      { shortCode: "BE", en: "Berlin", ar: "بيرلين" },
      { shortCode: "BB", en: "Brandenburg", ar: "براندينبورج" },
      { shortCode: "HB", en: "Bremen", ar: "بريمين" },
      { shortCode: "HH", en: "Hamburg", ar: "هامبورج" },
      { shortCode: "HE", en: "Hessen", ar: "هيسين" },
      {
        shortCode: "MV",
        en: "Mecklenburg-Vorpommern",
        ar: "ميكلينبورج فوربوميرن",
      },
      { shortCode: "NI", en: "Niedersachsen", ar: "نايديرساشينسين" },
      {
        shortCode: "NW",
        en: "Nordrhein-Westfalen",
        ar: "نوردرهين ويستفالين",
      },
      { shortCode: "RP", en: "Rheinland-Pfalz", ar: "رهينلاند بفالز" },
      { shortCode: "SL", en: "Saarland", ar: "سارلاند" },
      { shortCode: "SN", en: "Sachsen", ar: "ساشسين" },
      { shortCode: "ST", en: "Sachsen-Anhalt", ar: "ساشسين انهالت" },
      {
        shortCode: "SH",
        en: "Schleswig-Holstein",
        ar: "شليسويج هولستين",
      },
      { shortCode: "TH", en: "Thüringen", ar: "تورينجين" },
    ],
  },
  {
    id: 288,
    code: "gh",
    codeLng: "gha",
    en: "Ghana",
    ar: "غانا",
    dial: "+233",
    cities: [
      { en: "Ahafo", ar: "اهافو" },
      { shortCode: "AH", en: "Ashanti", ar: "اشانتي" },
      { en: "Bono", ar: "بونو" },
      { en: "Bono East", ar: "شرق بونو" },
      { shortCode: "CP", en: "Central", ar: "متوسط" },
      { shortCode: "EP", en: "Eastern", ar: "الشرقيه" },
      { shortCode: "AA", en: "Greater Accra", ar: "اكرا العظمى" },
      { shortCode: "NP", en: "Northern", ar: "الشماليه" },
      { en: "North East", ar: "شمال شرق" },
      { en: "Oti", ar: "اوتي" },
      { en: "Savannah", ar: "سافانا" },
      { shortCode: "UE", en: "Upper East", ar: "الشرق العلوي" },
      { shortCode: "UW", en: "Upper West", ar: "الاعلى" },
      { shortCode: "TV", en: "Volta", ar: "فولتا" },
      { shortCode: "WP", en: "Western", ar: "الغربي" },
      { en: "Western North", ar: "الشمال الغربي" },
    ],
  },
  {
    id: 292,
    code: "gi",
    codeLng: "gib",
    en: "Gibraltar",
    ar: "جبل طارق",
    dial: "+350",
    cities: [{ en: "Gibraltar", ar: "جيبرالتار" }],
  },
  {
    id: 300,
    code: "gr",
    codeLng: "grc",
    en: "Greece",
    ar: "اليونان",
    dial: "+30",
    cities: [
      {
        shortCode: "A",
        en: "Anatolikí Makedonía kai Thráki",
        ar: "الأناضول مقدونيا وتراقيا",
      },
      { shortCode: "I", en: "Attikḯ", ar: "اتيكي" },
      { shortCode: "G", en: "Dytikí Elláda", ar: "ديتيكي ايلادا" },
      { shortCode: "C", en: "Dytikí Makedonía", ar: "ديتيكي ماكيدونيا" },
      { shortCode: "F", en: "Ionía Nísia", ar: "ايونا نيسيا" },
      {
        shortCode: "B",
        en: "Kentrikí Makedonía",
        ar: "كنتريكي مقدونيا",
      },
      { shortCode: "M", en: "Krítí", ar: "كريتي" },
      { shortCode: "L", en: "Notío Aigaío", ar: "نوتيو ايجايو" },
      { shortCode: "J", en: "Peloponnísos", ar: "بيلوبونيسوس" },
      { shortCode: "H", en: "Stereá Elláda", ar: "ستيريا ايلادا" },
      { shortCode: "E", en: "Thessalía", ar: "تيساليا" },
      { shortCode: "K", en: "Voreío Aigaío", ar: "فوريو ايجايو" },
      { shortCode: "D", en: "Ípeiros", ar: "ايبيريوس" },
      { shortCode: "69", en: "Ágion Óros", ar: "اجنيون اوروس" },
    ],
  },
  {
    id: 304,
    code: "gl",
    codeLng: "grl",
    en: "Greenland",
    ar: "جرينلاند",
    dial: "+299",
    cities: [
      { shortCode: "KU", en: "Kommune Kujalleq", ar: "كومونى كوجالق" },
      {
        shortCode: "SM",
        en: "Kommuneqarfik Sermersooq",
        ar: "كومونيكارفيك سيرميرسوك",
      },
      {
        shortCode: "QA",
        en: "Qaasuitsup Kommunia",
        ar: "كاسوتساب كومونيا",
      },
      { shortCode: "QE", en: "Qeqqata Kommunia", ar: "كيكاتا كومونيا" },
    ],
  },
  {
    id: 308,
    code: "gd",
    codeLng: "grd",
    en: "Grenada",
    ar: "غرينادا",
    dial: "+1 473",
    cities: [
      { shortCode: "01", en: "Saint Andrew", ar: "القديس اندرو" },
      { shortCode: "02", en: "Saint David", ar: "القديس ديفيد" },
      { shortCode: "03", en: "Saint George", ar: "القديس جورج" },
      { shortCode: "04", en: "Saint John", ar: "القديس جون" },
      { shortCode: "05", en: "Saint Mark", ar: "القديس مارك" },
      { shortCode: "06", en: "Saint Patrick", ar: "القديس باتريك" },
      {
        shortCode: "10",
        en: "Southern Grenadine Islands",
        ar: "جزر الجرينادين الجنوبيه",
      },
    ],
  },
  {
    id: 312,
    code: "gp",
    codeLng: "glp",
    en: "Guadeloupe",
    ar: "غوادلوب",
    dial: "+590",
    cities: [{ en: "Guadeloupe", ar: "جواديلوب" }],
  },
  {
    id: 316,
    code: "gu",
    codeLng: "gum",
    en: "Guam",
    ar: "غوام",
    dial: "+1 671",
    cities: [{ en: "Guam", ar: "جوام" }],
  },
  {
    id: 320,
    code: "gt",
    codeLng: "gtm",
    en: "Guatemala",
    ar: "غواتيمالا",
    dial: "+502",
    cities: [
      { shortCode: "AV", en: "Alta Verapaz", ar: "التا فيراباز" },
      { shortCode: "BV", en: "Baja Verapaz", ar: "باجا فيراباز" },
      { shortCode: "CM", en: "Chimaltenango", ar: "شيمالتينانجو" },
      { shortCode: "CQ", en: "Chiquimula", ar: "شيكويمولا" },
      { shortCode: "PR", en: "El Progreso", ar: "البروجريسو" },
      { shortCode: "ES", en: "Escuintla", ar: "ايسكوينتلا" },
      { shortCode: "GU", en: "Guatemala", ar: "جواتيمالا" },
      { shortCode: "HU", en: "Huehuetenango", ar: "هوي هوي تينانجو" },
      { shortCode: "IZ", en: "Izabal", ar: "ايزابال" },
      { shortCode: "JA", en: "Jalapa", ar: "جالابان" },
      { shortCode: "JU", en: "Jutiapa", ar: "جوتيابا" },
      { shortCode: "PE", en: "Petén", ar: "بيتين" },
      { shortCode: "QZ", en: "Quetzaltenango", ar: "كويتزالتينانجو" },
      { shortCode: "QC", en: "Quiché", ar: "كويشي" },
      { shortCode: "Re", en: "Retalhuleu", ar: "ريتالهوليو" },
      { shortCode: "SA", en: "Sacatepéquez", ar: "ساكاتيبيكويز" },
      { shortCode: "SM", en: "San Marcos", ar: "سان ماركوس" },
      { shortCode: "SR", en: "Santa Rosa", ar: "سانتا روسا" },
      { shortCode: "SO", en: "Sololá", ar: "سولولا" },
      { shortCode: "SU", en: "Suchitepéquez", ar: "ساتشيتيبيكويز" },
      { shortCode: "TO", en: "Totonicapán", ar: "توتونيكابان" },
      { shortCode: "ZA", en: "Zacapa", ar: "زاكابا" },
    ],
  },
  {
    id: 831,
    code: "gg",
    codeLng: "ggy",
    en: "Guernsey",
    ar: "غيرنزي",
    dial: "+44",
    cities: [
      { en: "Castel", ar: "القلعه" },
      { en: "Forest", ar: "الغابه" },
      { en: "St. Andrew", ar: "شارع اندرو" },
      { en: "St. Martin", ar: "شارع مارتن" },
      { en: "St. Peter Port", ar: "شارع بورت بيتر" },
      { en: "St. Pierre du Bois", ar: "شارع بيري دو بويس" },
      { en: "St. Sampson", ar: "شارع سامبسون" },
      { en: "St. Saviour", ar: "شارع سافيور" },
      { en: "Torteval", ar: "تورتيفال" },
      { en: "Vale", ar: "فالي" },
    ],
  },
  {
    id: 324,
    code: "gn",
    codeLng: "gin",
    en: "Guinea",
    ar: "غينيا",
    dial: "+224",
    cities: [
      { shortCode: "B", en: "Boké", ar: "بوكي" },
      { shortCode: "C", en: "Conakry", ar: "كوناكري" },
      { shortCode: "F", en: "Faranah", ar: "فارانا" },
      { shortCode: "K", en: "Kankan", ar: "كانكان" },
      { shortCode: "D", en: "Kindia", ar: "كينديا" },
      { shortCode: "L", en: "Labé", ar: "لابي" },
      { shortCode: "M", en: "Mamou", ar: "مامو" },
      { shortCode: "N", en: "Nzérékoré", ar: "نزيريكوري" },
    ],
  },
  {
    id: 624,
    code: "gw",
    codeLng: "gnb",
    en: "Guinea-Bissau",
    ar: "غينيا بيساو",
    dial: "+245",
    cities: [
      { shortCode: "BA", en: "Bafatá", ar: "بافاتا" },
      { shortCode: "BM", en: "Biombo", ar: "بيومبو" },
      { shortCode: "BS", en: "Bissau", ar: "بيساو" },
      { shortCode: "BL", en: "Bolama-Bijagos", ar: "بولاما بيجاجوس" },
      { shortCode: "CA", en: "Cacheu", ar: "كاشو" },
      { shortCode: "GA", en: "Gabú", ar: "جابو" },
      { shortCode: "OI", en: "Oio", ar: "اويو" },
      { shortCode: "QU", en: "Quinara", ar: "كوينارا" },
      { shortCode: "TO", en: "Tombali", ar: "تومبالي" },
    ],
  },
  {
    id: 328,
    code: "gy",
    codeLng: "guy",
    en: "Guyana",
    ar: "غيانا",
    dial: "+595",
    cities: [
      { shortCode: "BA", en: "Barima-Waini", ar: "باريما وايني" },
      { shortCode: "CU", en: "Cuyuni-Mazaruni", ar: "كويوني ماذاروني" },
      { shortCode: "DE", en: "Demerara-Mahaica", ar: "ديميرارا ماهايكا" },
      {
        shortCode: "EB",
        en: "East Berbice-Corentyne",
        ar: "شرق بيربيكي كورينتين",
      },
      {
        shortCode: "ES",
        en: "Essequibo Islands-West Demerara",
        ar: "ايسيكويبو غرب جزر ديميرارا",
      },
      { shortCode: "MA", en: "Mahaica-Berbice", ar: "ماهايكا بيربيكي" },
      {
        shortCode: "PM",
        en: "Pomeroon-Supenaam",
        ar: "بوميرون سوبينام",
      },
      { shortCode: "PT", en: "Potaro-Siparuni", ar: "بوتارو سيباروني" },
      {
        shortCode: "UD",
        en: "Upper Demerara-Berbice",
        ar: "ديميرارا بيربيكي العلويه",
      },
      {
        shortCode: "UT",
        en: "Upper Takutu-Upper Essequibo",
        ar: "ايسيكويبو تاكوتو العلويه العلويه",
      },
    ],
  },
  {
    id: 332,
    code: "ht",
    codeLng: "hti",
    en: "Haiti",
    ar: "هايتي",
    dial: "+509",
    cities: [
      { shortCode: "AR", en: "Artibonite", ar: "ارتيبونيتي" },
      { shortCode: "CE", en: "Centre", ar: "سينترى" },
      { shortCode: "GA", en: "Grand'Anse", ar: "جراند انسي" },
      { shortCode: "NI", en: "Nippes", ar: "نيبيس" },
      { shortCode: "ND", en: "Nord", ar: "نورد" },
      { shortCode: "NE", en: "Nord-Est", ar: "نورد ايست" },
      { shortCode: "NO", en: "Nord-Ouest", ar: "نورد اويست" },
      { shortCode: "OU", en: "Ouest", ar: "اويست" },
      { shortCode: "SD", en: "Sud", ar: "سود" },
      { shortCode: "SE", en: "Sud-Est", ar: "سود ايست" },
    ],
  },
  {
    id: 334,
    code: "hm",
    codeLng: "hmd",
    en: "Heard Island and McDonald Islands",
    ar: "جزيرة هيرد وجزر ماكدونالد",
    dial: "+672",
    cities: [
      {
        en: "Heard Island and McDonald Islands",
        ar: "جزر الهيرد و جزر ماكنونالد",
      },
    ],
  },
  {
    id: 336,
    code: "va",
    codeLng: "vat",
    en: "Holy See",
    ar: "الفاتيكان",
    dial: "+379",
    cities: [
      {
        shortCode: "01",
        en: "Holy See (Vatican City)",
        ar: "هولي سي",
      },
    ],
  },
  {
    id: 340,
    code: "hn",
    codeLng: "hnd",
    en: "Honduras",
    ar: "هندوراس",
    dial: "+504",
    cities: [
      { shortCode: "AT", en: "Atlántida", ar: "اتلانتيدا" },
      { shortCode: "CH", en: "Choluteca", ar: "شولوتيكا" },
      { shortCode: "CL", en: "Colón", ar: "كولون" },
      { shortCode: "CM", en: "Comayagua", ar: "كوماياجوا" },
      { shortCode: "CP", en: "Copán", ar: "كوبان" },
      { shortCode: "CR", en: "Cortés", ar: "كورتيس" },
      { shortCode: "EP", en: "El Paraíso", ar: "البارايسو" },
      {
        shortCode: "FM",
        en: "Francisco Morazan",
        ar: "مورازان فرانسيسكو",
      },
      { shortCode: "GD", en: "Gracias a Dios", ar: "ديوس الشكر" },
      { shortCode: "IN", en: "Intibucá", ar: "انتيبوكا" },
      {
        shortCode: "IB",
        en: "Islas de la Bahía",
        ar: "ايسلاس دي باهيا",
      },
      { shortCode: "LP", en: "La Paz", ar: "لا باذ" },
      { shortCode: "LE", en: "Lempira", ar: "ليمبيرا" },
      { shortCode: "OC", en: "Ocotepeque", ar: "اكوتيبيكو" },
      { shortCode: "OL", en: "Olancho", ar: "اولانشو" },
      { shortCode: "SB", en: "Santa Bárbara", ar: "سانتا باربارا" },
      { shortCode: "VA", en: "Valle", ar: "فالي" },
      { shortCode: "YO", en: "Yoro", ar: "يورو" },
    ],
  },
  {
    id: 344,
    code: "hk",
    codeLng: "hkg",
    en: "Hong Kong",
    ar: "هونغ كونغ",
    dial: "+852",
    cities: [{ shortCode: "HK", en: "Hong Kong", ar: "هونغ كونغ" }],
  },
  {
    id: 348,
    code: "hu",
    codeLng: "hun",
    en: "Hungary",
    ar: "المجر",
    dial: "+36",
    cities: [
      { shortCode: "BK", en: "Bács-Kiskun", ar: "باكس كيسكون" },
      { shortCode: "BA", en: "Baranya", ar: "بيرانيا" },
      { shortCode: "BE", en: "Békés", ar: "بيكيس" },
      { shortCode: "BC", en: "Békéscsaba", ar: "بيكيسكسابا" },
      {
        shortCode: "BZ",
        en: "Borsod-Abauj-Zemplen",
        ar: "Borsod-Abauj-Zemplen",
      },
      { shortCode: "BU", en: "Budapest", ar: "بودابيست" },
      { shortCode: "CS", en: "Csongrád", ar: "سونجراد" },
      { shortCode: "DE", en: "Debrecen", ar: "ديبريكين" },
      { shortCode: "DU", en: "Dunaújváros", ar: "دوناوفاروس" },
      { shortCode: "EG", en: "Eger", ar: "ايجر" },
      { shortCode: "ER", en: "Érd", ar: "ايرد" },
      { shortCode: "FE", en: "Fejér", ar: "فيجير" },
      { shortCode: "GY", en: "Győr", ar: "جيور" },
      {
        shortCode: "GS",
        en: "Győr-Moson-Sopron",
        ar: "جيور موسون سوبرون",
      },
      { shortCode: "HB", en: "Hajdú-Bihar", ar: "هاجدو بيهار" },
      { shortCode: "HE", en: "Heves", ar: "هيفيس" },
      { shortCode: "HV", en: "Hódmezővásárhely", ar: "هودميزوفاسارهيلي" },
      {
        shortCode: "N",
        en: "Jász-Nagykun-Szolnok",
        ar: "جاز ناجيكون سزولنوك",
      },
      { shortCode: "KV", en: "Kaposvár", ar: "كابوسفار" },
      { shortCode: "KM", en: "Kecskemét", ar: "كيسكيميت" },
      {
        shortCode: "KE",
        en: "Komárom-Esztergom",
        ar: "كوماروم ايزتيرجوم",
      },
      { shortCode: "MI", en: "Miskolc", ar: "ميسكولك" },
      { shortCode: "NK", en: "Nagykanizsa", ar: "ناجيكانيسزا" },
      { shortCode: "NO", en: "Nógrád", ar: "نوجراد" },
      { shortCode: "NY", en: "Nyíregyháza", ar: "نيريجيهاذا" },
      { shortCode: "PS", en: "Pécs", ar: "بيكس" },
      { shortCode: "PE", en: "Pest", ar: "بيست" },
      { shortCode: "ST", en: "Salgótarján", ar: "سالجوتارجان" },
      { shortCode: "SO", en: "Somogy", ar: "سوموجي" },
      { shortCode: "SN", en: "Sopron", ar: "سوبرون" },
      { shortCode: "SZ", en: "Szabolcs-á-Bereg", ar: "زابولكس بيرج" },
      { shortCode: "SD", en: "Szeged", ar: "زيجيد" },
      { shortCode: "SF", en: "Székesfehérvár", ar: "زيكيسفيهيرفار" },
      { shortCode: "SS", en: "Szekszárd", ar: "زيكزارد" },
      { shortCode: "SK", en: "Szolnok", ar: "زولنوك" },
      { shortCode: "SH", en: "Szombathely", ar: "زومباتيلي" },
      { shortCode: "TB", en: "Tatabánya", ar: "تاتابانيا" },
      { shortCode: "TO", en: "Tolna", ar: "تولنا" },
      { shortCode: "VA", en: "Vas", ar: "فاس" },
      { shortCode: "VE", en: "Veszprém", ar: "فيسبريم" },
      { shortCode: "VM", en: "Veszprém (City)", ar: "فيسبريم" },
      { shortCode: "ZA", en: "Zala", ar: "زالا" },
      { shortCode: "ZE", en: "Zalaegerszeg", ar: "زالايجيرزيج" },
    ],
  },
  {
    id: 352,
    code: "is",
    codeLng: "isl",
    en: "Iceland",
    ar: "آيسلندا",
    dial: "+354",
    cities: [
      { shortCode: "7", en: "Austurland", ar: "اوستورلاند" },
      {
        shortCode: "1",
        en: "Höfuðborgarsvæði utan Reykjavíkur",
        ar: "منطقة العاصمة خارج ريكيافيك",
      },
      {
        shortCode: "6",
        en: "Norðurland eystra",
        ar: "الشمال الشرقي",
      },
      {
        shortCode: "5",
        en: "Norðurland vestra",
        ar: "الشمال الغربي",
      },
      { shortCode: "8", en: "Suðurland", ar: "جنوب" },
      { shortCode: "2", en: "Suðurnes", ar: "سورنيس" },
      { shortCode: "4", en: "Vestfirðir", ar: "ويستفيوردس" },
      { shortCode: "3", en: "Vesturland", ar: "غرب ايسلندا" },
    ],
  },
  {
    id: 356,
    code: "in",
    codeLng: "ind",
    en: "India",
    ar: "الهند",
    dial: "+91",
    cities: [
      {
        shortCode: "AN",
        en: "Andaman and Nicobar Islands",
        ar: "جزر اندامان و نيكوبار ",
      },
      { shortCode: "AP", en: "Andhra Pradesh", ar: "اندرا براديش" },
      {
        shortCode: "AR",
        en: "Arunachal Pradesh",
        ar: "اروناشال براديش",
      },
      { shortCode: "AS", en: "Assam", ar: "اسام" },
      { shortCode: "BR", en: "Bihar", ar: "بيهار" },
      { shortCode: "CH", en: "Chandigarh", ar: "كانديجار" },
      { shortCode: "CT", en: "Chhattisgarh", ar: "شاتيسجار" },
      {
        shortCode: "DN",
        en: "Dadra and Nagar Haveli",
        ar: "دادرا و نجار هافيلي",
      },
      { shortCode: "DD", en: "Daman and Diu", ar: "دامان و ديو" },
      { shortCode: "DL", en: "Delhi", ar: "ديلهي" },
      { shortCode: "GA", en: "Goa", ar: "جوا" },
      { shortCode: "GJ", en: "Gujarat", ar: "جوجارات" },
      { shortCode: "HR", en: "Haryana", ar: "هاريانا" },
      { shortCode: "HP", en: "Himachal Pradesh", ar: "هيماشال براديش" },
      {
        shortCode: "JK",
        en: "Jammu and Kashmir",
        ar: "جامو و كاشمير",
      },
      { shortCode: "JH", en: "Jharkhand", ar: "جاركاند" },
      { shortCode: "KA", en: "Karnataka", ar: "كارناتاكا" },
      { shortCode: "KL", en: "Kerala", ar: "كيرالا" },
      { shortCode: "LD", en: "Lakshadweep", ar: "لاكشادويب" },
      { shortCode: "MP", en: "Madhya Pradesh", ar: "ماديا براديش" },
      { shortCode: "MH", en: "Maharashtra", ar: "ماهاراشترا" },
      { shortCode: "MN", en: "Manipur", ar: "مانيبور" },
      { shortCode: "ML", en: "Meghalaya", ar: "ميغالايا" },
      { shortCode: "MZ", en: "Mizoram", ar: "ميزورام" },
      { shortCode: "NL", en: "Nagaland", ar: "ناجالاند" },
      { shortCode: "OR", en: "Odisha", ar: "اوديشا" },
      { shortCode: "PY", en: "Puducherry", ar: "بودوشيري" },
      { shortCode: "PB", en: "Punjab", ar: "بونجاب" },
      { shortCode: "RJ", en: "Rajasthan", ar: "راجاستان" },
      { shortCode: "WK", en: "Sikkim", ar: "سيكيم" },
      { shortCode: "TN", en: "Tamil Nadu", ar: "تاميل نادو" },
      { shortCode: "TG", en: "Telangana", ar: "تيلانجانا" },
      { shortCode: "TR", en: "Tripura", ar: "تريبورا" },
      { shortCode: "UT", en: "Uttarakhand", ar: "اوتاراكاند" },
      { shortCode: "UP", en: "Uttar Pradesh", ar: "اوتار براديش" },
      { shortCode: "WB", en: "West Bengal", ar: "غرب بينجال" },
    ],
  },
  {
    id: 360,
    code: "id",
    codeLng: "idn",
    en: "Indonesia",
    ar: "إندونيسيا",
    dial: "+62",
    cities: [
      { shortCode: "AC", en: "Aceh", ar: "اكيه" },
      { shortCode: "BA", en: "Bali", ar: "بالي" },
      { shortCode: "BB", en: "Bangka Belitung", ar: "بانجكا بيايتونج" },
      { shortCode: "BT", en: "Banten", ar: "بانتين" },
      { shortCode: "BE", en: "Bengkulu", ar: "بينجكولو" },
      { shortCode: "GO", en: "Gorontalo", ar: "جورونتالو" },
      { shortCode: "JK", en: "Jakarta Raya", ar: "جاكارتا رايا" },
      { shortCode: "JA", en: "Jambi", ar: "جامبي" },
      { shortCode: "JB", en: "Jawa Barat", ar: "جاوا بيرات" },
      { shortCode: "JT", en: "Jawa Tengah", ar: "جاوا تينجا" },
      { shortCode: "JI", en: "Jawa Timur", ar: "جاوا تيمور" },
      { shortCode: "KB", en: "Kalimantan Barat", ar: "كاليمانتان بارات" },
      {
        shortCode: "KS",
        en: "Kalimantan Selatan",
        ar: "كاليمانتان سيلاتان",
      },
      {
        shortCode: "KT",
        en: "Kalimantan Tengah",
        ar: "كاليمانتان تينجاه",
      },
      { shortCode: "KI", en: "Kalimantan Timur", ar: "كاليمانتان تيمور" },
      { shortCode: "KU", en: "Kalimantan Utara", ar: "كاليمانتان اوتارا" },
      { shortCode: "KR", en: "Kepulauan Riau", ar: "كيبولاوان رايو" },
      { shortCode: "LA", en: "Lampung", ar: "لامبونج" },
      { shortCode: "MA", en: "Maluku", ar: "مالوكو" },
      { shortCode: "MU", en: "Maluku Utara", ar: "مالوكو اوتارا" },
      {
        shortCode: "NB",
        en: "Nusa Tenggara Barat",
        ar: "نوسا تينجارا بارات",
      },
      {
        shortCode: "NT",
        en: "Nusa Tenggara Timur",
        ar: "نوسا تينجارا تيمور",
      },
      { shortCode: "PA", en: "Papua", ar: "بابوا" },
      { shortCode: "PB", en: "Papua Barat", ar: "بابوا بارات" },
      { shortCode: "RI", en: "Riau", ar: "رايو" },
      { shortCode: "SR", en: "Sulawesi Selatan", ar: "سولاويسي سيلاتان" },
      { shortCode: "ST", en: "Sulawesi Tengah", ar: "سولاويسي تينجاه" },
      {
        shortCode: "SG",
        en: "Sulawesi Tenggara",
        ar: "سولاويسي تينجارا",
      },
      { shortCode: "SA", en: "Sulawesi Utara", ar: "سولاويسي اوتارا" },
      { shortCode: "SB", en: "Sumatera Barat", ar: "سوماتيرا بارات" },
      { shortCode: "SS", en: "Sumatera Selatan", ar: "Sسوماتيرا سيلاتان" },
      { shortCode: "SU", en: "Sumatera Utara", ar: "سوماتيرا اوتارا" },
      { shortCode: "YO", en: "Yogyakarta", ar: "يوجيكارتا" },
    ],
  },
  {
    id: 364,
    code: "ir",
    codeLng: "irn",
    en: "Iran (Islamic Republic of)",
    ar: "إيران",
    dial: "+98",
    cities: [
      { shortCode: "32", en: "Alborz", ar: "البورز" },
      { shortCode: "03", en: "Ardabīl", ar: "اردابيل" },
      {
        shortCode: "02",
        en: "Āz̄arbāyjān-e Gharbī",
        ar: "ازاربايجان غربي",
      },
      {
        shortCode: "01",
        en: "Āz̄arbāyjān-e Sharqī",
        ar: "ازاربايجان شرقي",
      },
      { shortCode: "06", en: "Būshehr", ar: "بوشيهر" },
      {
        shortCode: "08",
        en: "Chahār Maḩāl va Bakhtīārī",
        ar: "شاهار ماهال باختياري",
      },
      { shortCode: "04", en: "Eşfahān", ar: "ايسفاهان" },
      { shortCode: "14", en: "Fārs", ar: "فارس" },
      { shortCode: "19", en: "Gīlān", ar: "جيلان" },
      { shortCode: "27", en: "Golestān", ar: "جوليستان" },
      { shortCode: "24", en: "Hamadān", ar: "هامادان" },
      { shortCode: "23", en: "Hormozgān", ar: "هورموزجان" },
      { shortCode: "05", en: "Īlām", ar: "ايلام" },
      { shortCode: "15", en: "Kermān", ar: "كيرمان" },
      { shortCode: "17", en: "Kermānshāh", ar: "كيرمانشاه" },
      {
        shortCode: "29",
        en: "Khorāsān-e Jonūbī",
        ar: "خوراسات جونوبي",
      },
      {
        shortCode: "30",
        en: "Khorāsān-e Raẕavī",
        ar: "خوراسان رازافي",
      },
      {
        shortCode: "61",
        en: "Khorāsān-e Shomālī",
        ar: "خوراسان شومالي",
      },
      { shortCode: "10", en: "Khūzestān", ar: "خوزيستان" },
      {
        shortCode: "18",
        en: "Kohgīlūyeh va Bowyer Aḩmad",
        ar: "كوجيلوي بوير احمد",
      },
      { shortCode: "16", en: "Kordestān", ar: "كورديستات" },
      { shortCode: "20", en: "Lorestān", ar: "لوريستان" },
      { shortCode: "22", en: "Markazi", ar: "ماركازي" },
      { shortCode: "21", en: "Māzandarān", ar: "مازانداران" },
      { shortCode: "28", en: "Qazvīn", ar: "كازفين" },
      { shortCode: "26", en: "Qom", ar: "كوم" },
      { shortCode: "12", en: "Semnān", ar: "سيمنان" },
      {
        shortCode: "13",
        en: "Sīstān va Balūchestān",
        ar: "سيستان بالوشيستان",
      },
      { shortCode: "07", en: "Tehrān", ar: "تيران" },
      { shortCode: "25", en: "Yazd", ar: "يازد" },
      { shortCode: "11", en: "Zanjān", ar: "زانزان" },
    ],
  },
  {
    id: 368,
    code: "iq",
    codeLng: "irq",
    en: "Iraq",
    ar: "العراق",
    dial: "+964",
    cities: [
      { shortCode: "AN", en: "Al Anbār", ar: "الانبار" },
      { shortCode: "BA", en: "Al Başrah", ar: "البصره" },
      { shortCode: "MU", en: "Al Muthanná", ar: "الموتانا" },
      { shortCode: "QA", en: "Al Qādisīyah", ar: "القديسياه" },
      { shortCode: "NA", en: "An Najaf", ar: "النجف" },
      { shortCode: "AR", en: "Arbīl", ar: "اربيل" },
      { shortCode: "SU", en: "As Sulaymānīyah", ar: "السليمانيه" },
      { shortCode: "BB", en: "Bābil", ar: "بابيل" },
      { shortCode: "BG", en: "Baghdād", ar: "بغداد" },
      { shortCode: "DA", en: "Dohuk", ar: "دوهاك" },
      { shortCode: "DQ", en: "Dhī Qār", ar: "دي قار" },
      { shortCode: "DI", en: "Diyālá", ar: "ديالا" },
      { shortCode: "KA", en: "Karbalā'", ar: "كاربالا'" },
      { shortCode: "KI", en: "Kirkuk", ar: "كيركوك" },
      { shortCode: "MA", en: "Maysān", ar: "مايسان" },
      { shortCode: "NI", en: "Nīnawá", ar: "نيناوا" },
      { shortCode: "SD", en: "Şalāḩ ad Dīn", ar: "صلاح الدين" },
      { shortCode: "WA", en: "Wāsiţ", ar: "وسيط" },
    ],
  },
  {
    id: 372,
    code: "ie",
    codeLng: "irl",
    en: "Ireland",
    ar: "أيرلندا",
    dial: "+353",
    cities: [
      { shortCode: "CW", en: "Carlow", ar: "كارلو" },
      { shortCode: "CN", en: "Cavan", ar: "كافان" },
      { shortCode: "CE", en: "Clare", ar: "كلاري" },
      { shortCode: "CO", en: "Cork", ar: "كورك" },
      { shortCode: "DL", en: "Donegal", ar: "دونيجال" },
      { shortCode: "D", en: "Dublin", ar: "دوبلين" },
      { shortCode: "G", en: "Galway", ar: "جالواي" },
      { shortCode: "KY", en: "Kerry", ar: "كيري" },
      { shortCode: "KE", en: "Kildare", ar: "كيلداري" },
      { shortCode: "KK", en: "Kilkenny", ar: "كيلكيني" },
      { shortCode: "LS", en: "Laois", ar: "لاويس" },
      { shortCode: "LM", en: "Leitrim", ar: "ليتريم" },
      { shortCode: "LK", en: "Limerick", ar: "ليمريك" },
      { shortCode: "LD", en: "Longford", ar: "لونجفورد" },
      { shortCode: "LH", en: "Louth", ar: "لاوث" },
      { shortCode: "MO", en: "Mayo", ar: "مايو" },
      { shortCode: "MH", en: "Meath", ar: "ميث" },
      { shortCode: "MN", en: "Monaghan", ar: "مونوغان" },
      { shortCode: "OY", en: "Offaly", ar: "اوفالي" },
      { shortCode: "RN", en: "Roscommon", ar: "روسكومون" },
      { shortCode: "SO", en: "Sligo", ar: "سليجو" },
      { shortCode: "TA", en: "Tipperary", ar: "تيبراري" },
      { shortCode: "WD", en: "Waterford", ar: "ووترفورد" },
      { shortCode: "WH", en: "Westmeath", ar: "ويستميث" },
      { shortCode: "WX", en: "Wexford", ar: "ويكسفورد" },
      { shortCode: "WW", en: "Wicklow", ar: "ويكلو" },
    ],
  },
  {
    id: 833,
    code: "im",
    codeLng: "imn",
    en: "Isle of Man",
    ar: "جزيرة مان",
    dial: "+44",
    cities: [{ en: "Isle of Man", ar: "جزيره الرجل" }],
  },
  {
    id: 376,
    code: "il",
    codeLng: "isr",
    en: "Israel",
    ar: "إسرائيل",
    dial: "+972",
    cities: [
      { shortCode: "D", en: "HaDarom", ar: "هاداروم" },
      { shortCode: "M", en: "HaMerkaz", ar: "هاميركاذ" },
      { shortCode: "Z", en: "HaTsafon", ar: "هاتسافون" },
      { shortCode: "HA", en: "H̱efa", ar: "هيفا" },
      { shortCode: "TA", en: "Tel-Aviv", ar: "تل ابيب" },
      { shortCode: "JM", en: "Yerushalayim", ar: "يروشالاييم" },
    ],
  },
  {
    id: 380,
    code: "it",
    codeLng: "ita",
    en: "Italy",
    ar: "إيطاليا",
    dial: "+39",
    cities: [
      { shortCode: "65", en: "Abruzzo", ar: "ابروزو" },
      { shortCode: "77", en: "Basilicata", ar: "باسيليكاتا" },
      { shortCode: "78", en: "Calabria", ar: "كالابريا" },
      { shortCode: "72", en: "Campania", ar: "كامبانيا" },
      { shortCode: "45", en: "Emilia-Romagna", ar: "ايميليا رومانجا" },
      {
        shortCode: "36",
        en: "Friuli-Venezia Giulia",
        ar: "Friuli-Venezia Giulia",
      },
      { shortCode: "62", en: "Lazio", ar: "لازيو" },
      { shortCode: "42", en: "Liguria", ar: "ليجويرا" },
      { shortCode: "25", en: "Lombardia", ar: "لومبارديا" },
      { shortCode: "57", en: "Marche", ar: "مارشش" },
      { shortCode: "67", en: "Molise", ar: "مويليسي" },
      { shortCode: "21", en: "Piemonte", ar: "بيمونتي" },
      { shortCode: "75", en: "Puglia", ar: "بوجليا" },
      { shortCode: "88", en: "Sardegna", ar: "سارديجنا" },
      { shortCode: "82", en: "Sicilia", ar: "سيسيليا" },
      { shortCode: "52", en: "Toscana", ar: "توسكانا" },
      {
        shortCode: "32",
        en: "Trentino-Alto Adige",
        ar: "تيرينتينو التو اديجي",
      },
      { shortCode: "55", en: "Umbria", ar: "امبريا" },
      { shortCode: "23", en: "Valle d'Aosta", ar: "فالي دي اوستا" },
      { shortCode: "34", en: "Veneto", ar: "فينيتو" },
    ],
  },
  {
    id: 388,
    code: "jm",
    codeLng: "jam",
    en: "Jamaica",
    ar: "جامايكا",
    dial: "+1 876",
    cities: [
      { shortCode: "13", en: "Clarendon", ar: "كلاريندون" },
      { shortCode: "09", en: "Hanover", ar: "هانوفر" },
      { shortCode: "01", en: "Kingston", ar: "كينجستون" },
      { shortCode: "12", en: "Manchester", ar: "مينشيستر" },
      { shortCode: "04", en: "Portland", ar: "بورتلاند" },
      { shortCode: "02", en: "Saint Andrew", ar: "القديس اندرو" },
      { shortCode: "06", en: "Saint Ann", ar: "القديس ان" },
      { shortCode: "14", en: "Saint Catherine", ar: "القديس كاترين" },
      { shortCode: "11", en: "Saint Elizabeth", ar: "القديسه ايليزابيس" },
      { shortCode: "08", en: "Saint James", ar: "القديس جيمس" },
      { shortCode: "05", en: "Saint Mary", ar: "القديسه ماري" },
      { shortCode: "03", en: "Saint Thomas", ar: "القديس توماس" },
      { shortCode: "07", en: "Trelawny", ar: "تريلاوني" },
      { shortCode: "10", en: "Westmoreland", ar: "ويستمورلاند" },
    ],
  },
  {
    id: 392,
    code: "jp",
    codeLng: "jpn",
    en: "Japan",
    ar: "اليابان",
    dial: "+81",
    cities: [
      { shortCode: "23", en: "Aichi", ar: "ايشي" },
      { shortCode: "05", en: "Akita", ar: "اكيتا" },
      { shortCode: "02", en: "Aomori", ar: "اموري" },
      { shortCode: "12", en: "Chiba", ar: "شيبا" },
      { shortCode: "38", en: "Ehime", ar: "ايهيمي" },
      { shortCode: "18", en: "Fukui", ar: "فوكوي" },
      { shortCode: "40", en: "Fukuoka", ar: "فوكوكا" },
      { shortCode: "07", en: "Fukushima", ar: "فوكوشيما" },
      { shortCode: "21", en: "Gifu", ar: "جيفو" },
      { shortCode: "10", en: "Gunma", ar: "جونما" },
      { shortCode: "34", en: "Hiroshima", ar: "هيروشيما" },
      { shortCode: "01", en: "Hokkaido", ar: "هوكايدو" },
      { shortCode: "28", en: "Hyogo", ar: "هيوجو" },
      { shortCode: "08", en: "Ibaraki", ar: "ايباراكي" },
      { shortCode: "17", en: "Ishikawa", ar: "ايشيكاوا" },
      { shortCode: "03", en: "Iwate", ar: "ايواتي" },
      { shortCode: "37", en: "Kagawa", ar: "كاجاوا" },
      { shortCode: "46", en: "Kagoshima", ar: "كاجوشيما" },
      { shortCode: "14", en: "Kanagawa", ar: "كاناجاوا" },
      { shortCode: "39", en: "Kochi", ar: "كوشي" },
      { shortCode: "43", en: "Kumamoto", ar: "كوماموتو" },
      { shortCode: "26", en: "Kyoto", ar: "كيوتو" },
      { shortCode: "24", en: "Mie", ar: "مي" },
      { shortCode: "04", en: "Miyagi", ar: "ميياجي" },
      { shortCode: "45", en: "Miyazaki", ar: "مييازاكي" },
      { shortCode: "20", en: "Nagano", ar: "ناجانو" },
      { shortCode: "42", en: "Nagasaki", ar: "ناجاساكي" },
      { shortCode: "29", en: "Nara", ar: "نارا" },
      { shortCode: "15", en: "Niigata", ar: "نيجاتا" },
      { shortCode: "44", en: "Oita", ar: "اويتا" },
      { shortCode: "33", en: "Okayama", ar: "اوكاياما" },
      { shortCode: "47", en: "Okinawa", ar: "اوكيناوا" },
      { shortCode: "27", en: "Osaka", ar: "اوساكا" },
      { shortCode: "41", en: "Saga", ar: "ساجا" },
      { shortCode: "11", en: "Saitama", ar: "سيتاما" },
      { shortCode: "25", en: "Shiga", ar: "شيجا" },
      { shortCode: "32", en: "Shimane", ar: "شيماني" },
      { shortCode: "22", en: "Shizuoka", ar: "شيزوكا" },
      { shortCode: "09", en: "Tochigi", ar: "توشيجي" },
      { shortCode: "36", en: "Tokushima", ar: "تو؛وشيما" },
      { shortCode: "13", en: "Tokyo", ar: "اوكيو" },
      { shortCode: "31", en: "Tottori", ar: "توتوري" },
      { shortCode: "16", en: "Toyama", ar: "توياما" },
      { shortCode: "30", en: "Wakayama", ar: "واكاياما" },
      { shortCode: "06", en: "Yamagata", ar: "ياماجاتا" },
      { shortCode: "35", en: "Yamaguchi", ar: "ياماجوتشي" },
      { shortCode: "19", en: "Yamanashi", ar: "ياماناشي" },
    ],
  },
  {
    id: 832,
    code: "je",
    codeLng: "jey",
    en: "Jersey",
    ar: "جيرزي",
    dial: "+44",
    cities: [{ en: "Jersey", ar: "جيرسي" }],
  },
  {
    id: 400,
    code: "jo",
    codeLng: "jor",
    en: "Jordan",
    ar: "الأردن",
    dial: "+962",
    cities: [
      { shortCode: "AJ", en: "‘Ajlūn", ar: "اجلون" },
      { shortCode: "AQ", en: "Al 'Aqabah", ar: "الاقبح" },
      { shortCode: "BA", en: "Al Balqā’", ar: "البلقه" },
      { shortCode: "KA", en: "Al Karak", ar: "الكارك" },
      { shortCode: "MA", en: "Al Mafraq", ar: "المفرق" },
      { shortCode: "AM", en: "Al ‘A̅şimah", ar: "القسيمه" },
      { shortCode: "AT", en: "Aţ Ţafīlah", ar: "اتيفالاه" },
      { shortCode: "AZ", en: "Az Zarqā’", ar: "الزرقه" },
      { shortCode: "IR", en: "Irbid", ar: "ايربيد" },
      { shortCode: "JA", en: "Jarash", ar: "جاراش" },
      { shortCode: "MN", en: "Ma‘ān", ar: "مان" },
      { shortCode: "MD", en: "Mādabā", ar: "مادابا" },
    ],
  },
  {
    id: 398,
    code: "kz",
    codeLng: "kaz",
    en: "Kazakhstan",
    ar: "كازاخستان",
    dial: "+7 7",
    cities: [
      { shortCode: "ALA", en: "Almaty", ar: "الماتي" },
      { shortCode: "AKM", en: "Aqmola", ar: "اكمولا" },
      { shortCode: "AKT", en: "Aqtobe", ar: "اكتوبي" },
      { shortCode: "AST", en: "Astana", ar: "استانا" },
      { shortCode: "ATY", en: "Atyrau", ar: "اترياو" },
      { shortCode: "ZAP", en: "Batys Qazaqstan", ar: "باتيس كازاكستان" },
      { en: "Bayqongyr", ar: "بايكونجير" },
      { shortCode: "MAN", en: "Mangghystau", ar: "مانجيستاو" },
      {
        shortCode: "YUZ",
        en: "Ongtustik Qazaqstan",
        ar: "اونجوستيك كازاكيستان",
      },
      { shortCode: "PAV", en: "Pavlodar", ar: "بافلودار" },
      { shortCode: "KAR", en: "Qaraghandy", ar: "كاراغاندي" },
      { shortCode: "KUS", en: "Qostanay", ar: "كوستاناي" },
      { shortCode: "KZY", en: "Qyzylorda", ar: "كيزيلوريدا" },
      {
        shortCode: "VOS",
        en: "Shyghys Qazaqstan",
        ar: "شيجيس كازاكستان",
      },
      {
        shortCode: "SEV",
        en: "Soltustik Qazaqstan",
        ar: "سولتوستيك كازاكيستان",
      },
      { shortCode: "ZHA", en: "Zhambyl", ar: "زامبيل" },
    ],
  },
  {
    id: 404,
    code: "ke",
    codeLng: "ken",
    en: "Kenya",
    ar: "كينيا",
    dial: "+254",
    cities: [
      { shortCode: "01", en: "Baringo", ar: "بارينجو" },
      { shortCode: "02", en: "Bomet", ar: "بوميت" },
      { shortCode: "03", en: "Bungoma", ar: "بونجوما" },
      { shortCode: "04", en: "Busia", ar: "بوسيا" },
      { shortCode: "05", en: "Eleyo/Marakwet", ar: "ايليو" },
      { shortCode: "06", en: "Embu", ar: "ايمبو" },
      { shortCode: "07", en: "Garissa", ar: "جاريسا" },
      { shortCode: "08", en: "Homa Bay", ar: "هوما باي" },
      { shortCode: "09", en: "Isiolo", ar: "ايسيولو" },
      { shortCode: "10", en: "Kajiado", ar: "كاجيادو" },
      { shortCode: "11", en: "Kakamega", ar: "كاكاميجا" },
      { shortCode: "12", en: "Kericho", ar: "كيريشو" },
      { shortCode: "13", en: "Kiambu", ar: "كيامبو" },
      { shortCode: "14", en: "Kilifi", ar: "كيليفي" },
      { shortCode: "15", en: "Kirinyaga", ar: "كيرينياجا" },
      { shortCode: "16", en: "Kisii", ar: "كيسي" },
      { shortCode: "17", en: "Kisumu", ar: "كيسومو" },
      { shortCode: "18", en: "Kitui", ar: "كيتوي" },
      { shortCode: "19", en: "Kwale", ar: "كوالي" },
      { shortCode: "20", en: "Laikipia", ar: "لايكيبيا" },
      { shortCode: "21", en: "Lamu", ar: "لامو" },
      { shortCode: "22", en: "Machakos", ar: "ماكاكوس" },
      { shortCode: "23", en: "Makueni", ar: "ماكويني" },
      { shortCode: "24", en: "Mandera", ar: "مينديرا" },
      { shortCode: "25", en: "Marsabit", ar: "مارسابيت" },
      { shortCode: "26", en: "Meru", ar: "ميرو" },
      { shortCode: "27", en: "Migori", ar: "ميجوري" },
      { shortCode: "28", en: "Mombasa", ar: "مومباسا" },
      { shortCode: "29", en: "Murang'a", ar: "مورانج" },
      { shortCode: "30", en: "Nairobi City", ar: "نايروبي" },
      { shortCode: "31", en: "Nakuru", ar: "نيكورو" },
      { shortCode: "32", en: "Nandi", ar: "ناندي" },
      { shortCode: "33", en: "Narok", ar: "ناروك" },
      { shortCode: "34", en: "Nyamira", ar: "نيميرا" },
      { shortCode: "35", en: "Nyandarua", ar: "نيانداروا" },
      { shortCode: "36", en: "Nyeri", ar: "نييري" },
      { shortCode: "37", en: "Samburu", ar: "سامبورو" },
      { shortCode: "38", en: "Siaya", ar: "سيايا" },
      { shortCode: "39", en: "Taita/Taveta", ar: "تايتا" },
      { shortCode: "40", en: "Tana River", ar: "نهر التانا" },
      { shortCode: "41", en: "Tharaka-Nithi", ar: "ثاراكا نيثي" },
      { shortCode: "42", en: "Trans Nzoia", ar: "ترانس نزويا" },
      { shortCode: "43", en: "Turkana", ar: "توركانا" },
      { shortCode: "44", en: "Uasin Gishu", ar: "ياسين جيشو" },
      { shortCode: "45", en: "Vihiga", ar: "فيهيجا" },
      { shortCode: "46", en: "Wajir", ar: "واجير" },
      { shortCode: "47", en: "West Pokot", ar: "ويست بوكوت" },
    ],
  },
  {
    id: 296,
    code: "ki",
    codeLng: "kir",
    en: "Kiribati",
    ar: "كيريباتي",
    dial: "+686",
    cities: [
      { en: "Abaiang", ar: "ابيانج" },
      { en: "Abemama", ar: "ابيماما" },
      { en: "Aranuka", ar: "ارانكو" },
      { en: "Arorae", ar: "اروراي" },
      { en: "Banaba", ar: "بانابا" },
      { en: "Beru", ar: "بيرو" },
      { en: "Butaritari", ar: "بوتاريتاري" },
      { en: "Central Gilberts", ar: "جيلبيرت المتوسطه" },
      { shortCode: "G", en: "Gilbert Islands", ar: "جزر جيلبيرت" },
      { en: "Kanton", ar: "كانتون" },
      { en: "Kiritimati", ar: "كيريتيماتي" },
      { en: "Kuria", ar: "كوريا" },
      { shortCode: "L", en: "Line Islands", ar: "جزر الخط" },
      { en: "Maiana", ar: "مايانا" },
      { en: "Makin", ar: "ماكين" },
      { en: "Marakei", ar: "ماراكي" },
      { en: "Nikunau", ar: "نيكوناو" },
      { en: "Nonouti", ar: "نونوتي" },
      { en: "Northern Gilberts", ar: "جيلبيرتس الشماليه" },
      { en: "Onotoa", ar: "انوتوا" },
      { shortCode: "P", en: "Phoenix Islands", ar: "جزر الفينكس" },
      { en: "Southern Gilberts", ar: "جيلبيرتس الجنوبيه" },
      { en: "Tabiteuea", ar: "تابيتيوا" },
      { en: "Tabuaeran", ar: "تابواران" },
      { en: "Tamana", ar: "تامانا" },
      { en: "Tarawa", ar: "تاراوا" },
      { en: "Teraina", ar: "تيراينا" },
    ],
  },
  {
    id: 408,
    code: "kp",
    codeLng: "prk",
    en: "Korea (Democratic People's Republic of)",
    ar: "كوريا الشمالية",
    dial: "+850",
    cities: [
      {
        shortCode: "04",
        en: "Chagang-do (Chagang Province)",
        ar: "شاجانج دو",
      },
      {
        shortCode: "09",
        en: "Hamgyong-bukto (North Hamgyong Province)",
        ar: "هامجيونج بوتكو",
      },
      {
        shortCode: "08",
        en: "Hamgyong-namdo (South Hamgyong Province)",
        ar: "هامجيونج نامدو",
      },
      {
        shortCode: "06",
        en: "Hwanghae-bukto (North Hwanghae Province)",
        ar: "وانجاي بوكتو",
      },
      {
        shortCode: "05",
        en: "Hwanghae-namdo (South Hwanghae Province)",
        ar: "وانجاي نامدو",
      },
      {
        shortCode: "07",
        en: "Kangwon-do (Kangwon Province)",
        ar: "كانجون دو",
      },
      {
        shortCode: "13",
        en: "Nasŏn (Najin-Sŏnbong)",
        ar: "نيسون",
      },
      {
        shortCode: "03",
        en: "P'yongan-bukto (North P'yongan Province)",
        ar: "يونجان بوتكو",
      },
      {
        shortCode: "02",
        en: "P'yongan-namdo (South P'yongan Province)",
        ar: "يونجان نامدو",
      },
      {
        shortCode: "01",
        en: "P'yongyang-si (P'yongyang City)",
        ar: "يونجيانج سي",
      },
      {
        shortCode: "10",
        en: "Yanggang-do (Yanggang Province)",
        ar: "سانج جانج دو",
      },
    ],
  },
  {
    id: 410,
    code: "kr",
    codeLng: "kor",
    en: "Korea, Republic of",
    ar: "كوريا الجنوبية",
    dial: "+82",
    cities: [
      {
        shortCode: "43",
        en: "Ch'ungch'ongbuk-do",
        ar: "شونج اونجبوكدو",
      },
      {
        shortCode: "44",
        en: "Ch'ungch'ongnam-do",
        ar: "شونج اونجنام دو",
      },
      { shortCode: "49", en: "Cheju-do", ar: "شيجو دو" },
      { shortCode: "45", en: "Chollabuk-do", ar: "كولابوك دو" },
      { shortCode: "46", en: "Chollanam-do", ar: "كولانام دو" },
      {
        shortCode: "28",
        en: "Inch'on-Kwangyokhi",
        ar: "انش اونكوانجيوكي",
      },
      { shortCode: "42", en: "Kang-won-do", ar: "كانج ون دو" },
      {
        shortCode: "28",
        en: "Kwangju-Kwangyokshi",
        ar: "كوانجو كوانجيوكشي",
      },
      { shortCode: "41", en: "Kyonggi-do", ar: "كيونجي دو" },
      { shortCode: "47", en: "Kyongsangbuk-do", ar: "كيونجسانجبوك دو" },
      { shortCode: "48", en: "Kyongsangnam-do", ar: "كيونجسانجنام دو" },
      {
        shortCode: "26",
        en: "Pusan-Kwangyokshi",
        ar: "بوسان كوانجيوكشي",
      },
      {
        shortCode: "11",
        en: "Seoul-T'ukpyolshi",
        ar: "سول توكبيولشي",
      },
      { shortCode: "50", en: "Sejong", ar: "سيونج" },
      {
        shortCode: "27",
        en: "Taegu-Kwangyokshi",
        ar: "تايجو كوانجيوكشي",
      },
      {
        shortCode: "30",
        en: "Taejon-Kwangyokshi",
        ar: "تايجون كوانجيوكشي",
      },
      {
        shortCode: "31",
        en: "Ulsan-Kwangyokshi",
        ar: "اولسان كوانجيوكشي",
      },
    ],
  },
  {
    id: 414,
    code: "kw",
    codeLng: "kwt",
    en: "Kuwait",
    ar: "الكويت",
    dial: "+965",
    cities: [
      { shortCode: "AH", en: "Al Aḩmadi", ar: "الاحمدي" },
      { shortCode: "FA", en: "Al Farwānīyah", ar: "الفروانيه" },
      { shortCode: "JA", en: "Al Jahrā’", ar: "الجاره’" },
      { shortCode: "KU", en: "Al ‘Āşimah", ar: "القسيمه" },
      { shortCode: "HA", en: "Ḩawallī", ar: "حوالي" },
      { shortCode: "MU", en: "Mubārak al Kabir", ar: "مبارك الكبير" },
    ],
  },
  {
    id: 417,
    code: "kg",
    codeLng: "kgz",
    en: "Kyrgyzstan",
    ar: "قيرغيزستان",
    dial: "+996",
    cities: [
      { shortCode: "B", en: "Batken Oblasty", ar: "باتكين اوبلاستي" },
      { shortCode: "GB", en: "Bishkek Shaary", ar: "بيشكيك شاري" },
      {
        shortCode: "C",
        en: "Chuy Oblasty (Bishkek)",
        ar: "شوي اوبلاستي",
      },
      {
        shortCode: "J",
        en: "Jalal-Abad Oblasty",
        ar: "جلال اباد اوبلاستي",
      },
      { shortCode: "N", en: "Naryn Oblasty", ar: "نارين اوبلاستي" },
      { shortCode: "O", en: "Osh Oblasty", ar: "اوش اوبلاستي" },
      { shortCode: "T", en: "Talas Oblasty", ar: "تالاس اوبلاستي" },
      {
        shortCode: "Y",
        en: "Ysyk-Kol Oblasty (Karakol)",
        ar: "يسيك كل اوبلاستي",
      },
    ],
  },
  {
    id: 418,
    code: "la",
    codeLng: "lao",
    en: "Lao People's Democratic Republic",
    ar: "لاوس",
    dial: "+856",
    cities: [
      { shortCode: "AT", en: "Attapu", ar: "اتابو" },
      { shortCode: "BK", en: "Bokèo", ar: "بوكيو" },
      { shortCode: "BL", en: "Bolikhamxai", ar: "بوليخامكساي" },
      { shortCode: "CH", en: "Champasak", ar: "شامباساك" },
      { shortCode: "HO", en: "Houaphan", ar: "هوبان" },
      { shortCode: "KH", en: "Khammouan", ar: "كاموان" },
      { shortCode: "LM", en: "Louang Namtha", ar: "لوانج نامسا" },
      { shortCode: "LP", en: "Louangphabang", ar: "لوانجبابانج" },
      { shortCode: "OU", en: "Oudômxai", ar: "اودومكساي" },
      { shortCode: "PH", en: "Phôngsali", ar: "فونجسالي" },
      { shortCode: "SL", en: "Salavan", ar: "سالافان" },
      { shortCode: "SV", en: "Savannakhét", ar: "سافاناكيت" },
      { shortCode: "VI", en: "Vientiane", ar: "فينتيان" },
      { shortCode: "XA", en: "Xaignabouli", ar: "شايجنابولي" },
      { shortCode: "XE", en: "Xékong", ar: "شيكونج" },
      { shortCode: "XS", en: "Xaisomboun", ar: "شايسومبون" },
      { shortCode: "XI", en: "Xiangkhouang", ar: "يانجكوانج" },
    ],
  },
  {
    id: 428,
    code: "lv",
    codeLng: "lva",
    en: "Latvia",
    ar: "لاتفيا",
    dial: "+371",
    cities: [
      { shortCode: "001", en: "Aglona", ar: "اجلونا" },
      { shortCode: "002", en: "Aizkraukle", ar: "ايزكراوكلي" },
      { shortCode: "003", en: "Aizpute", ar: "ايزبوتي" },
      { shortCode: "004", en: "Aknīste", ar: "اكنيستي" },
      { shortCode: "005", en: "Aloja", ar: "الوجا" },
      { shortCode: "06", en: "Alsunga", ar: "السونجا" },
      { shortCode: "007", en: "Alūksne", ar: "الوكسني" },
      { shortCode: "008", en: "Amata", ar: "اماتا" },
      { shortCode: "009", en: "Ape", ar: "ابي" },
      { shortCode: "010", en: "Auce", ar: "اوسي" },
      { shortCode: "011", en: "Ādaži", ar: "ادازي" },
      { shortCode: "012", en: "Babīte", ar: "بابيتي" },
      { shortCode: "013", en: "Baldone", ar: "بالدوني" },
      { shortCode: "014", en: "Baltinava", ar: "بالتينافا" },
      { shortCode: "015", en: "Balvi", ar: "بالفي" },
      { shortCode: "016", en: "Bauska", ar: "باوسكا" },
      { shortCode: "017", en: "Beverīna", ar: "بيفيرينا" },
      { shortCode: "018", en: "Brocēni", ar: "بروسيتي" },
      { shortCode: "019", en: "Burtnieki", ar: "بورتنيكي" },
      { shortCode: "020", en: "Carnikava", ar: "كارنيكافا" },
      { shortCode: "021", en: "Cesvaine", ar: "سيفاني" },
      { shortCode: "022", en: "Cēsis", ar: "سيسيس" },
      { shortCode: "023", en: "Cibla", ar: "كيبلا" },
      { shortCode: "024", en: "Dagda", ar: "داجدا" },
      { shortCode: "025", en: "Daugavpils", ar: "داوجابيلز" },
      {
        shortCode: "DGV",
        en: "Daugavpils (City)",
        ar: "Daugavpils (City)",
      },
      { shortCode: "026", en: "Dobele", ar: "دوبيلي" },
      { shortCode: "027", en: "Dundaga", ar: "دونداجا" },
      { shortCode: "028", en: "Durbe", ar: "دوربي" },
      { shortCode: "029", en: "Engure", ar: "اينجوري" },
      { shortCode: "030", en: "Ērgļi", ar: "ايرجلي" },
      { shortCode: "031", en: "Garkalne", ar: "جاركالني" },
      { shortCode: "032", en: "Grobiņa", ar: "جروبينا" },
      { shortCode: "033", en: "Gulbene", ar: "جولبيني" },
      { shortCode: "034", en: "Iecava", ar: "ايكافا" },
      { shortCode: "035", en: "Ikšķile", ar: "ايكسكيل" },
      { shortCode: "036", en: "Ilūkste", ar: "ايلوكستي" },
      { shortCode: "037", en: "Inčukalns", ar: "اينكوكالنز" },
      { shortCode: "038", en: "Jaunjelgava", ar: "وانجيلجافا" },
      { shortCode: "039", en: "Jaunpiebalga", ar: "وانبيبالجا" },
      { shortCode: "040", en: "Jaunpils", ar: "وانبيلز" },
      { shortCode: "041", en: "Jelgava", ar: "يلجافا" },
      { shortCode: "JEL", en: "Jelgava (City)", ar: "يلجافا (المدينه)" },
      { shortCode: "042", en: "Jēkabpils", ar: "جيكابيلز" },
      {
        shortCode: "JKB",
        en: "Jēkabpils (City)",
        ar: "Jēkabpils (City)",
      },
      { shortCode: "JUR", en: "Jūrmala (City)", ar: "جورمالا " },
      { shortCode: "043", en: "Kandava", ar: "كاندافا" },
      { shortCode: "044", en: "Kārsava", ar: "كارسافا" },
      { shortCode: "045", en: "Kocēni", ar: "كوسيني" },
      { shortCode: "046", en: "Koknese", ar: "كوكنيس" },
      { shortCode: "047", en: "Krāslava", ar: "كراسلافا" },
      { shortCode: "048", en: "Krimulda", ar: "كريمولدا" },
      { shortCode: "049", en: "Krustpils", ar: "كراستبيلز" },
      { shortCode: "050", en: "Kuldīga", ar: "كولديجا" },
      { shortCode: "051", en: "Ķegums", ar: "كيجومز" },
      { shortCode: "052", en: "Ķekava", ar: "كيكافا" },
      { shortCode: "053", en: "Lielvārde", ar: "ليلفاردي" },
      { shortCode: "LPX", en: "Liepāja", ar: "ليباجا" },
      { shortCode: "054", en: "Limbaži", ar: "ليمبازي" },
      { shortCode: "055", en: "Līgatne", ar: "ليجاتني" },
      { shortCode: "056", en: "Līvāni", ar: "ليفاني" },
      { shortCode: "057", en: "Lubāna", ar: "لوبانا" },
      { shortCode: "058", en: "Ludza", ar: "لودزا" },
      { shortCode: "059", en: "Madona", ar: "مادونا" },
      { shortCode: "060", en: "Mazsalaca", ar: "مازلاكا" },
      { shortCode: "061", en: "Mālpils", ar: "مالبيلز" },
      { shortCode: "062", en: "Mārupe", ar: "ماروبي" },
      { shortCode: "063", en: "Mērsrags", ar: "ميرسراجز" },
      { shortCode: "064", en: "Naukšēni", ar: "ناوكسيني" },
      { shortCode: "065", en: "Nereta", ar: "نيراتا" },
      { shortCode: "066", en: "Nīca", ar: "نيكا" },
      { shortCode: "067", en: "Ogre", ar: "اوجري" },
      { shortCode: "068", en: "Olaine", ar: "اولايني" },
      { shortCode: "069", en: "Ozolnieki", ar: "اوزولنيكي" },
      { shortCode: "070", en: "Pārgauja", ar: "بارجواجا" },
      { shortCode: "071", en: "Pāvilosta", ar: "بافيلوستا" },
      { shortCode: "072", en: "Pļaviņas", ar: "بلافيناز" },
      { shortCode: "073", en: "Preiļi", ar: "بريلي" },
      { shortCode: "074", en: "Priekule", ar: "بريكولي" },
      { shortCode: "075", en: "Priekuļi", ar: "بريكولي" },
      { shortCode: "076", en: "Rauna", ar: "راونا" },
      { shortCode: "077", en: "Rēzekne", ar: "ريزيكني" },
      { shortCode: "REZ", en: "Rēzekne (City)", ar: "ريزيكني" },
      { shortCode: "078", en: "Riebiņi", ar: "ريبيني" },
      { shortCode: "RIX", en: "Rīga", ar: "ريجا" },
      { shortCode: "079", en: "Roja", ar: "روجا" },
      { shortCode: "080", en: "Ropaži", ar: "روبازي" },
      { shortCode: "081", en: "Rucava", ar: "روكافا" },
      { shortCode: "082", en: "Rugāji", ar: "روجاجي" },
      { shortCode: "083", en: "Rundāle", ar: "روندالي" },
      { shortCode: "084", en: "Rūjiena", ar: "روجينا" },
      { shortCode: "085", en: "Sala", ar: "سالا" },
      { shortCode: "086", en: "Salacgrīva", ar: "سالاسجريفا" },
      { shortCode: "087", en: "Salaspils", ar: "سالاسبيلز" },
      { shortCode: "088", en: "Saldus", ar: "سالدوس" },
      { shortCode: "089", en: "Saulkrasti", ar: "ساولكراستي" },
      { shortCode: "090", en: "Sēja", ar: "سيجا" },
      { shortCode: "091", en: "Sigulda", ar: "سيجولدا" },
      { shortCode: "092", en: "Skrīveri", ar: "سكريفيري" },
      { shortCode: "093", en: "Skrunda", ar: "سكروندا" },
      { shortCode: "094", en: "Smiltene", ar: "سميلتيني" },
      { shortCode: "095", en: "Stopiņi", ar: "ستوبيني" },
      { shortCode: "096", en: "Strenči", ar: "سترينكي" },
      { shortCode: "097", en: "Talsi", ar: "تالسي" },
      { shortCode: "098", en: "Tērvete", ar: "تيرفيتي" },
      { shortCode: "099", en: "Tukums", ar: "توكومس" },
      { shortCode: "100", en: "Vaiņode", ar: "فاينودي" },
      { shortCode: "101", en: "Valka", ar: "فالكا" },
      { shortCode: "VMR", en: "Valmiera", ar: "فالميرا" },
      { shortCode: "102", en: "Varakļāni", ar: "فاراكلاني" },
      { shortCode: "103", en: "Vārkava", ar: "فاركافا" },
      { shortCode: "104", en: "Vecpiebalga", ar: "فيسبيبالجا" },
      { shortCode: "105", en: "Vecumnieki", ar: "فيكومنيكي" },
      { shortCode: "106", en: "Ventspils", ar: "فينتسبيلز" },
      {
        shortCode: "VEN",
        en: "Ventspils (City)",
        ar: "Ventspils (City)",
      },
      { shortCode: "107", en: "Viesīte", ar: "فيسيتي" },
      { shortCode: "108", en: "Viļaka", ar: "فيلاكا" },
      { shortCode: "109", en: "Viļāni", ar: "فيلاني" },
      { shortCode: "110", en: "Zilupe", ar: "زيلوبي" },
    ],
  },
  {
    id: 422,
    code: "lb",
    codeLng: "lbn",
    en: "Lebanon",
    ar: "لبنان",
    dial: "+961",
    cities: [
      { shortCode: "AK", en: "Aakkâr", ar: "اكار" },
      { shortCode: "BH", en: "Baalbelk-Hermel", ar: "بالبيلك-هيرميل" },
      { shortCode: "BI", en: "Béqaa", ar: "بيكا" },
      { shortCode: "BA", en: "Beyrouth", ar: "بيروت" },
      { shortCode: "AS", en: "Liban-Nord", ar: "نرد ليبان" },
      { shortCode: "JA", en: "Liban-Sud", ar: "سد ليبان" },
      { shortCode: "JL", en: "Mont-Liban", ar: "مونت ليبان" },
      { shortCode: "NA", en: "Nabatîyé", ar: "ناباتايي" },
    ],
  },
  {
    id: 426,
    code: "ls",
    codeLng: "lso",
    en: "Lesotho",
    ar: "ليسوتو",
    dial: "+266",
    cities: [
      { shortCode: "D", en: "Berea", ar: "بيريا" },
      { shortCode: "B", en: "Butha-Buthe", ar: "بوتا بوتي" },
      { shortCode: "C", en: "Leribe", ar: "ليريبي" },
      { shortCode: "E", en: "Mafeteng", ar: "مافيتينج" },
      { shortCode: "A", en: "Maseru", ar: "ماسيرو" },
      { shortCode: "F", en: "Mohales Hoek", ar: "موهاليس هويكي" },
      { shortCode: "J", en: "Mokhotlong", ar: "موختلونج" },
      { shortCode: "H", en: "Qacha's Nek", ar: "كاشا نك" },
      { shortCode: "G", en: "Quthing", ar: "كوسينج" },
      { shortCode: "K", en: "Thaba-Tseka", ar: "طابه تسيكا" },
    ],
  },
  {
    id: 430,
    code: "lr",
    codeLng: "lbr",
    en: "Liberia",
    ar: "ليبيريا",
    dial: "+231",
    cities: [
      { shortCode: "BM", en: "Bomi", ar: "بومي" },
      { shortCode: "BG", en: "Bong", ar: "بونج" },
      { shortCode: "GP", en: "Gbarpolu", ar: "باربولو" },
      { shortCode: "GB", en: "Grand Bassa", ar: "باسا العظيمه" },
      { shortCode: "CM", en: "Grand Cape Mount", ar: "جبل كابي العظيم" },
      { shortCode: "GG", en: "Grand Gedeh", ar: "جده العظيمه" },
      { shortCode: "GK", en: "Grand Kru", ar: "كرو العظيمه" },
      { shortCode: "LO", en: "Lofa", ar: "لوفا" },
      { shortCode: "MG", en: "Margibi", ar: "مارجيبي" },
      { shortCode: "MY", en: "Maryland", ar: "ماري لاند" },
      { shortCode: "MO", en: "Montserrado", ar: "مونتسيرادو" },
      { shortCode: "NI", en: "Nimba", ar: "نيمبا" },
      { shortCode: "RI", en: "River Cess", ar: "نهر سيس" },
      { shortCode: "RG", en: "River Geee", ar: "نهر جيي" },
      { shortCode: "SI", en: "Sinoe", ar: "سينو" },
    ],
  },
  {
    id: 434,
    code: "ly",
    codeLng: "lby",
    en: "Libya",
    ar: "ليبيا",
    dial: "+218",
    cities: [
      { shortCode: "BU", en: "Al Buţnān", ar: "البتنان" },
      {
        shortCode: "JA",
        en: "Al Jabal al Akhḑar",
        ar: "الجبل الاخضر",
      },
      {
        shortCode: "JG",
        en: "Al Jabal al Gharbī",
        ar: "الجبل الغربي",
      },
      { shortCode: "JA", en: "Al Jafārah", ar: "الجافاره" },
      { shortCode: "JU", en: "Al Jufrah", ar: "الجوفرا" },
      { shortCode: "FK", en: "Al Kufrah", ar: "الكوفرا" },
      { shortCode: "MJ", en: "Al Marj", ar: "المرج" },
      { shortCode: "MB", en: "Al Marquab", ar: "الماركواب" },
      { shortCode: "WA", en: "Al Wāḩāt", ar: "الواحات" },
      {
        shortCode: "NQ",
        en: "An Nuqaţ al Khams",
        ar: "النقاط الخامس",
      },
      { shortCode: "ZA", en: "Az Zāwiyah", ar: "الزاويه" },
      { shortCode: "BA", en: "Banghāzī", ar: "بنغازي" },
      { shortCode: "DR", en: "Darnah", ar: "دارنا" },
      { shortCode: "GH", en: "Ghāt", ar: "غات" },
      { shortCode: "MI", en: "Mişrātah", ar: "ميسراته" },
      { shortCode: "MQ", en: "Murzuq", ar: "مرزق" },
      { shortCode: "NL", en: "Nālūt", ar: "نالوت" },
      { shortCode: "SB", en: "Sabhā", ar: "صابحه" },
      { shortCode: "SR", en: "Surt", ar: "سورت" },
      { shortCode: "TB", en: "Ţarābulus", ar: "طربلس" },
      { shortCode: "WD", en: "Yafran", ar: "يافران" },
      { shortCode: "WS", en: "Wādī ash Shāţiʾ", ar: "وادي الشاتي" },
    ],
  },
  {
    id: 438,
    code: "li",
    codeLng: "lie",
    en: "Liechtenstein",
    ar: "ليختنشتاين",
    dial: "+423",
    cities: [
      { shortCode: "01", en: "Balzers", ar: "بالزر" },
      { shortCode: "02", en: "Eschen", ar: "ايكين" },
      { shortCode: "03", en: "Gamprin", ar: "جامبرين" },
      { shortCode: "04", en: "Mauren", ar: "ماورين" },
      { shortCode: "05", en: "Planken", ar: "بلانكين" },
      { shortCode: "06", en: "Ruggell", ar: "روجيل" },
      { shortCode: "07", en: "Schaan", ar: "شان" },
      { shortCode: "08", en: "Schellenberg", ar: "شيلنبيرج" },
      { shortCode: "09", en: "Triesen", ar: "تريسن" },
      { shortCode: "10", en: "Triesenberg", ar: "تريسنبرج" },
      { shortCode: "11", en: "Vaduz", ar: "فادوز" },
    ],
  },
  {
    id: 440,
    code: "lt",
    codeLng: "ltu",
    en: "Lithuania",
    ar: "ليتوانيا",
    dial: "+370",
    cities: [
      { shortCode: "AL", en: "Alytaus", ar: "اليتاوس" },
      { shortCode: "KU", en: "Kauno", ar: "كاونو" },
      { shortCode: "KL", en: "Klaipėdos", ar: "كلايبيدوز" },
      { shortCode: "MR", en: "Marijampolės", ar: "ماريجامبولز" },
      { shortCode: "PN", en: "Panevėžio", ar: "بانيفيزيو" },
      { shortCode: "SA", en: "Šiaulių", ar: "سياولو" },
      { shortCode: "TA", en: "Tauragės", ar: "تاوراجس" },
      { shortCode: "TE", en: "Telšių", ar: "تيلسيو" },
      { shortCode: "UT", en: "Utenos", ar: "يوتينوس" },
      { shortCode: "VL", en: "Vilniaus", ar: "فيلنياوس" },
    ],
  },
  {
    id: 442,
    code: "lu",
    codeLng: "lux",
    en: "Luxembourg",
    ar: "لوكسمبورغ",
    dial: "+352",
    cities: [
      { shortCode: "CA", en: "Capellen", ar: "كابلن" },
      { shortCode: "CL", en: "Clevaux", ar: "كليفاوكس" },
      { shortCode: "DI", en: "Diekirch", ar: "ديكيرش" },
      { shortCode: "EC", en: "Echternach", ar: "ايشترناخ" },
      { shortCode: "ES", en: "Esch-sur-Alzette", ar: "ايش سور الزيتي" },
      { shortCode: "GR", en: "Grevenmacher", ar: "جريفينماخر" },
      { shortCode: "LU", en: "Luxembourg", ar: "لوكسمبورج" },
      { shortCode: "ME", en: "Mersch", ar: "ميرش" },
      { shortCode: "RD", en: "Redange", ar: "ريدانجي" },
      { shortCode: "RM", en: "Remich", ar: "ريميش" },
      { shortCode: "VD", en: "Vianden", ar: "فياندين" },
      { shortCode: "WI", en: "Wiltz", ar: "فيلتز" },
    ],
  },
  {
    id: 446,
    code: "mo",
    codeLng: "mac",
    en: "Macao",
    ar: "ماكاو",
    dial: "+853",
    cities: [{ en: "Macao", ar: "ماكاو" }],
  },
  {
    id: 450,
    code: "mg",
    codeLng: "mdg",
    en: "Madagascar",
    ar: "مدغشقر",
    dial: "+261",
    cities: [
      { shortCode: "T", en: "Antananarivo", ar: "انتاناناريفو" },
      { shortCode: "D", en: "Antsiranana", ar: "انتسيرانانا" },
      { shortCode: "F", en: "Fianarantsoa", ar: "فيانارانتسو" },
      { shortCode: "M", en: "Mahajanga", ar: "ماهاجانجا" },
      { shortCode: "A", en: "Toamasina", ar: "توماسينا" },
      { shortCode: "U", en: "Toliara", ar: "توليرا" },
    ],
  },
  {
    id: 454,
    code: "mw",
    codeLng: "mwi",
    en: "Malawi",
    ar: "مالاوي",
    dial: "+265",
    cities: [
      { shortCode: "BA", en: "Balaka", ar: "بالاكا" },
      { shortCode: "BL", en: "Blantyre", ar: "بلانتيري" },
      { shortCode: "CK", en: "Chikwawa", ar: "شيكواوا" },
      { shortCode: "CR", en: "Chiradzulu", ar: "شيرادزولو" },
      { shortCode: "CT", en: "Chitipa", ar: "شيتيبا" },
      { shortCode: "DE", en: "Dedza", ar: "ديدزا" },
      { shortCode: "DO", en: "Dowa", ar: "دوا" },
      { shortCode: "KR", en: "Karonga", ar: "كورونجا" },
      { shortCode: "KS", en: "Kasungu", ar: "كاسونجو" },
      { shortCode: "LK", en: "Likoma", ar: "ليكوما" },
      { shortCode: "LI", en: "Lilongwe", ar: "ليلونجوي" },
      { shortCode: "MH", en: "Machinga", ar: "ماشينجا" },
      { shortCode: "MG", en: "Mangochi", ar: "مانجوشي" },
      { shortCode: "MC", en: "Mchinji", ar: "مشيجي" },
      { shortCode: "MU", en: "Mulanje", ar: "مولانجي" },
      { shortCode: "MW", en: "Mwanza", ar: "موانزا" },
      { shortCode: "MZ", en: "Mzimba", ar: "مزيمبا" },
      { shortCode: "NE", en: "Nkhata Bay", ar: "نخاتا باي" },
      { shortCode: "NB", en: "Nkhotakota", ar: "نخوتاكوتا" },
      { shortCode: "NS", en: "Nsanje", ar: "نسانجي" },
      { shortCode: "NU", en: "Ntcheu", ar: "نتشو" },
      { shortCode: "NI", en: "Ntchisi", ar: "نتشيسي" },
      { shortCode: "PH", en: "Phalombe", ar: "فالومبي" },
      { shortCode: "RU", en: "Rumphi", ar: "رومفي" },
      { shortCode: "SA", en: "Salima", ar: "ساليما" },
      { shortCode: "TH", en: "Thyolo", ar: "تيولو" },
      { shortCode: "ZO", en: "Zomba", ar: "زومبا" },
    ],
  },
  {
    id: 458,
    code: "my",
    codeLng: "mys",
    en: "Malaysia",
    ar: "ماليزيا",
    dial: "+60",
    cities: [
      { shortCode: "01", en: "Johor", ar: "جحور" },
      { shortCode: "02", en: "Kedah", ar: "كيدا" },
      { shortCode: "03", en: "Kelantan", ar: "كيلانتان" },
      { shortCode: "04", en: "Melaka", ar: "ميلاكا" },
      { shortCode: "05", en: "Negeri Sembilan", ar: "نيجيري سيمبيلان" },
      { shortCode: "06", en: "Pahang", ar: "باهانج" },
      { shortCode: "08", en: "Perak", ar: "بيراك" },
      { shortCode: "09", en: "Perlis", ar: "بيرليس" },
      { shortCode: "07", en: "Pulau Pinang", ar: "بولاو بينانج" },
      { shortCode: "12", en: "Sabah", ar: "سابا" },
      { shortCode: "13", en: "Sarawak", ar: "ساراواك" },
      { shortCode: "10", en: "Selangor", ar: "سيلانجور" },
      { shortCode: "11", en: "Terengganu", ar: "تيرينجانو" },
      {
        shortCode: "14",
        en: "Wilayah Persekutuan (Kuala Lumpur)",
        ar: "كوالا لامبور",
      },
      {
        shortCode: "15",
        en: "Wilayah Persekutuan (Labuan)",
        ar: "لابوان",
      },
      {
        shortCode: "16",
        en: "Wilayah Persekutuan (Putrajaya)",
        ar: "بوتراجايا",
      },
    ],
  },
  {
    id: 462,
    code: "mv",
    codeLng: "mdv",
    en: "Maldives",
    ar: "جزر المالديف",
    dial: "+960",
    cities: [
      { shortCode: "02", en: "Alifu Alifu", ar: "اليفو اليفو" },
      { shortCode: "00", en: "Alifu Dhaalu", ar: "اليفو دالو" },
      { shortCode: "20", en: "Baa", ar: "باا" },
      { shortCode: "17", en: "Dhaalu", ar: "دالو" },
      { shortCode: "14", en: "Faafu", ar: "فافو" },
      { shortCode: "27", en: "Gaafu Alifu", ar: "جافو اليفو" },
      { shortCode: "28", en: "Gaafu Dhaalu", ar: "جافو دالو" },
      { shortCode: "29", en: "Gnaviyani", ar: "جنافياني" },
      { shortCode: "07", en: "Haa Alifu", ar: "هاا اليفو" },
      { shortCode: "23", en: "Haa Dhaalu", ar: "ها دالو" },
      { shortCode: "29", en: "Kaafu", ar: "كافو" },
      { shortCode: "05", en: "Laamu", ar: "لامو" },
      { shortCode: "03", en: "Lhaviyani", ar: "لافياني" },
      { shortCode: "MLE", en: "Malé", ar: "مالي" },
      { shortCode: "12", en: "Meemu", ar: "ميمو" },
      { shortCode: "25", en: "Noonu", ar: "نونو" },
      { shortCode: "13", en: "Raa", ar: "را" },
      { shortCode: "01", en: "Seenu", ar: "سينو" },
      { shortCode: "24", en: "Shaviyani", ar: "شافياني" },
      { shortCode: "08", en: "Thaa", ar: "تا" },
      { shortCode: "04", en: "Vaavu", ar: "فافو" },
    ],
  },
  {
    id: 466,
    code: "ml",
    codeLng: "mli",
    en: "Mali",
    ar: "مالي",
    dial: "+223",
    cities: [
      { shortCode: "BKO", en: "Bamako", ar: "باماكو" },
      { shortCode: "7", en: "Gao", ar: "جاو" },
      { shortCode: "1", en: "Kayes", ar: "كايس" },
      { shortCode: "8", en: "Kidal", ar: "كيدال" },
      { shortCode: "2", en: "Koulikoro", ar: "كوليكورو" },
      { shortCode: "5", en: "Mopti", ar: "موبتي" },
      { shortCode: "4", en: "Segou", ar: "سيجو" },
      { shortCode: "3", en: "Sikasso", ar: "سيكاسو" },
      { shortCode: "6", en: "Tombouctou", ar: "تومبوستو" },
    ],
  },
  {
    id: 470,
    code: "mt",
    codeLng: "mlt",
    en: "Malta",
    ar: "مالطا",
    dial: "+356",
    cities: [
      { shortCode: "01", en: "Attard", ar: "اتارد" },
      { shortCode: "02", en: "Balzan", ar: "بالزان" },
      { shortCode: "03", en: "Birgu", ar: "بيرجو" },
      { shortCode: "04", en: "Birkirkara", ar: "بيركيركارا" },
      { shortCode: "05", en: "Birżebbuġa", ar: "بيرزيبوجا" },
      { shortCode: "06", en: "Bormla", ar: "بورملا" },
      { shortCode: "07", en: "Dingli", ar: "دينجلي" },
      { shortCode: "08", en: "Fgura", ar: "فجورا" },
      { shortCode: "09", en: "Floriana", ar: "فلوريانا" },
      { shortCode: "10", en: "Fontana", ar: "فونتانا" },
      { shortCode: "11", en: "Guda", ar: "جودا" },
      { shortCode: "12", en: "Gżira", ar: "جزيره" },
      { shortCode: "13", en: "Għajnsielem", ar: "جانسيلم" },
      { shortCode: "14", en: "Għarb", ar: "غرب" },
      { shortCode: "15", en: "Għargħur", ar: "غرغور" },
      { shortCode: "16", en: "Għasri", ar: "غاسري" },
      { shortCode: "17", en: "Għaxaq", ar: "غاكساك" },
      { shortCode: "18", en: "Ħamrun", ar: "هامرون" },
      { shortCode: "19", en: "Iklin", ar: "ايكلين" },
      { shortCode: "20", en: "Isla", ar: "ايسلا" },
      { shortCode: "21", en: "Kalkara", ar: "كالكارا" },
      { shortCode: "22", en: "Kerċem", ar: "كيرسيم" },
      { shortCode: "23", en: "Kirkop", ar: "كيركوب" },
      { shortCode: "24", en: "Lija", ar: "ليجا" },
      { shortCode: "25", en: "Luqa", ar: "لوكا" },
      { shortCode: "26", en: "Marsa", ar: "مارسا" },
      { shortCode: "27", en: "Marsaskala", ar: "مارساكالا" },
      { shortCode: "28", en: "Marsaxlokk", ar: "مارسالوك" },
      { shortCode: "29", en: "Mdina", ar: "مدينه" },
      { shortCode: "30", en: "Mellieħa", ar: "ميلينا" },
      { shortCode: "31", en: "Mġarr", ar: "جار" },
      { shortCode: "32", en: "Mosta", ar: "موستا" },
      { shortCode: "33", en: "Mqabba", ar: "كابا" },
      { shortCode: "34", en: "Msida", ar: "سيدا" },
      { shortCode: "35", en: "Mtarfa", ar: "تارفا" },
      { shortCode: "36", en: "Munxar", ar: "مونكسار" },
      { shortCode: "37", en: "Nadur", ar: "نادور" },
      { shortCode: "38", en: "Naxxar", ar: "ناكسار" },
      { shortCode: "39", en: "Paola", ar: "باولا" },
      { shortCode: "40", en: "Pembroke", ar: "بيمبروك" },
      { shortCode: "41", en: "Pietà", ar: "بيتا" },
      { shortCode: "42", en: "Qala", ar: "كالا" },
      { shortCode: "43", en: "Qormi", ar: "كورمي" },
      { shortCode: "44", en: "Qrendi", ar: "كريندي" },
      { shortCode: "45", en: "Rabat Għawdex", ar: "رابات غاوديكس" },
      { shortCode: "46", en: "Rabat Malta", ar: "رابات مالتا" },
      { shortCode: "47", en: "Safi", ar: "صافي" },
      { shortCode: "48", en: "San Ġiljan", ar: "سان جيلجان" },
      { shortCode: "49", en: "San Ġwann", ar: "سان جوان" },
      { shortCode: "50", en: "San Lawrenz", ar: "سان لورينز" },
      {
        shortCode: "51",
        en: "San Pawl il-Baħar",
        ar: "San Pawl il-Baħar",
      },
      { shortCode: "52", en: "Sannat", ar: "سانات" },
      { shortCode: "53", en: "Santa Luċija", ar: "سانتا لوسيجا" },
      { shortCode: "54", en: "Santa Venera", ar: "سانتا فينيرا" },
      { shortCode: "55", en: "Siġġiewi", ar: "سيجيوي" },
      { shortCode: "56", en: "Sliema", ar: "سليمه" },
      { shortCode: "57", en: "Swieqi", ar: "سويكي" },
      { shortCode: "58", en: "Tai Xbiex", ar: "تاي اكسبيكس" },
      { shortCode: "59", en: "Tarzien", ar: "تارزين" },
      { shortCode: "60", en: "Valletta", ar: "فاليتا" },
      { shortCode: "61", en: "Xagħra", ar: "كاغرا" },
      { shortCode: "62", en: "Xewkija", ar: "كوكيجا" },
      { shortCode: "63", en: "Xgħajra", ar: "جاجرا" },
      { shortCode: "64", en: "Żabbar", ar: "زابار" },
      { shortCode: "65", en: "Żebbuġ Għawde", ar: "زيبوج غاودي" },
      { shortCode: "66", en: "Żebbuġ Malta", ar: "زيبوج مالتا" },
      { shortCode: "67", en: "Żejtun", ar: "زيجتون" },
      { shortCode: "68", en: "Żurrieq", ar: "زوريك" },
    ],
  },
  {
    id: 584,
    code: "mh",
    codeLng: "mhl",
    en: "Marshall Islands",
    ar: "جزر مارشال",
    dial: "+692",
    cities: [
      { shortCode: "ALL", en: "Ailinglaplap", ar: "ايلينج لابلاب" },
      { shortCode: "ALK", en: "Ailuk", ar: "ايلوك" },
      { shortCode: "ARN", en: "Arno", ar: "ارنو" },
      { shortCode: "AUR", en: "Aur", ar: "اور" },
      { shortCode: "KIL", en: "Bikini and Kili", ar: "بيكيني و كيلي" },
      { shortCode: "EBO", en: "Ebon", ar: "ايبون" },
      { shortCode: "JAB", en: "Jabat", ar: "جابات" },
      { shortCode: "JAL", en: "Jaluit", ar: "جاليوت" },
      { shortCode: "KWA", en: "Kwajalein", ar: "كواجالين" },
      { shortCode: "LAE", en: "Lae", ar: "لاي" },
      { shortCode: "LIB", en: "Lib", ar: "ليب" },
      { shortCode: "LIK", en: "Likiep", ar: "ليكيب" },
      { shortCode: "MAJ", en: "Majuro", ar: "ماجورو" },
      { shortCode: "MAL", en: "Maloelap", ar: "مالولاب" },
      { shortCode: "MEJ", en: "Mejit", ar: "ميجيت" },
      { shortCode: "NMK", en: "Namdrik", ar: "نامدريك" },
      { shortCode: "NMU", en: "Namu", ar: "نامو" },
      { shortCode: "RON", en: "Rongelap", ar: "رونج لاب" },
      { shortCode: "UJA", en: "Ujae", ar: "يوجاي" },
      { shortCode: "UTI", en: "Utrik", ar: "يوتريك" },
      { shortCode: "WTH", en: "Wotho", ar: "ووتهو" },
      { shortCode: "WTJ", en: "Wotje", ar: "ووتجي" },
    ],
  },
  {
    id: 474,
    code: "mq",
    codeLng: "mtq",
    en: "Martinique",
    ar: "مارتينيك",
    dial: "+596",
    cities: [{ en: "Martinique", ar: "مارتنيك" }],
  },
  {
    id: 478,
    code: "mr",
    codeLng: "mrt",
    en: "Mauritania",
    ar: "موريتانيا",
    dial: "+222",
    cities: [
      { shortCode: "07", en: "Adrar", ar: "ادرار" },
      { shortCode: "03", en: "Assaba", ar: "اسابا" },
      { shortCode: "05", en: "Brakna", ar: "برانكا" },
      {
        shortCode: "08",
        en: "Dakhlet Nouadhibou",
        ar: "Dakhlet Nouadhibou",
      },
      { shortCode: "04", en: "Gorgol", ar: "جرجل" },
      { shortCode: "10", en: "Guidimaka", ar: "جويديماكا" },
      { shortCode: "01", en: "Hodh Ech Chargui", ar: "هود الشرقي" },
      { shortCode: "02", en: "Hodh El Gharbi", ar: "هود الغربي" },
      { shortCode: "12", en: "Inchiri", ar: "انشيري" },
      { shortCode: "14", en: "Nouakchott Nord", ar: "نورد نواكشوت" },
      { shortCode: "13", en: "Nouakchott Ouest", ar: "نواكشوت كويست" },
      { shortCode: "15", en: "Nouakchott Sud", ar: "نوتكشوت سود" },
      { shortCode: "09", en: "Tagant", ar: "تاجانت" },
      { shortCode: "11", en: "Tiris Zemmour", ar: "تيريس زيمور" },
      { shortCode: "06", en: "Trarza", ar: "ترترزا" },
    ],
  },
  {
    id: 480,
    code: "mu",
    codeLng: "mus",
    en: "Mauritius",
    ar: "موريشيوس",
    dial: "+230",
    cities: [
      { shortCode: "AG", en: "Agalega Islands", ar: "جزر اجليجا" },
      {
        shortCode: "BR",
        en: "Beau Bassin-Rose Hill",
        ar: "بيو باسن-روز هيل",
      },
      { shortCode: "BL", en: "Black River", ar: "النهر الاسود" },
      {
        shortCode: "CC",
        en: "Cargados Carajos Shoals",
        ar: "كارجادوس كاراجوس شولز",
      },
      { shortCode: "CU", en: "Curepipe", ar: "كوريبيب" },
      { shortCode: "FL", en: "Flacq", ar: "فلاك" },
      { shortCode: "GP", en: "Grand Port", ar: "بورت العظيمه" },
      { shortCode: "MO", en: "Moka", ar: "موكا" },
      { shortCode: "PA", en: "Pamplemousses", ar: "بامبليموسيس" },
      { shortCode: "PW", en: "Plaines Wilhems", ar: "بلينز ويلهمز" },
      {
        shortCode: "PU",
        en: "Port Louis (City)",
        ar: "مدينه لويس",
      },
      { shortCode: "PL", en: "Port Louis", ar: "سيناء لويس" },
      {
        shortCode: "RR",
        en: "Riviere du Rempart",
        ar: "نهر ريمبارت",
      },
      { shortCode: "RO", en: "Rodrigues Island", ar: "جزيره رودريجيز" },
      { shortCode: "SA", en: "Savanne", ar: "سافاني" },
      { shortCode: "CP", en: "Vacoas-Phoenix", ar: "فاكواس فينيكس" },
    ],
  },
  {
    id: 175,
    code: "yt",
    codeLng: "myt",
    en: "Mayotte",
    ar: "مايوت",
    dial: "+262",
    cities: [
      { shortCode: "01", en: "Dzaoudzi", ar: "زاودزي" },
      { shortCode: "02", en: "Pamandzi", ar: "باماندزي" },
      { shortCode: "03", en: "Mamoudzou", ar: "مامودزو" },
      { shortCode: "04", en: "Dembeni", ar: "ديمبيني" },
      { shortCode: "05", en: "Bandrélé", ar: "باندريلي" },
      { shortCode: "06", en: "Kani-Kéli", ar: "كاني-كيلي" },
      { shortCode: "07", en: "Bouéni", ar: "بويني" },
      { shortCode: "08", en: "Chirongui", ar: "شيرونجوي" },
      { shortCode: "09", en: "Sada", ar: "سادا" },
      { shortCode: "10", en: "Ouangani", ar: "وانجاني" },
      { shortCode: "11", en: "Chiconi", ar: "شيكوني" },
      { shortCode: "12", en: "Tsingoni", ar: "تسينجوني" },
      { shortCode: "13", en: "M'Tsangamouji", ar: "تسانجاموجي" },
      { shortCode: "14", en: "Acoua", ar: "اكوا" },
      { shortCode: "15", en: "Mtsamboro", ar: "سامبورو" },
      { shortCode: "16", en: "Bandraboua", ar: "باندرابوا" },
      { shortCode: "17", en: "Koungou", ar: "كونجو" },
    ],
  },
  {
    id: 484,
    code: "mx",
    codeLng: "mex",
    en: "Mexico",
    ar: "المكسيك",
    dial: "+52",
    cities: [
      { shortCode: "AGU", en: "Aguascalientes", ar: "اكواكالينتيس" },
      { shortCode: "BCN", en: "Baja California", ar: "باجا كاليفورنيا" },
      {
        shortCode: "BCS",
        en: "Baja California Sur",
        ar: "باجا كاليفورنيا سور",
      },
      { shortCode: "CAM", en: "Campeche", ar: "كامبيشي" },
      {
        shortCode: "DIF",
        en: "Ciudad de México",
        ar: "سيوداد دي ميكسيكو",
      },
      { shortCode: "CHP", en: "Chiapas", ar: "شياباس" },
      { shortCode: "CHH", en: "Chihuahua", ar: "شيواوا" },
      {
        shortCode: "COA",
        en: "Coahuila de Zaragoza",
        ar: "كواهويلا دي زاراجوزا",
      },
      { shortCode: "COL", en: "Colima", ar: "كوليما" },
      { shortCode: "DUR", en: "Durango", ar: "دورانجو" },
      {
        shortCode: "MEX",
        en: "Estado de México",
        ar: "ايستادو المكسيك",
      },
      { shortCode: "GUA", en: "Guanajuato", ar: "جواناوانتو" },
      { shortCode: "GRO", en: "Guerrero", ar: "جويريرو" },
      { shortCode: "HID", en: "Hidalgo", ar: "هيدالجو" },
      { shortCode: "JAL", en: "Jalisco", ar: "جاليسكو" },
      {
        shortCode: "MIC",
        en: "Michoacán de Ocampo",
        ar: "ميشواكان الاوكامبو",
      },
      { shortCode: "MOR", en: "Morelos", ar: "موريلوس" },
      { shortCode: "NAY", en: "Nayarit", ar: "نياريت" },
      { shortCode: "NLE", en: "Nuevo León", ar: "نويفي ليون" },
      { shortCode: "OAX", en: "Oaxaca", ar: "اكساكا" },
      { shortCode: "PUE", en: "Puebla", ar: "بويبلا" },
      {
        shortCode: "QUE",
        en: "Querétaro de Arteaga",
        ar: "كويريتارو الارتياجا",
      },
      { shortCode: "ROO", en: "Quintana Roo", ar: "كوينتانا رو" },
      { shortCode: "SLP", en: "San Luis Potosí", ar: "سان لويس بوتوسي" },
      { shortCode: "SIN", en: "Sinaloa", ar: "سينالوا" },
      { shortCode: "SON", en: "Sonora", ar: "سونورا" },
      { shortCode: "TAB", en: "Tabasco", ar: "تاباسكو" },
      { shortCode: "TAM", en: "Tamaulipas", ar: "تاماوليباس" },
      { shortCode: "TLA", en: "Tlaxcala", ar: "تلاكسكالا" },
      { shortCode: "VER", en: "Veracruz", ar: "فيراكروز" },
      { shortCode: "YUC", en: "Yucatán", ar: "يوكاتان" },
      { shortCode: "ZAC", en: "Zacatecas", ar: "زاكاتيكاس" },
    ],
  },
  {
    id: 583,
    code: "fm",
    codeLng: "fsm",
    en: "Micronesia (Federated States of)",
    ar: "ولايات ميكرونيسيا المتحدة",
    dial: "+691",
    cities: [
      { shortCode: "TRK", en: "Chuuk (Truk)", ar: "شوك" },
      { shortCode: "KSA", en: "Kosrae", ar: "كوسراي" },
      { shortCode: "PNI", en: "Pohnpei", ar: "بونبي" },
      { shortCode: "YAP", en: "Yap", ar: "ياب" },
    ],
  },
  {
    id: 498,
    code: "md",
    codeLng: "mda",
    en: "Moldova, Republic of",
    ar: "مولدوفا",
    dial: "+373",
    cities: [
      { shortCode: "AN", en: "Aenii Noi", ar: "ايني نوي" },
      { shortCode: "BS", en: "Basarabeasca", ar: "باسارابيسكا" },
      { shortCode: "BA", en: "Bălți", ar: "بالتي" },
      { shortCode: "BD", en: "Bender", ar: "بندر" },
      { shortCode: "BR", en: "Briceni", ar: "بريكيني" },
      { shortCode: "CA", en: "Cahul", ar: "كاهول" },
      { shortCode: "CT", en: "Cantemir", ar: "كانتيمير" },
      { shortCode: "CL", en: "Călărași", ar: "كالاراسي" },
      { shortCode: "CS", en: "Căușeni", ar: "كاوسيني" },
      { shortCode: "CU", en: "Chișinău", ar: "شيسيناو" },
      { shortCode: "CM", en: "Cimișlia", ar: "كيميسليا" },
      { shortCode: "CR", en: "Criuleni", ar: "كرويليني" },
      { shortCode: "DO", en: "Dondușeni", ar: "دوندوسيني" },
      { shortCode: "DR", en: "Drochia", ar: "دروشيا" },
      { shortCode: "DU", en: "Dubăsari", ar: "دوباساري" },
      { shortCode: "ED", en: "Edineț", ar: "ايدينت" },
      { shortCode: "FA", en: "Fălești", ar: "فاليستي" },
      { shortCode: "FL", en: "Florești", ar: "فلوريستي" },
      { shortCode: "GA", en: "Găgăuzia", ar: "جاجاوزيا" },
      { shortCode: "GL", en: "Glodeni", ar: "جلوديني" },
      { shortCode: "HI", en: "Hîncești", ar: "هينسيستي" },
      { shortCode: "IA", en: "Ialoveni", ar: "ايانلوفيني" },
      { shortCode: "LE", en: "Leova", ar: "ليوفا" },
      { shortCode: "NI", en: "Nisporeni", ar: "نيسبوريني" },
      { shortCode: "OC", en: "Ocnița", ar: "اوكنيتا" },
      { shortCode: "OR", en: "Orhei", ar: "اورهي" },
      { shortCode: "RE", en: "Rezina", ar: "ريزينا" },
      { shortCode: "RI", en: "Rîșcani", ar: "ريسكاني" },
      { shortCode: "SI", en: "Sîngerei", ar: "سينجيري" },
      { shortCode: "SO", en: "Soroca", ar: "سوروكا" },
      { shortCode: "SN", en: "Stînga Nistrului", ar: "ستينجا نيسترولوي" },
      { shortCode: "ST", en: "Strășeni", ar: "ستراسيني" },
      { shortCode: "SD", en: "Șoldănești", ar: "سولدانيستي" },
      { shortCode: "SV", en: "Ștefan Vodă", ar: "ستيفان فودا" },
      { shortCode: "TA", en: "Taraclia", ar: "تاراكليا" },
      { shortCode: "TE", en: "Telenești", ar: "تيلينيستي" },
      { shortCode: "UN", en: "Ungheni", ar: "اونجيني" },
    ],
  },
  {
    id: 492,
    code: "mc",
    codeLng: "mco",
    en: "Monaco",
    ar: "موناكو",
    dial: "+377",
    cities: [
      { shortCode: "CL", en: "Colle", ar: "كولي" },
      { shortCode: "CO", en: "Condamine", ar: "كوندامين" },
      { shortCode: "FO", en: "Fontvieille", ar: "فونتفيلي" },
      { shortCode: "GA", en: "Gare", ar: "جاري" },
      { shortCode: "JE", en: "Jardin Exotique", ar: "جاردين ايكسوتيك" },
      { shortCode: "LA", en: "Larvotto", ar: "لارفوتو" },
      { shortCode: "MA", en: "Malbousquet", ar: "مالبوسكيت" },
      { shortCode: "MO", en: "Monaco-Ville", ar: "موناكو فيلي" },
      { shortCode: "MG", en: "Moneghetti", ar: "مونيغيتي" },
      { shortCode: "MC", en: "Monte-Carlo", ar: "مونتي كارلو" },
      { shortCode: "MU", en: "Moulins", ar: "مولينس" },
      { shortCode: "PH", en: "Port-Hercule", ar: "ميناء هيركولي" },
      { shortCode: "SR", en: "Saint-Roman", ar: "القديس رومان" },
      { shortCode: "SD", en: "Sainte-Dévote", ar: "القديسه ديفوتي" },
      { shortCode: "SO", en: "Source", ar: "سورس" },
      { shortCode: "SP", en: "Spélugues", ar: "سبيلوجويس" },
      {
        shortCode: "VR",
        en: "Vallon de la Rousse",
        ar: "Vallon de la Rousse",
      },
    ],
  },
  {
    id: 496,
    code: "mn",
    codeLng: "mng",
    en: "Mongolia",
    ar: "منغوليا",
    dial: "+976",
    cities: [
      { shortCode: "073", en: "Arhangay", ar: "ارهانجاي" },
      { shortCode: "071", en: "Bayan-Olgiy", ar: "بيان اولجي" },
      { shortCode: "069", en: "Bayanhongor", ar: "بيانهونجور" },
      { shortCode: "067", en: "Bulgan", ar: "بولجان" },
      { shortCode: "037", en: "Darhan", ar: "دارهان" },
      { shortCode: "061", en: "Dornod", ar: "دورنود" },
      { shortCode: "063", en: "Dornogovi", ar: "دورنوجوفي" },
      { shortCode: "059", en: "Dundgovi", ar: "دوندجوفي" },
      { shortCode: "065", en: "Dzavhan", ar: "دافان" },
      { shortCode: "065", en: "Govi-Altay", ar: "جوفي التاي" },
      { shortCode: "064", en: "Govi-Sumber", ar: "جوفي سومبر" },
      { shortCode: "043", en: "Hovd", ar: "هوفد" },
      { shortCode: "041", en: "Hovsgol", ar: "هوفسجول" },
      { shortCode: "053", en: "Omnogovi", ar: "اومنوجوفي" },
      { shortCode: "055", en: "Ovorhangay", ar: "افورهانجاي" },
      { shortCode: "049", en: "Selenge", ar: "سيلينجي" },
      { shortCode: "051", en: "Suhbaatar", ar: "سوباتار" },
      { shortCode: "047", en: "Tov", ar: "توف" },
      { shortCode: "1", en: "Ulaanbaatar", ar: "اولانباتار" },
      { shortCode: "046", en: "Uvs", ar: "اوفس" },
    ],
  },
  {
    id: 499,
    code: "me",
    codeLng: "mne",
    en: "Montenegro",
    ar: "الجبل الأسود",
    dial: "+382",
    cities: [
      { shortCode: "01", en: "Andrijevica", ar: "اندريجيفيكا" },
      { shortCode: "02", en: "Bar", ar: "بار" },
      { shortCode: "03", en: "Berane", ar: "بيراني" },
      { shortCode: "04", en: "Bijelo Polje", ar: "بيجيلو بولجي" },
      { shortCode: "05", en: "Budva", ar: "بودفا" },
      { shortCode: "06", en: "Cetinje", ar: "سيتينجي" },
      { shortCode: "07", en: "Danilovgrad", ar: "دانيلوفجراد" },
      { shortCode: "22", en: "Gusinje", ar: "جوسينجي" },
      { shortCode: "08", en: "Herceg Novi", ar: "هيرسيج نوفي" },
      { shortCode: "09", en: "Kolašin", ar: "كولاسين" },
      { shortCode: "10", en: "Kotor", ar: "كوتور" },
      { shortCode: "11", en: "Mojkovac", ar: "موكوفاك" },
      { shortCode: "12", en: "Nikšić", ar: "نيكسيك" },
      { shortCode: "23", en: "Petnica", ar: "بيتنيكا" },
      { shortCode: "13", en: "Plav", ar: "بلاف" },
      { shortCode: "14", en: "Plužine", ar: "بلوزيني" },
      { shortCode: "15", en: "Pljevlja", ar: "بليفلا" },
      { shortCode: "16", en: "Podgorica", ar: "بودجوريكا" },
      { shortCode: "17", en: "Rožaje", ar: "روزاجي" },
      { shortCode: "18", en: "Šavnik", ar: "سافنيك" },
      { shortCode: "19", en: "Tivat", ar: "تيفات" },
      { shortCode: "20", en: "Ulcinj", ar: "اولسينج" },
      { shortCode: "21", en: "Žabljak", ar: "زابلاك" },
    ],
  },
  {
    id: 500,
    code: "ms",
    codeLng: "msr",
    en: "Montserrat",
    ar: "مونتسرات",
    dial: "+1664",
    cities: [
      { en: "Saint Anthony", ar: "القديس انتوني" },
      { en: "Saint Georges", ar: "القديس جرجس" },
      { en: "Saint Peter's", ar: "القديس بيترز" },
    ],
  },
  {
    id: 504,
    code: "ma",
    codeLng: "mar",
    en: "Morocco",
    ar: "المغرب",
    dial: "+212",
    cities: [
      {
        shortCode: "09",
        en: "Chaouia-Ouardigha",
        ar: "شاويه اورديجا",
      },
      { shortCode: "10", en: "Doukhala-Abda", ar: "دوخالا ابدا" },
      { shortCode: "05", en: "Fès-Boulemane", ar: "فيس بوليماني" },
      {
        shortCode: "02",
        en: "Gharb-Chrarda-Beni Hssen",
        ar: "غرب شرارده بني حسين",
      },
      { shortCode: "08", en: "Grand Casablanca", ar: "كازابلانكا العظيمه" },
      {
        shortCode: "14",
        en: "Guelmim-Es Semara",
        ar: "جويلميم السماره",
      },
      {
        shortCode: "15",
        en: "Laâyoune-Boujdour-Sakia el Hamra",
        ar: "العيون بوجدور ساقيه الحمراء",
      },
      {
        shortCode: "11",
        en: "Marrakech-Tensift-Al Haouz",
        ar: "ماراكي تينسيفت الهاوز",
      },
      { shortCode: "06", en: "Meknès-Tafilalet", ar: "ميكنيس تافالاليت" },
      { shortCode: "04", en: "Oriental", ar: "اورينتال" },
      {
        shortCode: "16",
        en: "Oued ed Dahab-Lagouira",
        ar: "Oued ed Dahab-Lagouira",
      },
      { shortCode: "13", en: "Souss-Massa-Drâa", ar: "سوس ماسا درا" },
      { shortCode: "12", en: "Tadla-Azilal", ar: "تادلا ازيلال" },
      { shortCode: "01", en: "Tanger-Tétouan", ar: "تانجر تيتوان" },
      {
        shortCode: "03",
        en: "Taza-Al Hoceima-Taounate",
        ar: "تازا الهوسيما تاوناتي",
      },
    ],
  },
  {
    id: 508,
    code: "mz",
    codeLng: "moz",
    en: "Mozambique",
    ar: "موزمبيق",
    dial: "+258",
    cities: [
      { shortCode: "P", en: "Cabo Delgado", ar: "كابو ديلجادو" },
      { shortCode: "G", en: "Gaza", ar: "غزه" },
      { shortCode: "I", en: "Inhambane", ar: "اينهامباني" },
      { shortCode: "B", en: "Manica", ar: "مانيكا" },
      { shortCode: "L", en: "Maputo", ar: "مابوتو" },
      { shortCode: "MPM", en: "Maputo (City)", ar: "مدينه مابوتو" },
      { shortCode: "N", en: "Nampula", ar: "نامبولا" },
      { shortCode: "A", en: "Niassa", ar: "نياسا" },
      { shortCode: "S", en: "Sofala", ar: "سوفولا" },
      { shortCode: "T", en: "Tete", ar: "تيتي" },
      { shortCode: "Q", en: "Zambezia", ar: "زيمبيزيا" },
    ],
  },
  {
    id: 104,
    code: "mm",
    codeLng: "mmr",
    en: "Myanmar",
    ar: "ميانمار",
    dial: "+95",
    cities: [
      { shortCode: "07", en: "Ayeyarwady", ar: "ايياروادي" },
      { shortCode: "02", en: "Bago", ar: "باجو" },
      { shortCode: "14", en: "Chin", ar: "شين" },
      { shortCode: "11", en: "Kachin", ar: "كاشين" },
      { shortCode: "12", en: "Kayah", ar: "كايا" },
      { shortCode: "13", en: "Kayin", ar: "كاين" },
      { shortCode: "03", en: "Magway", ar: "ماجواي" },
      { shortCode: "04", en: "Mandalay", ar: "ماندالاي" },
      { shortCode: "15", en: "Mon", ar: "مون" },
      { shortCode: "18", en: "Nay Pyi Taw", ar: "ناي بي تاو" },
      { shortCode: "16", en: "Rakhine", ar: "راخين" },
      { shortCode: "01", en: "Sagaing", ar: "ساجاينج" },
      { shortCode: "17", en: "Shan", ar: "شان" },
      { shortCode: "05", en: "Tanintharyi", ar: "تانينتايري" },
      { shortCode: "06", en: "Yangon", ar: "يانجون" },
    ],
  },
  {
    id: 516,
    code: "na",
    codeLng: "nam",
    en: "Namibia",
    ar: "ناميبيا",
    dial: "+264",
    cities: [
      { shortCode: "ER", en: "Erongo", ar: "ايرونجو" },
      { shortCode: "HA", en: "Hardap", ar: "هارداب" },
      { shortCode: "KE", en: "Kavango East", ar: "شرق كافانجو" },
      { shortCode: "KW", en: "Kavango West", ar: "غرب كافانجو" },
      { shortCode: "KA", en: "Karas", ar: "كاراس" },
      { shortCode: "KH", en: "Khomas", ar: "خوماس" },
      { shortCode: "KU", en: "Kunene", ar: "كونيني" },
      { shortCode: "OW", en: "Ohangwena", ar: "اوهانجوينا" },
      { shortCode: "OH", en: "Omaheke", ar: "اوماهيكي" },
      { shortCode: "OS", en: "Omusati", ar: "اوموساتي" },
      { shortCode: "ON", en: "Oshana", ar: "اوشانا" },
      { shortCode: "OT", en: "Oshikoto", ar: "اوشيكوتو" },
      { shortCode: "OD", en: "Otjozondjupa", ar: "اوتجوزوندجوبا" },
      { shortCode: "CA", en: "Zambezi", ar: "زامبيزي" },
    ],
  },
  {
    id: 520,
    code: "nr",
    codeLng: "nru",
    en: "Nauru",
    ar: "ناورو",
    dial: "+674",
    cities: [
      { shortCode: "01", en: "Aiwo", ar: "ايوو" },
      { shortCode: "02", en: "Anabar", ar: "انابار" },
      { shortCode: "03", en: "Anetan", ar: "انيتان" },
      { shortCode: "04", en: "Anibare", ar: "انيباري" },
      { shortCode: "05", en: "Baiti", ar: "بيتي" },
      { shortCode: "06", en: "Boe", ar: "بو" },
      { shortCode: "07", en: "Buada", ar: "بوادا" },
      { shortCode: "08", en: "Denigomodu", ar: "دينيجومودو" },
      { shortCode: "09", en: "Ewa", ar: "ايوا" },
      { shortCode: "10", en: "Ijuw", ar: "ايجو" },
      { shortCode: "11", en: "Meneng", ar: "مينينج" },
      { shortCode: "12", en: "Nibok", ar: "نيبوك" },
      { shortCode: "13", en: "Uaboe", ar: "اوبوي" },
      { shortCode: "14", en: "Yaren", ar: "يارين" },
    ],
  },
  {
    id: 524,
    code: "np",
    codeLng: "npl",
    en: "Nepal",
    ar: "نيبال",
    dial: "+977",
    cities: [
      { shortCode: "BA", en: "Bagmati", ar: "باجماتي" },
      { shortCode: "BH", en: "Bheri", ar: "بيري" },
      { shortCode: "DH", en: "Dhawalagiri", ar: "داوالاجيري" },
      { shortCode: "GA", en: "Gandaki", ar: "جانداكي" },
      { shortCode: "JA", en: "Janakpur", ar: "جناكبور" },
      { shortCode: "KA", en: "Karnali", ar: "كارنالي" },
      { shortCode: "KO", en: "Kosi", ar: "كوسي" },
      { shortCode: "LU", en: "Lumbini", ar: "لومبيني" },
      { shortCode: "MA", en: "Mahakali", ar: "ماهاكالي" },
      { shortCode: "ME", en: "Mechi", ar: "ميشي" },
      { shortCode: "NA", en: "Narayani", ar: "ناراياني" },
      { shortCode: "RA", en: "Rapti", ar: "رابتي" },
      { shortCode: "SA", en: "Sagarmatha", ar: "ساجارماتا" },
      { shortCode: "SE", en: "Seti", ar: "سيتي" },
    ],
  },
  {
    id: 528,
    code: "nl",
    codeLng: "nld",
    en: "Netherlands",
    ar: "هولندا",
    dial: "+31",
    cities: [
      { shortCode: "DR", en: "Drenthe", ar: "درينتي" },
      { shortCode: "FL", en: "Flevoland", ar: "فليفولاند" },
      { shortCode: "FR", en: "Friesland", ar: "فرايزلاند" },
      { shortCode: "GE", en: "Gelderland", ar: "جيلدرلاند" },
      { shortCode: "GR", en: "Groningen", ar: "جرونينجين" },
      { shortCode: "LI", en: "Limburg", ar: "ليمبورج" },
      { shortCode: "NB", en: "Noord-Brabant", ar: "نرد برابانت" },
      { shortCode: "NH", en: "Noord-Holland", ar: "نرد هولاند" },
      { shortCode: "OV", en: "Overijssel", ar: "اوفريسل" },
      { shortCode: "UT", en: "Utrecht", ar: "اتريخت" },
      { shortCode: "ZE", en: "Zeeland", ar: "زيلاند" },
      { shortCode: "ZH", en: "Zuid-Holland", ar: "زويد هولاند" },
    ],
  },
  {
    id: 540,
    code: "nc",
    codeLng: "ncl",
    en: "New Caledonia",
    ar: "كاليدونيا الجديدة",
    dial: "+687",
    cities: [
      { en: "Iles Loyaute", ar: "ايليس لوياتي" },
      { en: "Nord", ar: "نرد" },
      { en: "Sud", ar: "سود" },
    ],
  },
  {
    id: 554,
    code: "nz",
    codeLng: "nzl",
    en: "New Zealand",
    ar: "نيوزيلندا",
    dial: "+64",
    cities: [
      { shortCode: "AUK", en: "Auckland", ar: "اركلاند" },
      { shortCode: "BOP", en: "Bay of Plenty", ar: "باي الكثير" },
      { shortCode: "CAN", en: "Canterbury", ar: "كانتر بوري" },
      { shortCode: "GIS", en: "Gisborne", ar: "جيسبورني" },
      { shortCode: "HKB", en: "Hawke's Bay", ar: "باي هوك" },
      { shortCode: "MBH", en: "Marlborough", ar: "مارلبورو" },
      {
        shortCode: "MWT",
        en: "Manawatu-Wanganui",
        ar: "Manawatu-Wanganui",
      },
      { shortCode: "NTL", en: "Northland", ar: "ارض الشمال" },
      { shortCode: "NSN", en: "Nelson", ar: "نيلسون" },
      { shortCode: "OTA", en: "Otago", ar: "اوتاجو" },
      { shortCode: "STL", en: "Southland", ar: "ارض الغرب" },
      { shortCode: "TKI", en: "Taranaki", ar: "تاراناكي" },
      { shortCode: "TAS", en: "Tasman", ar: "تاسمان" },
      { shortCode: "WKO", en: "Waikato", ar: "وايكاتو" },
      { shortCode: "WGN", en: "Wellington", ar: "ويلينجتون" },
      { shortCode: "WTC", en: "West Coast", ar: "الساحل الغربي" },
      {
        shortCode: "CIT",
        en: "Chatham Islands Territory",
        ar: "Chatham Islands Territory",
      },
    ],
  },
  //-----------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------
  {
    id: 558,
    code: "ni",
    codeLng: "nic",
    en: "Nicaragua",
    ar: "نيكاراغوا",
    dial: "+505",
    cities: [
      { shortCode: "BO", en: "Boaco", ar: "بواكو" },
      { shortCode: "CA", en: "Carazo", ar: "كارازو" },
      { shortCode: "CI", en: "Chinandega", ar: "شينانديجا" },
      { shortCode: "CO", en: "Chontales", ar: "شونتاليس" },
      { shortCode: "ES", en: "Estelí", ar: "ايستيلي" },
      { shortCode: "GR", en: "Granada", ar: "جراناندا" },
      { shortCode: "JI", en: "Jinotega", ar: "جينوتيجا" },
      { shortCode: "LE", en: "León", ar: "ليون" },
      { shortCode: "MD", en: "Madriz", ar: "مادريز" },
      { shortCode: "MN", en: "Managua", ar: "ماناجوا" },
      { shortCode: "MS", en: "Masaya", ar: "ماسايا" },
      { shortCode: "MT", en: "Matagalpa", ar: "ماتاجالبا" },
      { shortCode: "NS", en: "Nueva Segovia", ar: "نويفا سيجوفيا" },
      { shortCode: "SJ", en: "Río San Juan", ar: "ريو سان جوان" },
      { shortCode: "RI", en: "Rivas", ar: "ريفاس" },
      { shortCode: "AN", en: "Atlántico Norte", ar: "اتلانتيكو نورتي" },
      { shortCode: "AS", en: "Atlántico Sur", ar: "اتلانتيكو سور" },
    ],
  },
  {
    id: 562,
    code: "ne",
    codeLng: "ner",
    en: "Niger",
    ar: "النيجر",
    dial: "+227",
    cities: [
      { shortCode: "1", en: "Agadez", ar: "اجاديز" },
      { shortCode: "2", en: "Diffa", ar: "ديفا" },
      { shortCode: "3", en: "Dosso", ar: "دوسو" },
      { shortCode: "4", en: "Maradi", ar: "مارادي" },
      { shortCode: "8", en: "Niamey", ar: "نيمي" },
      { shortCode: "5", en: "Tahoua", ar: "تاهوا" },
      { shortCode: "6", en: "Tillabéri", ar: "تيلابيري" },
      { shortCode: "7", en: "Zinder", ar: "زيندر" },
    ],
  },
  {
    id: 566,
    code: "ng",
    codeLng: "nga",
    en: "Nigeria",
    ar: "نيجيريا",
    dial: "+234",
    cities: [
      { shortCode: "AB", en: "Abia", ar: "ابيا" },
      {
        shortCode: "FC",
        en: "Abuja Federal Capital Territory",
        ar: "منطقه ابوجا الفيدراليه الكبرى",
      },
      { shortCode: "AD", en: "Adamawa", ar: "اداماوا" },
      { shortCode: "AK", en: "Akwa Ibom", ar: "اكوا ايبوم" },
      { shortCode: "AN", en: "Anambra", ar: "انامبرا" },
      { shortCode: "BA", en: "Bauchi", ar: "باوشي" },
      { shortCode: "BY", en: "Bayelsa", ar: "بايلسا" },
      { shortCode: "BE", en: "Benue", ar: "بينو" },
      { shortCode: "BO", en: "Borno", ar: "بورنو" },
      { shortCode: "CR", en: "Cross River", ar: "كروس ريفر" },
      { shortCode: "DE", en: "Delta", ar: "ديلتا" },
      { shortCode: "EB", en: "Ebonyi", ar: "ايبوني" },
      { shortCode: "ED", en: "Edo", ar: "ايدو" },
      { shortCode: "EK", en: "Ekiti", ar: "ايكيتي" },
      { shortCode: "EN", en: "Enugu", ar: "اينوجو" },
      { shortCode: "GO", en: "Gombe", ar: "جومبي" },
      { shortCode: "IM", en: "Imo", ar: "ايمو" },
      { shortCode: "JI", en: "Jigawa", ar: "جيجاوا" },
      { shortCode: "KD", en: "Kaduna", ar: "كادونا" },
      { shortCode: "KN", en: "Kano", ar: "كانو" },
      { shortCode: "KT", en: "Katsina", ar: "كاتسينا" },
      { shortCode: "KE", en: "Kebbi", ar: "كيبي" },
      { shortCode: "KO", en: "Kogi", ar: "كوجي" },
      { shortCode: "KW", en: "Kwara", ar: "كوارا" },
      { shortCode: "LA", en: "Lagos", ar: "لاجوس" },
      { shortCode: "NA", en: "Nassarawa", ar: "ناساروا" },
      { shortCode: "NI", en: "Niger", ar: "نيجر" },
      { shortCode: "OG", en: "Ogun", ar: "اوجن" },
      { shortCode: "ON", en: "Ondo", ar: "اندو" },
      { shortCode: "OS", en: "Osun", ar: "اسون" },
      { shortCode: "OY", en: "Oyo", ar: "ايو" },
      { shortCode: "PL", en: "Plateau", ar: "بلاتو" },
      { shortCode: "RI", en: "Rivers", ar: "الانهر" },
      { shortCode: "SO", en: "Sokoto", ar: "سوكوتو" },
      { shortCode: "TA", en: "Taraba", ar: "تارابا" },
      { shortCode: "YO", en: "Yobe", ar: "يوبي" },
      { shortCode: "ZA", en: "Zamfara", ar: "زامفارا" },
    ],
  },
  {
    id: 570,
    code: "nu",
    codeLng: "niu",
    en: "Niue",
    ar: "نييوي",
    dial: "+683",
    cities: [{ en: "Niue", ar: "نيو" }],
  },
  {
    id: 574,
    code: "nf",
    codeLng: "nfk",
    en: "Norfolk Island",
    ar: "جزيرة نورفولك",
    dial: "+672",
    cities: [{ en: "Norfolk Island", ar: "جزيره نورفولك" }],
  },
  {
    id: 807,
    code: "mk",
    codeLng: "mkd",
    en: "North Macedonia",
    ar: "مقدونيا",
    dial: "+389",
    cities: [
      { shortCode: "02", en: "Aračinovo", ar: "اراسينوفو" },
      { shortCode: "03", en: "Berovo", ar: "بيروفو" },
      { shortCode: "04", en: "Bitola", ar: "بيتولا" },
      { shortCode: "05", en: "Bogdanci", ar: "بوجدانسي" },
      { shortCode: "06", en: "Bogovinje", ar: "بوجوفينجي" },
      { shortCode: "07", en: "Bosilovo", ar: "بوسيلوفو" },
      { shortCode: "08", en: "Brvenica", ar: "بزفينيكا" },
      { shortCode: "78", en: "Centar Župa", ar: "منطصف زوبا" },
      { shortCode: "08", en: "Čaška", ar: "كاسكا" },
      {
        shortCode: "81",
        en: "Češinovo-Obleševo",
        ar: "سيسينوفو اوبليسيفو",
      },
      { shortCode: "82", en: "Čučer Sandevo", ar: "سوسر سانديفو" },
      { shortCode: "21", en: "Debar", ar: "ديبار" },
      { shortCode: "22", en: "Debarca", ar: "ديباركا" },
      { shortCode: "23", en: "Delčevo", ar: "ديلسيفو" },
      { shortCode: "25", en: "Demir Hisar", ar: "ديمير هيسار" },
      { shortCode: "24", en: "Demir Kapija", ar: "ديمير كابيجا" },
      { shortCode: "26", en: "Doran", ar: "دوران" },
      { shortCode: "27", en: "Dolneni", ar: "دولنيني" },
      { shortCode: "18", en: "Gevgelija", ar: "جيفجيليجا" },
      { shortCode: "19", en: "Gostivar", ar: "جوستيفار" },
      { shortCode: "20", en: "Gradsko", ar: "جرادسكو" },
      { shortCode: "34", en: "Ilinden", ar: "ايليندين" },
      { shortCode: "35", en: "Jegunovce", ar: "جيجونفسي" },
      { shortCode: "37", en: "Karbinci", ar: "كاربينسي" },
      { shortCode: "36", en: "Kavadarci", ar: "كافادارسي" },
      { shortCode: "40", en: "Kičevo", ar: "كيسيفو" },
      { shortCode: "42", en: "Kočani", ar: "كوكاني" },
      { shortCode: "41", en: "Konče", ar: "كونسي" },
      { shortCode: "43", en: "Kratovo", ar: "كراتوفو" },
      { shortCode: "44", en: "Kriva Palanka", ar: "كريفا بلانكا" },
      { shortCode: "45", en: "Krivogaštani", ar: "كريفوجاستاني" },
      { shortCode: "46", en: "Kruševo", ar: "كروسيفو" },
      { shortCode: "47", en: "Kumanovo", ar: "كومانوفو" },
      { shortCode: "48", en: "Lipkovo", ar: "ليبكوفو" },
      { shortCode: "49", en: "Lozovo", ar: "لوزوفو" },
      {
        shortCode: "51",
        en: "Makedonska Kamenica",
        ar: "ماكيدونسكا كامينيكا",
      },
      { shortCode: "52", en: "Makedonski Brod", ar: "ماكيدونسكي برود" },
      {
        shortCode: "50",
        en: "Mavrovo i Rostuša",
        ar: "مافروفو اي روستوسا",
      },
      { shortCode: "53", en: "Mogila", ar: "موجيلا" },
      { shortCode: "54", en: "Negotino", ar: "نيجوتينو" },
      { shortCode: "55", en: "Novaci", ar: "نوفاكي" },
      { shortCode: "56", en: "Novo Selo", ar: "نوفو سيلو" },
      { shortCode: "58", en: "Ohrid", ar: "اوهريد" },
      { shortCode: "60", en: "Pehčevo", ar: "بيسيفو" },
      { shortCode: "59", en: "Petrovec", ar: "بيتروفيك" },
      { shortCode: "61", en: "Plasnica", ar: "بلاسنيكا" },
      { shortCode: "62", en: "Prilep", ar: "بريليب" },
      { shortCode: "63", en: "Probištip", ar: "بروبيستيب" },
      { shortCode: "", en: "Radoviš", ar: "رادوفيس" },
      { shortCode: "65", en: "Rankovce", ar: "رانكوفسي" },
      { shortCode: "66", en: "Resen", ar: "ريسين" },
      { shortCode: "67", en: "Rosoman", ar: "روسومان" },
      { shortCode: "85", en: "Skopje", ar: "سكوبجي" },
      { shortCode: "70", en: "Sopište", ar: "سوبيستي" },
      { shortCode: "71", en: "Staro Nagoričane", ar: "ستارو ناجوريكاني" },
      { shortCode: "72", en: "Struga", ar: "ستروجا" },
      { shortCode: "73", en: "Strumica", ar: "ستروميكا" },
      { shortCode: "74", en: "Studeničani", ar: "ستودينيكاني" },
      { shortCode: "69", en: "Sveti Nikole", ar: "سفيتي نيكولي" },
      { shortCode: "83", en: "Štip", ar: "ستيب" },
      { shortCode: "75", en: "Tearce", ar: "تيرس" },
      { shortCode: "76", en: "Tetovo", ar: "تيتوفو" },
      { shortCode: "10", en: "Valandovo", ar: "فالاندوفو" },
      { shortCode: "11", en: "Vasilevo", ar: "فاسيليفو" },
      { shortCode: "13", en: "Veles", ar: "فيليس" },
      { shortCode: "12", en: "Vevčani", ar: "فيفكاني" },
      { shortCode: "14", en: "Vinica", ar: "فينيكا" },
      { shortCode: "16", en: "Vrapčište", ar: "رابسيستي" },
      { shortCode: "32", en: "Zelenikovo", ar: "زيلينيكوفو" },
      { shortCode: "33", en: "Zrnovci", ar: "زرنوفسي" },
      { shortCode: "30", en: "Želino", ar: "زيلينو" },
    ],
  },
  {
    id: 580,
    code: "mp",
    codeLng: "mnp",
    en: "Northern Mariana Islands",
    ar: "جزر ماريانا الشمالية",
    dial: "+1 670",
    cities: [
      { en: "Northern Islands", ar: "الجزر الشماليه" },
      { en: "Rota", ar: "روتا" },
      { en: "Saipan", ar: "سايبان" },
      { en: "Tinian", ar: "تينيان" },
    ],
  },
  {
    id: 578,
    code: "no",
    codeLng: "nor",
    en: "Norway",
    ar: "النرويج",
    dial: "+47",
    cities: [
      { shortCode: "02", en: "Akershus", ar: "اكيرشوش" },
      { shortCode: "09", en: "Aust-Agder", ar: "اوست ادجير" },
      { shortCode: "06", en: "Buskerud", ar: "بوسكيرود" },
      { shortCode: "20", en: "Finnmark", ar: "فينمارك" },
      { shortCode: "04", en: "Hedmark", ar: "هيدمارك" },
      { shortCode: "12", en: "Hordaland", ar: "هوردالاند" },
      { shortCode: "15", en: "Møre og Romsdal", ar: "موري اوج رومسدال" },
      { shortCode: "18", en: "Nordland", ar: "نوردلاند" },
      { shortCode: "17", en: "Nord-Trøndelag", ar: "نورد ترونديلاج" },
      { shortCode: "05", en: "Oppland", ar: "اوبلاند" },
      { shortCode: "03", en: "Oslo", ar: "اوسلو" },
      { shortCode: "11", en: "Rogaland", ar: "روجالاند" },
      { shortCode: "14", en: "Sogn og Fjordane", ar: "سوجن اوج فجورداني" },
      { shortCode: "16", en: "Sør-Trøndelag", ar: "سور ترونديلاج" },
      { shortCode: "08", en: "Telemark", ar: "تيليمارك" },
      { shortCode: "19", en: "Troms", ar: "ترومس" },
      { shortCode: "10", en: "Vest-Agder", ar: "فيست ادجير" },
      { shortCode: "07", en: "Vestfold", ar: "فيست فولد" },
      { shortCode: "01", en: "Østfold", ar: "اوستفولد" },
      { shortCode: "22", en: "Jan Mayen", ar: "جان ماين" },
      { shortCode: "21", en: "Svalbard", ar: "سفالبارد" },
    ],
  },
  {
    id: 512,
    code: "om",
    codeLng: "omn",
    en: "Oman",
    ar: "عمان",
    dial: "+968",
    cities: [
      { shortCode: "DA", en: "Ad Dakhiliyah", ar: "الدخليه" },
      { shortCode: "BU", en: "Al Buraymi", ar: "البورايمي" },
      { shortCode: "WU", en: "Al Wusta", ar: "الوسطى" },
      { shortCode: "ZA", en: "Az Zahirah", ar: "الظاهره" },
      { shortCode: "BS", en: "Janub al Batinah", ar: "جنوب البطينه" },
      {
        shortCode: "SS",
        en: "Janub ash Sharqiyah",
        ar: "جنوب الشرقيه",
      },
      { shortCode: "MA", en: "Masqat", ar: "مسقط" },
      { shortCode: "MU", en: "Musandam", ar: "موساندام" },
      {
        shortCode: "BJ",
        en: "Shamal al Batinah",
        ar: "شمال البطينه",
      },
      {
        shortCode: "SJ",
        en: "Shamal ash Sharqiyah",
        ar: "شمال الشرقيه",
      },
      { shortCode: "ZU", en: "Zufar", ar: "زوفار" },
    ],
  },
  {
    id: 586,
    code: "pk",
    codeLng: "pak",
    en: "Pakistan",
    ar: "باكستان",
    dial: "+92",
    cities: [
      { shortCode: "JK", en: "Āzād Kashmīr", ar: "ازاد كاشمير" },
      { shortCode: "BA", en: "Balōchistān", ar: "بالوشيستان" },
      { shortCode: "GB", en: "Gilgit-Baltistān", ar: "جيلجيت بالتيستان" },
      { shortCode: "IS", en: "Islāmābād", ar: "اسلاماباد" },
      {
        shortCode: "KP",
        en: "Khaībar Pakhtūnkhwās",
        ar: "كايبار باختونخواس",
      },
      { shortCode: "PB", en: "Punjāb", ar: "بونجاب" },
      { shortCode: "SD", en: "Sindh", ar: "سيند" },
      {
        shortCode: "TA",
        en: "Federally Administered Tribal Areas",
        ar: "مناطق تريبال الفيدراليه",
      },
    ],
  },
  {
    id: 585,
    code: "pw",
    codeLng: "plw",
    en: "Palau",
    ar: "بالاو",
    dial: "+680",
    cities: [
      { shortCode: "002", en: "Aimeliik", ar: "ايمليك" },
      { shortCode: "004", en: "Airai", ar: "ايراي" },
      { shortCode: "010", en: "Angaur", ar: "انجاور" },
      { shortCode: "050", en: "Hatobohei", ar: "هاتوبوهي" },
      { shortCode: "100", en: "Kayangel", ar: "كايانجيل" },
      { shortCode: "150", en: "Koror", ar: "كورور" },
      { shortCode: "212", en: "Melekeok", ar: "ميليكيوك" },
      { shortCode: "214", en: "Ngaraard", ar: "نجارارد" },
      { shortCode: "218", en: "Ngarchelong", ar: "نجارشيلونج" },
      { shortCode: "222", en: "Ngardmau", ar: "نجاردماو" },
      { shortCode: "224", en: "Ngatpang", ar: "نجاتبانج" },
      { shortCode: "226", en: "Ngchesar", ar: "نجشيسار" },
      { shortCode: "227", en: "Ngeremlengui", ar: "نجيرملينجوي" },
      { shortCode: "228", en: "Ngiwal", ar: "نجيوال" },
      { shortCode: "350", en: "Peleliu", ar: "بيليلوي" },
      { shortCode: "350", en: "Sonsoral", ar: "سونسورال" },
    ],
  },
  {
    id: 275,
    code: "ps",
    codeLng: "pse",
    en: "Palestine, State of",
    ar: "فلسطين",
    dial: "+970",
    cities: [
      { shortCode: "HBN", en: "Ak Khalīl", ar: "الخليل" },
      { shortCode: "JEM", en: "Al Quds", ar: "القدس" },
      {
        shortCode: "JRH",
        en: "Arīḩā wal Aghwār",
        ar: "اريها و الجوهر",
      },
      { shortCode: "BTH", en: "Bayt Laḩm", ar: "بيت لهم" },
      { shortCode: "DEB", en: "Dayr al Balaḩ", ar: "داير البلاه" },
      { shortCode: "GZA", en: "Ghazzah", ar: "غزه" },
      { shortCode: "JEN", en: "Janīn", ar: "جانين" },
      { shortCode: "KYS", en: "Khān Yūnis", ar: "خان يونس" },
      { shortCode: "NBS", en: "Nāblus", ar: "نابلوس" },
      { shortCode: "QQA", en: "Qalqīyah", ar: "كالكيا" },
      { shortCode: "RFH", en: "Rafaḩ", ar: "رفح" },
      {
        shortCode: "RBH",
        en: "Rām Allāh wal Bīrah",
        ar: "رام الله و البيره",
      },
      { shortCode: "SLT", en: "Salfīt", ar: "سالفيت" },
      { shortCode: "NGZ", en: "Shamāl Ghazzah", ar: "شمال غزه" },
      { shortCode: "TBS", en: "Ţūbās", ar: "توباس" },
      { shortCode: "TKM", en: "Ţūlkarm", ar: "تولكارم" },
    ],
  },
  {
    id: 591,
    code: "pa",
    codeLng: "pan",
    en: "Panama",
    ar: "بنما",
    dial: "+507",
    cities: [
      { shortCode: "1", en: "Bocas del Toro", ar: "بوكاس التورو" },
      { shortCode: "4", en: "Chiriquí", ar: "شيريكوي" },
      { shortCode: "2", en: "Coclé", ar: "كوكلي" },
      { shortCode: "3", en: "Colón", ar: "كولون" },
      { shortCode: "5", en: "Darién", ar: "دارين" },
      { shortCode: "EM", en: "Emberá", ar: "ايمبيرا" },
      { shortCode: "6", en: "Herrera", ar: "هيريرا" },
      { shortCode: "KY", en: "Kuna Yala", ar: "كونا يالا" },
      { shortCode: "7", en: "Los Santos", ar: "لوس سانتوس" },
      { shortCode: "NB", en: "Ngäbe-Buglé", ar: "نجابي بوحلي" },
      { shortCode: "8", en: "Panamá", ar: "باناما" },
      { shortCode: "10", en: "Panamá Oeste", ar: "باناما اويستي" },
      { shortCode: "9", en: "Veraguas", ar: "فيراجواس" },
    ],
  },
  {
    id: 598,
    code: "pg",
    codeLng: "png",
    en: "Papua New Guinea",
    ar: "بابوا غينيا الجديدة",
    dial: "+675",
    cities: [
      { shortCode: "NSB", en: "Bougainville", ar: "بوجاينفيلي" },
      { shortCode: "CPM", en: "Central", ar: "سنترال" },
      { shortCode: "CPK", en: "Chimbu", ar: "شيمبو" },
      {
        shortCode: "EBR",
        en: "East New Britain",
        ar: "بريتاين الجديدة الشرقية",
      },
      { shortCode: "ESW", en: "East Sepik", ar: "شرق سيبك" },
      {
        shortCode: "EHG",
        en: "Eastern Highlands",
        ar: "هايلاند الشرقية",
      },
      { shortCode: "EPW", en: "Enga", ar: "اينجا" },
      { shortCode: "GPK", en: "Gulf", ar: "جولف" },
      { shortCode: "HLA", en: "Hela", ar: "هيلا" },
      { shortCode: "JWK", en: "Jiwaka", ar: "جيواكا" },
      { shortCode: "MOM", en: "Madang", ar: "مادانج" },
      { shortCode: "MRL", en: "Manus", ar: "مانوس" },
      { shortCode: "MBA", en: "Milne Bay", ar: "ميلني باي" },
      { shortCode: "MPL", en: "Morobe", ar: "موروبي" },
      { shortCode: "NCD", en: "Port Moresby", ar: "ميناط موريسبي" },
      { shortCode: "NIK", en: "New Ireland", ar: "ايرلاند الجديده" },
      { shortCode: "NPP", en: "Northern", ar: "الشماليه" },
      {
        shortCode: "SHM",
        en: "Southern Highlands",
        ar: "هايلاندز الجنوبيه",
      },
      {
        shortCode: "WBK",
        en: "West New Britain",
        ar: "بريطانيه الجديده الغربيه",
      },
      { shortCode: "SAN", en: "West Sepik", ar: "غرب سيبيك" },
      { shortCode: "WPD", en: "Western", ar: "الغربيه" },
      {
        shortCode: "WHM",
        en: "Western Highlands",
        ar: "هايلاند الغربيه",
      },
    ],
  },
  {
    id: 600,
    code: "py",
    codeLng: "pry",
    en: "Paraguay",
    ar: "باراغواي",
    dial: "+595",
    cities: [
      { shortCode: "16", en: "Alto Paraguay", ar: "التو باراجواي" },
      { shortCode: "10", en: "Alto Parana", ar: "التو بارانا" },
      { shortCode: "13", en: "Amambay", ar: "امامباي" },
      { shortCode: "ASU", en: "Asuncion", ar: "اسانكشن" },
      { shortCode: "5", en: "Caaguazu", ar: "كاجوازو" },
      { shortCode: "6", en: "Caazapa", ar: "كازابا" },
      { shortCode: "14", en: "Canindeyu", ar: "كانينديو" },
      { shortCode: "11", en: "Central", ar: "سنترال" },
      { shortCode: "1", en: "Concepcion", ar: "كونسيبشن" },
      { shortCode: "3", en: "Cordillera", ar: "كورديليرا" },
      { shortCode: "4", en: "Guaira", ar: "جوايرا" },
      { shortCode: "7", en: "Itapua", ar: "ايتابوا" },
      { shortCode: "8", en: "Misiones", ar: "ميشونيس" },
      { shortCode: "12", en: "Neembucu", ar: "نيمبوكو" },
      { shortCode: "9", en: "Paraguari", ar: "باراجواري" },
      { shortCode: "15", en: "Presidente Hayes", ar: "هايس الرئيس" },
      { shortCode: "2", en: "San Pedro", ar: "سان بيدرو" },
    ],
  },
  {
    id: 604,
    code: "pe",
    codeLng: "per",
    en: "Peru",
    ar: "بيرو",
    dial: "+51",
    cities: [
      { shortCode: "AMA", en: "Amazonas", ar: "امازوناس" },
      { shortCode: "ANC", en: "Ancash", ar: "انكاش" },
      { shortCode: "APU", en: "Apurimac", ar: "ابوريماك" },
      { shortCode: "ARE", en: "Arequipa", ar: "اريكويبا" },
      { shortCode: "AYA", en: "Ayacucho", ar: "اياكوشو" },
      { shortCode: "CAJ", en: "Cajamarca", ar: "كاجاماركا" },
      { shortCode: "CAL", en: "Callao", ar: "كالاو" },
      { shortCode: "CUS", en: "Cusco", ar: "كوسكو" },
      { shortCode: "HUV", en: "Huancavelica", ar: "هوانكافيليكا" },
      { shortCode: "HUC", en: "Huanuco", ar: "هوانوكو" },
      { shortCode: "ICA", en: "Ica", ar: "ايكا" },
      { shortCode: "JUN", en: "Junin", ar: "جونين" },
      { shortCode: "LAL", en: "La Libertad", ar: "الليبرتاد" },
      { shortCode: "LAM", en: "Lambayeque", ar: "لابايكو" },
      { shortCode: "LIM", en: "Lima", ar: "ليما" },
      { shortCode: "LOR", en: "Loreto", ar: "لوريتو" },
      { shortCode: "MDD", en: "Madre de Dios", ar: "مادري الديوس" },
      { shortCode: "MOQ", en: "Moquegua", ar: "موكويجوا" },
      {
        shortCode: "LMA",
        en: "Municipalidad Metropolitana de Lima",
        ar: "مونيسيباليداد ميتروبوليتانا دي ليما",
      },
      { shortCode: "PAS", en: "Pasco", ar: "باسكو" },
      { shortCode: "PIU", en: "Piura", ar: "بيورا" },
      { shortCode: "PUN", en: "Puno", ar: "بونو" },
      { shortCode: "SAM", en: "San Martin", ar: "سان مارتن" },
      { shortCode: "TAC", en: "Tacna", ar: "تاكنا" },
      { shortCode: "TUM", en: "Tumbes", ar: "تومبيس" },
      { shortCode: "UCA", en: "Ucayali", ar: "اوكايالي" },
    ],
  },
  {
    id: 608,
    code: "ph",
    codeLng: "phl",
    en: "Philippines",
    ar: "الفلبين",
    dial: "+63",
    cities: [
      { shortCode: "ABR", en: "Abra", ar: "ابرا" },
      {
        shortCode: "AGN",
        en: "Agusan del Norte",
        ar: "Agusan del Norte",
      },
      { shortCode: "AGS", en: "Agusan del Sur", ar: "اجوسان ديل سور" },
      { shortCode: "AKL", en: "Aklan", ar: "اكلان" },
      { shortCode: "ALB", en: "Albay", ar: "الباي" },
      { shortCode: "ANT", en: "Antique", ar: "انتيك" },
      { shortCode: "APA", en: "Apayao", ar: "ابايو" },
      { shortCode: "AUR", en: "Aurora", ar: "ارورا" },
      { shortCode: "BAS", en: "Basilan", ar: "باسيلان" },
      { shortCode: "BAN", en: "Bataan", ar: "باتان" },
      { shortCode: "BTN", en: "Batanes", ar: "باتانيس" },
      { shortCode: "BTG", en: "Batangas", ar: "باتانجاس" },
      { shortCode: "BEN", en: "Benguet", ar: "بينجويت" },
      { shortCode: "BIL", en: "Biliran", ar: "بيليران" },
      { shortCode: "BOH", en: "Bohol", ar: "بوهول" },
      { shortCode: "BUK", en: "Bukidnon", ar: "بوكيدنون" },
      { shortCode: "BUL", en: "Bulacan", ar: "بولاكان" },
      { shortCode: "CAG", en: "Cagayan", ar: "كاجايان" },
      { shortCode: "CAN", en: "Camarines Norte", ar: "كامارينيز نورتي" },
      { shortCode: "CAS", en: "Camarines Sur", ar: "كامارينز سور" },
      { shortCode: "CAM", en: "Camiguin", ar: "كاميجوين" },
      { shortCode: "CAP", en: "Capiz", ar: "كابيز" },
      { shortCode: "CAT", en: "Catanduanes", ar: "كاتاندوانيز" },
      { shortCode: "CAV", en: "Cavite", ar: "كافيت" },
      { shortCode: "CEB", en: "Cebu", ar: "كيبو" },
      { shortCode: "COM", en: "Compostela", ar: "كومبوستيلا" },
      { shortCode: "NCO", en: "Cotabato", ar: "كوتاباتو" },
      { shortCode: "DAV", en: "Davao del Norte", ar: "دافاو ديل نورتي" },
      { shortCode: "DAS", en: "Davao del Sur", ar: "دافاو ديل سور" },
      {
        shortCode: "DVO",
        en: "Davao Occidental",
        ar: "دافاو اوكسيدينتال",
      },
      { shortCode: "DAO", en: "Davao Oriental", ar: "دافاو اورينتال" },
      { shortCode: "DIN", en: "Dinagat Islands", ar: "جزر ديناجات" },
      { shortCode: "EAS", en: "Eastern Samar", ar: "سمار الشرقيه" },
      { shortCode: "GUI", en: "Guimaras", ar: "جويماراس" },
      { shortCode: "IFU", en: "Ifugao", ar: "ايفوجاو" },
      { shortCode: "ILN", en: "Ilocos Norte", ar: "ايلوكوس نورتي" },
      { shortCode: "ILS", en: "Ilocos Sur", ar: "ايلوكوس سور" },
      { shortCode: "ILI", en: "Iloilo", ar: "ايلويلو" },
      { shortCode: "ISA", en: "Isabela", ar: "ايسابيلا" },
      { shortCode: "KAL", en: "Kalinga", ar: "كالينجا" },
      { shortCode: "LUN", en: "La Union", ar: "الاتحاد" },
      { shortCode: "LAG", en: "Laguna", ar: "لاجونا" },
      { shortCode: "LAN", en: "Lanao del Norte", ar: "لاناو ديل نورتي" },
      { shortCode: "LAS", en: "Lanao del Sur", ar: "لاناو ديل سور" },
      { shortCode: "LEY", en: "Leyte", ar: "ليتي" },
      { shortCode: "MAG", en: "Maguindanao", ar: "ماجوينداناو" },
      { shortCode: "MAS", en: "Masbate", ar: "ماسباتي" },
      { shortCode: "00", en: "Metro Manila", ar: "ميترو مانيلا" },
      {
        shortCode: "MDC",
        en: "Mindoro Occidental",
        ar: "ميندورو اوكسيدينتال",
      },
      {
        shortCode: "MDR",
        en: "Mindoro Oriental",
        ar: "ميندورو اورينتال",
      },
      {
        shortCode: "MSC",
        en: "Misamis Occidental",
        ar: "ميساميس اوكسيدنتال",
      },
      {
        shortCode: "MSR",
        en: "Misamis Oriental",
        ar: "ميساميس اورينتال",
      },
      {
        shortCode: "MOU",
        en: "Mountain Province",
        ar: "جبل برفينس",
      },
      {
        shortCode: "NEC",
        en: "Negros Occidental",
        ar: "نيجروس اوكسيدينتال",
      },
      { shortCode: "NER", en: "Negros Oriental", ar: "نيجروس اورينتال" },
      { shortCode: "NSA", en: "Northern Samar", ar: "سمر الشماليه" },
      { shortCode: "NUE", en: "Nueva Ecija", ar: "نويفا اسيجا" },
      { shortCode: "NUV", en: "Nueva Vizcaya", ar: "نويفا فيزكايا" },
      { shortCode: "PLW", en: "Palawan", ar: "بالاوان" },
      { shortCode: "PAM", en: "Pampanga", ar: "بامبانجا" },
      { shortCode: "PAN", en: "Pangasinan", ar: "بانجاسيان" },
      { shortCode: "QUE", en: "Quezon", ar: "كويزون" },
      { shortCode: "QUI", en: "Quirino", ar: "كويرينو" },
      { shortCode: "RIZ", en: "Rizal", ar: "ريزال" },
      { shortCode: "ROM", en: "Romblon", ar: "رومبلون" },
      { shortCode: "WSA", en: "Samar", ar: "سمار" },
      { shortCode: "SAR", en: "Sarangani", ar: "سارانجاني" },
      { shortCode: "SIG", en: "Siquijor", ar: "سيضويجور" },
      { shortCode: "SOR", en: "Sorsogon", ar: "سورسوجون" },
      { shortCode: "SLE", en: "Southern Leyte", ar: "ليتي الجنوبيه" },
      { shortCode: "AUK", en: "Sultan Kudarat", ar: "سلطان كودارات" },
      { shortCode: "SLU", en: "Sulu", ar: "سولو" },
      {
        shortCode: "SUN",
        en: "Surigao del Norte",
        ar: "سوريجاو ديل نورتي",
      },
      { shortCode: "SUR", en: "Surigao del Sur", ar: "سوريجار ديل سور" },
      { shortCode: "TAR", en: "Tarlac", ar: "تارلاك" },
      { shortCode: "TAW", en: "Tawi-Tawi", ar: "تاوي تاوي" },
      { shortCode: "ZMB", en: "Zambales", ar: "زامباليس" },
      {
        shortCode: "ZAN",
        en: "Zamboanga del Norte",
        ar: "زامبوانجا ديل نورتي",
      },
      {
        shortCode: "ZAS",
        en: "Zamboanga del Sur",
        ar: "زامبوانجا ديل سور",
      },
      {
        shortCode: "ZSI",
        en: "Zamboanga Sibugay",
        ar: "زامبوانجا سيبوجاي",
      },
    ],
  },
  {
    id: 612,
    code: "pn",
    codeLng: "pcn",
    en: "Pitcairn",
    ar: "جزر بيتكيرن",
    dial: "+872",
    cities: [{ en: "Pitcairn Islands", ar: "جزر بتكايرن" }],
  },
  {
    id: 616,
    code: "pl",
    codeLng: "pol",
    en: "Poland",
    ar: "بولندا",
    dial: "+48",
    cities: [
      { shortCode: "DS", en: "Dolnośląskie", ar: "دولنوسلاسكي" },
      {
        shortCode: "KP",
        en: "Kujawsko-pomorskie",
        ar: "كوجاوسكو بومورسكي",
      },
      { shortCode: "LD", en: "Łódzkie", ar: "لودزكي" },
      { shortCode: "LU", en: "Lubelskie", ar: "لوبلسكي" },
      { shortCode: "LB", en: "Lubuskie", ar: "لوبوسكي" },
      { shortCode: "MA", en: "Małopolskie", ar: "مالوبوسكي" },
      { shortCode: "MZ", en: "Mazowieckie", ar: "مازويكي" },
      { shortCode: "OP", en: "Opolskie", ar: "اوبولسكي" },
      { shortCode: "PK", en: "Podkarpackie", ar: "بودكارباكي" },
      { shortCode: "PD", en: "Podlaskie", ar: "بودلاسكي" },
      { shortCode: "PM", en: "Pomorskie", ar: "بومرسكي" },
      { shortCode: "SL", en: "Śląskie", ar: "سلاسكي" },
      { shortCode: "SK", en: "Świętokrzyskie", ar: "سويتوكرزسكي" },
      {
        shortCode: "WN",
        en: "Warmińsko-mazurskie",
        ar: "وارمينسكو مازورسكي",
      },
      { shortCode: "WP", en: "Wielkopolskie", ar: "ويلكوبولسكي" },
      {
        shortCode: "ZP",
        en: "Zachodniopomorskie",
        ar: "زاكودنيوبومورسكي",
      },
    ],
  },
  {
    id: 620,
    code: "pt",
    codeLng: "prt",
    en: "Portugal",
    ar: "البرتغال",
    dial: "+351",
    cities: [
      { shortCode: "20", en: "Açores", ar: "اكوريس" },
      { shortCode: "01", en: "Aveiro", ar: "افيرو" },
      { shortCode: "02", en: "Beja", ar: "بيجا" },
      { shortCode: "03", en: "Braga", ar: "براجا" },
      { shortCode: "04", en: "Bragança", ar: "براجانكا" },
      { shortCode: "05", en: "Castelo Branco", ar: "كاستيلو برانكو" },
      { shortCode: "06", en: "Coimbra", ar: "كويمبرا" },
      { shortCode: "07", en: "Évora", ar: "ايفورا" },
      { shortCode: "08", en: "Faro", ar: "فارو" },
      { shortCode: "09", en: "Guarda", ar: "جواردا" },
      { shortCode: "10", en: "Leiria", ar: "ليريا" },
      { shortCode: "11", en: "Lisboa", ar: "ليسبوا" },
      { shortCode: "30", en: "Madeira", ar: "ماديرا" },
      { shortCode: "12", en: "Portalegre", ar: "بورتاليجري" },
      { shortCode: "13", en: "Porto", ar: "بورتو" },
      { shortCode: "14", en: "Santarém", ar: "سانتاريم" },
      { shortCode: "15", en: "Setúbal", ar: "سيتوبال" },
      { shortCode: "16", en: "Viana do Castelo", ar: "فيانا دو كاستيلو" },
      { shortCode: "17", en: "Vila Real", ar: "فيلا ريل" },
      { shortCode: "18", en: "Viseu", ar: "فيسو" },
    ],
  },
  {
    id: 630,
    code: "pr",
    codeLng: "pri",
    en: "Puerto Rico",
    ar: "بورتوريكو",
    dial: "+1 939",
    cities: [
      { en: "Adjuntas", ar: "ادجونتاس" },
      { en: "Aguada", ar: "اجوادا" },
      { en: "Aguadilla", ar: "اجواديلا" },
      { en: "Aguas Buenas", ar: "اجواس بويناس" },
      { en: "Aibonito", ar: "ايبونيتو" },
      { en: "Anasco", ar: "اناسكو" },
      { en: "Arecibo", ar: "اريسيبو" },
      { en: "Arroyo", ar: "ارويو" },
      { en: "Barceloneta", ar: "بارسيلونيتا" },
      { en: "Barranquitas", ar: "بارانكويتاس" },
      { en: "Bayamon", ar: "بايامون" },
      { en: "Cabo Rojo", ar: "كابو روجو" },
      { en: "Caguas", ar: "كاجواس" },
      { en: "Camuy", ar: "كاموي" },
      { en: "Canovanas", ar: "كانوفاناس" },
      { en: "Carolina", ar: "كارولينا" },
      { en: "Cat", ar: "كات" },
      { en: "Ceiba", ar: "سيبا" },
      { en: "Ciales", ar: "سياليس" },
      { en: "Cidra", ar: "سيدرا" },
      { en: "Coamo", ar: "كوامو" },
      { en: "Comerio", ar: "كوميريو" },
      { en: "Corozal", ar: "كوروزال" },
      { en: "Culebra", ar: "كوليبرا" },
      { en: "Dorado", ar: "دورادو" },
      { en: "Fajardo", ar: "فاجاردو" },
      { en: "Florida", ar: "فلوريدا" },
      { en: "Guanica", ar: "جوانيكا" },
      { en: "Guayama", ar: "جواياما" },
      { en: "Guayanilla", ar: "جوايانيلا" },
      { en: "Guaynabo", ar: "جواينابو" },
      { en: "Gurabo", ar: "جورابو" },
      { en: "Hatillo", ar: "هاتيلو" },
      { en: "Hormigueros", ar: "هورميجويروس" },
      { en: "Humacao", ar: "هوماكاو" },
      { en: "Isabe", ar: "ايسابي" },
      { en: "Juana Diaz", ar: "وانا دياز" },
      { en: "Juncos", ar: "وانكوس" },
      { en: "Lajas", ar: "لاجاس" },
      { en: "Lares", ar: "لاريس" },
      { en: "Las Marias", ar: "لاس مارياس" },
      { en: "Las oiza", ar: "لاس ويزا" },
      { en: "Luquillo", ar: "لوكويلو" },
      { en: "Manati", ar: "ماناتي" },
      { en: "Maricao", ar: "ماريكاو" },
      { en: "Maunabo", ar: "موانابو" },
      { en: "Mayaguez", ar: "ماياجويز" },
      { en: "Moca", ar: "موكا" },
      { en: "Morovis", ar: "موروفيس" },
      { en: "Naguabo", ar: "ناجوابو" },
      { en: "Naranjito", ar: "نارانجيتو" },
      { en: "Orocovis", ar: "اوروكوفيس" },
      { en: "Patillas", ar: "باتيلاس" },
      { en: "Penuelas", ar: "بينويلاس" },
      { en: "Ponce", ar: "بونكي" },
      { en: "Quebradillas", ar: "كوبراديلاس" },
      { en: "Rincon", ar: "رينكون" },
      { en: "Rio Grande", ar: "ريو جراندي" },
      { en: "Sabana linas", ar: "سابانا ليناس" },
      { en: "San German", ar: "سان جيرمان" },
      { en: "San Juan", ar: "سان وان" },
      { en: "San Lorenzo", ar: "سان لورينزو" },
      { en: "San Sebastian", ar: "سان سيباشتيان" },
      { en: "Santa Isabel", ar: "سانتا ايزابيل" },
      { en: "Toa Alta", ar: "توا التا" },
      { en: "Toa Baja", ar: "توا باجا" },
      { en: "Trujillo Alto", ar: "تروجيلو التو" },
      { en: "Utuado", ar: "اوتوادو" },
      { en: "Vega Alta", ar: "فيجا التا" },
      { en: "Vega ues", ar: "فيجا ويس" },
      { en: "Villalba", ar: "فيلالبا" },
      { en: "Yabucoa", ar: "يوبوكوا" },
      { en: "Yauco", ar: "ياوكو" },
    ],
  },
  {
    id: 634,
    code: "qa",
    codeLng: "qat",
    en: "Qatar",
    ar: "قطر",
    dial: "+974",
    cities: [
      { shortCode: "DA", en: "Ad Dawḩah", ar: "الدوحه" },
      {
        shortCode: "KH",
        en: "Al Khawr wa adh Dhakhīrah",
        ar: "الخور و الدخيره",
      },
      { shortCode: "WA", en: "Al Wakrah", ar: "الوكره" },
      { shortCode: "RA", en: "Ar Rayyān", ar: "الريان" },
      { shortCode: "MS", en: "Ash Shamāl", ar: "الشمال" },
      { shortCode: "ZA", en: "Az̧ Za̧`āyin", ar: "الزين" },
      { shortCode: "US", en: "Umm Şalāl", ar: "ام سلال" },
    ],
  },
  {
    id: 638,
    code: "re",
    codeLng: "reu",
    en: "Réunion",
    ar: "لا ريونيون",
    dial: "+262",
    cities: [{ en: "Réunion", ar: "لا رينيون" }],
  },
  {
    id: 642,
    code: "ro",
    codeLng: "rou",
    en: "Romania",
    ar: "رومانيا",
    dial: "+40",
    cities: [
      { shortCode: "AB", en: "Alba", ar: "البا" },
      { shortCode: "AR", en: "Arad", ar: "اراد" },
      { shortCode: "AG", en: "Arges", ar: "ارجيس" },
      { shortCode: "BC", en: "Bacau", ar: "باكاو" },
      { shortCode: "BH", en: "Bihor", ar: "بيهور" },
      { shortCode: "BN", en: "Bistrita-Nasaud", ar: "بيستريتا ناساود" },
      { shortCode: "BT", en: "Botosani", ar: "بوتوساني" },
      { shortCode: "BR", en: "Braila", ar: "برايلا" },
      { shortCode: "BV", en: "Brasov", ar: "براسوف" },
      { shortCode: "B", en: "Bucuresti", ar: "بوكوريستي" },
      { shortCode: "BZ", en: "Buzau", ar: "بوزاو" },
      { shortCode: "CL", en: "Calarasi", ar: "كالاراسي" },
      { shortCode: "CS", en: "Caras-Severin", ar: "كاراس سيفرين" },
      { shortCode: "CJ", en: "Cluj", ar: "كلوج" },
      { shortCode: "CT", en: "Constanta", ar: "كونستانتا" },
      { shortCode: "CV", en: "Covasna", ar: "كوفاسنا" },
      { shortCode: "DB", en: "Dambovita", ar: "دامبوفيتا" },
      { shortCode: "DJ", en: "Dolj", ar: "دولج" },
      { shortCode: "GL", en: "Galati", ar: "جالاتي" },
      { shortCode: "GR", en: "Giurgiu", ar: "جويرجو" },
      { shortCode: "GJ", en: "Gorj", ar: "جورج" },
      { shortCode: "HR", en: "Harghita", ar: "هارجيتا" },
      { shortCode: "HD", en: "Hunedoara", ar: "هونيدوارا" },
      { shortCode: "IL", en: "Ialomita", ar: "ايالوميتا" },
      { shortCode: "IS", en: "Iasi", ar: "اياسي" },
      { shortCode: "MM", en: "Maramures", ar: "ماراموريس" },
      { shortCode: "MH", en: "Mehedinti", ar: "ميهيدينتي" },
      { shortCode: "MS", en: "Mures", ar: "موريس" },
      { shortCode: "NT", en: "Neamt", ar: "نيمت" },
      { shortCode: "OT", en: "Olt", ar: "اولت" },
      { shortCode: "PH", en: "Prahova", ar: "براهرفا" },
      { shortCode: "SJ", en: "Salaj", ar: "سالاج" },
      { shortCode: "SM", en: "Satu Mare", ar: "ساتو ماري" },
      { shortCode: "SB", en: "Sibiu", ar: "سيبو" },
      { shortCode: "SV", en: "Suceava", ar: "سوكيفا" },
      { shortCode: "TR", en: "Teleorman", ar: "تيلي اورمان" },
      { shortCode: "TM", en: "Timis", ar: "تيميس" },
      { shortCode: "TL", en: "Tulcea", ar: "تولكيا" },
      { shortCode: "VL", en: "Valcea", ar: "فالكيا" },
      { shortCode: "VS", en: "Vaslui", ar: "فاسلوي" },
      { shortCode: "VN", en: "Vrancea", ar: "فرانسيا" },
    ],
  },
  {
    id: 643,
    code: "ru",
    codeLng: "rus",
    en: "Russian Federation",
    ar: "روسيا",
    dial: "+7",
    cities: [
      {
        shortCode: "AD",
        en: "Republic of Adygea",
        ar: " جمهوريه اديجا ",
      },
      {
        shortCode: "AL",
        en: "Republic of Altai (Gorno-Altaysk)",
        ar: "جمهوريه التاي ",
      },
      { shortCode: "ALT", en: "Altai Krai", ar: "التاي كراي" },
      { shortCode: "AMU", en: "Amur Oblast", ar: "امور اوبلاست" },
      {
        shortCode: "ARK",
        en: "Arkhangelsk Oblast",
        ar: "اركانجيلسك اوبلاست",
      },
      {
        shortCode: "AST",
        en: "Astrakhan Oblast",
        ar: "استراكان اوبلاست",
      },
      {
        shortCode: "BA",
        en: "Republic of Bashkortostan",
        ar: "جمهوريه باشكورتوستان",
      },
      { shortCode: "BEL", en: "Belgorod Oblast", ar: "Belgorod Oblast" },
      { shortCode: "BRY", en: "Bryansk Oblast", ar: "بريانسك اوبلاست" },
      {
        shortCode: "BU",
        en: "Republic of Buryatia",
        ar: "جمهوريه بورياتيا",
      },
      { shortCode: "CE", en: "Chechen Republic", ar: "جمهوريه شيشين" },
      {
        shortCode: "CHE",
        en: "Chelyabinsk Oblast",
        ar: "شيليابينسك اوبلاست",
      },
      {
        shortCode: "CHU",
        en: "Chukotka Autonomous Okrug",
        ar: "شوكوتكا اوتونوموس اوكروج",
      },
      { shortCode: "CU", en: "Chuvash Republic", ar: "جمهوريه شوفاش" },
      {
        shortCode: "DA",
        en: "Republic of Dagestan",
        ar: "جمهوريه داجيستان",
      },
      {
        shortCode: "IN",
        en: "Republic of Ingushetia",
        ar: "جمهوريه انجوشيتيا",
      },
      { shortCode: "IRK", en: "Irkutsk Oblast", ar: "Irkutsk Oblast" },
      { shortCode: "IVA", en: "Ivanovo Oblast", ar: "ايفانونو اوبلاست" },
      {
        shortCode: "JEW",
        en: "Jewish Autonomous Oblast",
        ar: "جويش اوتونوموس اوبلاست",
      },
      {
        shortCode: "KB",
        en: "Kabardino-Balkar Republic",
        ar: " جمهوريه كاباردينو بالكار ",
      },
      {
        shortCode: "KLN",
        en: "Kaliningrad Oblast",
        ar: "كالين اوبلاست",
      },
      {
        shortCode: "KL",
        en: "Republic of Kalmykia",
        ar: "جمهوريه كالميكيا",
      },
      { shortCode: "KLU", en: "Kaluga Oblast", ar: "كالوجا اوبلاست" },
      { shortCode: "KAM", en: "Kamchatka Krai", ar: "كامشاتكا كراي" },
      {
        shortCode: "KC",
        en: "Karachay-Cherkess Republic",
        ar: "جمهوريه كاراشاي شيركس",
      },
      {
        shortCode: "KR",
        en: "Republic of Karelia",
        ar: "جمهوريه كاريليا",
      },
      { shortCode: "KHA", en: "Khabarovsk Krai", ar: "كاباروفسك كراي" },
      {
        shortCode: "KK",
        en: "Republic of Khakassia",
        ar: "جمهوريه خاكاسيا",
      },
      {
        shortCode: "KHM",
        en: "Khanty-Mansi Autonomous Okrug - Yugra",
        ar: "خانتي منسي اوتونوموس اوكروج يوجرا ",
      },
      { shortCode: "KEM", en: "Kemerovo Oblast", ar: "كيميروفو اوبلاست" },
      { shortCode: "KIR", en: "Kirov Oblast", ar: "كيروف اوبلاست" },
      { shortCode: "KO", en: "Komi Republic", ar: "جمهوريه كومي" },
      { shortCode: "KOS", en: "Kostroma Oblast", ar: "كوستروما اوبلاست" },
      { shortCode: "KDA", en: "Krasnodar Krai", ar: "كراسنودار كراي" },
      {
        shortCode: "KYA",
        en: "Krasnoyarsk Krai",
        ar: "كراسنويارسك كراي",
      },
      { shortCode: "KGN", en: "Kurgan Oblast", ar: "كرجن اوبلاست" },
      { shortCode: "KRS", en: "Kursk Oblast", ar: "كرسك اوبلاست" },
      {
        shortCode: "LEN",
        en: "Leningrad Oblast",
        ar: "لينينجراد اوبلاست",
      },
      { shortCode: "LIP", en: "Lipetsk Oblast", ar: "ليبيستيك اوبلاست" },
      { shortCode: "MAG", en: "Magadan Oblast", ar: "ماجادان اوبلاست" },
      { shortCode: "ME", en: "Mari El Republic", ar: "جمهوريه ماري" },
      {
        shortCode: "MO",
        en: "Republic of Mordovia",
        ar: "جمهوريه موردوفيا",
      },
      { shortCode: "MOS", en: "Moscow Oblast", ar: "موسكو اوبلاست" },
      { shortCode: "MOW", en: "Moscow", ar: "موسكو" },
      { shortCode: "MU", en: "Murmansk Oblast", ar: "مورمانسك اوبلاست" },
      {
        shortCode: "NEN",
        en: "Nenets Autonomous Okrug",
        ar: "نينيتس اوتونوموس اوكروج",
      },
      {
        shortCode: "NIZ",
        en: "Nizhny Novgorod Oblast",
        ar: "نيزهيني نوفجورود اوبلاست",
      },
      { shortCode: "NGR", en: "Novgorod Oblast", ar: "نوفجورود اوبلاست" },
      {
        shortCode: "NVS",
        en: "Novosibirsk Oblast",
        ar: "نوفوسيبيرسك اوبلاست",
      },
      { shortCode: "OMS", en: "Omsk Oblast", ar: "اومسك اوبلاست" },
      { shortCode: "ORE", en: "Orenburg Oblast", ar: "اورينبورج اوبلاست" },
      { shortCode: "ORL", en: "Oryol Oblast", ar: "اوريول اوبلاست" },
      { shortCode: "PNZ", en: "Penza Oblast", ar: "بينزا اوبلاست" },
      { shortCode: "PER", en: "Perm Krai", ar: "بيرم كراي" },
      { shortCode: "PRI", en: "Primorsky Krai", ar: "بريمورسكي كراي" },
      { shortCode: "PSK", en: "Pskov Oblast", ar: "بسكوف اوبلاست" },
      { shortCode: "ROS", en: "Rostov Oblast", ar: "روستوف اوبلاست" },
      { shortCode: "RYA", en: "Ryazan Oblast", ar: "ريازان اوبلاست" },
      {
        shortCode: "SPE",
        en: "Saint Petersburg",
        ar: "سينت بيترزبيرج",
      },
      {
        shortCode: "SA",
        en: "Sakha (Yakutia) Republic",
        ar: "جمهوريه سخا",
      },
      { shortCode: "SAK", en: "Sakhalin Oblast", ar: "ساخالين اوبلاست" },
      { shortCode: "SAM", en: "Samara Oblast", ar: "سماره اوبلاست" },
      { shortCode: "SAR", en: "Saratov Oblast", ar: "ساراتوف اوبلاست" },
      {
        shortCode: "NOA",
        en: "Republic of North Ossetia-Alania",
        ar: "جمهوريه شمال اسيتيا الانيا",
      },
      { shortCode: "SMO", en: "Smolensk Oblast", ar: "سمولينسك اوبلاست" },
      { shortCode: "STA", en: "Stavropol Krai", ar: "سترافروبول كراي" },
      {
        shortCode: "SVE",
        en: "Sverdlovsk Oblast",
        ar: "سفيرد اوبلاست",
      },
      { shortCode: "TAM", en: "Tambov Oblast", ar: "تامبوف اوبلاست" },
      {
        shortCode: "TA",
        en: "Republic of Tatarstan",
        ar: "جمهوريه تاراستان",
      },
      { shortCode: "TOM", en: "Tomsk Oblast", ar: "تومسك اوبلاست" },
      { shortCode: "TU", en: "Tuva Republic", ar: "جمهوريه توفا" },
      { shortCode: "TUL", en: "Tula Oblast", ar: "تولا اوبلاست" },
      { shortCode: "TVE", en: "Tver Oblast", ar: "تفير اوبلاست" },
      { shortCode: "TYU", en: "Tyumen Oblast", ar: "تيومين اوبلاست" },
      { shortCode: "UD", en: "Udmurt Republic", ar: "جمهوريه اودمورت" },
      {
        shortCode: "ULY",
        en: "Ulyanovsk Oblast",
        ar: "اليانوفسك اوبلاست",
      },
      { shortCode: "VLA", en: "Vladimir Oblast", ar: "فلاديمير اوبلاست" },
      {
        shortCode: "VGG",
        en: "Volgograd Oblast",
        ar: "فولجوجراد اوبلاست",
      },
      { shortCode: "VLG", en: "Vologda Oblast", ar: "فولوجدا اوبلاست" },
      { shortCode: "VOR", en: "Voronezh Oblast", ar: "فورونيزه اوبلاست" },
      {
        shortCode: "YAN",
        en: "Yamalo-Nenets Autonomous Okrug",
        ar: "يامالو نينيتس اوتونوموس اوكروج",
      },
      {
        shortCode: "YAR",
        en: "Yaroslavl Oblast",
        ar: "ياروسلافل اوبلاست",
      },
      { shortCode: "ZAB", en: "Zabaykalsky Krai", ar: "زابايكالسكي كراي" },
    ],
  },
  {
    id: 646,
    code: "rw",
    codeLng: "rwa",
    en: "Rwanda",
    ar: "رواندا",
    dial: "+250",
    cities: [
      { shortCode: "01", en: "Kigali", ar: "كيجالي" },
      { shortCode: "02", en: "Eastern", ar: "الشرقيه" },
      { shortCode: "03", en: "Northern", ar: "الشماليه" },
      { shortCode: "04", en: "Western", ar: "الغربيه" },
      { shortCode: "05", en: "Southern", ar: "الجنوبيه" },
    ],
  },
  {
    id: 652,
    code: "bl",
    codeLng: "blm",
    en: "Saint Barthélemy",
    ar: "سان بارتيلمي",
    dial: "+590",
    cities: [
      { shortCode: "02", en: "Au Vent", ar: "او فينت" },
      { shortCode: "01", en: "Sous le Vent", ar: "سوس لا فينت" },
    ],
  },
  {
    id: 654,
    code: "sh",
    codeLng: "shn",
    en: "Saint Helena, Ascension and Tristan da Cunha",
    ar: "سانت هيلانة وأسينشين وتريستان دا كونا",
    dial: "+290",
    cities: [
      { shortCode: "AC", en: "Ascension", ar: "اسينشن" },
      { shortCode: "HL", en: "Saint Helena", ar: "القديس هيلينا" },
      { shortCode: "TA", en: "Tristan da Cunha", ar: "تريستان دا كونها" },
    ],
  },
  {
    id: 659,
    code: "kn",
    codeLng: "kna",
    en: "Saint Kitts and Nevis",
    ar: "سانت كيتس ونيفيس",
    dial: "+1 869",
    cities: [
      { shortCode: "K", en: "Saint Kitts", ar: "القديس كيتس" },
      { shortCode: "N", en: "Nevis", ar: "نيفيس" },
    ],
  },
  {
    id: 662,
    code: "lc",
    codeLng: "lca",
    en: "Saint Lucia",
    ar: "سانت لوسيا",
    dial: "+1 758",
    cities: [
      { shortCode: "01", en: "Anse-la-Raye", ar: "انسي لا رايو" },
      { shortCode: "12", en: "Canaries", ar: "كاناريز" },
      { shortCode: "02", en: "Castries", ar: "كاستريز" },
      { shortCode: "03", en: "Choiseul", ar: "شويسيول" },
      { shortCode: "05", en: "Dennery", ar: "دينيري" },
      { shortCode: "06", en: "Gros Islet", ar: "جروس ايسليت" },
      { shortCode: "07", en: "Laborie", ar: "لابوري" },
      { shortCode: "08", en: "Micoud", ar: "ميكود" },
      { shortCode: "10", en: "Soufriere", ar: "سوفريري" },
      { shortCode: "11", en: "Vieux Fort", ar: "فيوكس فورت" },
    ],
  },
  {
    id: 663,
    code: "mf",
    codeLng: "maf",
    en: "Saint Martin (French part)",
    ar: "تجمع سان مارتين",
    dial: "+590",
    cities: [{ en: "Saint Martin", ar: "القديس مارتن" }],
  },
  {
    id: 666,
    code: "pm",
    codeLng: "spm",
    en: "Saint Pierre and Miquelon",
    ar: "سان بيير وميكلون",
    dial: "+508",
    cities: [
      { en: "Miquelon", ar: "ميكويلون" },
      { en: "Saint Pierre", ar: "قديس بيري" },
    ],
  },
  {
    id: 670,
    code: "vc",
    codeLng: "vct",
    en: "Saint Vincent and the Grenadines",
    ar: "سانت فينسنت والغرينادين",
    dial: "+1 784",
    cities: [
      { shortCode: "01", en: "Charlotte", ar: "شارلوت" },
      { shortCode: "06", en: "Grenadines", ar: "جرينادينيس" },
      { shortCode: "02", en: "Saint Andrew", ar: "القديس اندرو" },
      { shortCode: "03", en: "Saint David", ar: "القديس ديفيد" },
      { shortCode: "04", en: "Saint George", ar: "القديس جورج" },
      { shortCode: "05", en: "Saint Patrick", ar: "القديس باتريك" },
    ],
  },
  {
    id: 882,
    code: "ws",
    codeLng: "wsm",
    en: "Samoa",
    ar: "ساموا",
    dial: "+685",
    cities: [
      { shortCode: "AA", en: "A'ana", ar: "انا" },
      { shortCode: "AL", en: "Aiga-i-le-Tai", ar: "ايجا اي لي تاي" },
      { shortCode: "AT", en: "Atua", ar: "اتوا" },
      { shortCode: "FA", en: "Fa'asaleleaga", ar: "فا اساليليجا" },
      { shortCode: "GE", en: "Gaga'emauga", ar: "جاجا ايماوجا" },
      { shortCode: "GI", en: "Gagaifomauga", ar: "جاجايفوماوجا" },
      { shortCode: "PA", en: "Palauli", ar: "باباولي" },
      { shortCode: "SA", en: "Satupa'itea", ar: "ساتوبا ايتيا" },
      { shortCode: "TU", en: "Tuamasaga", ar: "تواماساجا" },
      { shortCode: "VF", en: "Va'a-o-Fonoti", ar: "فا او فونوتي" },
      { shortCode: "VS", en: "Vaisigano", ar: "فايسيجانو" },
    ],
  },
  {
    id: 674,
    code: "sm",
    codeLng: "smr",
    en: "San Marino",
    ar: "سان مارينو",
    dial: "+378",
    cities: [
      { shortCode: "01", en: "Acquaviva", ar: "اكوافيفا" },
      { shortCode: "06", en: "Borgo Maggiore", ar: "بورجو ماجواير" },
      { shortCode: "02", en: "Chiesanuova", ar: "شيسانوفا" },
      { shortCode: "03", en: "Domagnano", ar: "دوماجنانو" },
      { shortCode: "04", en: "Faetano", ar: "فايتانو" },
      { shortCode: "05", en: "Fiorentino", ar: "فيورينتينو" },
      { shortCode: "08", en: "Montegiardino", ar: "مونتيجياردينو" },
      { shortCode: "07", en: "San Marino", ar: "سان مارينو" },
      { shortCode: "09", en: "Serravalle", ar: "سيرافالي" },
    ],
  },
  {
    id: 678,
    code: "st",
    codeLng: "stp",
    en: "Sao Tome and Principe",
    ar: "ساو تومي وبرينسيب",
    dial: "+239",
    cities: [
      { shortCode: "P", en: "Principe", ar: "برينسيبي" },
      { shortCode: "S", en: "Sao Tome", ar: "ساو تومي" },
    ],
  },
  {
    id: 682,
    code: "sa",
    codeLng: "sau",
    en: "Saudi Arabia",
    ar: "السعودية",
    dial: "+966",
    cities: [
      { shortCode: "14", en: "'Asir", ar: "'عصير" },
      { shortCode: "15", en: "'Jeddah", ar: "'جده" },
      { shortCode: "11", en: "Al Bahah", ar: "الباحه" },
      {
        shortCode: "08",
        en: "Al Hudud ash Shamaliyah",
        ar: "الحدود الشماليه",
      },
      { shortCode: "12", en: "Al Jawf", ar: "الجوف" },
      {
        shortCode: "03",
        en: "Al Madinah al Munawwarah",
        ar: "المدينه المنوره",
      },
      { shortCode: "05", en: "Al Qasim", ar: "القسيم" },
      { shortCode: "01", en: "Ar Riyad", ar: "الرياض" },
      { shortCode: "04", en: "Ash Sharqiyah", ar: "الشرقيه" },
      { shortCode: "06", en: "Ha'il", ar: "هايل" },
      { shortCode: "09", en: "Jazan", ar: "جازان" },
      {
        shortCode: "02",
        en: "Makkah al Mukarramah",
        ar: "مكه المكرمة",
      },
      { shortCode: "10", en: "Najran", ar: "نجران" },
      { shortCode: "07", en: "Tabuk", ar: "تابوك" },
    ],
  },
  {
    id: 686,
    code: "sn",
    codeLng: "sen",
    en: "Senegal",
    ar: "السنغال",
    dial: "+221",
    cities: [
      { shortCode: "DK", en: "Dakar", ar: "دكار" },
      { shortCode: "DB", en: "Diourbel", ar: "ديوربيل" },
      { shortCode: "FK", en: "Fatick", ar: "فاتيك" },
      { shortCode: "KA", en: "Kaffrine", ar: "كافرين" },
      { shortCode: "KL", en: "Kaolack", ar: "كاولاك" },
      { shortCode: "KE", en: "Kedougou", ar: "كيدوجو" },
      { shortCode: "KD", en: "Kolda", ar: "كولدا" },
      { shortCode: "LG", en: "Louga", ar: "لوجا" },
      { shortCode: "MT", en: "Matam", ar: "ماتام" },
      { shortCode: "SL", en: "Saint-Louis", ar: "القديس لويس" },
      { shortCode: "SE", en: "Sedhiou", ar: "سيدهوي" },
      { shortCode: "TC", en: "Tambacounda", ar: "تامباكوندا" },
      { shortCode: "TH", en: "Thies", ar: "تيس" },
      { shortCode: "ZG", en: "Ziguinchor", ar: "زيجوينشور" },
    ],
  },
  {
    id: 688,
    code: "rs",
    codeLng: "srb",
    en: "Serbia",
    ar: "صربيا",
    dial: "+381",
    cities: [
      {
        shortCode: "00",
        en: "Beograd (Belgrade)",
        ar: "بيوجراد",
      },
      { shortCode: "14", en: "Borski", ar: "بورسكي" },
      { shortCode: "11", en: "Braničevski", ar: "برانيسيفسكي" },
      { shortCode: "23", en: "Jablanički", ar: "جبلانيكي" },
      { shortCode: "06", en: "Južnobački", ar: "جوزونوباكي" },
      { shortCode: "04", en: "Južnobanatski", ar: "جوزنوباناتسكي" },
      { shortCode: "09", en: "Kolubarski", ar: "كولوبارسكي" },
      { shortCode: "25", en: "Kosovski", ar: "كوسوفسكي" },
      {
        shortCode: "28",
        en: "Kosovsko-Mitrovački",
        ar: "كوسوفسكو ميتروفاكي",
      },
      {
        shortCode: "29",
        en: "Kosovsko-Pomoravski",
        ar: "كوسوفسكو بومورافسكي",
      },
      { shortCode: "08", en: "Mačvanski", ar: "ماكفانسكي" },
      { shortCode: "17", en: "Moravički", ar: "مورافيكي" },
      { shortCode: "20", en: "Nišavski", ar: "نيسافسكي" },
      { shortCode: "24", en: "Pčinjski", ar: "بسينجسكي" },
      { shortCode: "26", en: "Pećki", ar: "بيكي" },
      { shortCode: "22", en: "Pirotski", ar: "بيروتسكي" },
      { shortCode: "10", en: "Podunavski", ar: "بودونافسكي" },
      { shortCode: "13", en: "Pomoravski", ar: "بومورافسكي" },
      { shortCode: "27", en: "Prizrenski", ar: "بريزرينسكي" },
      { shortCode: "19", en: "Rasinski", ar: "راسينسكي" },
      { shortCode: "18", en: "Raški", ar: "راسكي" },
      { shortCode: "01", en: "Severnobački", ar: "سيفرنوباكي" },
      { shortCode: "03", en: "Severnobanatski", ar: "سيفرنوباناتسكي" },
      { shortCode: "02", en: "Srednjebanatski", ar: "سريدجيباناتسكي" },
      { shortCode: "07", en: "Sremski", ar: "سريمسكي" },
      { shortCode: "12", en: "Šumadijski", ar: "سوماديجسكي" },
      { shortCode: "21", en: "Toplički", ar: "بوبليكي" },
      { shortCode: "15", en: "Zaječarski", ar: "زاجيكارسكي" },
      { shortCode: "05", en: "Zapadnobački", ar: "زابادنوباكي" },
      { shortCode: "16", en: "Zlatiborski", ar: "زلاتيبورسكي" },
    ],
  },
  {
    id: 690,
    code: "sc",
    codeLng: "syc",
    en: "Seychelles",
    ar: "سيشل",
    dial: "+248",
    cities: [
      { shortCode: "01", en: "Anse aux Pins", ar: "انسي اوكس بينز" },
      { shortCode: "02", en: "Anse Boileau", ar: "انسي بويليو" },
      { shortCode: "03", en: "Anse Etoile", ar: "انسي ايتولي" },
      { shortCode: "05", en: "Anse Royale", ar: "انسي رويال" },
      { shortCode: "04", en: "Anu Cap", ar: "انو كاب" },
      { shortCode: "06", en: "Baie Lazare", ar: "باي لازاري" },
      { shortCode: "07", en: "Baie Sainte Anne", ar: "باي القديسه اني" },
      { shortCode: "08", en: "Beau Vallon", ar: "بيو فالون" },
      { shortCode: "09", en: "Bel Air", ar: "بيل اير" },
      { shortCode: "10", en: "Bel Ombre", ar: "بيل اومبري" },
      { shortCode: "11", en: "Cascade", ar: "كاسكادي" },
      { shortCode: "12", en: "Glacis", ar: "جلاسيس" },
      { shortCode: "13", en: "Grand'Anse Mahe", ar: "جراند انسي ماهي" },
      {
        shortCode: "14",
        en: "Grand'Anse Praslin",
        ar: "جراند انسي براسلين",
      },
      { shortCode: "15", en: "La Digue", ar: "لا ديجوي" },
      {
        shortCode: "16",
        en: "La Riviere Anglaise",
        ar: "لا ريفيري انجلايسي",
      },
      { shortCode: "24", en: "Les Mamelles", ar: "ليس ماميلس" },
      { shortCode: "17", en: "Mont Buxton", ar: "مونت بوكستون" },
      { shortCode: "18", en: "Mont Fleuri", ar: "مونت فلويري" },
      { shortCode: "19", en: "Plaisance", ar: "بلايسانسي" },
      { shortCode: "20", en: "Pointe La Rue", ar: "بوينتي لا رو" },
      { shortCode: "21", en: "Port Glaud", ar: "بورت جلاود" },
      { shortCode: "25", en: "Roche Caiman", ar: "روشي كايمان" },
      { shortCode: "22", en: "Saint Louis", ar: "القديس لويس" },
      { shortCode: "23", en: "Takamaka", ar: "تاكاماكا" },
    ],
  },
  {
    id: 694,
    code: "sl",
    codeLng: "sle",
    en: "Sierra Leone",
    ar: "سيراليون",
    dial: "+232",
    cities: [
      { shortCode: "E", en: "Eastern", ar: "الشرقيه" },
      { shortCode: "N", en: "Northern", ar: "الشماليه" },
      { shortCode: "S", en: "Southern", ar: "الجنوبيه" },
      { shortCode: "W", en: "Western", ar: "الغربيه" },
    ],
  },
  {
    id: 702,
    code: "sg",
    codeLng: "sgp",
    en: "Singapore",
    ar: "سنغافورة",
    dial: "+65",
    cities: [
      {
        shortCode: "01",
        en: "Central Singapore",
        ar: "منتصف سينجابور",
      },
      { shortCode: "02", en: "North East", ar: "شمال شرق" },
      { shortCode: "03", en: "North West", ar: "شمال غرب" },
      { shortCode: "04", en: "South East", ar: "جنوب شرق" },
      { shortCode: "05", en: "South West", ar: "جنوب غرب" },
    ],
  },
  {
    id: 534,
    code: "sx",
    codeLng: "sxm",
    en: "Sint Maarten (Dutch part)",
    ar: "سينت مارتن",
    dial: "+599",
    cities: [{ en: "Sint Maarten", ar: "سينت مارتين" }],
  },
  {
    id: 703,
    code: "sk",
    codeLng: "svk",
    en: "Slovakia",
    ar: "سلوفاكيا",
    dial: "+421",
    cities: [
      { shortCode: "BC", en: "Banskobystricky", ar: "بانسكوبيستريكي" },
      { shortCode: "BL", en: "Bratislavsky", ar: "براتيسلافيسكي" },
      { shortCode: "KI", en: "Kosicky", ar: "كوسيكي" },
      { shortCode: "NI", en: "Nitriansky", ar: "نيتريانسكي" },
      { shortCode: "PV", en: "Presovsky", ar: "بريسوفسكي" },
      { shortCode: "TC", en: "Trenciansky", ar: "ترينكيانسكي" },
      { shortCode: "TA", en: "Trnavsky", ar: "ترنافسكي" },
      { shortCode: "ZI", en: "Zilinsky", ar: "زيلينسكي" },
    ],
  },
  {
    id: 705,
    code: "si",
    codeLng: "svn",
    en: "Slovenia",
    ar: "سلوفينيا",
    dial: "+386",
    cities: [
      { shortCode: "001", en: "Ajdovscina", ar: "اجدوفسكينا" },
      { shortCode: "195", en: "Apace", ar: "اباسي" },
      { shortCode: "002", en: "Beltinci", ar: "بيلتينكي" },
      { shortCode: "148", en: "Benedikt", ar: "بينيديكت" },
      {
        shortCode: "149",
        en: "Bistrica ob Sotli",
        ar: "بيستريكا اوب سولي",
      },
      { shortCode: "003", en: "Bled", ar: "بليد" },
      { shortCode: "150", en: "Bloke", ar: "بلوك" },
      { shortCode: "004", en: "Bohinj", ar: "بوهينج" },
      { shortCode: "005", en: "Borovnica", ar: "بوروفنيكا" },
      { shortCode: "006", en: "Bovec", ar: "بوفيك" },
      { shortCode: "151", en: "Braslovce", ar: "براسلوفسي" },
      { shortCode: "007", en: "Brda", ar: "بردا" },
      { shortCode: "009", en: "Brezice", ar: "بريزيس" },
      { shortCode: "008", en: "Brezovica", ar: "بريزوفيكا" },
      { shortCode: "152", en: "Cankova", ar: "كانكوفا" },
      { shortCode: "011", en: "Celje", ar: "كيلجي" },
      {
        shortCode: "012",
        en: "Cerklje na Gorenjskem",
        ar: "كيركلجي نا جورينجسكيم",
      },
      { shortCode: "013", en: "Cerknica", ar: "كيركنيكا" },
      { shortCode: "014", en: "Cerkno", ar: "كيركنو" },
      { shortCode: "153", en: "Cerkvenjak", ar: "كيركفينجاك" },
      { shortCode: "196", en: "Cirkulane", ar: "كيركولين" },
      { shortCode: "015", en: "Crensovci", ar: "كرينسوفسي" },
      {
        shortCode: "016",
        en: "Crna na Koroskem",
        ar: "كرنا نا كوروسكيم",
      },
      { shortCode: "017", en: "Crnomelj", ar: "كرنوميلج" },
      { shortCode: "018", en: "Destrnik", ar: "ديسترنيك" },
      { shortCode: "019", en: "Divaca", ar: "ديفاكا" },
      { shortCode: "154", en: "Dobje", ar: "دوبجي" },
      { shortCode: "020", en: "Dobrepolje", ar: "دوبريبولجي" },
      { shortCode: "155", en: "Dobrna", ar: "دوبرنا" },
      {
        shortCode: "021",
        en: "Dobrova-Polhov Gradec",
        ar: "دوبروفا بولهوف جراديك",
      },
      { shortCode: "156", en: "Dobrovnik", ar: "دوبروفنيك" },
      {
        shortCode: "022",
        en: "Dol pri Ljubljani",
        ar: "دول بري لجوبلجاني",
      },
      {
        shortCode: "157",
        en: "Dolenjske Toplice",
        ar: "دولينجسكي توبليس",
      },
      { shortCode: "023", en: "Domzale", ar: "دومزالي" },
      { shortCode: "024", en: "Dornava", ar: "دورنافا" },
      { shortCode: "025", en: "Dravograd", ar: "درافوجرلد" },
      { shortCode: "026", en: "Duplek", ar: "دوبليك" },
      {
        shortCode: "027",
        en: "Gorenja Vas-Poljane",
        ar: "جورينجا فاس بولجان",
      },
      { shortCode: "028", en: "Gorisnica", ar: "جوريسنيكا" },
      { shortCode: "207", en: "Gorje", ar: "جورجي" },
      { shortCode: "029", en: "Gornja Radgona", ar: "جورنجا رادجونا" },
      { shortCode: "030", en: "Gornji Grad", ar: "جورنجي جراد" },
      { shortCode: "031", en: "Gornji Petrovci", ar: "جورنجي بيتروفسي" },
      { shortCode: "158", en: "Grad", ar: "جراد" },
      { shortCode: "032", en: "Grosuplje", ar: "جروسوبلجي" },
      { shortCode: "159", en: "Hajdina", ar: "هاجدينا" },
      { shortCode: "160", en: "Hoce-Slivnica", ar: "هوسي سليفنيكا" },
      { shortCode: "161", en: "Hodos", ar: "هودوس" },
      { shortCode: "162", en: "Horjul", ar: "هورجل" },
      { shortCode: "034", en: "Hrastnik", ar: "هراستنيك" },
      { shortCode: "035", en: "Hrpelje-Kozina", ar: "هربيلجي كوزينا" },
      { shortCode: "036", en: "Idrija", ar: "ايدريجا" },
      { shortCode: "037", en: "Ig", ar: "ايج" },
      {
        shortCode: "038",
        en: "Ilirska Bistrica",
        ar: "ايليرسكا بيستريكا",
      },
      { shortCode: "039", en: "Ivancna Gorica", ar: "ايفانكنا جوريسا" },
      { shortCode: "040s", en: "Izola", ar: "ايزولا" },
      { shortCode: "041", en: "Jesenice", ar: "جيسينيك" },
      { shortCode: "042", en: "Jursinci", ar: "جورسينكي" },
      { shortCode: "043", en: "Kamnik", ar: "كامنيك" },
      { shortCode: "044", en: "Kanal", ar: "كانال" },
      { shortCode: "045", en: "Kidricevo", ar: "كيدريسيفو" },
      { shortCode: "046", en: "Kobarid", ar: "كوباريد" },
      { shortCode: "047", en: "Kobilje", ar: "كوبيلجي" },
      { shortCode: "048", en: "Kocevje", ar: "كوسيفجي" },
      { shortCode: "049", en: "Komen", ar: "كومين" },
      { shortCode: "164", en: "Komenda", ar: "كويندا" },
      { shortCode: "050", en: "Koper", ar: "كوبر" },
      {
        shortCode: "197",
        en: "Kodanjevica na Krki",
        ar: "كودانجيفيسا نا كيركي",
      },
      { shortCode: "165", en: "Kostel", ar: "كوستل" },
      { shortCode: "051", en: "Kozje", ar: "كوزجي" },
      { shortCode: "052", en: "Kranj", ar: "Kranj" },
      { shortCode: "053", en: "Kranjska Gora", ar: "كرانجسكا جورا" },
      { shortCode: "166", en: "Krizevci", ar: "كريزيفكي" },
      { shortCode: "054", en: "Krsko", ar: "كرسكو" },
      { shortCode: "055", en: "Kungota", ar: "كونجوتا" },
      { shortCode: "056", en: "Kuzma", ar: "كوزما" },
      { shortCode: "057", en: "Lasko", ar: "لاسكو" },
      { shortCode: "058", en: "Lenart", ar: "لينارت" },
      { shortCode: "059", en: "Lendava", ar: "ليندافا" },
      { shortCode: "068", en: "Litija", ar: "ليتيجا" },
      { shortCode: "061", en: "Ljubljana", ar: "لجوبلجانا" },
      { shortCode: "062", en: "Ljubno", ar: "لجوبنو" },
      { shortCode: "063", en: "Ljutomer", ar: "لجوتومير" },
      { shortCode: "208", en: "Log-Dragomer", ar: "لوج دراجومير" },
      { shortCode: "064", en: "Logatec", ar: "لوجاتيك" },
      { shortCode: "065", en: "Loska Dolina", ar: "لوسكا دولينا" },
      { shortCode: "066", en: "Loski Potok", ar: "لوسكي بوتوك" },
      {
        shortCode: "167",
        en: "Lovrenc na Pohorju",
        ar: "لوفرينس نا بوهورجو",
      },
      { shortCode: "068", en: "Lukovica", ar: "لوكوفيكا" },
      { shortCode: "067", en: "Luce", ar: "لوسي" },
      { shortCode: "069", en: "Majsperk", ar: "ماجسبيرك" },
      { shortCode: "198", en: "Makole", ar: "ماكولي" },
      { shortCode: "070", en: "Maribor", ar: "ماريبور" },
      { shortCode: "168", en: "Markovci", ar: "كاركوفكي" },
      { shortCode: "071", en: "Medvode", ar: "ميدفودي" },
      { shortCode: "072", en: "Menges", ar: "مينجس" },
      { shortCode: "073", en: "Metlika", ar: "ميتليكا" },
      { shortCode: "074", en: "Mezica", ar: "ميزيكا" },
      {
        shortCode: "169",
        en: "Miklavz na Dravskem Polju",
        ar: "ميكلافز نا درافسكيم بولجو",
      },
      {
        shortCode: "075",
        en: "Miren-Kostanjevica",
        ar: "ميرين كوستانجيفيكا",
      },
      { shortCode: "212", en: "Mirna", ar: "ميرنا" },
      { shortCode: "170", en: "Mirna Pec", ar: "ميرنا بيك" },
      { shortCode: "076", en: "Mislinja", ar: "ميسلينجا" },
      {
        shortCode: "199",
        en: "Mokronog-Trebelno",
        ar: "موكرونوج تريبيلنو",
      },
      { shortCode: "077", en: "Moravce", ar: "مورافكي" },
      {
        shortCode: "078",
        en: "Moravske Toplice",
        ar: "مورافسكي توبليس",
      },
      { shortCode: "079", en: "Mozirje", ar: "موزيرجي" },
      { shortCode: "080", en: "Murska Sobota", ar: "مورسكا سوبوتا" },
      { shortCode: "082", en: "Naklo", ar: "ناكلو" },
      { shortCode: "083", en: "Nazarje", ar: "نازارجي" },
      { shortCode: "084", en: "Nova Gorica", ar: "نوفا جوريكا" },
      { shortCode: "085", en: "Novo Mesto", ar: "نوفو ميستو" },
      { shortCode: "086", en: "Odranci", ar: "اودرانكي" },
      { shortCode: "087", en: "Ormoz", ar: "ارموز" },
      { shortCode: "088", en: "Osilnica", ar: "اوسيلنيكا" },
      { shortCode: "089", en: "Pesnica", ar: "بيسنيكا" },
      { shortCode: "090", en: "Piran", ar: "بيران" },
      { shortCode: "091", en: "Pivka", ar: "بيفكا" },
      { shortCode: "092", en: "Podcetrtek", ar: "بودسيترتيك" },
      { shortCode: "172", en: "Podlehnik", ar: "بودليهنيك" },
      { shortCode: "093", en: "Podvelka", ar: "بودفيلكا" },
      { shortCode: "200", en: "Poljcane", ar: "بولجكاني" },
      { shortCode: "094", en: "Postojna", ar: "بوستوجنا" },
      { shortCode: "174", en: "Prebold", ar: "بريبولد" },
      { shortCode: "095", en: "Preddvor", ar: "بريدفور" },
      { shortCode: "175", en: "Prevalje", ar: "بريفالجي" },
      { shortCode: "096", en: "Ptuj", ar: "بتوج" },
      { shortCode: "098", en: "Race-Fram", ar: "ريس فرام" },
      { shortCode: "099", en: "Radece", ar: "راديسي" },
      { shortCode: "100", en: "Radenci", ar: "رادينسي" },
      { shortCode: "101", en: "Radlje ob Dravi", ar: "رادلجي اوب درافي" },
      { shortCode: "102", en: "Radovljica", ar: "رادوفلجيكا" },
      {
        shortCode: "103",
        en: "Ravne na Koroskem",
        ar: "رافني نا كوروسكيم",
      },
      { shortCode: "176", en: "Razkrizje", ar: "رازكريزجي" },
      {
        shortCode: "209",
        en: "Recica ob Savinji",
        ar: "ريسيكا اوب سافينجي",
      },
      { shortCode: "201", en: "Rence-Vogrsko", ar: "رينسي فوجرسكو" },
      { shortCode: "104", en: "Ribnica", ar: "ريبنيكا" },
      {
        shortCode: "177",
        en: "Ribnica na Poboriu",
        ar: "ريبنيكا نا بوبوريو",
      },
      { shortCode: "106", en: "Rogaska Slatina", ar: "روجاسكا سلاتينا" },
      { shortCode: "105", en: "Rogasovci", ar: "روجاسوفكي" },
      { shortCode: "107", en: "Rogatec", ar: "روجاتيك" },
      { shortCode: "108", en: "Ruse", ar: "روسي" },
      { shortCode: "033", en: "Salovci", ar: "سالوفكي" },
      {
        shortCode: "178",
        en: "Selnica ob Dravi",
        ar: "سيلنيكي اوب درافي",
      },
      { shortCode: "109", en: "Semic", ar: "سيميك" },
      {
        shortCode: "183",
        en: "Sempeter-Vrtojba",
        ar: "سيمبيتر فرتوجبا",
      },
      { shortCode: "117", en: "Sencur", ar: "سينكور" },
      { shortCode: "118", en: "Sentilj", ar: "سينتيلجي" },
      { shortCode: "119", en: "Sentjernej", ar: "سينتجيرنيج" },
      { shortCode: "120", en: "Sentjur", ar: "سينتجور" },
      { shortCode: "211", en: "Sentrupert", ar: "سينتروبيرت" },
      { shortCode: "110", en: "Sevnica", ar: "سيفنيكا" },
      { shortCode: "111", en: "Sezana", ar: "سيزانا" },
      { shortCode: "121", en: "Skocjan", ar: "سكوسجان" },
      { shortCode: "122", en: "Skofja Loka", ar: "سكوفجا لوكا" },
      { shortCode: "123", en: "Skofljica", ar: "سكوفليجيكا" },
      { shortCode: "112", en: "Slovenj Gradec", ar: "سلوفينج جراديك" },
      {
        shortCode: "113",
        en: "Slovenska Bistrica",
        ar: "سلوفيسكا بيستريكا",
      },
      {
        shortCode: "114",
        en: "Slovenske Konjice",
        ar: "سلوفينسكي كونجيس",
      },
      {
        shortCode: "124",
        en: "Smarje pri elsah",
        ar: "سمارجي بري الساه",
      },
      {
        shortCode: "206",
        en: "Smarjeske Toplice",
        ar: "سمارجسكي توبليس",
      },
      { shortCode: "125", en: "Smartno ob Paki", ar: "سمارتنو اوب باكي" },
      {
        shortCode: "194",
        en: "Smartno pri Litiji",
        ar: "سمارتنو بري ليتيجي",
      },
      { shortCode: "179", en: "Sodrazica", ar: "سودرازيكا" },
      { shortCode: "180", en: "Solcava", ar: "سولكاف" },
      { shortCode: "126", en: "Sostanj", ar: "اسوستانج" },
      {
        shortCode: "202",
        en: "Sredisce ob Dravi",
        ar: "سريديسي اوب درافي",
      },
      { shortCode: "115", en: "Starse", ar: "ستارسي" },
      { shortCode: "127", en: "Store", ar: "ستور" },
      { shortCode: "203", en: "Straza", ar: "سترازا" },
      { shortCode: "181", en: "Sveta Ana", ar: "سفيتا انا" },
      {
        shortCode: "204",
        en: "Sveta Trojica v Slovenskih Goricah",
        ar: " سفيتا تروجيكا و سلوفينسكيه جوريكاه",
      },
      {
        shortCode: "182",
        en: "Sveta Andraz v Slovenskih Goricah",
        ar: "سفيتا اندراز  و سلوفينسكيه جوريكاه",
      },
      { shortCode: "116", en: "Sveti Jurij", ar: "سفيتي جوريج" },
      {
        shortCode: "210",
        en: "Sveti Jurij v Slovenskih Goricah",
        ar: "سفيتا جوريج و سلوفينسكيه جوريكاه",
      },
      { shortCode: "205", en: "Sveti Tomaz", ar: "سفيتي توماز" },
      { shortCode: "184", en: "Tabor", ar: "تابور" },
      { shortCode: "128", en: "Tisina", ar: "تيسينا" },
      { shortCode: "128", en: "Tolmin", ar: "تولمين" },
      { shortCode: "129", en: "Trbovlje", ar: "تربوفلجي" },
      { shortCode: "130", en: "Trebnje", ar: "تريبنجي" },
      { shortCode: "185", en: "Trnovska Vas", ar: "ترنوفسكا فاس" },
      { shortCode: "186", en: "Trzin", ar: "ترزين" },
      { shortCode: "131", en: "Trzic", ar: "ترزيك" },
      { shortCode: "132", en: "Turnisce", ar: "تورنيسي" },
      { shortCode: "133", en: "Velenje", ar: "فيلينجي" },
      { shortCode: "187", en: "Velika Polana", ar: "فيليكا بولانا" },
      { shortCode: "134", en: "Velike Lasce", ar: "فيليكي لاسي" },
      { shortCode: "188", en: "Verzej", ar: "فيرزيج" },
      { shortCode: "135", en: "Videm", ar: "فيديم" },
      { shortCode: "136", en: "Vipava", ar: "فيبافا" },
      { shortCode: "137", en: "Vitanje", ar: "فيتانجي" },
      { shortCode: "138", en: "Vodice", ar: "فوديس" },
      { shortCode: "139", en: "Vojnik", ar: "فوجنيك" },
      { shortCode: "189", en: "Vransko", ar: "فرانسكو" },
      { shortCode: "140", en: "Vrhnika", ar: "فرهنيكا" },
      { shortCode: "141", en: "Vuzenica", ar: "فوزينيكا" },
      { shortCode: "142", en: "Zagorje ob Savi", ar: "زابورجي و سافي" },
      { shortCode: "143", en: "Zavrc", ar: "زافرك" },
      { shortCode: "144", en: "Zrece", ar: "زريكي" },
      { shortCode: "190", en: "Zalec", ar: "زاليك" },
      { shortCode: "146", en: "Zelezniki", ar: "زيليزنيكي" },
      { shortCode: "191", en: "Zetale", ar: "زيتالي" },
      { shortCode: "147", en: "Ziri", ar: "زيري" },
      { shortCode: "192", en: "Zirovnica", ar: "زيروفنيكا" },
      { shortCode: "193", en: "Zuzemberk", ar: "زوزيمبيرك" },
    ],
  },
  {
    id: 90,
    code: "sb",
    codeLng: "slb",
    en: "Solomon Islands",
    ar: "جزر سليمان",
    dial: "+677",
    cities: [
      { shortCode: "CE", en: "Central", ar: "سنترال" },
      { shortCode: "CH", en: "Choiseul", ar: "شويسيول" },
      { shortCode: "GU", en: "Guadalcanal", ar: "جواداكانال" },
      { shortCode: "CT", en: "Honiara", ar: "هونيارا" },
      { shortCode: "IS", en: "Isabel", ar: "ايزابيل" },
      { shortCode: "MK", en: "Makira-Ulawa", ar: "ماكيرا اولاوا" },
      { shortCode: "ML", en: "Malaita", ar: "مالايتا" },
      {
        shortCode: "RB",
        en: "Rennell and Bellona",
        ar: "رينيل و بالونا",
      },
      { shortCode: "TE", en: "Temotu", ar: "تيموتو" },
      { shortCode: "WE", en: "Western", ar: "الغربيه" },
    ],
  },
  {
    id: 706,
    code: "so",
    codeLng: "som",
    en: "Somalia",
    ar: "الصومال",
    dial: "+252",
    cities: [
      { shortCode: "AW", en: "Awdal", ar: "اودال" },
      { shortCode: "BK", en: "Bakool", ar: "باكول" },
      { shortCode: "BN", en: "Banaadir", ar: "بانادير" },
      { shortCode: "BR", en: "Bari", ar: "باري" },
      { shortCode: "BY", en: "Bay", ar: "باي" },
      { shortCode: "GA", en: "Galguduud", ar: "جالجودود" },
      { shortCode: "GE", en: "Gedo", ar: "جيدو" },
      { shortCode: "HI", en: "Hiiraan", ar: "هيران" },
      { shortCode: "JD", en: "Jubbada Dhexe", ar: "جوبادا ديكسي" },
      { shortCode: "JH", en: "Jubbada Hoose", ar: "جوبادا هوز" },
      { shortCode: "MU", en: "Mudug", ar: "مودوج" },
      { shortCode: "NU", en: "Nugaal", ar: "نوجال" },
      { shortCode: "SA", en: "Sanaag", ar: "ساناج" },
      {
        shortCode: "SD",
        en: "Shabeellaha Dhexe",
        ar: "شابيلا ديكسي",
      },
      {
        shortCode: "SH",
        en: "Shabeellaha Hoose",
        ar: "شابيلاها هوز",
      },
      { shortCode: "SO", en: "Sool", ar: "سول" },
      { shortCode: "TO", en: "Togdheer", ar: "تودير" },
      { shortCode: "WO", en: "Woqooyi Galbeed", ar: "وكويي جالبيد" },
    ],
  },
  {
    id: 710,
    code: "za",
    codeLng: "zaf",
    en: "South Africa",
    ar: "جنوب أفريقيا",
    dial: "+27",
    cities: [
      { shortCode: "EC", en: "Eastern Cape", ar: "كابي الشرقيه" },
      { shortCode: "FS", en: "Free State", ar: "الولايه المحرره" },
      { shortCode: "GT", en: "Gauteng", ar: "جاوتينج" },
      { shortCode: "NL", en: "KwaZulu-Natal", ar: "كوازولو ناتال" },
      { shortCode: "LP", en: "Limpopo", ar: "ليمبوبو" },
      { shortCode: "MP", en: "Mpumalanga", ar: "مبومالانجا" },
      { shortCode: "NC", en: "Northern Cape", ar: "كابي الشماليه" },
      { shortCode: "NW", en: "North West", ar: "الشمال غرب" },
      { shortCode: "WC", en: "Western Cape", ar: "كابي الغربيه" },
    ],
  },
  {
    id: 239,
    code: "gs",
    codeLng: "sgs",
    en: "South Georgia and the South Sandwich Islands",
    ar: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
    dial: "+500",
    cities: [
      { en: "Bird Island", ar: "جزيره الطائر" },
      { en: "Bristol Island", ar: "جزيره بريستول" },
      { en: "Clerke Rocks", ar: "صخور كلاركي" },
      { en: "Montagu Island", ar: "جزيره منتاجو" },
      { en: "Saunders Island", ar: "جزيره ساونديرز" },
      { en: "South Georgia", ar: "جنوب جورجيا" },
      { en: "Southern Thule", ar: "تولي الجنوبيه" },
      { en: "Traversay Islands", ar: "جزيره ترافيرسي " },
    ],
  },
  {
    id: 728,
    code: "ss",
    codeLng: "ssd",
    en: "South Sudan",
    ar: "جنوب السودان",
    dial: "+211",
    cities: [
      {
        shortCode: "CE",
        en: "Central Equatoria",
        ar: "ايكواتوريا المنتصفه",
      },
      {
        shortCode: "EE",
        en: "Eastern Equatoria",
        ar: "ايكواتوريا الشرقيه",
      },
      { shortCode: "JG", en: "Jonglei", ar: "جونجلي" },
      { shortCode: "LK", en: "Lakes", ar: "البحيرات" },
      {
        shortCode: "BN",
        en: "Northern Bahr el Ghazal",
        ar: "بحر الغزال الشمالي",
      },
      { shortCode: "UY", en: "Unity", ar: "الاتحاد" },
      { shortCode: "NU", en: "Upper Nile", ar: "النهر العلوي" },
      { shortCode: "WR", en: "Warrap", ar: "واراب" },
      {
        shortCode: "BW",
        en: "Western Bahr el Ghazal",
        ar: "بحر الغزال الغربي",
      },
      {
        shortCode: "EW",
        en: "Western Equatoria",
        ar: "ايكواتوريا الغربيه",
      },
    ],
  },
  {
    id: 724,
    code: "es",
    codeLng: "esp",
    en: "Spain",
    ar: "إسبانيا",
    dial: "+34",
    cities: [
      { shortCode: "AB", en: "Albacete", ar: "الباكيتي" },
      { shortCode: "A", en: "Alicante", ar: "اليكانتي" },
      { shortCode: "AN", en: "Almería", ar: "الميريا" },
      { shortCode: "VI", en: "Araba/Álava", ar: "ارابا" },
      { shortCode: "O", en: "Asturias", ar: "استورياس" },
      { shortCode: "AV", en: "Ávila", ar: "افيلا" },
      { shortCode: "BA", en: "Badajoz", ar: "باداجوز" },
      { shortCode: "B", en: "Barcelona", ar: "برشلونه" },
      { shortCode: "BI", en: "Bizkaia", ar: "بيزكايا" },
      { shortCode: "BU", en: "Burgos", ar: "بورجوس" },
      { shortCode: "CC", en: "Cáceres", ar: "كاكيريس" },
      { shortCode: "CA", en: "Cádiz", ar: "كاديز" },
      { shortCode: "S", en: "Cantabria", ar: "كانتابريا" },
      {
        shortCode: "CS",
        en: "Castellón/Castelló",
        ar: "كاستيلو",
      },
      { shortCode: "CE", en: "Ceuta", ar: "كويتا" },
      { shortCode: "CR", en: "Ciudad Real", ar: "ريال سويداد" },
      { shortCode: "CO", en: "Córdoba", ar: "كوردوبا" },
      { shortCode: "C", en: "A Coruña", ar: "كورونا" },
      { shortCode: "CU", en: "Cuenca", ar: "كويكا" },
      { shortCode: "SS", en: "Gipuzkoa", ar: "جيبوزكوا" },
      { shortCode: "GI", en: "Girona", ar: "جيرونا" },
      { shortCode: "GR", en: "Granada", ar: "جراندا" },
      { shortCode: "GU", en: "Guadalajara", ar: "جوادالاجارا" },
      { shortCode: "H", en: "Huelva", ar: "هويلفا" },
      { shortCode: "HU", en: "Huesca", ar: "هويسكا" },
      { shortCode: "PM", en: "Illes Balears", ar: "ايليس باليرز" },
      { shortCode: "J", en: "Jaén", ar: "جاين" },
      { shortCode: "LE", en: "León", ar: "ليون" },
      { shortCode: "L", en: "Lleida", ar: "ليدا" },
      { shortCode: "LU", en: "Lugo", ar: "لوجو" },
      { shortCode: "M", en: "Madrid", ar: "مادريد" },
      { shortCode: "MA", en: "Málaga", ar: "مالاجا" },
      { shortCode: "ML", en: "Melilla", ar: "ميليلا" },
      { shortCode: "MU", en: "Murcia", ar: "موركيا" },
      { shortCode: "NA", en: "Navarra/Nafarroa", ar: "نافارا" },
      { shortCode: "OR", en: "Ourense", ar: "اورينس" },
      { shortCode: "P", en: "Palencia", ar: "بالينسيا" },
      { shortCode: "GC", en: "Las Palmas", ar: "لاس بالماس" },
      { shortCode: "PO", en: "Pontevedra", ar: "بونتيفيدرا" },
      { shortCode: "LO", en: "La Rioja", ar: "لا ريوجا" },
      { shortCode: "SA", en: "Salamanca", ar: "سالامكانكا" },
      {
        shortCode: "TF",
        en: "Santa Cruz de Tenerife",
        ar: "سانتا كروز دي تينيريفي",
      },
      { shortCode: "SG", en: "Segovia", ar: "سيجوفيا" },
      { shortCode: "SE", en: "Sevilla", ar: "ايشبيليا" },
      { shortCode: "SO", en: "Soria", ar: "سوريا" },
      { shortCode: "T", en: "Tarragona", ar: "تاراجونا" },
      { shortCode: "TE", en: "Teruel", ar: "تيريول" },
      { shortCode: "TO", en: "Toledo", ar: "توليدو" },
      {
        shortCode: "V",
        en: "Valencia/València",
        ar: "فالينسيا",
      },
      { shortCode: "VA", en: "Valladolid", ar: "فالادوليد" },
      { shortCode: "ZA", en: "Zamora", ar: "زامورا" },
      { shortCode: "Z", en: "Zaragoza", ar: "زاراجوزا" },
    ],
  },
  {
    id: 144,
    code: "lk",
    codeLng: "lka",
    en: "Sri Lanka",
    ar: "سريلانكا",
    dial: "+94",
    cities: [
      { shortCode: "1", en: "Basnahira", ar: "باشناهيرا" },
      { shortCode: "3", en: "Dakunu", ar: "داكونو" },
      { shortCode: "2", en: "Madhyama", ar: "مادياما" },
      { shortCode: "5", en: "Naegenahira", ar: "نايجيناهيرا" },
      { shortCode: "9", en: "Sabaragamuwa", ar: "ساباراجاموا" },
      { shortCode: "4", en: "Uturu", ar: "اوتورو" },
      { shortCode: "7", en: "Uturumaeda", ar: "اوتورومايدا" },
      { shortCode: "6", en: "Vayamba", ar: "فايامبا" },
      { shortCode: "8", en: "Uva", ar: "اوفا" },
    ],
  },
  {
    id: 729,
    code: "sd",
    codeLng: "sdn",
    en: "Sudan",
    ar: "السودان",
    dial: "+249",
    cities: [
      { shortCode: "RS", en: "Al Bahr al Ahmar", ar: "البحر الاحمر" },
      { shortCode: "GZ", en: "Al Jazirah", ar: "الجزيره" },
      { shortCode: "KH", en: "Al Khartum", ar: "الخرطوم" },
      { shortCode: "GD", en: "Al Qadarif", ar: "القداريف" },
      { shortCode: "NW", en: "An Nil al Abyad", ar: "النيل الابيض" },
      { shortCode: "NB", en: "An Nil al Azraq", ar: "النيل الازرق" },
      { shortCode: "NO", en: "Ash Shamaliyah", ar: "الشماليه" },
      { shortCode: "DW", en: "Gharb Darfur", ar: "غرب درفور" },
      { shortCode: "GK", en: "Gharb Kurdufan", ar: "غرب كوردوفان" },
      { shortCode: "DS", en: "Janub Darfur", ar: "جنوب دورفان" },
      { shortCode: "KS", en: "Janub Kurdufan", ar: "جنوب كوردوفان" },
      { shortCode: "KA", en: "Kassala", ar: "كاسالا" },
      { shortCode: "NR", en: "Nahr an Nil", ar: "نهر النيل" },
      { shortCode: "DN", en: "Shamal Darfur", ar: "شمال دارفور" },
      { shortCode: "DE", en: "Sharq Darfur", ar: "شرق دارفور" },
      { shortCode: "KN", en: "Shiamal Kurdufan", ar: "شمال كوردوفان" },
      { shortCode: "SI", en: "Sinnar", ar: "سينار" },
      {
        shortCode: "DC",
        en: "Wasat Darfur Zalinjay",
        ar: "وسط درفور زالنجاي",
      },
    ],
  },
  {
    id: 740,
    code: "sr",
    codeLng: "sur",
    en: "Suriname",
    ar: "سورينام",
    dial: "+597",
    cities: [
      { shortCode: "BR", en: "Brokopondo", ar: "بركوبوندو" },
      { shortCode: "CM", en: "Commewijne", ar: "كوميويجني" },
      { shortCode: "CR", en: "Coronie", ar: "كوروني" },
      { shortCode: "MA", en: "Marowijne", ar: "مارويجني" },
      { shortCode: "NI", en: "Nickerie", ar: "نيكيري" },
      { shortCode: "PR", en: "Para", ar: "بارا" },
      { shortCode: "PM", en: "Paramaribo", ar: "باراماريبو" },
      { shortCode: "SA", en: "Saramacca", ar: "ساراماكا" },
      { shortCode: "SI", en: "Sipaliwini", ar: "سيباليويني" },
      { shortCode: "WA", en: "Wanica", ar: "وانيكا" },
    ],
  },
  {
    id: 752,
    code: "se",
    codeLng: "swe",
    en: "Sweden",
    ar: "السويد",
    dial: "+46",
    cities: [
      { shortCode: "K", en: "Blekinge", ar: "بليكينجي" },
      { shortCode: "W", en: "Dalarna", ar: "دالارنا" },
      { shortCode: "X", en: "Gävleborg", ar: "جافليبورج" },
      { shortCode: "I", en: "Gotland", ar: "جوتلاند" },
      { shortCode: "N", en: "Halland", ar: "هالاند" },
      { shortCode: "Z", en: "Jämtland", ar: "جامتلاند" },
      { shortCode: "F", en: "Jönköping", ar: "جونكوبينج" },
      { shortCode: "H", en: "Kalmar", ar: "كالمار" },
      { shortCode: "G", en: "Kronoberg", ar: "كرونوبيرج" },
      { shortCode: "BD", en: "Norrbotten", ar: "نوربوتين" },
      { shortCode: "T", en: "Örebro", ar: "اوريبو" },
      { shortCode: "E", en: "Östergötland", ar: "اوسترجوت لاند" },
      { shortCode: "M", en: "Skåne", ar: "سكاني" },
      { shortCode: "D", en: "Södermanland", ar: "سودرمان لاند" },
      { shortCode: "AB", en: "Stockholm", ar: "ستوكهولم" },
      { shortCode: "C", en: "Uppsala", ar: "اوبسالا" },
      { shortCode: "S", en: "Värmland", ar: "فارم لاند" },
      { shortCode: "AC", en: "Västerbotten", ar: "فاستربوتن" },
      { shortCode: "Y", en: "Västernorrland", ar: "فاسترنورلاند" },
      { shortCode: "U", en: "Västmanland", ar: "فاستمان لاند" },
      { shortCode: "O", en: "Västra Götaland", ar: "فاستراجورالاند" },
    ],
  },
  {
    id: 756,
    code: "ch",
    codeLng: "che",
    en: "Switzerland",
    ar: "سويسرا",
    dial: "+41",
    cities: [
      { shortCode: "AG", en: "Aargau", ar: "ارجاو" },
      {
        shortCode: "AR",
        en: "Appenzell Ausserrhoden",
        ar: "ابينزيل اوسرهودين",
      },
      {
        shortCode: "AI",
        en: "Appenzell Innerhoden",
        ar: "ابينريل انيرهودين",
      },
      { shortCode: "BL", en: "Basel-Landschaft", ar: "باسل لاندشافت" },
      { shortCode: "BS", en: "Basel-Stadt", ar: "باسل ستادت" },
      { shortCode: "BE", en: "Bern", ar: "بيرن" },
      { shortCode: "FR", en: "Fribourg", ar: "فريبورج" },
      { shortCode: "GE", en: "Genève", ar: "جينيفي" },
      { shortCode: "GL", en: "Glarus", ar: "جلاروس" },
      { shortCode: "GR", en: "Graubünden", ar: "جراوبوندن" },
      { shortCode: "JU", en: "Jura", ar: "جورا" },
      { shortCode: "LU", en: "Luzern", ar: "لوزيرن" },
      { shortCode: "NE", en: "Neuchâtel", ar: "نيوشاتل" },
      { shortCode: "NW", en: "Nidwalden", ar: "نيدوالدين" },
      { shortCode: "OW", en: "Obwalden", ar: "اوبوالدن" },
      { shortCode: "SG", en: "Sankt Gallen", ar: "سانكت جالن" },
      { shortCode: "SH", en: "Schaffhausen", ar: "شافهاوسن" },
      { shortCode: "SZ", en: "Schwyz", ar: "شويز" },
      { shortCode: "SO", en: "Solothurn", ar: "سولوهورن" },
      { shortCode: "TG", en: "Thurgau", ar: "سورجاو" },
      { shortCode: "TI", en: "Ticino", ar: "تيكينو" },
      { shortCode: "UR", en: "Uri", ar: "اوري" },
      { shortCode: "VS", en: "Valais", ar: "فالايس" },
      { shortCode: "VD", en: "Vaud", ar: "فاود" },
      { shortCode: "ZG", en: "Zug", ar: "زوج" },
      { shortCode: "ZH", en: "Zürich", ar: "زوريك" },
    ],
  },
  {
    id: 760,
    code: "sy",
    codeLng: "syr",
    en: "Syrian Arab Republic",
    ar: "سوريا",
    dial: "+963",
    cities: [
      { shortCode: "HA", en: "Al Hasakah", ar: "الهسكاه" },
      { shortCode: "LA", en: "Al Ladhiqiyah", ar: "اللاديكايا" },
      { shortCode: "QU", en: "Al Qunaytirah", ar: "القناطريه" },
      { shortCode: "RA", en: "Ar Raqqah", ar: "الرقه" },
      { shortCode: "SU", en: "As Suwayda'", ar: "السويده" },
      { shortCode: "DR", en: "Dar'a", ar: "دارئه" },
      { shortCode: "DY", en: "Dayr az Zawr", ar: "دار الزور" },
      { shortCode: "DI", en: "Dimashq", ar: "دمشق" },
      { shortCode: "HL", en: "Halab", ar: "حلب" },
      { shortCode: "HM", en: "Hamah", ar: "حماه" },
      { shortCode: "HI", en: "Hims", ar: "همس" },
      { shortCode: "ID", en: "Idlib", ar: "ايدليب" },
      { shortCode: "RD", en: "Rif Dimashq", ar: "ريف دمشق" },
      { shortCode: "TA", en: "Tartus", ar: "تارتوس" },
    ],
  },
  {
    id: 158,
    code: "tw",
    codeLng: "twn",
    en: "Taiwan, Province of China",
    ar: "تايوان",
    dial: "+886",
    cities: [
      { shortCode: "CHA", en: "Chang-hua", ar: "شانج هوا" },
      { shortCode: "CYQ", en: "Chia-i", ar: "شيا" },
      { shortCode: "HSQ", en: "Hsin-chu", ar: "هسين شو" },
      { shortCode: "HUA", en: "Hua-lien", ar: "هوا لين" },
      { shortCode: "KHH", en: "Kao-hsiung", ar: "كاو هسيونج" },
      { shortCode: "KEE", en: "Keelung", ar: "كيلونج" },
      { shortCode: "KIN", en: "Kinmen", ar: "كينمين" },
      { shortCode: "LIE", en: "Lienchiang", ar: "لينشيانج" },
      { shortCode: "MIA", en: "Miao-li", ar: "مياو لي" },
      { shortCode: "NAN", en: "Nan-t'ou", ar: "نان تو" },
      { shortCode: "PEN", en: "P'eng-hu", ar: "بينج هو" },
      { shortCode: "NWT", en: "New Taipei", ar: "نيو تايبي" },
      { shortCode: "PIF", en: "P'ing-chung", ar: "بينج شونج" },
      { shortCode: "TXG", en: "T'ai-chung", ar: "تاي شونج" },
      { shortCode: "TNN", en: "T'ai-nan", ar: "تاي نان" },
      { shortCode: "TPE", en: "T'ai-pei", ar: "تاي بي" },
      { shortCode: "TTT", en: "T'ai-tung", ar: "تاي تونج" },
      { shortCode: "TAO", en: "T'ao-yuan", ar: "تاو وان" },
      { shortCode: "ILA", en: "Yi-lan", ar: "يي لان" },
      { shortCode: "YUN", en: "Yun-lin", ar: "يون لين" },
    ],
  },
  {
    id: 762,
    code: "tj",
    codeLng: "tjk",
    en: "Tajikistan",
    ar: "طاجيكستان",
    dial: "+992",
    cities: [
      { shortCode: "DU", en: "Dushanbe", ar: "دوشانبي" },
      {
        shortCode: "GB",
        en: "Kŭhistoni Badakhshon",
        ar: "كوهيستوني باداخشون",
      },
      { shortCode: "KT", en: "Khatlon", ar: "خاتلون" },
      { shortCode: "SU", en: "Sughd", ar: "سغد" },
    ],
  },
  {
    id: 834,
    code: "tz",
    codeLng: "tza",
    en: "Tanzania, United Republic of",
    ar: "تنزانيا",
    dial: "+255",
    cities: [
      { shortCode: "01", en: "Arusha", ar: "اروشا" },
      { shortCode: "19", en: "Coast", ar: "الساحل" },
      { shortCode: "02", en: "Dar es Salaam", ar: "دار السلام" },
      { shortCode: "03", en: "Dodoma", ar: "دودوما" },
      { shortCode: "04", en: "Iringa", ar: "ايرينجا" },
      { shortCode: "05", en: "Kagera", ar: "كاجيرا" },
      { shortCode: "08", en: "Kigoma", ar: "كيجوما" },
      { shortCode: "09", en: "Kilimanjaro", ar: "كيليمانجارو" },
      { shortCode: "12", en: "Lindi", ar: "ليندي" },
      { shortCode: "26", en: "Manyara", ar: "مانيارا" },
      { shortCode: "13", en: "Mara", ar: "مارا" },
      { shortCode: "14", en: "Mbeya", ar: "مبيا" },
      { shortCode: "16", en: "Morogoro", ar: "موروجورو" },
      { shortCode: "17", en: "Mtwara", ar: "متوارا" },
      { shortCode: "18", en: "Mwanza", ar: "موانزا" },
      { shortCode: "06", en: "Pemba North", ar: "شمال بيمبا" },
      { shortCode: "10", en: "Pemba South", ar: "جنوب بيمبا" },
      { shortCode: "20", en: "Rukwa", ar: "روكوا" },
      { shortCode: "21", en: "Ruvuma", ar: "روفوما" },
      { shortCode: "22", en: "Shinyanga", ar: "شينيانجا" },
      { shortCode: "23", en: "Singida", ar: "سينجيدا" },
      { shortCode: "24", en: "Tabora", ar: "تابورا" },
      { shortCode: "25", en: "Tanga", ar: "تانجا" },
      { shortCode: "07", en: "Zanzibar North", ar: "شمال زانزيبار" },
      {
        shortCode: "11",
        en: "Zanzibar Central/South",
        ar: "جنوب زانزيبار",
      },
      {
        shortCode: "15",
        en: "Zanzibar Urban/West",
        ar: "غرب زانزيبارق",
      },
    ],
  },
  {
    id: 764,
    code: "th",
    codeLng: "tha",
    en: "Thailand",
    ar: "تايلاند",
    dial: "+66",
    cities: [
      { shortCode: "37", en: "Amnat Charoen", ar: "امنات كاروين" },
      { shortCode: "15", en: "Ang Thong", ar: "انج ثونج" },
      { shortCode: "38", en: "Bueng Kan", ar: "بوينج كان" },
      { shortCode: "31", en: "Buri Ram", ar: "بوري رام" },
      { shortCode: "24", en: "Chachoengsao", ar: "كاكوينجساو" },
      { shortCode: "18", en: "Chai Nat", ar: "شاي نات" },
      { shortCode: "36", en: "Chaiyaphum", ar: "شايافوم" },
      { shortCode: "22", en: "Chanthaburi", ar: "كانتابوري" },
      { shortCode: "50", en: "Chiang Mai", ar: "كيانج ماي" },
      { shortCode: "57", en: "Chiang Rai", ar: "كيانج راي" },
      { shortCode: "20", en: "Chon Buri", ar: "كون بوري" },
      { shortCode: "86", en: "Chumphon", ar: "كومفون" },
      { shortCode: "46", en: "Kalasin", ar: "كالاسن" },
      { shortCode: "62", en: "Kamphaeng Phet", ar: "كامفاينج فيت" },
      { shortCode: "71", en: "Kanchanaburi", ar: "كانكانابوري" },
      { shortCode: "40", en: "Khon Kaen", ar: "خون كاين" },
      { shortCode: "81", en: "Krabi", ar: "كرابي" },
      {
        shortCode: "10",
        en: "Krung Thep Mahanakhon (Bangkok)",
        ar: "بانجكوك",
      },
      { shortCode: "52", en: "Lampang", ar: "لامبانج" },
      { shortCode: "51", en: "Lamphun", ar: "لامفون" },
      { shortCode: "42", en: "Loei", ar: "لوي" },
      { shortCode: "16", en: "Lop Buri", ar: "لوب بوري" },
      { shortCode: "58", en: "Mae Hong Son", ar: "ماي هونج سون" },
      { shortCode: "44", en: "Maha Sarakham", ar: "مها ساراخام" },
      { shortCode: "49", en: "Mukdahan", ar: "موكداهان" },
      { shortCode: "26", en: "Nakhon Nayok", ar: "ناكون نايوك" },
      { shortCode: "73", en: "Nakhon Phathom", ar: "ناكون فاتوم" },
      { shortCode: "48", en: "Nakhon Phanom", ar: "ناكون فانوم" },
      {
        shortCode: "30",
        en: "Nakhon Ratchasima",
        ar: "ناكون راتشاسيما",
      },
      { shortCode: "60", en: "Nakhon Sawan", ar: "ناكون سوان" },
      {
        shortCode: "80",
        en: "Nakhon Si Thammarat",
        ar: "ناكون سي سامارات",
      },
      { shortCode: "55", en: "Nan", ar: "نان" },
      { shortCode: "96", en: "Narathiwat", ar: "ناراتيوات" },
      { shortCode: "39", en: "Nong Bua Lam Phu", ar: "نونج بوا لام فو" },
      { shortCode: "43", en: "Nong Khai", ar: "نونج كاي" },
      { shortCode: "12", en: "Nonthaburi", ar: "نونتابوري" },
      { shortCode: "13", en: "Pathum Thani", ar: "باتوم تاني" },
      { shortCode: "94", en: "Pattani", ar: "باتاني" },
      { shortCode: "82", en: "Phangnga", ar: "فانجنجا" },
      { shortCode: "93", en: "Phatthalung", ar: "فاتالونج" },
      { shortCode: "56", en: "Phayao", ar: "فاياو" },
      { shortCode: "76", en: "Phetchabun", ar: "فيتشابون" },
      { shortCode: "76", en: "Phetchaburi", ar: "فيتشابوري" },
      { shortCode: "66", en: "Phichit", ar: "فيشيت" },
      { shortCode: "65", en: "Phitsanulok", ar: "فيتسانولوك" },
      {
        shortCode: "14",
        en: "Phra Nakhon Si Ayutthaya",
        ar: "فرا ناكون سي ايوتهايا",
      },
      { shortCode: "54", en: "Phrae", ar: "فراي" },
      { shortCode: "83", en: "Phuket", ar: "فوكيت" },
      { shortCode: "25", en: "Prachin Buri", ar: "براشين بوري" },
      {
        shortCode: "77",
        en: "Prachuap Khiri Khan",
        ar: "براشواب خيري كان",
      },
      { shortCode: "85", en: "Ranong", ar: "رانونج" },
      { shortCode: "70", en: "Ratchaburi", ar: "راتشابوري" },
      { shortCode: "21", en: "Rayong", ar: "رايونج" },
      { shortCode: "45", en: "Roi Et", ar: "روي اي تي" },
      { shortCode: "27", en: "Sa Kaeo", ar: "سا كايو" },
      { shortCode: "47", en: "Sakon Nakhon", ar: "ساكون ناكون" },
      { shortCode: "11", en: "Samut Prakan", ar: "ساموت براكان" },
      { shortCode: "74", en: "Samut Sakhon", ar: "ساموت ساكون" },
      { shortCode: "75", en: "Samut Songkhram", ar: "ساموت سونجكرام" },
      { shortCode: "19", en: "Saraburi", ar: "سارابوري" },
      { shortCode: "91", en: "Satun", ar: "ساتون" },
      { shortCode: "17", en: "Sing Buri", ar: "سينج بوري" },
      { shortCode: "33", en: "Si Sa ket", ar: "سي سا كيت" },
      { shortCode: "90", en: "Songkhla", ar: "سونج خلا" },
      { shortCode: "64", en: "Sukhothai", ar: "سوخوتاي" },
      { shortCode: "72", en: "Suphan Buri", ar: "سوفان بوري" },
      { shortCode: "84", en: "Surat Thani", ar: "سورات تاني" },
      { shortCode: "32", en: "Surin", ar: "سورين" },
      { shortCode: "63", en: "Tak", ar: "تاك" },
      { shortCode: "92", en: "Trang", ar: "ترانج" },
      { shortCode: "23", en: "Trat", ar: "ترات" },
      { shortCode: "34", en: "Ubon Ratchathani", ar: "اوبون راتشاتاني" },
      { shortCode: "41", en: "Udon Thani", ar: "اودون تاني" },
      { shortCode: "61", en: "Uthai Thani", ar: "اوتاي تاني" },
      { shortCode: "53", en: "Uttaradit", ar: "اوتاراديت" },
      { shortCode: "95", en: "Yala", ar: "يالا" },
      { shortCode: "35", en: "Yasothon", ar: "ياسونتون" },
    ],
  },
  {
    id: 626,
    code: "tl",
    codeLng: "tls",
    en: "Timor-Leste",
    ar: "تيمور الشرقية",
    dial: "+670",
    cities: [
      { shortCode: "AL", en: "Aileu", ar: "ايلو" },
      { shortCode: "AN", en: "Ainaro", ar: "اينارو" },
      { shortCode: "BA", en: "Baucau", ar: "باكاو" },
      { shortCode: "BO", en: "Bobonaro", ar: "بوبونارو" },
      { shortCode: "CO", en: "Cova Lima", ar: "كوفا ليما" },
      { shortCode: "DI", en: "Dili", ar: "ديلي" },
      { shortCode: "ER", en: "Ermera", ar: "ايرميرا" },
      { shortCode: "LA", en: "Lautem", ar: "لاوتيم" },
      { shortCode: "LI", en: "Liquica", ar: "ليكويكا" },
      { shortCode: "MT", en: "Manatuto", ar: "ماناتوتو" },
      { shortCode: "MF", en: "Manufahi", ar: "مانوفاهي" },
      { shortCode: "OE", en: "Oecussi", ar: "اويكوسي" },
      { shortCode: "VI", en: "Viqueque", ar: "فيكويكوي" },
    ],
  },
  {
    id: 768,
    code: "tg",
    codeLng: "tgo",
    en: "Togo",
    ar: "توغو",
    dial: "+228",
    cities: [
      { shortCode: "C", en: "Centre", ar: "منتصف" },
      { shortCode: "K", en: "Kara", ar: "كارا" },
      { shortCode: "M", en: "Maritime", ar: "ماريتيمي" },
      { shortCode: "P", en: "Plateaux", ar: "بلاتوكس" },
      { shortCode: "S", en: "Savannes", ar: "سافانيس" },
    ],
  },
  {
    id: 772,
    code: "tk",
    codeLng: "tkl",
    en: "Tokelau",
    ar: "توكيلاو",
    dial: "+690",
    cities: [
      { en: "Atafu", ar: "اتافو" },
      { en: "Fakaofo", ar: "فاكاوفو" },
      { en: "Nukunonu", ar: "نوكونونو" },
    ],
  },
  {
    id: 776,
    code: "to",
    codeLng: "ton",
    en: "Tonga",
    ar: "تونغا",
    dial: "+676",
    cities: [
      { shortCode: "01", en: "'Eua", ar: "'ايوا" },
      { shortCode: "02", en: "Ha'apai", ar: "هاباي" },
      { shortCode: "03", en: "Niuas", ar: "نيواس" },
      { shortCode: "04", en: "Tongatapu", ar: "تونجاتابو" },
      { shortCode: "05", en: "Vava'u", ar: "فافاو" },
    ],
  },
  {
    id: 780,
    code: "tt",
    codeLng: "tto",
    en: "Trinidad and Tobago",
    ar: "ترينيداد وتوباغو",
    dial: "+1 868",
    cities: [
      { shortCode: "ARI", en: "Arima", ar: "اريما" },
      { shortCode: "CHA", en: "Chaguanas", ar: "شانجواناس" },
      {
        shortCode: "CTT",
        en: "Couva-Tabaquite-Talparo",
        ar: "كوافا تاباكويتي تالبارو",
      },
      { shortCode: "DMN", en: "Diefo Martin", ar: "ديفو مارتن" },
      {
        shortCode: "MRC",
        en: "Mayaro-Rio Claro",
        ar: "مايارو ريو كلارو",
      },
      { shortCode: "PED", en: "Penal-Debe", ar: "بينال ديبي" },
      { shortCode: "PTF", en: "Point Fortin", ar: "بوينت فورتن" },
      { shortCode: "POS", en: "Port-of-Spain", ar: "ميناء اسبانيا" },
      { shortCode: "PRT", en: "Princes Town", ar: "بلده المدينه" },
      { shortCode: "SFO", en: "San Fernando", ar: "سان فيرناندو" },
      {
        shortCode: "SJL",
        en: "San Juan-Laventille",
        ar: "سان وان لافينتيلي",
      },
      { shortCode: "SGE", en: "Sangre Grande", ar: "سانجري جراندي" },
      { shortCode: "SIP", en: "Siparia", ar: "سيباريا" },
      { shortCode: "TOB", en: "Tobago", ar: "توباجو" },
      { shortCode: "TUP", en: "Tunapuna-Piarco", ar: "تونابونا بياركو" },
    ],
  },
  {
    id: 788,
    code: "tn",
    codeLng: "tun",
    en: "Tunisia",
    ar: "تونس",
    dial: "+216",
    cities: [
      { shortCode: "12", en: "Ariana", ar: "اريانا" },
      { shortCode: "31", en: "Beja", ar: "بيجا" },
      { shortCode: "13", en: "Ben Arous", ar: "بين اروس" },
      { shortCode: "23", en: "Bizerte", ar: "بيزيرتي" },
      { shortCode: "81", en: "Gabes", ar: "جابيس" },
      { shortCode: "71", en: "Gafsa", ar: "جافسا" },
      { shortCode: "32", en: "Jendouba", ar: "جيندوبا" },
      { shortCode: "41", en: "Kairouan", ar: "كايروان" },
      { shortCode: "42", en: "Kasserine", ar: "كاسرين" },
      { shortCode: "73", en: "Kebili", ar: "كيبيلي" },
      { shortCode: "33", en: "Kef", ar: "كيف" },
      { shortCode: "53", en: "Mahdia", ar: "ماهديا" },
      { shortCode: "82", en: "Medenine", ar: "ميدينين" },
      { shortCode: "52", en: "Monastir", ar: "موناستير" },
      { shortCode: "21", en: "Nabeul", ar: "نابيول" },
      { shortCode: "61", en: "Sfax", ar: "سفاكس" },
      { shortCode: "43", en: "Sidi Bouzid", ar: "سيدي بوزيد" },
      { shortCode: "34", en: "Siliana", ar: "سيليانا" },
      { shortCode: "51", en: "Sousse", ar: "سوسي" },
      { shortCode: "83", en: "Tataouine", ar: "تاتاوين" },
      { shortCode: "72", en: "Tozeur", ar: "توزيور" },
      { shortCode: "11", en: "Tunis", ar: "تونس" },
      { shortCode: "22", en: "Zaghouan", ar: "زاجوان" },
    ],
  },
  {
    id: 792,
    code: "tr",
    codeLng: "tur",
    en: "Turkey",
    ar: "تركيا",
    dial: "+90",
    cities: [
      { shortCode: "01", en: "Adana", ar: "ادانا" },
      { shortCode: "02", en: "Adiyaman", ar: "اديامان" },
      { shortCode: "03", en: "Afyonkarahisar", ar: "افيونكارايسار" },
      { shortCode: "04", en: "Agri", ar: "اجري" },
      { shortCode: "68", en: "Aksaray", ar: "اكساراي" },
      { shortCode: "05", en: "Amasya", ar: "اماسيا" },
      { shortCode: "06", en: "Ankara", ar: "انكارا" },
      { shortCode: "07", en: "Antalya", ar: "انتاليا" },
      { shortCode: "75", en: "Ardahan", ar: "ارداهان" },
      { shortCode: "08", en: "Artvin", ar: "ارتفين" },
      { shortCode: "09", en: "Aydin", ar: "ايدين" },
      { shortCode: "10", en: "Balikesir", ar: "باليكيسير" },
      { shortCode: "74", en: "Bartin", ar: "بارتن" },
      { shortCode: "72", en: "Batman", ar: "باتمان" },
      { shortCode: "69", en: "Bayburt", ar: "بايبورت" },
      { shortCode: "11", en: "Bilecik", ar: "بيليكيك" },
      { shortCode: "12", en: "Bingol", ar: "بينجول" },
      { shortCode: "13", en: "Bitlis", ar: "بيتليس" },
      { shortCode: "14", en: "Bolu", ar: "بولو" },
      { shortCode: "15", en: "Burdur", ar: "بوردور" },
      { shortCode: "16", en: "Bursa", ar: "بورسا" },
      { shortCode: "17", en: "Canakkale", ar: "كاناكالي" },
      { shortCode: "18", en: "Cankiri", ar: "كانكيري" },
      { shortCode: "19", en: "Corum", ar: "كورم" },
      { shortCode: "20", en: "Denizli", ar: "دينيزيلي" },
      { shortCode: "21", en: "Diyarbakir", ar: "ديار باكير" },
      { shortCode: "81", en: "Duzce", ar: "دوزي" },
      { shortCode: "22", en: "Edirne", ar: "اديرني" },
      { shortCode: "23", en: "Elazig", ar: "ايلازيج" },
      { shortCode: "24", en: "Erzincan", ar: "ايرزينكان" },
      { shortCode: "25", en: "Erzurum", ar: "ايرزوروم" },
      { shortCode: "26", en: "Eskisehir", ar: "ايسكيسيهير" },
      { shortCode: "27", en: "Gaziantep", ar: "جازيانتيب" },
      { shortCode: "28", en: "Giresun", ar: "جيريسون" },
      { shortCode: "29", en: "Gumushane", ar: "جوموساني" },
      { shortCode: "30", en: "Hakkari", ar: "هاكاري" },
      { shortCode: "31", en: "Hatay", ar: "هاتاي" },
      { shortCode: "76", en: "Igdir", ar: "ايجدير" },
      { shortCode: "32", en: "Isparta", ar: "ايسبارتا" },
      { shortCode: "34", en: "Istanbul", ar: "ايستانبول" },
      { shortCode: "35", en: "Izmir", ar: "ايزمير" },
      { shortCode: "46", en: "Kahramanmaras", ar: "كاهرامانماراس" },
      { shortCode: "78", en: "Karabuk", ar: "كارابوك" },
      { shortCode: "70", en: "Karaman", ar: "كارامان" },
      { shortCode: "36", en: "Kars", ar: "كارس" },
      { shortCode: "37", en: "Kastamonu", ar: "كاستامونو" },
      { shortCode: "38", en: "Kayseri", ar: "كايسيري" },
      { shortCode: "79", en: "Kilis", ar: "كيليس" },
      { shortCode: "71", en: "Kirikkale", ar: "كيريكالي" },
      { shortCode: "39", en: "Kirklareli", ar: "كيركلاريلي" },
      { shortCode: "40", en: "Kirsehir", ar: "كيرسيهير" },
      { shortCode: "41", en: "Kocaeli", ar: "كوسايلي" },
      { shortCode: "42", en: "Konya", ar: "كونيا" },
      { shortCode: "43", en: "Kutahya", ar: "كوتاهيا" },
      { shortCode: "44", en: "Malatya", ar: "مالاتيا" },
      { shortCode: "45", en: "Manisa", ar: "مانيس" },
      { shortCode: "47", en: "Mardin", ar: "ماردن" },
      { shortCode: "33", en: "Mersin", ar: "ميرسن" },
      { shortCode: "48", en: "Mugla", ar: "موجلا" },
      { shortCode: "49", en: "Mus", ar: "موس" },
      { shortCode: "50", en: "Nevsehir", ar: "نيفسيهير" },
      { shortCode: "51", en: "Nigde", ar: "نيجدي" },
      { shortCode: "52", en: "Ordu", ar: "اردو" },
      { shortCode: "80", en: "Osmaniye", ar: "اسماني" },
      { shortCode: "53", en: "Rize", ar: "ريزي" },
      { shortCode: "54", en: "Sakarya", ar: "ساكاريا" },
      { shortCode: "55", en: "Samsun", ar: "سامسون" },
      { shortCode: "63", en: "Sanliurfa", ar: "سانلويرفا" },
      { shortCode: "56", en: "Siirt", ar: "سييرت" },
      { shortCode: "57", en: "Sinop", ar: "سينوب" },
      { shortCode: "73", en: "Sirnak", ar: "سارناك" },
      { shortCode: "58", en: "Sivas", ar: "سيفاس" },
      { shortCode: "59", en: "Tekirdag", ar: "تيكيرداج" },
      { shortCode: "60", en: "Tokat", ar: "توكات" },
      { shortCode: "61", en: "Trabzon", ar: "ترابزون" },
      { shortCode: "62", en: "Tunceli", ar: "توكيلي" },
      { shortCode: "64", en: "Usak", ar: "اوساك" },
      { shortCode: "65", en: "Van", ar: "فان" },
      { shortCode: "77", en: "Yalova", ar: "يالوفا" },
      { shortCode: "66", en: "Yozgat", ar: "يوزجات" },
      { shortCode: "67", en: "Zonguldak", ar: "زونجولداك" },
    ],
  },
  {
    id: 795,
    code: "tm",
    codeLng: "tkm",
    en: "Turkmenistan",
    ar: "تركمانستان",
    dial: "+993",
    cities: [
      { shortCode: "A", en: "Ahal", ar: "اهال" },
      { shortCode: "S", en: "Asgabat", ar: "اساجابات" },
      { shortCode: "B", en: "Balkan", ar: "بالكان" },
      { shortCode: "D", en: "Dashoguz", ar: "داشوجوز" },
      { shortCode: "L", en: "Lebap", ar: "ليباب" },
      { shortCode: "M", en: "Mary", ar: "ماري" },
    ],
  },
  {
    id: 796,
    code: "tc",
    codeLng: "tca",
    en: "Turks and Caicos Islands",
    ar: "جزر توركس وكايكوس",
    dial: "+1 649",
    cities: [{ en: "Turks and Caicos Islands", ar: "جزيرتان تركس و شياكوس" }],
  },
  {
    id: 798,
    code: "tv",
    codeLng: "tuv",
    en: "Tuvalu",
    ar: "توفالو",
    dial: "+688",
    cities: [
      { shortCode: "FUN", en: "Funafuti", ar: "فونافوتي" },
      { shortCode: "NMG", en: "Nanumanga", ar: "نانومانجا" },
      { shortCode: "NMA", en: "Nanumea", ar: "نانوميا" },
      { shortCode: "NIT", en: "Niutao", ar: "نيتاو" },
      { shortCode: "NUI", en: "Nui", ar: "نوي" },
      { shortCode: "NKF", en: "Nukufetau", ar: "نوكوفيتاو" },
      { shortCode: "NKL", en: "Nukulaelae", ar: "نوكولايلاي" },
      { shortCode: "VAU", en: "Vaitupu", ar: "فايتوبو" },
    ],
  },
  {
    id: 800,
    code: "ug",
    codeLng: "uga",
    en: "Uganda",
    ar: "أوغندا",
    dial: "+256",
    cities: [
      { shortCode: "317", en: "Abim", ar: "ابيم" },
      { shortCode: "301", en: "Adjumani", ar: "ادجوماني" },
      { shortCode: "314", en: "Amolatar", ar: "امولاتار" },
      { shortCode: "216", en: "Amuria", ar: "اموريا" },
      { shortCode: "319", en: "Amuru", ar: "امورو" },
      { shortCode: "302", en: "Apac", ar: "اباك" },
      { shortCode: "303", en: "Arua", ar: "اروا" },
      { shortCode: "217", en: "Budaka", ar: "بوداكا" },
      { shortCode: "223", en: "Bududa", ar: "بودادا" },
      { shortCode: "201", en: "Bugiri", ar: "بوجيري" },
      { shortCode: "224", en: "Bukedea", ar: "بوكيديا" },
      { shortCode: "218", en: "Bukwa", ar: "بوكوا" },
      { shortCode: "419", en: "Buliisa", ar: "بوليسا" },
      { shortCode: "401", en: "Bundibugyo", ar: "بونديبوجيو" },
      { shortCode: "402", en: "Bushenyi", ar: "بوشينيي" },
      { shortCode: "202", en: "Busia", ar: "بوسيا" },
      { shortCode: "219", en: "Butaleja", ar: "بوتاليجا" },
      { shortCode: "318", en: "Dokolo", ar: "دوكولو" },
      { shortCode: "304", en: "Gulu", ar: "جولو" },
      { shortCode: "403", en: "Hoima", ar: "هويما" },
      { shortCode: "416", en: "Ibanda", ar: "ايباندا" },
      { shortCode: "203", en: "Iganga", ar: "ايجاندا" },
      { shortCode: "417", en: "Isingiro", ar: "ايسينجيرو" },
      { shortCode: "204", en: "Jinja", ar: "جينجا" },
      { shortCode: "315", en: "Kaabong", ar: "كابونج" },
      { shortCode: "404", en: "Kabale", ar: "كابالي" },
      { shortCode: "405", en: "Kabarole", ar: "كابارولي" },
      { shortCode: "213", en: "Kaberamaido", ar: "كابيرمايدو" },
      { shortCode: "101", en: "Kalangala", ar: "كالانجالا" },
      { shortCode: "220", en: "Kaliro", ar: "كاليرو" },
      { shortCode: "102", en: "Kampala", ar: "كامبالا" },
      { shortCode: "205", en: "Kamuli", ar: "كامولي" },
      { shortCode: "413", en: "Kamwenge", ar: "كاموينجي" },
      { shortCode: "414", en: "Kanungu", ar: "كانونجو" },
      { shortCode: "206", en: "Kapchorwa", ar: "كابشوروا" },
      { shortCode: "406", en: "Kasese", ar: "كاسيسي" },
      { shortCode: "207", en: "Katakwi", ar: "كاتاكوي" },
      { shortCode: "112", en: "Kayunga", ar: "كايونجا" },
      { shortCode: "407", en: "Kibaale", ar: "كيبالي" },
      { shortCode: "103", en: "Kiboga", ar: "كيبوجا" },
      { shortCode: "418", en: "Kiruhura", ar: "كيروهورا" },
      { shortCode: "408", en: "Kisoro", ar: "كيسورو" },
      { shortCode: "305", en: "Kitgum", ar: "كيتجوم" },
      { shortCode: "316", en: "Koboko", ar: "كوبوكو" },
      { shortCode: "306", en: "Kotido", ar: "كوتيدو" },
      { shortCode: "208", en: "Kumi", ar: "كومي" },
      { shortCode: "415", en: "Kyenjojo", ar: "كينجوجو" },
      { shortCode: "307", en: "Lira", ar: "ليرا" },
      { shortCode: "104", en: "Luwero", ar: "لويرو" },
      { shortCode: "116", en: "Lyantonde", ar: "ليانتوندي" },
      { shortCode: "221", en: "Manafwa", ar: "مانافوا" },
      { shortCode: "320", en: "Maracha", ar: "ماراكا" },
      { shortCode: "105", en: "Masaka", ar: "ماساكا" },
      { shortCode: "409", en: "Masindi", ar: "ماسيندي" },
      { shortCode: "214", en: "Mayuge", ar: "مايوجي" },
      { shortCode: "209", en: "Mbale", ar: "ايمبالي" },
      { shortCode: "410", en: "Mbarara", ar: "ايمبارارا" },
      { shortCode: "114", en: "Mityana", ar: "ميتيانا" },
      { shortCode: "308", en: "Moroto", ar: "موروتو" },
      { shortCode: "309", en: "Moyo", ar: "مويو" },
      { shortCode: "106", en: "Mpigi", ar: "ايمبيجي" },
      { shortCode: "107", en: "Mubende", ar: "موبيندي" },
      { shortCode: "108", en: "Mukono", ar: "موكونو" },
      { shortCode: "311", en: "Nakapiripirit", ar: "ناكابيريبيريت" },
      { shortCode: "115", en: "Nakaseke", ar: "ناكاسيكي" },
      { shortCode: "109", en: "Nakasongola", ar: "ناكاسونجولا" },
      { shortCode: "222", en: "Namutumba", ar: "ناموتومبا" },
      { shortCode: "310", en: "Nebbi", ar: "نيبي" },
      { shortCode: "411", en: "Ntungamo", ar: "اينتونجامو" },
      { shortCode: "321", en: "Oyam", ar: "اويام" },
      { shortCode: "312", en: "Pader", ar: "بادير" },
      { shortCode: "210", en: "Pallisa", ar: "باليسا" },
      { shortCode: "110", en: "Rakai", ar: "راكاي" },
      { shortCode: "412", en: "Rukungiri", ar: "روكونجيري" },
      { shortCode: "111", en: "Sembabule", ar: "سيمبابولي" },
      { shortCode: "215", en: "Sironko", ar: "سيرونكو" },
      { shortCode: "211", en: "Soroti", ar: "سوروتي" },
      { shortCode: "212", en: "Tororo", ar: "تورورو" },
      { shortCode: "113", en: "Wakiso", ar: "واكيسو" },
      { shortCode: "313", en: "Yumbe", ar: "يومبي" },
    ],
  },
  {
    id: 804,
    code: "ua",
    codeLng: "ukr",
    en: "Ukraine",
    ar: "أوكرانيا",
    dial: "+380",
    cities: [
      { shortCode: "71", en: "Cherkasy", ar: "شيركاسي" },
      { shortCode: "74", en: "Chernihiv", ar: "شيرنيهيف" },
      { shortCode: "77", en: "Chernivtsi", ar: "شيرنيفيتسي" },
      { shortCode: "12", en: "Dnipropetrovsk", ar: "دنيبروبيتروفسك" },
      { shortCode: "14", en: "Donetsk", ar: "دونيتسك" },
      { shortCode: "26", en: "Ivano-Frankivsk", ar: "ايفانو فرانكيفسك" },
      { shortCode: "63", en: "Kharkiv", ar: "كاركيف" },
      { shortCode: "65", en: "Kherson", ar: "كيرسون" },
      { shortCode: "68", en: "Khmelnytskyi", ar: "كميلنيتسكي" },
      { shortCode: "32", en: "Kiev", ar: "كيف" },
      { shortCode: "35", en: "Kirovohrad", ar: "كيروفوهراد" },
      { shortCode: "09", en: "Luhansk", ar: "لوهانسك" },
      { shortCode: "46", en: "Lviv", ar: "لفيف" },
      { shortCode: "48", en: "Mykolaiv", ar: "ميكولايف" },
      { shortCode: "51", en: "Odessa", ar: "اوديسا" },
      { shortCode: "53", en: "Poltava", ar: "بولتافا" },
      { shortCode: "56", en: "Rivne", ar: "ريفني" },
      { shortCode: "59", en: "Sumy", ar: "سومي" },
      { shortCode: "61", en: "Ternopil", ar: "تيرنوبيل" },
      { shortCode: "05", en: "Vinnytsia", ar: "فينيتسيا" },
      { shortCode: "07", en: "Volyn", ar: "فولين" },
      { shortCode: "21", en: "Zakarpattia", ar: "زاكارباتيا" },
      { shortCode: "23", en: "Zaporizhia", ar: "زابوريزهيا" },
      { shortCode: "18", en: "Zhytomyr", ar: "زهيتومير" },
      {
        shortCode: "43",
        en: "Avtonomna Respublika Krym",
        ar: "كريم جمهوريه افتونومنا",
      },
      { shortCode: "30", en: "Kyïv", ar: "كيف" },
      { shortCode: "40", en: "Sevastopol", ar: "سيفاستوبول" },
    ],
  },
  {
    id: 784,
    code: "ae",
    codeLng: "are",
    en: "United Arab Emirates",
    ar: "الإمارات العربية المتحدة",
    dial: "+971",
    cities: [
      { shortCode: "AZ", en: "Abu Dhabi", ar: "ابو ظبي" },
      { shortCode: "AJ", en: "Ajman", ar: "عمان" },
      { shortCode: "DU", en: "Dubai", ar: "دبي" },
      { shortCode: "FU", en: "Fujairah", ar: "فوجايرا" },
      { shortCode: "RK", en: "Ras al Khaimah", ar: "راس الخيمه" },
      { shortCode: "SH", en: "Sharjah", ar: "الشرقه" },
      { shortCode: "UQ", en: "Umm Al Quwain", ar: "ام الكوين" },
    ],
  },
  {
    id: 826,
    code: "uk",
    codeLng: "unk",
    en: "United Kingdom of Great Britain and Northern Ireland",
    ar: "المملكة المتحدة",
    dial: "+44",
    cities: [
      { shortCode: "ABE", en: "Aberdeen City", ar: "مدينه ابردين" },
      { shortCode: "ABD", en: "Aberdeenshire", ar: "ابردينشير" },
      { shortCode: "ANS", en: "Angus", ar: "انجوس" },
      {
        shortCode: "ANN",
        en: "Antrim and Newtownabbey",
        ar: "انتريم و المدينه الجديده ابي",
      },
      {
        shortCode: "AND",
        en: "Ards and North Down",
        ar: "اردز و شمال الاسف",
      },
      { shortCode: "AGB", en: "Argyll and Bute", ar: "ارجيل و بوتي" },
      {
        shortCode: "ABC",
        en: "Armagh, Banbridge and Craigavon",
        ar: "ارماغ، بان بريدج و كرايجافون",
      },
      {
        shortCode: "BDG",
        en: "Barking and Dagenham",
        ar: "باركينج و دابينهام",
      },
      { shortCode: "BNE", en: "Barnet", ar: "بارنيت" },
      { shortCode: "BNS", en: "Barnsley", ar: "بارنسلي" },
      {
        shortCode: "BAS",
        en: "Bath and North East Somerset",
        ar: "باس ر شمال شرق سمر سيت",
      },
      { shortCode: "BDF", en: "Bedford", ar: "بيدفورد" },
      { shortCode: "BFS", en: "Belfast", ar: "بيلفاست" },
      { shortCode: "BEX", en: "Bexley", ar: "بيكسلي" },
      { shortCode: "BIR", en: "Birmingham", ar: "بيرمينجهام" },
      {
        shortCode: "BBD",
        en: "Blackburn with Darwen",
        ar: "بلاكبرن و داروين",
      },
      { shortCode: "BPL", en: "Blackpool", ar: "بلاكبول" },
      { shortCode: "BGW", en: "Blaenau Gwent", ar: "بلايناو جوينت" },
      { shortCode: "BOL", en: "Bolton", ar: "بولتون" },
      { shortCode: "BMH", en: "Bournemouth", ar: "بورنيموث" },
      {
        shortCode: "BRC",
        en: "Bracknell Forest",
        ar: "غابه براكنيل ",
      },
      { shortCode: "BRD", en: "Bradford", ar: "برادفورد" },
      { shortCode: "BEN", en: "Brent", ar: "برينت" },
      { shortCode: "BGE", en: "Bridgend", ar: "بريدجيند" },
      {
        shortCode: "BNH",
        en: "Brighton and Hove",
        ar: "برايتون و هوفي",
      },
      {
        shortCode: "BST",
        en: "Bristol, City of",
        ar: "مدينه بريستول",
      },
      { shortCode: "BRY", en: "Bromley", ar: "بروملي" },
      { shortCode: "BKM", en: "Buckinghamshire", ar: "بوكينج هامشير" },
      { shortCode: "BUR", en: "Bury", ar: "بوري" },
      { shortCode: "CAY", en: "Caerphilly", ar: "كايرفيلي" },
      { shortCode: "CLD", en: "Calderdale", ar: "كالدر ديل" },
      { shortCode: "CAM", en: "Cambridgeshire", ar: "كامبريدجشير" },
      { shortCode: "CMD", en: "Camden", ar: "كامدن" },
      { shortCode: "CRF", en: "Cardiff", ar: "كارديف" },
      { shortCode: "CMN", en: "Carmarthenshire", ar: "كارمارزينشير" },
      {
        shortCode: "CCG",
        en: "Causeway Coast and Glens",
        ar: "ساحل كوزواي و جلينز",
      },
      {
        shortCode: "CBF",
        en: "Central Bedfordshire",
        ar: "بيدفوردشير المنتصفه",
      },
      { shortCode: "CGN", en: "Ceredigion", ar: "سيرينديجيون" },
      { shortCode: "CHE", en: "Cheshire East", ar: "شرق شيشير" },
      {
        shortCode: "CHW",
        en: "Cheshire West and Chester",
        ar: "غرب شيشير و شيستر",
      },
      {
        shortCode: "CLK",
        en: "Clackmannanshire",
        ar: "كلاكمانانشير",
      },
      { shortCode: "CWY", en: "Conwy", ar: "كونوي" },
      { shortCode: "CON", en: "Cornwall", ar: "كورن" },
      { shortCode: "COV", en: "Coventry", ar: "كوفينتري" },
      { shortCode: "CRY", en: "Croydon", ar: "كرويدون" },
      { shortCode: "CMA", en: "Cumbria", ar: "كومبريا" },
      { shortCode: "DAL", en: "Darlington", ar: "دارلينجتون" },
      { shortCode: "DEN", en: "Denbighshire", ar: "دينبيجشير" },
      { shortCode: "DER", en: "Derby", ar: "ديربي" },
      { shortCode: "DBY", en: "Derbyshire", ar: "ديربيشير" },
      {
        shortCode: "DRS",
        en: "Derry and Strabane",
        ar: "ديري اند سترابان",
      },
      { shortCode: "DEV", en: "Devon", ar: "ديفون" },
      { shortCode: "DNC", en: "Doncaster", ar: "دونكاستر" },
      { shortCode: "DOR", en: "Dorset", ar: "دورسيت" },
      { shortCode: "DUD", en: "Dudley", ar: "دودلي" },
      {
        shortCode: "DGY",
        en: "Dumfries and Galloway",
        ar: "دومفرايز و جالوواي",
      },
      { shortCode: "DND", en: "Dundee City", ar: "مدينه داندي" },
      { shortCode: "DUR", en: "Durham County", ar: "دورهام كاونتي" },
      { shortCode: "EAL", en: "Ealing", ar: "اييلينج" },
      { shortCode: "EAY", en: "East Ayrshire", ar: "شرق اريشير" },
      {
        shortCode: "EDU",
        en: "East Dunbartonshire",
        ar: "شرق دونبارتونشير",
      },
      { shortCode: "ELN", en: "East Lothian", ar: "شرق لوثيان" },
      {
        shortCode: "ERW",
        en: "East Renfrewshire",
        ar: "شرق رينفريوشير",
      },
      {
        shortCode: "ERY",
        en: "East Riding of Yorkshire",
        ar: "شرق ركوب اليوركشير",
      },
      { shortCode: "ESX", en: "East Sussex", ar: "شرق سوسيكس" },
      {
        shortCode: "EDH",
        en: "Edinburgh, City of",
        ar: "مدينه ايدينبورج",
      },
      { shortCode: "ELS", en: "Eilean Siar", ar: "اليلين سيار" },
      { shortCode: "ENF", en: "Enfield", ar: "اينفيلد" },
      { shortCode: "ESS", en: "Essex", ar: "ايسيكس" },
      { shortCode: "FAL", en: "Falkirk", ar: "فالكيرك" },
      {
        shortCode: "FMO",
        en: "Fermanagh and Omagh",
        ar: "فيرماناج و اوماغ",
      },
      { shortCode: "FIF", en: "Fife", ar: "فيفي" },
      { shortCode: "FLN", en: "Flintshire", ar: "فلينتشير" },
      { shortCode: "GAT", en: "Gateshead", ar: "جاتيسهيد" },
      { shortCode: "GLG", en: "Glasgow City", ar: "مدينه جلاسكو" },
      { shortCode: "GLS", en: "Gloucestershire", ar: "جلوسيستيرشير" },
      { shortCode: "GRE", en: "Greenwich", ar: "جريين ويتش" },
      { shortCode: "GWN", en: "Gwynedd", ar: "جوينيد" },
      { shortCode: "HCK", en: "Hackney", ar: "هاكني" },
      { shortCode: "HAL", en: "Halton", ar: "هلتون" },
      {
        shortCode: "HMF",
        en: "Hammersmith and Fulham",
        ar: "هامرسميث و فولهام",
      },
      { shortCode: "HAM", en: "Hampshire", ar: "هامبشير" },
      { shortCode: "HRY", en: "Haringey", ar: "هارينجي" },
      { shortCode: "HRW", en: "Harrow", ar: "هارو" },
      { shortCode: "HPL", en: "Hartlepool", ar: "هارتلبوول" },
      { shortCode: "HAV", en: "Havering", ar: "هافيرينج" },
      { shortCode: "HEF", en: "Herefordshire", ar: "هيرفوردشير" },
      { shortCode: "HRT", en: "Hertfordshire", ar: "هيرتفوردشير" },
      { shortCode: "HLD", en: "Highland", ar: "هاي لاند" },
      { shortCode: "HIL", en: "Hillingdon", ar: "هيلينجدون" },
      { shortCode: "HNS", en: "Hounslow", ar: "هاونسلو" },
      { shortCode: "IVC", en: "Inverclyde", ar: "اينفيركلايد" },
      {
        shortCode: "AGY",
        en: "Isle of Anglesey",
        ar: "جزيره انجليسي",
      },
      { shortCode: "IOW", en: "Isle of Wight", ar: "جزيره وايت" },
      { shortCode: "IOS", en: "Isles of Scilly", ar: "جزر سكيلي" },
      { shortCode: "ISL", en: "Islington", ar: "ايسلينجلون" },
      {
        shortCode: "KEC",
        en: "Kensington and Chelsea",
        ar: "كينسينجتون و شيلسي",
      },
      { shortCode: "KEN", en: "Kent", ar: "كينت" },
      {
        shortCode: "KHL",
        en: "Kingston upon Hull",
        ar: "كينجستون ابون هال",
      },
      {
        shortCode: "KTT",
        en: "Kingston upon Thames",
        ar: "كينجستون ابون تاميس",
      },
      { shortCode: "KIR", en: "Kirklees", ar: "كيركليز" },
      { shortCode: "KWL", en: "Knowsley", ar: "نوسلي" },
      { shortCode: "LBH", en: "Lambeth", ar: "لامبيث" },
      { shortCode: "LAN", en: "Lancashire", ar: "لانكاسير" },
      { shortCode: "LDS", en: "Leeds", ar: "لييدز" },
      { shortCode: "LCE", en: "Leicester", ar: "ليستر" },
      { shortCode: "LEC", en: "Leicestershire", ar: "ليستر شير" },
      { shortCode: "LEW", en: "Lewisham", ar: "لويشام" },
      { shortCode: "LIN", en: "Lincolnshire", ar: "لينكولنشير" },
      {
        shortCode: "LBC",
        en: "Lisburn and Castlereagh",
        ar: "ليسبورن و كاستليريج",
      },
      { shortCode: "LIV", en: "Liverpool", ar: "ليفر بوول" },
      { shortCode: "LND", en: "London, City of", ar: "مدينه لندن" },
      { shortCode: "LUT", en: "Luton", ar: "لوتون" },
      { shortCode: "MAN", en: "Manchester", ar: "مانشيستر" },
      { shortCode: "MDW", en: "Medway", ar: "ميدواي" },
      { shortCode: "MTY", en: "Merthyr Tydfil", ar: "مارتر تيدفيل" },
      { shortCode: "MRT", en: "Merton", ar: "ميرتون" },
      {
        shortCode: "MEA",
        en: "Mid and East Antrim",
        ar: "منتصف و شرق انتريم",
      },
      { shortCode: "MUL", en: "Mid Ulster", ar: "منتصف اولستر" },
      { shortCode: "MDB", en: "Middlesbrough", ar: "ميدلسبروغ" },
      { shortCode: "MLN", en: "Midlothian", ar: "ميد لوثيان" },
      { shortCode: "MIK", en: "Milton Keynes", ar: "ميلتون كيينز" },
      { shortCode: "MON", en: "Monmouthshire", ar: "مونموثشير" },
      { shortCode: "MRY", en: "Moray", ar: "موراي" },
      {
        shortCode: "NTL",
        en: "Neath Port Talbot",
        ar: "نيث بورت تالبوت",
      },
      {
        shortCode: "NET",
        en: "Newcastle upon Tyne",
        ar: "نيو كاستل ابون تين",
      },
      { shortCode: "NWM", en: "Newham", ar: "نيو هام" },
      { shortCode: "NWP", en: "Newport", ar: "نيو بورت" },
      {
        shortCode: "NMD",
        en: "Newry, Mourne and Down",
        ar: "نوري مورن و داون",
      },
      { shortCode: "NFK", en: "Norfolk", ar: "نورفولك" },
      { shortCode: "NAY", en: "North Ayrshire", ar: "شمال اريشير" },
      {
        shortCode: "NEL",
        en: "North East Lincolnshire",
        ar: "شمال شرق لينكولنشير",
      },
      {
        shortCode: "NLK",
        en: "North Lanarkshire",
        ar: "شمال لاناركشير",
      },
      {
        shortCode: "NLN",
        en: "North Lincolnshire",
        ar: "شمال لينكولنشير",
      },
      { shortCode: "NSM", en: "North Somerset", ar: "شمال سومرسيت" },
      { shortCode: "NTY", en: "North Tyneside", ar: "شمال تينيسايد" },
      { shortCode: "NYK", en: "North Yorkshire", ar: "شمال يوركشير" },
      {
        shortCode: "NTH",
        en: "Northamptonshire",
        ar: "نورثتامبتونشير",
      },
      { shortCode: "NBL", en: "Northumberland", ar: "نورثامبرلاند" },
      { shortCode: "NGM", en: "Nottingham", ar: "نوتينجهام" },
      { shortCode: "NTT", en: "Nottinghamshire", ar: "نوتينجهامشير" },
      { shortCode: "OLD", en: "Oldham", ar: "اولدهام" },
      { shortCode: "ORK", en: "Orkney Islands", ar: "جزر اوركني" },
      { shortCode: "OXF", en: "Oxfordshire", ar: "اوكسفورد شير" },
      { shortCode: "PEM", en: "Pembrokeshire", ar: "بيمبروكشير" },
      {
        shortCode: "PKN",
        en: "Perth and Kinross",
        ar: "بيرث و كينروس",
      },
      { shortCode: "PTE", en: "Peterborough", ar: "بيتربورو" },
      { shortCode: "PLY", en: "Plymouth", ar: "بلايموث" },
      { shortCode: "POL", en: "Poole", ar: "بولي" },
      { shortCode: "POR", en: "Portsmouth", ar: "بورتسموث" },
      { shortCode: "POW", en: "Powys", ar: "باور" },
      { shortCode: "RDG", en: "Reading", ar: "ريدينج" },
      { shortCode: "RDB", en: "Redbridge", ar: "ريدبريدج" },
      {
        shortCode: "RCC",
        en: "Redcar and Cleveland",
        ar: "ريدكار و كليفلاند",
      },
      { shortCode: "RFW", en: "Renfrewshire", ar: "رينفريوشير" },
      {
        shortCode: "RCT",
        en: "Rhondda, Cynon, Taff",
        ar: "روندا سينون تاف",
      },
      {
        shortCode: "RIC",
        en: "Richmond upon Thames",
        ar: "ريتشموند ابون تاميس",
      },
      { shortCode: "RCH", en: "Rochdale", ar: "روتش ديل" },
      { shortCode: "ROT", en: "Rotherham", ar: "روسيرهام" },
      { shortCode: "RUT", en: "Rutland", ar: "روتلاند" },
      { shortCode: "SHN", en: "St. Helens", ar: "شارع هيلينز" },
      { shortCode: "SLF", en: "Salford", ar: "سالفورد" },
      { shortCode: "SAW", en: "Sandwell", ar: "ساندويل" },
      {
        shortCode: "SCB",
        en: "Scottish Borders, The",
        ar: "حدود سكوتلاندا",
      },
      { shortCode: "SFT", en: "Sefton", ar: "سيفتون" },
      { shortCode: "SHF", en: "Sheffield", ar: "شيفيلد" },
      {
        shortCode: "ZET",
        en: "Shetland Islands",
        ar: "جزر شيتلاند",
      },
      { shortCode: "SHR", en: "Shropshire", ar: "شروبشير" },
      { shortCode: "SLG", en: "Slough", ar: "سلوج" },
      { shortCode: "SOL", en: "Solihull", ar: "سوليهال" },
      { shortCode: "SOM", en: "Somerset", ar: "سمرسيت" },
      { shortCode: "SAY", en: "South Ayrshire", ar: "جنوب اريشير" },
      {
        shortCode: "SGC",
        en: "South Gloucestershire",
        ar: "جنوب جلوسيستيرشير",
      },
      {
        shortCode: "SLK",
        en: "South Lanarkshire",
        ar: "جنوب لاناركشير",
      },
      { shortCode: "STY", en: "South Tyneside", ar: "جنوب تاينيسايد" },
      { shortCode: "STH", en: "Southampton", ar: "ساوثهامتون" },
      { shortCode: "SOS", en: "Southend-on-Sea", ar: "ساوثيند على البحر" },
      { shortCode: "SWK", en: "Southwark", ar: "ساوثوارك" },
      { shortCode: "STS", en: "Staffordshire", ar: "ستافوردشير" },
      { shortCode: "STG", en: "Stirling", ar: "ستيرلينج" },
      { shortCode: "SKP", en: "Stockport", ar: "ستومكبورت" },
      {
        shortCode: "STT",
        en: "Stockton-on-Tees",
        ar: "ستوكتون على تيز",
      },
      { shortCode: "STE", en: "Stoke-on-Trent", ar: "ستوك على ترينت" },
      { shortCode: "SFK", en: "Suffolk", ar: "سوفولك" },
      { shortCode: "SND", en: "Sunderland", ar: "ساندر لاند" },
      { shortCode: "SRY", en: "Surrey", ar: "سوري" },
      { shortCode: "STN", en: "Sutton", ar: "سوتون" },
      { shortCode: "SWA", en: "Swansea", ar: "سوانسي" },
      { shortCode: "SWD", en: "Swindon", ar: "سويندون" },
      { shortCode: "TAM", en: "Tameside", ar: "تاميسايد" },
      {
        shortCode: "TFW",
        en: "Telford and Wrekin",
        ar: "تيلفورد و ريكين",
      },
      { shortCode: "THR", en: "Thurrock", ar: "ثاروك" },
      { shortCode: "TOB", en: "Torbay", ar: "تورباي" },
      { shortCode: "TOF", en: "Torfaen", ar: "تورفاين" },
      { shortCode: "TWH", en: "Tower Hamlets", ar: "برج هامليتس" },
      { shortCode: "TRF", en: "Trafford", ar: "ترافورد" },
      {
        shortCode: "VGL",
        en: "Vale of Glamorgan, The",
        ar: "فالي الجلامورجان",
      },
      { shortCode: "WKF", en: "Wakefield", ar: "واكيفيلد" },
      { shortCode: "WLL", en: "Walsall", ar: "والسال" },
      { shortCode: "WFT", en: "Waltham Forest", ar: "غابه والسام" },
      { shortCode: "WND", en: "Wandsworth", ar: "واندزورث" },
      { shortCode: "WRT", en: "Warrington", ar: "وارينجتون" },
      { shortCode: "WAR", en: "Warwickshire", ar: "وارويكشير" },
      { shortCode: "WBK", en: "West Berkshire", ar: "غرب بيركشير" },
      {
        shortCode: "WDU",
        en: "West Dunbartonshire",
        ar: "غرب دونبارتونشير",
      },
      { shortCode: "WLN", en: "West Lothian", ar: "غرب اوسيان" },
      { shortCode: "WSX", en: "West Sussex", ar: "غرب سوسيكس" },
      { shortCode: "WSM", en: "Westminster", ar: "ويستمينستر" },
      { shortCode: "WGN", en: "Wigan", ar: "ويجان" },
      { shortCode: "WIL", en: "Wiltshire", ar: "ويلتشير" },
      {
        shortCode: "WNM",
        en: "Windsor and Maidenhead",
        ar: "ويندسور و مايدنهيد",
      },
      { shortCode: "WRL", en: "Wirral", ar: "ويرال" },
      { shortCode: "WOK", en: "Wokingham", ar: "وركينجهام" },
      { shortCode: "WLV", en: "Wolverhampton", ar: "وولفرهامتون" },
      { shortCode: "WOR", en: "Worcestershire", ar: "وورسيسترشير" },
      { shortCode: "WRX", en: "Wrexham", ar: "وريكسهام" },
      { shortCode: "YOR", en: "York", ar: "يورك" },
    ],
  },
  {
    id: 840,
    code: "us",
    codeLng: "usa",
    en: "United States of America",
    ar: "الولايات المتحدة",
    dial: "+1",
    cities: [
      { shortCode: "AL", en: "Alabama", ar: "الاباما" },
      { shortCode: "AK", en: "Alaska", ar: "الاسكا" },
      { shortCode: "AS", en: "American Samoa", ar: "ساموا الامريكيه" },
      { shortCode: "AZ", en: "Arizona", ar: "اريزونا" },
      { shortCode: "AR", en: "Arkansas", ar: "اركانساس" },
      { shortCode: "CA", en: "California", ar: "كاليفورنيا" },
      { shortCode: "CO", en: "Colorado", ar: "كولارادو" },
      { shortCode: "CT", en: "Connecticut", ar: "كوينيكتيكوت" },
      { shortCode: "DE", en: "Delaware", ar: "ديلاوير" },
      {
        shortCode: "DC",
        en: "District of Columbia",
        ar: "حي كولومبيا",
      },
      { shortCode: "FM", en: "Micronesia", ar: "ميكرونيسيا" },
      { shortCode: "FL", en: "Florida", ar: "فلوريدا" },
      { shortCode: "GA", en: "Georgia", ar: "جويجيا" },
      { shortCode: "GU", en: "Guam", ar: "جوام" },
      { shortCode: "HI", en: "Hawaii", ar: "هاواي" },
      { shortCode: "ID", en: "Idaho", ar: "ايداهو" },
      { shortCode: "IL", en: "Illinois", ar: "ايلينويز" },
      { shortCode: "IN", en: "Indiana", ar: "اينديانا" },
      { shortCode: "IA", en: "Iowa", ar: "ايوا" },
      { shortCode: "KS", en: "Kansas", ar: "كانساس" },
      { shortCode: "KY", en: "Kentucky", ar: "كنتاكي" },
      { shortCode: "LA", en: "Louisiana", ar: "اويزيانا" },
      { shortCode: "ME", en: "Maine", ar: "ماين" },
      { shortCode: "MH", en: "Marshall Islands", ar: "جزر المارشال" },
      { shortCode: "MD", en: "Maryland", ar: "ماري لاند" },
      { shortCode: "MA", en: "Massachusetts", ar: "ماسيتشوسيتس" },
      { shortCode: "MI", en: "Michigan", ar: "ميشيجن" },
      { shortCode: "MN", en: "Minnesota", ar: "مينرسوتا" },
      { shortCode: "MS", en: "Mississippi", ar: "ميسيسيبي" },
      { shortCode: "MO", en: "Missouri", ar: "ميسوري" },
      { shortCode: "MT", en: "Montana", ar: "مونتانا" },
      { shortCode: "NE", en: "Nebraska", ar: "نيبراسكا" },
      { shortCode: "NV", en: "Nevada", ar: "نافادا" },
      { shortCode: "NH", en: "New Hampshire", ar: " نيو هامبشير" },
      { shortCode: "NJ", en: "New Jersey", ar: "نيو جيرزي" },
      { shortCode: "NM", en: "New Mexico", ar: "نيو ميكسيكو" },
      { shortCode: "NY", en: "New York", ar: "نيو يورك" },
      { shortCode: "NC", en: "North Carolina", ar: "شمال كارولينا" },
      { shortCode: "ND", en: "North Dakota", ar: "شمال داكوتا" },
      {
        shortCode: "MP",
        en: "Northern Mariana Islands",
        ar: "جزر ماريانا الشماليه",
      },
      { shortCode: "OH", en: "Ohio", ar: "اوهايو" },
      { shortCode: "OK", en: "Oklahoma", ar: "اوكلاهوما" },
      { shortCode: "OR", en: "Oregon", ar: "اوريجن" },
      { shortCode: "PW", en: "Palau", ar: "بالاو" },
      { shortCode: "PA", en: "Pennsylvania", ar: "بينسلفينيا" },
      { shortCode: "PR", en: "Puerto Rico", ar: "بورتو ريكو" },
      { shortCode: "RI", en: "Rhode Island", ar: "جزيره رودي" },
      { shortCode: "SC", en: "South Carolina", ar: "جنوب كارولينا" },
      { shortCode: "SD", en: "South Dakota", ar: "جنوب داكوتا" },
      { shortCode: "TN", en: "Tennessee", ar: "تينيسي" },
      { shortCode: "TX", en: "Texas", ar: "تيكساس" },
      { shortCode: "UT", en: "Utah", ar: "اوتاه" },
      { shortCode: "VT", en: "Vermont", ar: "فيرمونت" },
      { shortCode: "VI", en: "Virgin Islands", ar: "جزر فيرجن" },
      { shortCode: "VA", en: "Virginia", ar: "فيرجنيا" },
      { shortCode: "WA", en: "Washington", ar: "واشنجتون" },
      { shortCode: "WV", en: "West Virginia", ar: "غرب فيرجينيا" },
      { shortCode: "WI", en: "Wisconsin", ar: "ويسكونسن" },
      { shortCode: "WY", en: "Wyoming", ar: "ويومينج" },
      {
        shortCode: "AA",
        en: "Armed Forces Americas",
        ar: "القوات المسلحه الامريكيه",
      },
      {
        shortCode: "AE",
        en: "Armed Forces Europe, Canada, Africa and Middle East",
        ar: "القوات المسلحه لاوروبا، كانداو افريقيا و الشرق الاوسط",
      },
      {
        shortCode: "AP",
        en: "Armed Forces Pacific",
        ar: "القوات المسلحه الهادئه",
      },
    ],
  },
  {
    id: 581,
    code: "um",
    codeLng: "umi",
    en: "United States Minor Outlying Islands",
    ar: "جزر الولايات المتحدة الصغيرة النائية",
    dial: "+246",
    cities: [
      { shortCode: "81", en: "Baker Island", ar: "جزيره بيكر" },
      { shortCode: "84", en: "Howland Island", ar: "جزيره هاولاند" },
      { shortCode: "86", en: "Jarvis Island", ar: "جزيره جارفيس" },
      { shortCode: "67", en: "Johnston Atoll", ar: "جونسون اتول" },
      { shortCode: "89", en: "Kingman Reef", ar: "رييف كينجمان" },
      { shortCode: "71", en: "Midway Islands", ar: "جزر ميدواي" },
      { shortCode: "76", en: "Navassa Island", ar: "جزيره نافاسا" },
      { shortCode: "95", en: "Palmyra Atoll", ar: "بالميرا اتول" },
      { shortCode: "79", en: "Wake Island", ar: "جزيره ويك" },
      { shortCode: "BN", en: "Bajo Nuevo Bank", ar: "بنك باجو نويفو" },
      { shortCode: "SB", en: "Serranilla Bank", ar: "بنج سيوانيلا" },
    ],
  },
  {
    id: 858,
    code: "uy",
    codeLng: "ury",
    en: "Uruguay",
    ar: "الأوروغواي",
    dial: "+598",
    cities: [
      { shortCode: "AR", en: "Artigas", ar: "ارتيجاس" },
      { shortCode: "CA", en: "Canelones", ar: "كانيلونيس" },
      { shortCode: "CL", en: "Cerro Largo", ar: "سيرو لارجو" },
      { shortCode: "CO", en: "Colonia", ar: "كولونيا" },
      { shortCode: "DU", en: "Durazno", ar: "دورازنو" },
      { shortCode: "FS", en: "Flores", ar: "فلوريس" },
      { shortCode: "FD", en: "Florida", ar: "فلوريدا" },
      { shortCode: "LA", en: "Lavalleja", ar: "لافيليجا" },
      { shortCode: "MA", en: "Maldonado", ar: "مالدونادو" },
      { shortCode: "MO", en: "Montevideo", ar: "مونتيفيديو" },
      { shortCode: "PA", en: "Paysandú", ar: "بايساندو" },
      { shortCode: "RN", en: "Río Negro", ar: "ريو نيجرو" },
      { shortCode: "RV", en: "Rivera", ar: "ريفيرو" },
      { shortCode: "RO", en: "Rocha", ar: "روكا" },
      { shortCode: "SA", en: "Salto", ar: "سالتو" },
      { shortCode: "SJ", en: "San José", ar: "سان خوسيه" },
      { shortCode: "SO", en: "Soriano", ar: "سوريانو" },
      { shortCode: "TA", en: "Tacuarembó", ar: "تاكواريمبو" },
      { shortCode: "TT", en: "Treinta y Tres", ar: "ترينتا واي تريس" },
    ],
  },
  {
    id: 860,
    code: "uz",
    codeLng: "uzb",
    en: "Uzbekistan",
    ar: "أوزبكستان",
    dial: "+998",
    cities: [
      { shortCode: "TK", en: "Toshkent shahri", ar: "توشكينت شاري" },
      { shortCode: "AN", en: "Andijon", ar: "انديجون" },
      { shortCode: "BU", en: "Buxoro", ar: "بوكسورو" },
      { shortCode: "FA", en: "Farg‘ona", ar: "فرجونا" },
      { shortCode: "JI", en: "Jizzax", ar: "جيزاكس" },
      { shortCode: "NG", en: "Namangan", ar: "نامانجان" },
      { shortCode: "NW", en: "Navoiy", ar: "نافوي" },
      {
        shortCode: "QA",
        en: "Qashqadaryo (Qarshi)",
        ar: "قرشي",
      },
      { shortCode: "SA", en: "Samarqand", ar: "سمركاند" },
      {
        shortCode: "SI",
        en: "Sirdaryo (Guliston)",
        ar: "جوليستون",
      },
      {
        shortCode: "SU",
        en: "Surxondaryo (Termiz)",
        ar: "تيرميز",
      },
      {
        shortCode: "TO",
        en: "Toshkent wiloyati",
        ar: "توشكينت ويلوياتي",
      },
      { shortCode: "XO", en: "Xorazm (Urganch)", ar: "اورجانش" },
      {
        shortCode: "QR",
        en: "Qoraqalpog‘iston Respublikasi (Nukus)",
        ar: "نوكوس",
      },
    ],
  },
  {
    id: 548,
    code: "vu",
    codeLng: "vut",
    en: "Vanuatu",
    ar: "فانواتو",
    dial: "+678",
    cities: [
      { shortCode: "MAP", en: "Malampa", ar: "مالامبا" },
      { shortCode: "PAM", en: "Pénama", ar: "بيناما" },
      { shortCode: "SAM", en: "Sanma", ar: "سانما" },
      { shortCode: "SEE", en: "Shéfa", ar: "شيفا" },
      { shortCode: "TAE", en: "Taféa", ar: "تافيا" },
      { shortCode: "TOB", en: "Torba", ar: "توربا" },
    ],
  },
  {
    id: 862,
    code: "ve",
    codeLng: "ven",
    en: "Venezuela (Bolivarian Republic of)",
    ar: "فنزويلا",
    dial: "+58",
    cities: [
      {
        shortCode: "W",
        en: "Dependencias Federales",
        ar: "ديبيندينسياز فيديرالس",
      },
      { shortCode: "A", en: "Distrito Federal", ar: "ديستريتو الفدراليه" },
      { shortCode: "Z", en: "Amazonas", ar: "امازوناس" },
      { shortCode: "B", en: "Anzoátegui", ar: "انزواتيجوي" },
      { shortCode: "C", en: "Apure", ar: "ابوري" },
      { shortCode: "D", en: "Aragua", ar: "اراجوا" },
      { shortCode: "E", en: "Barinas", ar: "باريناس" },
      { shortCode: "F", en: "Bolívar", ar: "بوليفار" },
      { shortCode: "G", en: "Carabobo", ar: "كارابوبو" },
      { shortCode: "H", en: "Cojedes", ar: "كوجيديز" },
      { shortCode: "Y", en: "Delta Amacuro", ar: "ديلتا اماكورو" },
      { shortCode: "I", en: "Falcón", ar: "فالكون" },
      { shortCode: "J", en: "Guárico", ar: "جواريكو" },
      { shortCode: "K", en: "Lara", ar: "لارا" },
      { shortCode: "L", en: "Mérida", ar: "كيريدا" },
      { shortCode: "M", en: "Miranda", ar: "ميراندا" },
      { shortCode: "N", en: "Monagas", ar: "موناجاس" },
      { shortCode: "O", en: "Nueva Esparta", ar: "نويفا ايسبراتا" },
      { shortCode: "P", en: "Portuguesa", ar: "بورتوجيزا" },
      { shortCode: "R", en: "Sucre", ar: "سوكري" },
      { shortCode: "S", en: "Táchira", ar: "تاشيرا" },
      { shortCode: "T", en: "Trujillo", ar: "ترجيلو" },
      { shortCode: "X", en: "Vargas", ar: "فارجاس" },
      { shortCode: "U", en: "Yaracuy", ar: "ياراكوي" },
      { shortCode: "V", en: "Zulia", ar: "زوليا" },
    ],
  },
  {
    id: 704,
    code: "vn",
    codeLng: "vnm",
    en: "Viet Nam",
    ar: "فيتنام",
    dial: "+84",
    cities: [
      { shortCode: "39", en: "Đồng Nai", ar: "دونج ناي" },
      { shortCode: "45", en: "Đồng Tháp", ar: "دونج ساب" },
      { shortCode: "30", en: "Gia Lai", ar: "جيا لاي" },
      { shortCode: "03", en: "Hà Giang", ar: "ها جيانج" },
      { shortCode: "63", en: "Hà Nam", ar: "ها نام" },
      { shortCode: "15", en: "Hà Tây", ar: "ها تاي" },
      { shortCode: "23", en: "Hà Tĩnh", ar: "ها تينه" },
      { shortCode: "61", en: "Hải Dương", ar: "هاي دونج" },
      { shortCode: "73", en: "Hậu Giang", ar: "هاو جيانج" },
      { shortCode: "14", en: "Hòa Bình", ar: "هاو بينه" },
      { shortCode: "66", en: "Hưng Yên", ar: "هونج ين" },
      { shortCode: "34", en: "Khánh Hòa", ar: "كان هوا" },
      { shortCode: "47", en: "Kiên Giang", ar: "كين جيانج" },
      { shortCode: "28", en: "Kon Tum", ar: "كون توم" },
      { shortCode: "01", en: "Lai Châu", ar: "لاي شاو" },
      { shortCode: "35", en: "Lâm Đồng", ar: "لام دونج" },
      { shortCode: "09", en: "Lạng Sơn", ar: "لانج سون" },
      { shortCode: "02", en: "Lào Cai", ar: "لاو كاي" },
      { shortCode: "41", en: "Long An", ar: "لونج ان" },
      { shortCode: "67", en: "Nam Định", ar: "نام دينه" },
      { shortCode: "22", en: "Nghệ An", ar: "اينجي ان" },
      { shortCode: "18", en: "Ninh Bình", ar: "نينه بينه" },
      { shortCode: "36", en: "Ninh Thuận", ar: "نينه توان" },
      { shortCode: "68", en: "Phú Thọ", ar: "بو تو" },
      { shortCode: "32", en: "Phú Yên", ar: "بو ين" },
      { shortCode: "24", en: "Quảng Bình", ar: "كوانح بينه" },
      { shortCode: "27", en: "Quảng Nam", ar: "كوانج نام" },
      { shortCode: "29", en: "Quảng Ngãi", ar: "كوانج نجاي" },
      { shortCode: "13", en: "Quảng Ninh", ar: "كوانج نينه" },
      { shortCode: "25", en: "Quảng Trị", ar: "كوانج تري" },
      { shortCode: "52", en: "Sóc Trăng", ar: "سوك ترانج" },
      { shortCode: "05", en: "Sơn La", ar: "سون لا" },
      { shortCode: "37", en: "Tây Ninh", ar: "تاي نينه" },
      { shortCode: "20", en: "Thái Bình", ar: "تاي بين" },
      { shortCode: "69", en: "Thái Nguyên", ar: "تاس نجوين" },
      { shortCode: "21", en: "Thanh Hóa", ar: "تان هوا" },
      { shortCode: "26", en: "Thừa Thiên–Huế", ar: "تورا تين هوي" },
      { shortCode: "46", en: "Tiền Giang", ar: "تين جيانج" },
      { shortCode: "51", en: "Trà Vinh", ar: "ترا فين" },
      { shortCode: "07", en: "Tuyên Quang", ar: "توين كوانج" },
      { shortCode: "49", en: "Vĩnh Long", ar: "فين لونج" },
      { shortCode: "70", en: "Vĩnh Phúc", ar: "فين بوك" },
      { shortCode: "06", en: "Yên Bái", ar: "ين باي" },
      { shortCode: "CT", en: "Cần Thơ", ar: "كان تو" },
      { shortCode: "DN", en: "Đà Nẵng", ar: "دا نانج" },
      { shortCode: "HN", en: "Hà Nội", ar: "ها نوي" },
      { shortCode: "HP", en: "Hải Phòng", ar: "هاي بونج" },
      {
        shortCode: "SG",
        en: "Hồ Chí Minh (Sài Gòn)",
        ar: "ساي جون",
      },
    ],
  },
  {
    id: 92,
    code: "vg",
    codeLng: "vgb",
    en: "Virgin Islands (British)",
    ar: "جزر العذراء البريطانية",
    dial: "+1 284",
    cities: [
      { shortCode: "ANG", en: "Anegada", ar: "انيجادا" },
      { shortCode: "JVD", en: "Jost Van Dyke", ar: "جوست فان ديكي" },
      { shortCode: "TTA", en: "Tortola", ar: "تورتولا" },
      { shortCode: "VGD", en: "Virgin Gorda", ar: "فيرجن جوردا" },
    ],
  },
  {
    id: 850,
    code: "vi",
    codeLng: "vir",
    en: "Virgin Islands (U.S.)",
    ar: "جزر العذراء الأمريكية",
    dial: "+1 340",
    cities: [
      { shortCode: "STH", en: "St. Thomas", ar: "شارع توماس" },
      { shortCode: "SJO", en: "St. John", ar: "شارع جون" },
      { shortCode: "SCR", en: "St. Croix", ar: "شارع كروكس" },
    ],
  },
  {
    id: 876,
    code: "wf",
    codeLng: "wlf",
    en: "Wallis and Futuna",
    ar: "والس وفوتونا",
    dial: "+681",
    cities: [
      { shortCode: "ALO", en: "Alo", ar: "الو" },
      { shortCode: "SIG", en: "Sigave", ar: "سيجافي" },
      { shortCode: "WAL", en: "Wallis", ar: "واليس" },
    ],
  },
  {
    id: 732,
    code: "eh",
    codeLng: "esh",
    en: "Western Sahara",
    ar: "الصحراء الغربية",
    dial: "+212",
    cities: [
      { shortCode: "ESM", en: "Es Smara", ar: "ايس سامارا" },
      { shortCode: "BOD", en: "Boujdour", ar: "بوجدور" },
      { shortCode: "LAA", en: "Laâyoune", ar: "لايوني" },
      { shortCode: "AOU", en: "Aousserd", ar: "اوسيرد" },
      { shortCode: "OUD", en: "Oued ed Dahab", ar: "كويد ايد دهب" },
    ],
  },
  {
    id: 887,
    code: "ye",
    codeLng: "yem",
    en: "Yemen",
    ar: "اليمن",
    dial: "+967",
    cities: [
      { shortCode: "AB", en: "Abyān", ar: "ابيان" },
      { shortCode: "AD", en: "'Adan", ar: "'ادان" },
      { shortCode: "DA", en: "Aḑ Ḑāli'", ar: "الدالي'" },
      { shortCode: "BA", en: "Al Bayḑā'", ar: "البايده'" },
      { shortCode: "HU", en: "Al Ḩudaydah", ar: "الهوديداه" },
      { shortCode: "JA", en: "Al Jawf", ar: "الجوف" },
      { shortCode: "MR", en: "Al Mahrah", ar: "المهراه" },
      { shortCode: "MW", en: "Al Maḩwīt", ar: "الماهويت" },
      { shortCode: "AM", en: "'Amrān", ar: "'امران" },
      { shortCode: "DH", en: "Dhamār", ar: "دامال" },
      { shortCode: "HD", en: "Ḩaḑramawt", ar: "حضر موت" },
      { shortCode: "HJ", en: "Ḩajjah", ar: "حجه" },
      { shortCode: "IB", en: "Ibb", ar: "ايب" },
      { shortCode: "LA", en: "Laḩij", ar: "لاهيج" },
      { shortCode: "MA", en: "Ma'rib", ar: "مغرب" },
      { shortCode: "RA", en: "Raymah", ar: "ريماه" },
      { shortCode: "SD", en: "Şā‘dah", ar: "ساداه" },
      { shortCode: "SN", en: "Şan‘ā'", ar: "سانا" },
      { shortCode: "SH", en: "Shabwah", ar: "شابوا" },
      { shortCode: "TA", en: "Tā‘izz", ar: "تائيز" },
    ],
  },
  {
    id: 894,
    code: "zm",
    codeLng: "zmb",
    en: "Zambia",
    ar: "زامبيا",
    dial: "+260",
    cities: [
      { shortCode: "02", en: "Central", ar: "سنترال" },
      { shortCode: "08", en: "Copperbelt", ar: "كوبربيلت" },
      { shortCode: "03", en: "Eastern", ar: "الشرقيه" },
      { shortCode: "04", en: "Luapula", ar: "لوابولا" },
      { shortCode: "09", en: "Lusaka", ar: "لوساكا" },
      { shortCode: "05", en: "Northern", ar: "الشماليه" },
      { shortCode: "06", en: "North-Western", ar: "شمال الغربيه" },
      { shortCode: "07", en: "Southern", ar: "الجنوبيه" },
      { shortCode: "01", en: "Western", ar: "الغربيه" },
    ],
  },
  {
    id: 716,
    code: "zw",
    codeLng: "zwe",
    en: "Zimbabwe",
    ar: "زيمبابوي",
    dial: "+263",
    cities: [
      { shortCode: "BU", en: "Bulawayo", ar: "بولاوايو" },
      { shortCode: "HA", en: "Harare", ar: "هاراري" },
      { shortCode: "MA", en: "Manicaland", ar: "مانيكالاند" },
      {
        shortCode: "MC",
        en: "Mashonaland Central",
        ar: "ماشونالاند المنتصفه",
      },
      { shortCode: "ME", en: "Mashonaland East", ar: "شرق ماشونالاند" },
      { shortCode: "MW", en: "Mashonaland West", ar: "غرب ماشونالاند" },
      { shortCode: "MV", en: "Masvingo", ar: "ماسفينجو" },
      {
        shortCode: "MN",
        en: "Matabeleland North",
        ar: "شمال ماتابيليلاند",
      },
      {
        shortCode: "MS",
        en: "Matabeleland South",
        ar: "جنوب ماتابيليلاند",
      },
      { shortCode: "MI", en: "Midlands", ar: "ميد لاندز" },
    ],
  },
];
