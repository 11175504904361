import React from "react";
import AvatarSkeleton from "../../common/loaders/skeleton/avatarSkeleton";
import LineSkeleton from "../../common/loaders/skeleton/lineSkeleton";

const InviteCardSkeleton = () => {
  return (
    <div className="single-grid">
      <div className="card one-third-grid-resp grid-zero-gap">
        <AvatarSkeleton fill shape="square" minHeight={100} />
        <LineSkeleton rows={2} title classes="all-med-padd" />
      </div>
    </div>
  );
};

export default InviteCardSkeleton;
