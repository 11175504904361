import React from "react";
// Packages
import { Tooltip } from "antd";
import TextItem from "../text/TextItem";

const TooltipContainer = ({ placement, tooltip, children, content }) => {
  return (
    <Tooltip
      placement={placement ?? "top"}
      title={<TextItem content={tooltip} />}
    >
      {content ? content() : <div>{children}</div>}
    </Tooltip>
  );
};

export default TooltipContainer;
