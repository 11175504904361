import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import loggerMiddleware from "./middleware/logger";
import rootReducer from "./reducers";
import onRehydrationMiddleware from "./middleware/onRehydrate";

const middlewares = [
  loggerMiddleware,
  thunkMiddleware,
  onRehydrationMiddleware,
];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(rootReducer, composedEnhancers);

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
}

persistStore(store);

export default store;
