const { authTypes } = require("./authTypes");

export function auth(state = {}, action) {
  switch (action.type) {
    case authTypes.LOGIN:
      return {
        id: action.auth._id,
        token: action.token,
        type: action.auth.type,
        email: action.auth.email,
        username: action.auth.username,
        initial: action.auth.initial,
        initStage: action.auth.initStage,
        approved: action.auth.approved,
        org: action.auth.organization?._id,
        accId: action.auth.data?.id?._id,
        accountDataId: action.auth.data?.id?.accData?.id,
        organizationName: action.auth.data?.id?.name,
        dataType: action.auth.data?.id?.type,
        image: action.auth.data?.id?.image,
        admin: action.auth.data?.id?.admin,
        notificationToken: action.notificationToken
      };
    case authTypes.CHANGE_USERNAME:
      return {
        ...state,
        username: action.username,
      };
    case authTypes.END_INIT:
      return {
        ...state,
        initial: true,
      };
    case authTypes.INC_STAGE:
      return {
        ...state,
        initStage: state.initStage + 1,
      };
    case authTypes.APPROVE_PROF:
      return {
        ...state,
        approved: true,
      };
    case authTypes.ADD_IMAGE:
      return {
        ...state,
        image: action.image,
        imgFlag: !state.imgFlag,
      };
    case authTypes.LOGOUT:
      return {};
    default:
      return state;
  }
}
