import moment from "moment";

export const momentHelper = {
  getAgeDate,
  getDateFormat,
  getTimeFormat,
  getFrom,
  getHours,
  getDateTimeFormat,
};
function getAgeDate(date, lang, t) {
  moment.locale(lang);
  let current = moment(new Date());
  let birth = moment(date);
  let yrs = current.diff(birth, "years");
  return `${birth.format("Do MMM YYYY")}, ${yrs} ${t("lbl.yrs")}`;
}
function getDateFormat(date, lang) {
  moment.locale(lang);
  return moment(date).format("Do MMM YYYY");
}
function getTimeFormat(date, lang) {
  moment.locale(lang);
  return moment(date).format("h:mm A");
}
function getDateTimeFormat(date, lang) {
  moment.locale(lang);
  return moment(date).format("Do MMM YYYY, h:mm A");
}
function getFrom(date, lang) {
  moment.locale(lang);
  return moment(date).fromNow();
}
function getHours(date) {
  const now = moment(new Date());
  const end = moment(date);
  const duration = moment.duration(now.diff(end));
  return duration.asHours();
}

export function getDateOfBirthWithAge(date, language, t) {
  moment.locale(language);
  let current = moment(new Date());
  let birth = moment(date);
  let yrs = current.diff(birth, "years");
  return `${birth.format("Do MMM YYYY")}, ${yrs} ${t("title.yrs")}`;
}

export function getAge(date, language, t) {
  moment.locale(language);
  let current = moment(new Date());
  let birth = moment(date);
  let yrs = current.diff(birth, "years");
  return `${yrs} ${t("title.yrs")}`;
}
