import React from "react";
// Packages
import { Skeleton } from "antd";

const LineSkeleton = ({ rows, title, classes, disable }) => {
  return (
    <div className={classes}>
      <Skeleton active={!disable} paragraph={{ rows }} title={title ?? false} />
    </div>
  );
};

export default LineSkeleton;
