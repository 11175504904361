import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { genders } from "../../../assets/const/gender";
import { reduxActions } from "../../../redux/actions";
import { apiServices } from "../../../services/apiServices";
import { constHelper } from "../../../utils/constHelper";
import history from "../../../utils/history";
import { HandleApiHook } from "../../../utils/hooks";
// Components
import FormButtons from "../../common/buttons/FormButtons";
import DateField from "../../common/fields/dateField";
import SelectField from "../../common/fields/selectField";
import TopLoader from "../../common/loaders/topLoader";
import ConfirmModal from "../../common/modals/confirmModal";
import TextItem from "../../common/text/TextItem";

const PersonalStart = ({ token, type, logout, endStage }) => {
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [country, setCountry] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [city, setCity] = useState(null);
  const [gender, setGender] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [logoutModal, setLogoutModal] = useState(false);

  // Submit personal
  const submitPersonal = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.auth.personalUpdate,
      body: {
        country,
        nationality,
        city,
        gender,
        dateOfBirth,
      },
      query: {
        token,
      },
      onSuccess: () => {
        endStage();
        history.replace(
          type === "acc" ? "/sub-account/explore" : "/third/approve"
        );
      },
    });
  };

  return (
    <form onSubmit={submitPersonal} className="single-grid">
      {loading && <TopLoader />}
      <TextItem classes="parag-lit" content="desc.enter-proceed" />
      <SelectField
        place="field.nationality"
        innerPlace="field.sel-nationality"
        value={nationality}
        error={getErrorItem("nationality")}
        onChange={(value) => {
          setNationality(value);
        }}
        options={constHelper.getCountries()}
      />
      <SelectField
        place="field.country"
        innerPlace="field.sel-country"
        value={country}
        error={getErrorItem("country")}
        onChange={(value) => {
          setCountry(value);
          setCity(null);
        }}
        options={constHelper.getCountries()}
      />
      <SelectField
        place="field.city"
        innerPlace="field.sel-city"
        value={city}
        error={getErrorItem("city")}
        onChange={setCity}
        options={country ? constHelper.getCities(country) : []}
        disabled={!country}
      />
      <SelectField
        place="field.gender"
        innerPlace="field.sel-gender"
        value={gender}
        error={getErrorItem("gender")}
        onChange={setGender}
        options={genders}
      />
      <DateField
        place="field.birth-date"
        value={dateOfBirth}
        error={getErrorItem("dateOfBirth")}
        onChange={setDateOfBirth}
        allowClear
        type="backward"
      />
      <FormButtons
        disabled={loading}
        onCancel={() => setLogoutModal(true)}
        submitContent="action.next"
        cancelContent="action.log-out"
        icon="right-arrow"
        flip
      />
      <ConfirmModal
        open={logoutModal}
        setOpen={setLogoutModal}
        title="confirm.logout"
        onAgree={logout}
      />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    type: state.auth.type,
  };
};

const mapDispathToProps = {
  addImage: reduxActions.authActions.addImage,
  logout: reduxActions.authActions.logout,
  endStage: reduxActions.authActions.endInit,
};

export default connect(mapStateToProps, mapDispathToProps)(PersonalStart);
