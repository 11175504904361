import React, { useState } from "react";
import { apiServices } from "../../../../services/apiServices";
import { HandleApiHook } from "../../../../utils/hooks";
import FormButtons from "../../../common/buttons/FormButtons";
import HeadTextField from "../../../common/fields/headTextField";
import TopLoader from "../../../common/loaders/topLoader";
import SettingItem from "../helpers/settingItem";

const PasswordSettingCard = ({ token }) => {
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [modal, setModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [rePass, setRePass] = useState("");

  // Submit password change
  const submitPasswordChange = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.auth.changePass,
      body: {
        currentPassword,
        newPassword,
        rePass,
      },
      query: {
        token,
      },
      onSuccess: () => {
        setCurrentPassword("");
        setNewPassword("");
        setRePass("");
        setModal(false);
      },
      successMessage: "change",
      params: { field: "field.password" },
    });
  };

  // Modal content
  const modalContent = () => {
    return (
      <form
        onSubmit={submitPasswordChange}
        className="single-grid grid-high-gap all-med-padd"
        noValidate
      >
        {loading && <TopLoader />}
        <HeadTextField
          place="field.curr-pass"
          innerPlace="field.enter-pass"
          value={currentPassword}
          error={getErrorItem("currentPassword")}
          onChange={setCurrentPassword}
          password
          autoFocus
        />
        <HeadTextField
          place="field.new-pass"
          innerPlace="field.enter-pass"
          value={newPassword}
          error={getErrorItem("newPassword")}
          onChange={setNewPassword}
          password
        />
        <HeadTextField
          place="field.conf-new-pass"
          innerPlace="field.enter-pass"
          value={rePass}
          error={getErrorItem("rePass")}
          password
          onChange={setRePass}
        />
        <FormButtons
          disabled={loading}
          onCancel={() => {
            setCurrentPassword("");
            setNewPassword("");
            setRePass("");
            setModal(false);
          }}
          submitContent="action.changeL"
          classes="top-sm-padd"
        />
      </form>
    );
  };

  return (
    <SettingItem
      icon="key"
      title="setting.pass"
      talk="setting.talk-pass"
      modal={modal}
      setModal={setModal}
      modalContent={modalContent}
      setValue={(value) => {
        setCurrentPassword(value);
        setNewPassword(value);
        setRePass(value);
      }}
    />
  );
};

export default PasswordSettingCard;
