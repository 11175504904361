import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { apiServices } from "../../../services/apiServices";
import { castingGalleryFilters, projectRoles } from "../../../utils/constants";
import history from "../../../utils/history";
import { HandleApiHook, LanguageHook } from "../../../utils/hooks";
import FormButtons from "../../common/buttons/FormButtons";
import ExploreItems from "../../common/layouts/exploreItems";
import FetchUiData from "../../common/layouts/fetchUiData";
import TopLoader from "../../common/loaders/topLoader";
import IconMenu from "../../common/menu/iconMenu";
import ConfirmModal from "../../common/modals/confirmModal";
import TextItem from "../../common/text/TextItem";
import UserCard from "../cards/userCard";
import CastingExploreSkeleton from "../skeleton/castingExploreSkeleton";
import UserCardsSkeleton from "../skeleton/userCardsSkeleton";
import CastingCard from "./cards/castingCard";
import ThirdPartyProfile from "../profile/thirdProfile";
import RadialButton from "../../common/buttons/radialButton";
import { getAge } from "../../../utils/momentHelper";

const ProjectCastingExplore = ({
  location,
  token,
  dataType,
  type: loggedType,
}) => {
  // Destruct Edit data
  const { type, projectId, castingId } = location.state ?? {};
  // Hooks
  const { language, t } = LanguageHook();
  const { loading, error, setError, submit } = HandleApiHook(true);
  const { loading: addCastingLoading, submit: submitAddCasting } =
    HandleApiHook();
  const { loading: removeCastingLoading, submit: submitRemoveCasting } =
    HandleApiHook();
  const [castingData, setCastingData] = useState(null);
  const [restCasting, setRestCasting] = useState(null);
  const [gallery, setGallery] = useState([]);
  const [openHero, setOpenHero] = useState(false);
  const [openBack, setOpenBack] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  // Check if location is valid
  useEffect(() => {
    if (!location.state || !location.state.projectId) history.replace("/");
    if (projectRoles[dataType ?? loggedType].casting === "none")
      history.replace(`/project/${projectId}`);
  }, []);

  // Empty casting object
  const emptyCastingObject = {
    type: type,
    role: "",
    hero: {
      third: "",
      accept: false,
    },
    back: {
      third: "",
      accept: false,
    },
  };

  const getGallTitleIcon = () => {
    if (type === "dir") return "director";
    if (type === "dop") return "user-camera";
    if (type === "cast") return "user-group";
    if (type === "photographer") return "camera";
    if (type === "artDirector") return "art-director";
    if (type === "hair") return "hair";
    if (type === "food") return "food";
    if (type === "makup") return "makup";
    if (type === "woredrope") return "woredrope";
  };

  // Add to page scrolling
  const addToPage = (data) => {
    let gallData = [...gallery, ...data.thrs];
    filterGallery(gallData);
  };

  // Filter Gallery
  const filterGallery = (data) => {
    let chosen = [];
    if (castingData && castingData.hero && castingData.hero.third)
      chosen.push(castingData.hero.third._id);
    if (castingData && castingData.back && castingData.back.third)
      chosen.push(castingData.back.third._id);
    if (type === "dir" || type === "dop") {
      data = data.filter(
        (x) => !chosen.includes(x._id) && x.auth.approved && x.auth.status
      );
    } else {
      data = data.filter(
        (x) =>
          !chosen.includes(x._id) &&
          x.auth.approved &&
          x.auth.status &&
          !restCasting[type]?.includes(x._id)
      );
    }
    setGallery(data);
  };

  // Fetch Casting Explore
  const fetchCastingExplore = () => {
    submit({
      service: apiServices.casting.getCastingExp,
      query: {
        token,
        type,
        proj: projectId,
      },
      onSuccess: ({ casting, ...restCasting }) => {
        setRestCasting(restCasting);
        if (casting.length !== 0) {
          if (castingId) {
            const find = casting.find((x) => x._id === castingId);
            setCastingData(find);
          } else setCastingData(emptyCastingObject);
        } else setCastingData(emptyCastingObject);
      },
    });
  };

  // Add casting
  const addCasting = (isHero, thirdId) => {
    const afterCasting = (data) => {
      let gall = [...gallery];
      if (data.hero.third && castingData.hero.third) {
        if (data.hero.third._id !== castingData.hero.third._id) {
          gall.push(castingData.hero.third);
        }
      }
      if (data.back.third && castingData.back.third) {
        if (data.back.third._id !== castingData.back.third._id) {
          gall.push(castingData.back.third);
        }
      }
      if (data.hero.third)
        gall = gall.filter((x) => x._id !== data.hero.third._id);
      if (data.back.third)
        gall = gall.filter((x) => x._id !== data.back.third._id);
      setGallery(gall);
      setCastingData(data);
    };
    setOpenBack(false);
    setOpenHero(false);
    submitAddCasting({
      service: apiServices.casting.addCasting,
      body: {
        token,
        casting: castingData,
        id: thirdId,
        hero: isHero,
        proj: projectId,
      },
      onSuccess: (data) => afterCasting(data),
    });
  };

  // Remove Casting
  const removeCasting = (isHero) => {
    const afterRemovingCasting = (data) => {
      let gallerySample = [...gallery];
      if (!data.hero.third && castingData.hero.third)
        gallerySample.push(castingData.hero.third);
      if (!data.back.third && castingData.back.third)
        gallerySample.push(castingData.back.third);
      setGallery(gallerySample);
      setCastingData(data);
    };
    submitRemoveCasting({
      service: apiServices.casting.removeCasting,
      body: {
        token,
        casting: castingData,
        proj: projectId,
        type,
        hero: isHero,
      },
      onSuccess: (data) => afterRemovingCasting(data),
    });
  };

  const getMenuItems = (id) => {
    return [
      {
        icon: "crown",
        iconColor: "brand",
        text: "casting.hero",
        onClick: () => {
          if (castingData.hero.third) setOpenHero(id);
          else addCasting(true, id);
        },
      },
      {
        icon: "refresh",
        iconColor: "orange",
        text: "casting.back",
        onClick: () => {
          if (castingData.back.third) setOpenBack(id);
          else addCasting(false, id);
        },
      },
    ];
  };

  const renderCards = () => {
    return (
      <div className="triple-grid">
        {gallery.map((gall) => {
          return (
            <UserCard
              key={gall._id}
              image={gall.image}
              name={gall.id}
              onClick={() => setSelectedAccount(gall)}
              UtilsRender={() => {
                return (
                  <div className="flex justify-start">
                    <TextItem
                      content={getAge(gall.dateOfBirth, language, t)}
                      classes="micro-lit"
                    />
                  </div>
                );
              }}
              OptionsRender={() => (
                <IconMenu
                  icon="dots"
                  color="black"
                  menuItems={getMenuItems(gall._id)}
                />
              )}
            />
          );
        })}
      </div>
    );
  };

  return (
    <FetchUiData
      service={fetchCastingExplore}
      observer={castingId}
      loading={loading}
      dataFetched={castingData}
      error={error}
      setError={setError}
      Loader={CastingExploreSkeleton}
      classes="single-grid bottom-extend top-extend-fill"
      noWait
    >
      {(addCastingLoading || removeCastingLoading) && <TopLoader />}
      <div className="flex justify-space-between full-width">
        <TextItem content={`casting.pot-${type}`} classes="parag-hev" />
        <FormButtons
          onSubmit={() => history.replace(`/project/${projectId}/casting`)}
        />
      </div>
      <div className="double-grid">
        <CastingCard
          isHero
          third={castingData?.hero?.third}
          accepted={castingData?.hero?.accept}
          type={type}
          projectId={projectId}
          removeCasting={removeCasting}
          removeLoad={removeCastingLoading}
        />
        <CastingCard
          third={castingData?.back?.third}
          accepted={castingData?.back?.accept}
          type={type}
          projectId={projectId}
          removeCasting={removeCasting}
          removeLoad={removeCastingLoading}
        />
      </div>
      <ExploreItems
        filterGroups={castingGalleryFilters}
        title={`casting.gall-${type}`}
        titleIcon={getGallTitleIcon()}
        service={apiServices.third.getCastingGallery}
        query={{
          token,
          type,
          projectId,
        }}
        succ={(data) => filterGallery(data.thrs)}
        addToPage={addToPage}
        loader={UserCardsSkeleton}
        isEmpty={gallery.length === 0}
        emptyTitle="empty.gallery"
        result={renderCards}
        wait={type}
        classes={selectedAccount ? "none" : ""}
        loadMore
        filterName="casting-gallery-filters"
      />
      {selectedAccount && (
        <div className="">
          <div className="flex justify-start not-dir">
            <RadialButton
              icon="left-arrow"
              onClick={() => setSelectedAccount(null)}
              classes="border-grey"
            />
            <TextItem
              content={`casting.prof-${type}`}
              classes="sparag-hev text-upper side-med-padd"
            />
          </div>
          <ThirdPartyProfile
            location={{
              state: {
                authId: selectedAccount.auth._id,
              },
            }}
            menuItems={getMenuItems(selectedAccount._id)}
            limited
          />
        </div>
      )}
      <ConfirmModal
        open={openHero}
        setOpen={setOpenHero}
        title="confirm.over-hero"
        onAgree={() => addCasting(true, openHero)}
      />
      <ConfirmModal
        open={openBack}
        setOpen={setOpenBack}
        title="confirm.over-back"
        onAgree={() => addCasting(false, openBack)}
      />
    </FetchUiData>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    dataType: state.auth.dataType,
    type: state.auth.type,
  };
};

export default connect(mapStateToProps)(ProjectCastingExplore);
