// Email regular expression pattern
export const EmailPattern = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

// Sub-account navbar
export const subAccountNavbarNavigation = [
  {
    icon: "dashboard",
    text: "navigation.dashboard",
    url: "/sub-account/explore",
  },
  {
    icon: "project",
    text: "navigation.ongoing",
    url: "/sub-account/explore/ongoing",
  },
  {
    icon: "team",
    text: "navigation.team",
    url: "/sub-account/explore/team",
  },
  {
    icon: "new-request",
    text: "navigation.new-request",
    url: "/sub-account/explore/new-request",
  },
];

export const assetTypes = {
  video: ["avi", "quicktime", "mov", "mp4"],
  image: ["jpg", "jpeg", "png"],
  videoOrImage: ["avi", "quicktime", "mov", "mp4", "jpg", "jpeg", "png"],
  file: ["pdf", "docx", "pptx"],
};

// Third navbar
export const thirdNavbarNavigation = [
  {
    icon: "dashboard",
    text: "navigation.dashboard",
    url: "/third/explore",
  },
  {
    icon: "project",
    text: "navigation.ongoing",
    url: "/third/explore/ongoing",
  },
];

// Project navbar
export const projectNavbarNavigation = (id, dataType) => {
  return [
    dataType &&
      projectRoles[dataType].casting !== "none" && {
        icon: "user-group",
        text: "title.casts",
        url: `/project/${id}/casting`,
      },
    {
      icon: "home",
      text: "navigation.back-home",
      url: "/",
    },
  ];
};

// Third party types for radio group
export const thirdPartyTypes = [
  { value: "cast", label: "title.cast", localize: true },
  { value: "dir", label: "title.dir", localize: true },
  { value: "dop", label: "title.dop-lng", localize: true },
  { value: "artDirector", label: "title.artDirector", localize: true },
  { value: "woredrope", label: "title.woredrope", localize: true },
  { value: "food", label: "title.food", localize: true },
  { value: "hair", label: "title.hair", localize: true },
  { value: "makup", label: "title.makup", localize: true },
  { value: "photographer", label: "title.photographer", localize: true },
];

// Knowledge types for radio group
export const knowledgeTypes = [
  { value: "facebook", label: "social.facebook" },
  { value: "twitter", label: "social.twitter" },
  { value: "instagram", label: "social.instagram" },
  { value: "linkedin", label: "social.linkedin" },
  { value: "behance", label: "social.behance" },
  { value: "other", label: "social.other" },
];

// Third profile approve steps
export const thirdProfileApproveSteps = (dataType) => {
  return [
    {
      title: "title.personal-info",
      desc: "desc.personal-comp",
      stage: 0,
    },
    dataType === "cast" && {
      title: "title.gallery",
      desc: "desc.upload-gallery",
      stage: 1,
    },
    dataType === "cast" && {
      title: "title.measure",
      desc: "desc.enter-measure",
      stage: 2,
    },
    dataType !== "cast" && {
      title: "title.showreel",
      desc: "desc.upload-showreel",
      stage: 1,
    },
  ];
};

// Icon Text Mapper
export const iconTextMapper = {
  micro: "micro",
  micro1: "micro",
  micro2: "micro",
  tiny: "micro",
  tiny1: "micro",
  tiny2: "micro",
  small: "tiny",
  small1: "tiny",
  small2: "tiny",
  med: "sparag",
  med1: "sparag",
  med2: "parag",
  big: "label",
  big1: "label",
  big2: "label",
  giant: "shead",
  giant1: "shead",
  giant2: "shead",
  monster: "head",
  monster1: "head",
  monster2: "head",
  monster3: "head",
  monster4: "head",
};

// Project Tab view tabs
export const projectTabs = [
  {
    tag: "screenplay",
    title: "nav.screenplay",
    unit: "title.lines",
    icon: "writing",
    url: `/project/:projectId/screenplay`,
  },
  {
    tag: "breakdown",
    title: "nav.breakdowns",
    unit: "title.breakdowns",
    icon: "layers",
    url: `/project/:projectId/breakdowns`,
  },
  {
    tag: "callsheet",
    title: "nav.callsheets",
    unit: "title.sheets",
    icon: "sheets",
    url: `/project/:projectId/callsheets`,
  },
  {
    tag: "schedule",
    title: "nav.schedule",
    unit: "title.scenes",
    icon: "list",
    url: `/project/:projectId/schedule`,
  },
  {
    tag: "casting",
    title: "project.cast",
    unit: "title.casts",
    icon: "user-group",
    url: `/project/:projectId/casting`,
  },
  {
    tag: "location",
    title: "nav.locations",
    unit: "nav.locations",
    icon: "location",
    url: `/project/:projectId/location`,
  },
];

// Project Roles
export const projectRoles = {
  cast: {
    project: "view",
    casting: "none",
  },
  dir: {
    project: "view",
    casting: "edit",
  },
  dop: {
    project: "view",
    casting: "edit",
  },
  photographer: {
    project: "view",
    casting: "none",
  },
  artDirector: {
    project: "view",
    casting: "none",
  },
  woredrope: {
    project: "view",
    casting: "none",
  },
  hair: {
    project: "view",
    casting: "none",
  },
  food: {
    project: "view",
    casting: "none",
  },
  makup: {
    project: "view",
    casting: "none",
  },
  acc: {
    project: "edit",
    casting: "edit",
  },
};

// Age ranges
export const ageRanges = {
  baby: "baby",
  kid: "kid",
  youngAdult: "youngAdult",
  adult: "adult",
  middleAgedAdult: "middleAgedAdult",
  oldAdult: "oldAdult",
};

// Casting gallery filters
export const castingGalleryFilters = [
  {
    title: "field.gender",
    defVal: "",
    name: "gender",
    values: [
      { value: "", label: "title.all" },
      { value: "Male", label: "gender.Male" },
      { value: "Female", label: "gender.Female" },
    ],
  },
  {
    title: "title.age",
    defVal: "",
    name: "age",
    values: [
      { value: "", label: "title.all" },
      ...Object.keys(ageRanges).map((range) => ({
        value: range,
        label: `age.${range}`,
      })),
    ],
  },
];
