import React, { useState } from "react";
import { hist } from "../../../../utils/history";
import { LanguageHook } from "../../../../utils/hooks";
import { getAge, getDateOfBirthWithAge } from "../../../../utils/momentHelper";
import FloatIconButton from "../../../common/buttons/floatIconButton";
import RadialButton from "../../../common/buttons/radialButton";
import IconTitle from "../../../common/layouts/IconTitle";
import TextItem from "../../../common/text/TextItem";
import UserCard from "../../cards/userCard";
import NotChosenThirdSkeleton from "../../skeleton/notChosenThirdSkeleton";

const NotConfirmedCard = ({
  type,
  notConfirmedData,
  projectId,
  confirmCasting,
  confirmLoading,
}) => {
  const { language, t } = LanguageHook();
  const submitConfirm = (isHero) => {
    confirmCasting({
      hero: isHero,
      casting: notConfirmedData,
      type,
    });
  };

  const renderChosenUser = (isHero) => {
    const { hero, back } = notConfirmedData ?? {};
    const { third: heroThird, accept: heroAccept } = hero ?? {};
    const { third: backThird, accept: backAccept } = back ?? {};

    const data = isHero ? heroThird : backThird;
    const accept = isHero ? heroAccept : backAccept;

    if (data)
      return (
        <UserCard
          image={data.image}
          name={data.id}
          onClick={() => {
            hist.push(`/project/${projectId}/casting/third-profile`, {
              authId: data.auth,
            });
          }}
          UtilsRender={() => {
            return (
              <div>
                <div className="flex justify-start">
                  <TextItem
                    content={getAge(data.dateOfBirth, language, t)}
                    classes="micro-lit"
                  />
                </div>
                <div className="flex justify-start">
                  <IconTitle
                    content={`casting.${isHero ? "hero" : "back"}`}
                    icon={isHero ? "crown" : "refresh"}
                    iconColor={isHero ? "brand" : "orange"}
                    textClasses="tiny-med"
                  />
                  {accept ? (
                    <TextItem
                      content={`status.accept`}
                      classes={`micro-hev active`}
                    />
                  ) : (
                    <TextItem
                      content={`status.pend`}
                      classes={`micro-hev pend`}
                    />
                  )}
                </div>
              </div>
            );
          }}
          OptionsRender={() => {
            if (accept && !confirmLoading)
              return (
                <RadialButton
                  icon="check"
                  iconSize="small"
                  classes="grid-center border-grey"
                  onClick={() => submitConfirm(isHero)}
                  tooltip="title.confirm-casting"
                />
              );
            return null;
          }}
        />
      );
    return <NotChosenThirdSkeleton />;
  };
  return (
    <div>
      <div className="flex justify-space-between full-width">
        <div></div>
        <FloatIconButton
          name="edit"
          color="black"
          tooltip="title.edit"
          onClick={() =>
            hist.push(`/project/${projectId}/casting/explore`, {
              type,
              projectId,
              castingId: notConfirmedData._id,
            })
          }
        />
      </div>
      <div className="double-grid">
        {renderChosenUser(true)}
        {renderChosenUser(false)}
      </div>
    </div>
  );
};

export default NotConfirmedCard;
