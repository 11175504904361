import { Image } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { apiServices } from "../../../services/apiServices";
// Helpers
import { HandleApiHook } from "../../../utils/hooks";
import FetchUiData from "../../common/layouts/fetchUiData";
import FullGallerySkeleton from "../skeleton/fullGallerySkeleton";
import AlbumCard from "./cards/albumCard";
// Components
import GalleryCard from "./cards/galleryCard";

const FullGallery = ({ token, location }) => {
  // Hooks
  const { loading, error, setError, submit } = HandleApiHook(true);
  const [profileData, setProfileData] = useState(null);
  const [otherProfile, setOtherProfile] = useState(
    location.state ? location.state.authId : undefined
  );

  // Fetch profile data
  const fetchProfile = () => {
    setOtherProfile(location.state ? location.state.authId : undefined);
    submit({
      service: apiServices.third.getThirdPartyProfile,
      query: {
        token,
        authId: otherProfile,
      },
      onSuccess: (profileDataResult) => setProfileData(profileDataResult),
    });
  };

  return (
    <FetchUiData
      service={fetchProfile}
      observer={location.state}
      loading={loading}
      dataFetched={profileData}
      error={error}
      setError={setError}
      Loader={FullGallerySkeleton}
      classes="single-grid bottom-extend top-extend-fill"
      noWait
    >
      <Image.PreviewGroup>
        <GalleryCard profileData={profileData} other={otherProfile} />
        <AlbumCard profileData={profileData} other={otherProfile} />
      </Image.PreviewGroup>
    </FetchUiData>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(FullGallery);
