import React, { useState } from "react";
import { connect } from "react-redux";
import { apiServices } from "../../../../services/apiServices";
import { getCastingInfo } from "../../../../utils/helperFunctions";
import { HandleApiHook } from "../../../../utils/hooks";
import FetchUiData from "../../../common/layouts/fetchUiData";
import InviteCardSkeleton from "../../skeleton/inviteCardSkeleton";
import InviteCardAvatar from "../helpers/inviteCardAvatar";
import InviteCardTalk from "../helpers/inviteCardTalk";

const InviteCard = ({ casting, token, accId, type }) => {
  // Hooks
  const { loading, error, setError, submit } = HandleApiHook(true);
  const [castingData, setCastingData] = useState(null);

  // Fetch invitation data
  const fetchInvitationData = () => {
    submit({
      service: apiServices.casting.getCasting,
      query: { token, casting },
      onSuccess: (castingDataResult) =>
        setCastingData(getCastingInfo(accId, castingDataResult)),
    });
  };

  // Change acceptance
  const acceptRequest = () => {
    let castingDataSample = { ...castingData };
    castingDataSample.accepted = true;
    setCastingData(castingDataSample);
  };

  if (type === "3rd")
    return (
      <FetchUiData
        service={fetchInvitationData}
        observer={casting}
        loading={loading}
        dataFetched={castingData}
        error={error}
        setError={setError}
        Loader={InviteCardSkeleton}
        noWait
      >
        <div className="card one-third-grid-resp grid-zero-gap">
          <InviteCardAvatar castingData={castingData} />
          <InviteCardTalk
            castingData={castingData}
            acceptRequest={acceptRequest}
          />
        </div>
      </FetchUiData>
    );
  return null;
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    accId: state.auth.accId,
    type: state.auth.type,
  };
};

export default connect(mapStateToProps)(InviteCard);
