import React, { useState, useEffect } from "react";
// Packages
import { Steps } from "antd";
// Components
import TextItem from "../../../common/text/TextItem";
import { calculateThirdPartyProfileStage } from "../../../../utils/helperFunctions";
import ApproveCardSkeleton from "../../skeleton/approveCardSkeleton";

const { Step } = Steps;

const StepsRender = ({
  steps,
  dataType,
  profileData,
  currentStageProp,
  stageStatusProp,
}) => {
  // Hooks
  const [stepDirection, setStepDirection] = useState("horizontal");
  const { currentStage, setCurrentStage } = currentStageProp;
  const { stageStatus, setStageStatus } = stageStatusProp;

  // Set stage and status
  useEffect(() => {
    const { stage, status } = calculateThirdPartyProfileStage(
      dataType,
      profileData
    );
    setCurrentStage(stage);
    setStageStatus(status);
  }, [profileData]);

  // Set steps direction
  useEffect(() => {
    if (dataType === "cast") {
      if (window.innerWidth > 1100) setStepDirection("horizontal");
      else setStepDirection("vertical");
    } else setStepDirection("");
  }, []);

  // Loading Stage
  if (!currentStage) return <ApproveCardSkeleton />;

  return (
    <div className="card all-med-padd not-dir single-grid">
      <Steps
        className={`${stageStatus === "done" && "opacity"}`}
        direction={stepDirection}
        current={currentStage}
      >
        {steps.map((step) => {
          return (
            <Step
              key={step.title}
              title={
                <TextItem
                  content={step.title}
                  classes={`tiny-med text-height-lrg ${
                    (currentStage > step.stage || stageStatus === "done") &&
                    "brand"
                  } ${currentStage < step.stage && "text-opacity"}`}
                />
              }
              description={
                <TextItem
                  content={step.desc}
                  classes="micro-lit text-opacity text-start"
                />
              }
            />
          );
        })}
      </Steps>
    </div>
  );
};

export default StepsRender;
