import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxActions } from "../../../redux/actions";
import { HandleApiHook } from "../../../utils/hooks";
import TopLoader from "../../common/loaders/topLoader";
import TextItem from "../../common/text/TextItem";
import ImageComponent from "../../common/image/image";
import { apiServices } from "../../../services/apiServices";
import HeadTextField from "../../common/fields/headTextField";
import FormButtons from "../../common/buttons/FormButtons";
import ConfirmModal from "../../common/modals/confirmModal";
import history from "../../../utils/history";

const OrganizationPasswordImageStart = ({
  token,
  organizationName,
  image,
  addImage,
  incStage,
  logout,
}) => {
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [rePass, setRePass] = useState("");
  const [logoutModal, setLogoutModal] = useState(false);

  // Submit welcome
  const submitWelcome = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.org.organizationWelcome,
      body: {
        currentPassword,
        newPassword,
        rePass,
        image,
      },
      query: {
        token,
      },
      onSuccess: () => {
        incStage();
        history.replace(`/organization/start/sub-account`);
      },
    });
  };

  return (
    <form onSubmit={submitWelcome} className="single-grid">
      {loading && <TopLoader />}
      <TextItem content="title.welcome" classes="shead-hev" />
      <TextItem
        content="desc.org-welcome"
        classes="sparag-lit text-start"
        params={{ orgName: organizationName }}
      />
      <TextItem content="desc.org-welc" classes="tiny-lit opacity" />
      <div className="one-third-grid-resp grid-gap">
        <ImageComponent
          name="field.profile-picture"
          size="monster1"
          image={image}
          upload={apiServices.org.addProfilePic}
          preview
          emptyState={{
            type: "icon",
            icon: "building",
            color: "black",
            classes: "opacity",
          }}
          exportImage={addImage}
          classes="align-self-center justify-self-center"
        />
        <div className="single-grid">
          <HeadTextField
            place="field.curr-pass"
            innerPlace="field.enter-pass"
            value={currentPassword}
            error={getErrorItem("currentPassword")}
            onChange={setCurrentPassword}
            password
            autoFocus
          />
          <HeadTextField
            place="field.new-pass"
            innerPlace="field.enter-pass"
            value={newPassword}
            error={getErrorItem("newPassword")}
            onChange={setNewPassword}
            password
          />
          <HeadTextField
            place="field.conf-new-pass"
            innerPlace="field.enter-pass"
            value={rePass}
            error={getErrorItem("rePass")}
            password
            onChange={setRePass}
          />
        </div>
      </div>
      <FormButtons
        disabled={loading}
        onCancel={() => setLogoutModal(true)}
        submitContent="action.next"
        cancelContent="action.log-out"
        icon="right-arrow"
        flip
      />
      <ConfirmModal
        open={logoutModal}
        setOpen={setLogoutModal}
        title="confirm.logout"
        onAgree={logout}
      />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    image: state.auth.image,
    organizationName: state.auth.organizationName,
  };
};

const mapDispathToProps = {
  addImage: reduxActions.authActions.addImage,
  incStage: reduxActions.authActions.incStage,
  logout: reduxActions.authActions.logout,
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(OrganizationPasswordImageStart);
