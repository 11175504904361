import React from "react";
import AvatarSkeleton from "../../common/loaders/skeleton/avatarSkeleton";
import LineSkeleton from "../../common/loaders/skeleton/lineSkeleton";

const UserCardSkeleton = () => {
  return (
    <div className="one-third-grid-resp grid-zero-gap">
      <AvatarSkeleton shape="circle" size="big2" classes="grid-center" />
      <LineSkeleton rows={2} classes="all-med-padd" />
    </div>
  );
};

export default UserCardSkeleton;
