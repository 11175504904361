import React from "react";
// Components
import TextItem from "../../../common/text/TextItem";

const IdentificationSubHeader = ({ profileData, userData }) => {
  return (
    <>
      <div className="flex justify-start">
        {profileData.username && (
          <TextItem
            content={`@${profileData.username.trim()}`}
            classes="tiny-lit"
          />
        )}
        {profileData.type === "3rd" && (
          <>
            <div className="padd-dot"></div>
            <TextItem content={`#${userData.id}`} classes="tiny-lit" />
          </>
        )}
        <div className="padd-dot"></div>
        <TextItem
          content={`status.${profileData.status ? "active" : "deac"}`}
          classes={`tiny-hev ${profileData.status ? "active" : "deac"}`}
        />
      </div>
      <TextItem
        content={
          profileData.type === "3rd"
            ? `title.${userData.type}${userData.type === "dop" ? "-lng" : ""}`
            : userData.title
        }
        classes="sparag-hev brand top-sm-marg"
      />
      <TextItem content={userData.about} classes="top-sm-padd tiny-lit" />
    </>
  );
};

export default IdentificationSubHeader;
