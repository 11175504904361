import React from "react";
// Components
import TextItem from "../../text/TextItem";

const FieldHeader = ({ head, label, disabled, error }) => {
  return (
    <div
      style={{ marginBottom: "0.2rem" }}
      className="flex-nowrap justify-space-between align-center side-sm-padd"
    >
      <TextItem
        classes={`tiny-med ${disabled && "disabled"} ${error && "error"}`}
        content={head}
      />
      <TextItem
        classes={`micro-lit opacity ${disabled ? "disabled" : ""} `}
        content={label}
      />
    </div>
  );
};

export default FieldHeader;
