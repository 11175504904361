import React, { useEffect } from "react";
// Helpers
import { connect } from "react-redux";
import { reduxActions } from "../../../redux/actions";
import history from "../../../utils/history";
// Components
import NormalButton from "../../common/buttons/NormalButton";
import { GetIcon } from "../../../assets/utils/IconGenerator";
import TextItem from "../../common/text/TextItem";

const AccountActivated = ({ clearEmail }) => {
  // Clean redux mail
  useEffect(() => {
    clearEmail();
  }, []);

  return (
    <div className="single-grid">
      <GetIcon name="check-circle" size="big2" classes="margin-auto" />
      <TextItem classes="shead-hev text-center" content="title.acc-activated" />
      <TextItem classes="sparag-lit text-center" content="desc.acc-activated" />
      <NormalButton
        onClick={() => history.push("/")}
        content="action.log-in"
        classes="margin-auto"
      />
    </div>
  );
};

const mapDispatchToProps = {
  clearEmail: reduxActions.emailActions.clearEmail,
};

export default connect(null, mapDispatchToProps)(AccountActivated);
