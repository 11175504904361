import React, { useState } from "react";
// Helpers
import history from "../../../../utils/history";
// Components
import { GetBrandLogo } from "../../../../assets/utils/IconGenerator";
import {
  projectNavbarNavigation,
  subAccountNavbarNavigation,
  thirdNavbarNavigation,
} from "../../../../utils/constants";
import FloatIconButton from "../../../common/buttons/floatIconButton";
import FullScreenNavigation from "../navigation/fullScreenNavigation";
import WideScreenNavigation from "../navigation/wideScreenNavigation";
import { connect } from "react-redux";

const NavbarNavigations = ({ type, approved, project, dataType }) => {
  // Hooks
  const [menu, setMenu] = useState(false);

  // Navigations
  const navigations = {
    "3rd": thirdNavbarNavigation,
    acc: subAccountNavbarNavigation,
    project: project
      ? projectNavbarNavigation(project._id, dataType ?? type)
      : null,
  };

  return (
    <div className="flex icon-big width-fit-content-imp">
      {approved && (
        <FloatIconButton
          name="text"
          color="white"
          size="med"
          classes="end-med-padd to-show"
          flip
          onClick={() => setMenu(true)}
        />
      )}
      <GetBrandLogo
        name="logo-sm-wht"
        size="big"
        classes={`pointer ${project && "to-show"}`}
        onClick={() => history.push("/")}
      />
      <FullScreenNavigation
        navigations={navigations[project ? "project" : type]}
        project={project}
        active={menu}
        setActive={setMenu}
      />
      {!project && (
        <WideScreenNavigation
          navigations={approved ? navigations[type] : null}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.auth.type,
    approved: state.auth.approved,
    dataType: state.auth.dataType,
  };
};

export default connect(mapStateToProps)(NavbarNavigations);
