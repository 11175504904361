import React from "react";
// Components
import { GetIcon } from "../../../assets/utils/IconGenerator";
import FloatIconButton from "../buttons/floatIconButton";
import TextItem from "../text/TextItem";

const ErrorConnection = ({ onRefresh, classes }) => {
  return (
    <div className={`flex flex-column all-padd ${classes}`}>
      <GetIcon name="error" color="second" size="med2" />
      <TextItem content="error.error" classes="sparag-lit" />
      <FloatIconButton
        onClick={onRefresh}
        name="refresh"
        color="black"
        size="tiny2"
        classes="top-marg"
      />
    </div>
  );
};

export default ErrorConnection;
