const { setAccount } = require("../stores/account/accountActions");

let hasRehydrated = false;

const onRehydrationMiddleware = (store) => (next) => (action) => {
  try {
    if (!hasRehydrated && action.type === "persist/REHYDRATE") {
      hasRehydrated = true;
      next(action);
      store.dispatch(setAccount(store.getState().auth.token, store.getState().auth.id, store.getState().auth.notificationToken));
    } else next(action);
  } catch (error) {}
};

export default onRehydrationMiddleware;
