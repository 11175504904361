import React from "react";
import { Switch, Route } from "react-router-dom";
import FullGallery from "../../leap/profile/fullGallery";
import ProjectExplore from "../../leap/project/projectExplore";
import Settings from "../../leap/settings/settings";
import NavbarComponent from "../../leap/navbar/navbar";
import editPersonalInformation from "../../leap/profile/editPersonalInformation";
import EditCastingShot from "../../leap/profile/editCastingShot";
import EditShowreel from "../../leap/profile/editShowreel";
import ThirdPartyProfile from "../../leap/profile/thirdProfile";
import { apiServices } from "../../../services/apiServices";
import Notifications from "../../leap/notifications/notifications";

const ExploreThird = ({ location }) => {
  return (
    <div className="layout-container">
      <NavbarComponent location={location} />
      <div className="flow-cont">
        <div className="full-container content-container">
          <Switch>
            <Route path="/third/explore/settings" component={Settings} />
            <Route
              path="/third/explore/profile/full-gallery"
              component={FullGallery}
            />
            <Route
              path="/third/explore/profile/edit-casting"
              component={EditCastingShot}
            />
            <Route
              path="/third/explore/profile/edit-showreel"
              component={EditShowreel}
            />
            <Route
              path="/third/explore/profile/edit-personal"
              component={editPersonalInformation}
            />
            <Route
              path="/third/explore/profile"
              component={() => (
                <ThirdPartyProfile
                  location={location}
                  imageUploadService={apiServices.third.addProfilePic}
                  fullGalleryUrl="/third/explore/profile/full-gallery"
                  identificationCardEditUrl="/third/explore/profile/edit-personal"
                  castingShotEditUrl="/third/explore/profile/edit-casting"
                  showreelEditUrl="/third/explore/profile/edit-showreel"
                />
              )}
            />
            <Route
                path="/third/explore/notifications"
                component={() => (
                  <div className="side-med-padd-fill">
                    <Notifications />
                  </div>
                )}
              />
            <Route
              path="/third/explore/ongoing"
              component={() => (
                <ProjectExplore
                  filterName="third-projects-filter"
                  type="proj"
                />
              )}
            />
            <Route
              path="/third/explore/"
              component={() => (
                <ProjectExplore filterName="third-requests-filter" type="req" />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default ExploreThird;
