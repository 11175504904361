import React from "react";
import { GetIcon } from "../../../assets/utils/IconGenerator";
// Helpers
import { hist } from "../../../utils/history";
// Components
import FloatIconButton from "../buttons/floatIconButton";
import IconMenu from "../menu/iconMenu";

const CardUtilities = ({
  icon,
  iconSize,
  edit,
  editUrl,
  editData,
  onEditClick,
  menuItems,
  classes,
}) => {
  return (
    <div className={`flex justify-start ${classes}`}>
      {icon && <GetIcon classes="end-sm-marg" name={icon} size={iconSize} />}
      {edit && (
        <FloatIconButton
          name="edit"
          size="micro2"
          color="black"
          tooltip="title.edit"
          onClick={() =>
            onEditClick
              ? onEditClick()
              : hist.push(editUrl, {
                  editData: { ...editData },
                })
          }
        />
      )}
      {menuItems && (
        <IconMenu
          icon="dots"
          color="black"
          menuItems={menuItems}
          placement="right"
          classes="start-sm-marg"
        />
      )}
    </div>
  );
};

export default CardUtilities;
