import { connect } from "react-redux";
import { GetIcon } from "../../../../assets/utils/IconGenerator";
import { apiServices } from "../../../../services/apiServices";
import { HandleApiHook, LanguageHook } from "../../../../utils/hooks";
import history from "../../../../utils/history";
import { momentHelper } from "../../../../utils/momentHelper";
import TextItem from "../../../common/text/TextItem";

const NotificationCard = ({ notification, updateNotification, token }) => {
  const { language } = LanguageHook();
  const { loading, submit } = HandleApiHook();

  const readNotification = () => {
    submit({
      service: apiServices.notification.markAsRead,
      query: {
        notificationId: notification._id,
        token,
      },
      onSuccess: () => {
        updateNotification({
          ...notification,
          read: true,
        });
      },
    });
  };

  return (
    <div
      style={{ backgroundColor: notification.read ? "#fff" : "#d6e9b1" }}
      className={`single-grid card all-med-padd pointer scale-hover ${loading ? "opacity" : ""}`}
      onClick={
         !loading
          ? () => {
            readNotification();
            if (notification.link) {
              let resultLink = notification.link.includes("http")
                ? notification.link.replace(
                    process.env.REACT_APP_CURRENT_URL,
                    ""
                  )
                : notification.link;
              history.push(resultLink);
            }
          }
          : () => {}
      }
    >
      <div>
        <div className="flex-nowrap justify-space-between">
          <div style={{ gap: 10 }} className="flex justify-start">
            <TextItem
              content={`notifications.${
                notification.type ?? "new-notification"
              }`}
              classes="sparag-hev"
            />
            <TextItem
              content={momentHelper.getFrom(notification.createdAt, language)}
              classes="micro-lit opacity"
            />
          </div>
          {notification.link && <GetIcon name="open" color="black" />}
        </div>
        <TextItem
          content={notification.title}
          classes="tiny-med top-sm-padd"
          normal
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(NotificationCard);
