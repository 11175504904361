import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { apiServices } from "../../../services/apiServices";
// Components
import ProjectCard from "./cards/projectCard";
import ExploreItems from "../../common/layouts/exploreItems";
import ProjectsSkeleton from "../skeleton/projectsSkeleton";

const ProjectExplore = ({
  filterName,
  subAccount,
  type,
  auth,
  otherProfileId,
}) => {
  // Hooks
  const [projects, setProjects] = useState([]);
  const isRequest = type === "req";

  const filterGroups = [
    isRequest && {
      title: "title.status",
      defVal: "",
      name: "status",
      values: [
        { value: "", label: "title.all" },
        { value: "pend", label: "statusL.pend" },
        { value: "rej", label: "statusL.rej" },
        { value: "conf", label: "statusL.conf" },
      ],
    },
    !isRequest && {
      title: "title.status",
      defVal: "going",
      name: "status",
      values: [
        { value: "", label: "title.all" },
        { value: "going", label: "statusL.going" },
        { value: "fin", label: "statusL.fin" },
        { value: "arch", label: "statusL.arch" },
      ],
    },
    subAccount && {
      title: "title.projects",
      defVal: "",
      name: "creator",
      values: [
        { value: "", label: "title.all" },
        { value: "creator", label: "title.creator" },
        { value: "collab", label: "title.collaborator" },
      ],
    },
    !subAccount &&
      isRequest && {
        title: "casting.type",
        defVal: "",
        name: "type",
        values: [
          { value: "", label: "title.all" },
          { value: "hero", label: "casting.hero" },
          { value: "back", label: "casting.back" },
        ],
      },
    !subAccount &&
      isRequest && {
        title: "title.acceptance",
        defVal: "",
        name: "accept",
        values: [
          { value: "", label: "title.all" },
          { value: "pending", label: "statusL.not-yet" },
          { value: "accept", label: "statusL.accept" },
        ],
      },
  ];

  // Add to page
  const addToPage = (data) => {
    let projectsData = [...projects, ...data.projects];
    setProjects(projectsData);
  };

  // Render Item
  const renderResult = () => {
    return (
      <div className="grid projects-grid">
        {projects.map((project) => {
          return (
            <ProjectCard
              key={project._id}
              project={project}
              subAccount={subAccount}
            />
          );
        })}
      </div>
    );
  };

  return (
    <ExploreItems
      title={`title.${isRequest ? "requests" : "projects"}`}
      titleIcon={isRequest ? "writing" : "project"}
      filterName={filterName}
      filterGroups={filterGroups}
      observer={type}
      service={
        subAccount
          ? apiServices.proj.exploreSubProjects
          : apiServices.casting.exploreCastingProjects
      }
      query={{
        token: auth.token,
        id: otherProfileId,
        type,
      }}
      succ={(data) => setProjects(data.projects)}
      addToPage={addToPage}
      loader={ProjectsSkeleton}
      isEmpty={projects.length === 0}
      emptyTitle={`empty.${isRequest ? "requests" : "projects"}`}
      result={renderResult}
      classes="bottom-extend top-extend"
      loadMore
      noWait
    />
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ProjectExplore);
