import React from "react";
// Helpers
import { Switch } from "react-router-dom";
import PrivateRoute from "../../routes/privateRoute";
// Components
import ExploreSubAccoount from "./explore";
import SubAccountStart from "./start";

const SubAccountHome = () => {
  return (
    <Switch>
      <PrivateRoute
        path="/sub-account/start"
        component={SubAccountStart}
        navigationKey="start"
      />
      <PrivateRoute
        path="/sub-account/explore"
        component={ExploreSubAccoount}
        navigationKey="explore"
      />
    </Switch>
  );
};

export default SubAccountHome;
