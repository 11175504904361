import React from "react";
// Helpers
import { GetIcon } from "../../../assets/utils/IconGenerator";
import TooltipContainer from "../tooltip/Tooltip";

const RadialButton = ({
  icon,
  iconSize,
  color,
  onClick,
  disabled,
  classes,
  tooltip,
  placement,
}) => {
  // Radial button content
  const content = () => {
    return (
      <div
        onClick={disabled ? null : onClick}
        className={`flex radial fit-content all-sm-padd ${
          disabled ? "" : "white-back pointer scale-hover"
        } ${classes}`}
      >
        <GetIcon
          disabled={disabled}
          name={icon}
          size={iconSize}
          color={color}
        />
      </div>
    );
  };

  if (!tooltip) return content();
  return (
    <TooltipContainer
      tooltip={tooltip}
      placement={placement}
      content={content}
    />
  );
};

export default RadialButton;
