import React, { useState, useEffect } from "react";
// Packages
import { connect } from "react-redux";
// Helpers
import { genders } from "../../../assets/const/gender";
import { apiServices } from "../../../services/apiServices";
import { constHelper } from "../../../utils/constHelper";
import history from "../../../utils/history";
import { HandleApiHook } from "../../../utils/hooks";
// Components
import FormButtons from "../../common/buttons/FormButtons";
import DateField from "../../common/fields/dateField";
import HeadTextField from "../../common/fields/headTextField";
import PhoneField from "../../common/fields/phoneField";
import SelectField from "../../common/fields/selectField";
import TextAreaField from "../../common/fields/TextAreaField";
import IconTitle from "../../common/layouts/IconTitle";
import TopLoader from "../../common/loaders/topLoader";
import ConfirmModal from "../../common/modals/confirmModal";

const EditPersonalInformation = ({ location, token }) => {
  // Destruct Edit data
  const { editData } = location.state ?? {};
  const {
    firstName: initFirstName,
    lastName: initLastName,
    country: initCountry,
    nationality: initNationality,
    city: initCity,
    gender: initGender,
    dateOfBirth: initdateOfBirth,
    about: initAbout,
    number: initNumber,
    otherNumber: initOtherNumber,
    whatsapp: initWhatsapp,
    facebook: initFacebook,
    twitter: initTwitter,
    linkedin: initLinkedin,
  } = editData ?? {};
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [firstName, setFirstName] = useState(initFirstName);
  const [lastName, setLastName] = useState(initLastName);
  const [country, setCountry] = useState(initCountry);
  const [nationality, setNationality] = useState(initNationality);
  const [city, setCity] = useState(initCity);
  const [gender, setGender] = useState(initGender);
  const [dateOfBirth, setDateOfBirth] = useState(initdateOfBirth);
  const [about, setAbout] = useState(initAbout);
  const [number, setNumber] = useState(initNumber);
  const [otherNumber, setOtherNumber] = useState(initOtherNumber ?? "");
  const [whatsapp, setWhatsapp] = useState(initWhatsapp ?? "");
  const [facebook, setFacebook] = useState(initFacebook ?? "");
  const [twitter, setTwitter] = useState(initTwitter ?? "");
  const [linkedin, setLinkedin] = useState(initLinkedin ?? "");
  const [cancelModal, setCancelModal] = useState(false);

  // Return if no data provided
  useEffect(() => {
    if (!editData) history.goBack();
  }, []);

  // Submit
  const submitPersonalEdit = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.auth.personalEdit,
      body: {
        firstName,
        lastName,
        country,
        nationality,
        city,
        gender,
        dateOfBirth,
        about,
        number,
        otherNumber,
        whatsapp,
        facebook,
        twitter,
        linkedin,
      },
      query: {
        token,
      },
      onSuccess: () => history.goBack(),
    });
  };

  return (
    <form
      onSubmit={submitPersonalEdit}
      className="noncard-container single-grid bottom-extend top-extend"
      noValidate
    >
      {loading && <TopLoader />}
      <IconTitle
        content="title.edit-personal"
        icon="user"
        iconSize="tiny1"
        textClasses="parag-med text-upper text-left"
        classes="align-center"
      />
      <div className="double-grid">
        <HeadTextField
          place="field.firstName"
          value={firstName}
          error={getErrorItem("firstName")}
          onChange={setFirstName}
        />
        <HeadTextField
          place="field.lastName"
          value={lastName}
          error={getErrorItem("lastName")}
          onChange={setLastName}
        />
        <SelectField
          place="field.nationality"
          innerPlace="field.sel-nationality"
          value={nationality}
          error={getErrorItem("nationality")}
          onChange={(value) => {
            setNationality(value);
          }}
          options={constHelper.getCountries()}
        />
        <SelectField
          place="field.country"
          innerPlace="field.sel-country"
          value={country}
          error={getErrorItem("country")}
          onChange={(value) => {
            setCountry(value);
            setCity(null);
          }}
          options={constHelper.getCountries()}
        />
        <SelectField
          place="field.city"
          innerPlace="field.sel-city"
          value={city}
          error={getErrorItem("city")}
          onChange={setCity}
          options={country ? constHelper.getCities(country) : []}
          disabled={!country}
        />
        <SelectField
          place="field.gender"
          innerPlace="field.sel-gender"
          value={gender}
          error={getErrorItem("gender")}
          onChange={setGender}
          options={genders}
        />
        <DateField
          place="field.birth-date"
          value={dateOfBirth}
          error={getErrorItem("dateOfBirth")}
          onChange={setDateOfBirth}
          allowClear
          type="backward"
        />
        <PhoneField
          place="field.number"
          value={number}
          error={getErrorItem("number")}
          onChange={setNumber}
          defaultCode={country ?? "sa"}
        />
        <PhoneField
          place="field.otherNumber"
          value={otherNumber}
          error={getErrorItem("otherNumber")}
          onChange={setOtherNumber}
          defaultCode={country ?? "sa"}
        />
      </div>
      <TextAreaField
        place="field.about"
        innerPlace="field.say-about"
        value={about}
        error={getErrorItem("about")}
        onChange={setAbout}
        rows={3}
        maxLength={500}
      />
      {/* <IconTitle
        content="title.social"
        icon="social"
        iconSize="tiny1"
        textClasses="parag-med text-upper"
        classes="align-center top-med-marg"
      />
      <div className="double-grid">
        <HeadTextField
          place="social.facebook"
          innerPlace="field.enter-link"
          label="title.optional"
          value={facebook}
          error={getErrorItem("facebook")}
          onChange={setFacebook}
        />
        <HeadTextField
          place="social.twitter"
          innerPlace="field.enter-link"
          label="title.optional"
          value={twitter}
          error={getErrorItem("twitter")}
          onChange={setTwitter}
        />
        <HeadTextField
          place="social.linkedin"
          innerPlace="field.enter-link"
          label="title.optional"
          value={linkedin}
          error={getErrorItem("linkedin")}
          onChange={setLinkedin}
        />
        <PhoneField
          place="social.whatsapp"
          label="title.optional"
          value={whatsapp}
          error={getErrorItem("whatsapp")}
          onChange={setWhatsapp}
          defaultCode={country}
        />
      </div> */}
      <FormButtons
        disabled={loading}
        onCancel={() => setCancelModal(true)}
        submitContent="action.save"
        icon="save"
        classes="top-med-padd"
      />
      <ConfirmModal
        open={cancelModal}
        setOpen={setCancelModal}
        title="confirm.cancel-editing"
        talk="confirm.cancel-editing-effect"
        onAgree={() => history.goBack()}
      />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(EditPersonalInformation);
