import React, { useState } from "react";
import { connect } from "react-redux";
import { apiServices } from "../../../../services/apiServices";
import history from "../../../../utils/history";
import { HandleApiHook } from "../../../../utils/hooks";
import FormButtons from "../../../common/buttons/FormButtons";
import ConfirmModal from "../../../common/modals/confirmModal";
import TextItem from "../../../common/text/TextItem";
import CircleLoader from "../../../common/loaders/circleLoader";

const InviteCardTalk = ({ castingData, acceptRequest, token }) => {
  // Destruct casting data
  const {
    id: castingId,
    third,
    type,
    accepted,
    chosenAs,
    projectId,
  } = castingData ?? {};
  // Hooks
  const { loading, submit } = HandleApiHook();
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  // Accept request
  const submitAccept = () => {
    submit({
      service: apiServices.casting.acceptCasting,
      body: {
        token,
        proj: projectId,
        castingId,
        type: chosenAs,
      },
      onSuccess: () => acceptRequest(),
    });
  };

  // Reject request
  const submitReject = () => {
    submit({
      service: apiServices.casting.rejectCasting,
      body: {
        token,
        proj: projectId,
        castingId,
        type: chosenAs,
      },
      onSuccess: () => history.replace("/"),
    });
  };

  return (
    <div className="single-grid all-med-padd align-self-center">
      <TextItem content={`casting.ch-${third}`} classes="sparag-med" />
      {type === "still" && !accepted && (
        <>
          {loading && <CircleLoader size="large" classes="grid-center" />}
          <FormButtons
            disabled={loading}
            onSubmit={() => setAcceptModal(true)}
            onCancel={() => setRejectModal(true)}
            cancelContent="action.reject"
            submitContent="action.accept"
          />
        </>
      )}
      <ConfirmModal
        open={acceptModal}
        setOpen={setAcceptModal}
        title="confirm.acc-casting"
        talk="confirm.acc-casting-talk"
        onAgree={submitAccept}
      />
      <ConfirmModal
        open={rejectModal}
        setOpen={setRejectModal}
        title="confirm.rej-casting"
        talk="confirm.rej-casting-talk"
        onAgree={submitReject}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(InviteCardTalk);
