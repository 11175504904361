import { emailActions } from "./stores/email/emailActions";
import { authActions } from "./stores/auth/authActions";
import { accountActions } from "./stores/account/accountActions";
import { tabTitleActions } from "./stores/tabTitle/tabTitleActions";

export const reduxActions = {
  emailActions,
  authActions,
  accountActions,
  tabTitleActions,
};
