import React, { useEffect, useState } from "react";
// Helpers
import history from "../../../../utils/history";
// Components
import { GetIcon } from "../../../../assets/utils/IconGenerator";
import TextItem from "../../../common/text/TextItem";

const FullScreenNavigationItem = ({
  navigationItem,
  setActiveMenu,
  collapsed,
  setCollapsed,
}) => {
  // Hooks
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(window.location.pathname === navigationItem.url);
  }, []);
  history.listen(() => {
    setActive(window.location.pathname === navigationItem.url);
  });

  return (
    <div
      className={`full-screen-navigation-item pointer full-width vertical-med-padd ${
        active && "black-back"
      }`}
      onClick={() => {
        if (setCollapsed) setCollapsed(true);
        if (setActiveMenu) setActiveMenu(false);
        history.push(navigationItem.url);
      }}
    >
      <div className={`flex-nowrap ${!collapsed && "justify-start"}`}>
        <GetIcon
          name={navigationItem.icon}
          color={active ? "brand" : "black"}
          size="small"
        />
        {!collapsed && (
          <TextItem
            content={navigationItem.text}
            classes={`side-med-padd parag-${active ? "hev white" : "lit"}`}
          />
        )}
      </div>
    </div>
  );
};

export default FullScreenNavigationItem;
