import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { apiServices } from "../../../../services/apiServices";
import {
  pantSizes,
  shirtSizes,
  shoeSizes,
  sizeTypes,
} from "../../../../assets/const/size";
import { HandleApiHook } from "../../../../utils/hooks";
// Components
import FormButtons from "../../../common/buttons/FormButtons";
import LineDivider from "../../../common/dividers/lineDivider";
import HeadTextField from "../../../common/fields/headTextField";
import SelectField from "../../../common/fields/selectField";
import TopLoader from "../../../common/loaders/topLoader";
import ConfirmModal from "../../../common/modals/confirmModal";
import CustomModal from "../../../common/modals/customModal";
import RadioGroup from "../../../common/radio/radioGroup";
import TextItem from "../../../common/text/TextItem";

const EditMeasurementsModal = ({
  editMeasures,
  setEditMeasures,
  measurements,
  gender,
  setMeasurements,
  token,
}) => {
  // Destruct data
  const {
    weight: initWeight,
    height: initHeight,
    shoulders: initShoulders,
    shirt: initShirt,
    pants: initPants,
    shoes: initShoes,
    thobe: initThobe,
  } = measurements ?? {};
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [weight, setWeight] = useState(initWeight ?? "");
  const [height, setHeight] = useState(initHeight ?? "");
  const [shoulders, setShoulders] = useState(initShoulders ?? "");
  const [shirt, setShirt] = useState(initShirt ?? null);
  const [pants, setPants] = useState(initPants ?? null);
  const [shoes, setShoes] = useState(initShoes ?? null);
  const [thobe, setThobe] = useState(initThobe ?? null);
  const [cancelModal, setCancelModal] = useState(false);

  // Reset on change size type
  const resetSizes = () => {
    setShirt(null);
    setPants(null);
    setShoes(null);
    setThobe(null);
  };

  // Submit measurements update
  const submitMeasurements = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.cast.updateMeasures,
      body: {
        weight,
        height,
        shoulders,
        shirt,
        pants,
        shoes,
        thobe,
      },
      query: {
        token,
      },
      onSuccess: (measures) => {
        setMeasurements(measures);
        setEditMeasures(false);
      },
      successMessage: "update",
      params: { field: "title.measures" },
    });
  };

  return (
    <CustomModal
      icon="ruler"
      title="title.measures"
      open={editMeasures}
      setOpen={setEditMeasures}
    >
      <form
        onSubmit={submitMeasurements}
        className="single-grid all-med-padd"
        noValidate
      >
        {loading && <TopLoader />}
        <HeadTextField
          place="measure.weight"
          label="measure.kg"
          innerPlace="measure.enter-weight"
          type="number"
          value={weight}
          error={getErrorItem("weight")}
          onChange={setWeight}
        />
        <div className="double-grid">
          <HeadTextField
            place="measure.height"
            label="measure.cm"
            innerPlace="measure.enter-height"
            type="number"
            value={height}
            error={getErrorItem("height")}
            onChange={setHeight}
          />
          <HeadTextField
            place="measure.shoulders"
            label="measure.cm"
            innerPlace="measure.enter-shoulders"
            type="number"
            value={shoulders}
            error={getErrorItem("shoulders")}
            onChange={setShoulders}
          />
          <HeadTextField
            place="measure.shirt"
            innerPlace="measure.sel-shirt"
            value={shirt}
            error={getErrorItem("shirt")}
            onChange={setShirt}
          />
          <HeadTextField
            place="measure.pants"
            innerPlace="measure.sel-pants"
            value={pants}
            error={getErrorItem("pants")}
            onChange={setPants}
          />
          <HeadTextField
            place="measure.shoes"
            innerPlace="measure.sel-shoes"
            value={shoes}
            error={getErrorItem("shoes")}
            onChange={setShoes}
          />
          <HeadTextField
            place={gender === "Male" ? "measure.thobe" : "measure.abaya"}
            innerPlace={
              gender === "Male" ? "measure.sel-thobe" : "measure.sel-abaya"
            }
            value={thobe}
            error={getErrorItem("thobe")}
            onChange={setThobe}
          />
        </div>
        <FormButtons
          disabled={loading}
          onCancel={() => setCancelModal(true)}
          submitContent="action.save"
          icon="save"
          classes="top-sm-marg"
        />
      </form>
      <ConfirmModal
        open={cancelModal}
        setOpen={setCancelModal}
        title="confirm.cancel-editing"
        talk="confirm.cancel-editing-effect"
        onAgree={() => setEditMeasures(false)}
      />
    </CustomModal>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(EditMeasurementsModal);
