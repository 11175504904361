import { apiHelper } from "../../utils/apiHelper";
const endPoint = "/contact";

export const contact = {
  sendMessage,
};

// Exec
function execPost(route, data) {
  apiHelper.post(endPoint + route, data);
}
function execPut(route, data) {
  apiHelper.put(endPoint + route, data);
}
function execGet(route, data) {
  apiHelper.get(endPoint + route, data);
}

// functions
function sendMessage(data) {
  execPost("/send-mess", data);
}
