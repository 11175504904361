import React from "react";
// Components
import TextItem from "../../../common/text/TextItem";
import VideoPlayerCard from "../../../common/video/videoPlayerCard";
import ImageComponent from "../../../common/image/image";

const SampleCard = ({ sample }) => {
  // Destruct sample data
  const { asset, title, desc } = sample ?? {};

  return (
    <div className="single-grid align-self-start full-width margin-auto">
      {asset.type === "image" ? (
        <ImageComponent
          shape="square"
          fill
          minHeight={200}
          preview
          size="big2"
          image={asset.url}
          resize="contain"
          emptyState={{
            type: "icon",
            icon: "images",
            color: "black",
            classes: "opacity",
          }}
        />
      ) : (
        <VideoPlayerCard link={asset.url} />
      )}
      <TextItem content={title} classes="sparag-hev text-center" normal />
      <TextItem content={desc} classes="micro-lit text-center" normal />
    </div>
  );
};

export default SampleCard;
