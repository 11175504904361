import React from "react";
// Components
import AvatarSkeleton from "../../common/loaders/skeleton/avatarSkeleton";

const ApproveCardSkeleton = () => {
  return (
    <div className="card all-med-padd not-dir">
      <div className="flex full-width justify-space-around">
        <AvatarSkeleton size="med2" />
        <AvatarSkeleton size="med2" />
        <AvatarSkeleton size="med2" />
        <AvatarSkeleton size="med2" />
      </div>
    </div>
  );
};

export default ApproveCardSkeleton;
