import React, { useEffect, useState } from "react";
import { GetIcon } from "../../../assets/utils/IconGenerator";
import TooltipContainer from "../tooltip/Tooltip";

const BoxButton = ({
  disabled,
  icon,
  onClick,
  classes,
  icoSize,
  color,
  tooltip,
  placement,
}) => {
  const [iconLit, setIconLit] = useState(null);
  const [classItem, setClassItem] = useState("border-none");

  useEffect(() => {
    setClassItem(
      `box-btn flex fit-content all-sm-marg white-back border-grey outline-none ${
        disabled ? "forbid border-none" : "scale-hover pointer"
      } ${classes}`
    );
    if (icon) {
      setIconLit(
        <GetIcon
          name={icon}
          size={icoSize || "tiny"}
          color={disabled ? "dark" : color || "black"}
          classes={disabled ? "opacity" : ""}
        />
      );
    }
  }, [disabled, icon, classes]);

  const content = () => {
    return (
      <button
        disabled={disabled}
        type="submit"
        onClick={onClick}
        className={classItem}
        style={{ padding: "0.3rem" }}
      >
        {iconLit}
      </button>
    );
  };

  if (!tooltip) return content();
  return (
    <TooltipContainer
      tooltip={tooltip}
      placement={placement}
      content={content}
    />
  );
};

export default BoxButton;
