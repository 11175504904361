import React, { useEffect, useState } from "react";
// Packages
import { Menu, Dropdown } from "antd";
// Components
import TextItem from "../text/TextItem";
import { GetIcon } from "../../../assets/utils/IconGenerator";
// Helpers
import { capitalizeFirstLetter } from "../../../utils/helperFunctions";
// Hooks
import { LanguageHook } from "../../../utils/hooks";

const IconMenu = ({ icon, color, size, menuItems, placement, classes }) => {
  // Hooks
  const { isRtl } = LanguageHook();
  const [rtlPlacement, setRtlPlacement] = useState("right");

  // Manage rtl placement
  useEffect(() => {
    setRtlPlacement(placement === "right" ? "left" : "right");
  }, [placement]);

  // Menu overlay
  const menuOverlay = () => (
    <Menu>
      {menuItems && menuItems.length !== 0 ? (
        menuItems.map((menuItem) => {
          if (menuItem)
            return (
              <Menu.Item
                key={menuItem.text}
                onClick={menuItem.onClick}
                disabled={menuItem.disabled}
                danger={menuItem.danger}
              >
                <div className="flex-nowrap justify-start">
                  <GetIcon
                    name={menuItem.icon}
                    color={menuItem.iconColor ?? "black"}
                    classes="end-med-marg"
                    size="micro2"
                  />
                  <TextItem content={menuItem.text} classes="tiny-med" />
                </div>
              </Menu.Item>
            );
          return null;
        })
      ) : (
        <Menu.Item disabled>
          <TextItem content="empty.noData" classes="tiny-med" />
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown
      placement={`bottom${
        placement
          ? capitalizeFirstLetter(isRtl ? rtlPlacement : placement)
          : "Right"
      }`}
      overlay={menuOverlay}
      className={`fit-content ${classes}`}
    >
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <GetIcon name={icon} color={color} size={size} />
      </a>
    </Dropdown>
  );
};

export default IconMenu;
