import React, { useState } from "react";
import { GetIcon } from "../../../../assets/utils/IconGenerator";
import { hist } from "../../../../utils/history";
import { LanguageHook } from "../../../../utils/hooks";
import { getAge } from "../../../../utils/momentHelper";
import RadialButton from "../../../common/buttons/radialButton";
import ConfirmModal from "../../../common/modals/confirmModal";
import TextItem from "../../../common/text/TextItem";
import UserCard from "../../cards/userCard";
import NotChosenThirdSkeleton from "../../skeleton/notChosenThirdSkeleton";

const CastingCard = ({
  isHero,
  third,
  accepted,
  type,
  projectId,
  removeCasting,
  removeLoad,
}) => {
  const { language, t } = LanguageHook();
  const [open, setOpen] = useState(false);

  return (
    <div className="one-third-grid card grid">
      <div
        className={`single-grid grid-center all-med-padd full-width full-height ${
          isHero ? "brand-back" : "orange-back"
        }`}
      >
        <GetIcon
          name={isHero ? "crown" : "refresh"}
          color="white"
          size="small"
          classes="margin-auto"
        />
        <TextItem
          content={isHero ? "casting.hero" : "casting.back"}
          classes="sparag-hev text-upper white justify-self-center"
        />
      </div>
      {third ? (
        <UserCard
          image={third.image}
          name={third.id}
          onClick={() => {
            hist.push(`/project/${projectId}/casting/third-profile`, {
              authId: third.auth,
            });
          }}
          UtilsRender={() => {
            return (
              <div className="flex justify-start">
                <TextItem
                  content={getAge(third.dateOfBirth, language, t)}
                  classes={`micro-lit end-med-padd`}
                />
                {accepted ? (
                  <TextItem
                    content={`status.accept`}
                    classes={`micro-hev active`}
                  />
                ) : (
                  <TextItem
                    content={`status.pend`}
                    classes={`micro-hev pend`}
                  />
                )}
              </div>
            );
          }}
          OptionsRender={() => {
            if (!removeLoad)
              return (
                <RadialButton
                  icon="trash"
                  iconSize="small"
                  color="second"
                  classes="grid-center border-grey"
                  onClick={() => setOpen(true)}
                  tooltip="title.remove-casting"
                />
              );
            return null;
          }}
        />
      ) : (
        <NotChosenThirdSkeleton />
      )}
      <ConfirmModal
        open={open}
        setOpen={setOpen}
        title="confirm.remove-casting"
        talk="confirm.remove-casting-talk"
        onAgree={() => {
          setOpen(false);
          removeCasting(isHero, third._id);
        }}
      />
    </div>
  );
};

export default CastingCard;
