import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { genres } from "../../../assets/const/genre";
import { HandleApiHook } from "../../../utils/hooks";
import history, { hist } from "../../../utils/history";
import { apiServices } from "../../../services/apiServices";
// Components
import FormButtons from "../../common/buttons/FormButtons";
import HeadTextField from "../../common/fields/headTextField";
import SelectField from "../../common/fields/selectField";
import TextAreaField from "../../common/fields/TextAreaField";
import IconTitle from "../../common/layouts/IconTitle";
import TopLoader from "../../common/loaders/topLoader";
import TagsRender from "../../common/tags/tags";
import TextItem from "../../common/text/TextItem";
import WorkSamplesCard from "./cards/workSamplesCard";
import ConfirmModal from "../../common/modals/confirmModal";

const EditShowreel = ({ location, token }) => {
  // Destruct Edit data
  const { editData } = location.state ?? {};
  const {
    reelDesc: initReelDesc,
    rate: initRate,
    genre: initGenre,
    areas: initAreas,
    samples: initSamples,
  } = editData ?? {};
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [reelDesc, setReelDesc] = useState(initReelDesc ?? "");
  const [rate, setRate] = useState(initRate ?? "");
  const [genre, setGenre] = useState(initGenre ?? null);
  const [areas, setAreas] = useState(initAreas ?? "");
  const [area, setArea] = useState("");
  const [samples, setSamples] = useState(initSamples ?? []);
  const [cancelModal, setCancelModal] = useState(false);

  // Update work samples location state
  const updateLocationWorkSample = (newSamples) => {
    setSamples(newSamples);
    hist.replace(location.pathname, {
      editData: {
        ...editData,
        samples: newSamples,
      },
    });
  };

  // Add area
  const addArea = () => {
    if (area) {
      let areasSample = [...areas];
      let isExists = areasSample.find((x) => x === area);
      if (!isExists) {
        areasSample.push(area);
        setAreas(areasSample);
      }
      setArea("");
    }
  };

  // Remove area
  const removeArea = (areaItem) => {
    let areasSample = [...areas];
    areasSample = areasSample.filter((obj) => {
      return obj !== areaItem;
    });
    setAreas(areasSample);
  };

  // Submit showreel edit
  const submitShowreelEdit = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.third.updateShowreel,
      body: {
        reelDesc,
        rate,
        genre,
        areas,
        samples,
      },
      query: {
        token,
      },
      onSuccess: () => history.goBack(),
    });
  };

  return (
    <form
      onSubmit={submitShowreelEdit}
      className="noncard-container single-grid bottom-extend top-extend"
      noValidate
    >
      {loading && <TopLoader />}
      <IconTitle
        content="title.edit-showreel"
        icon="shot"
        iconSize="tiny1"
        textClasses="parag-med text-upper"
        classes="align-center"
      />
      <TextAreaField
        place="field.desc"
        innerPlace="field.reel-desc"
        value={reelDesc}
        error={getErrorItem("reelDesc")}
        onChange={setReelDesc}
        rows={3}
        maxLength={500}
      />
      <div className="double-grid">
        <HeadTextField
          place="field.daily-rate"
          innerPlace="field.enter-daily-rate"
          label="money.usd"
          value={rate}
          error={getErrorItem("rate")}
          onChange={setRate}
          type="number"
        />
        <SelectField
          place="field.genre"
          innerPlace="field.sel-genre"
          value={genre}
          error={getErrorItem("genre")}
          onChange={setGenre}
          options={genres}
        />
      </div>
      <div className="card single-grid all-med-padd height-fit-content align-start top-sm-marg">
        <TextItem
          content="title.areas"
          classes="tiny-med text-upper full-width justify-start"
        />
        <TextItem
          content="title.edit-showreel-info"
          classes="micro-lit dark full-width justify-start"
        />
        <TagsRender tags={areas} onRemove={removeArea} />
        <HeadTextField
          innerPlace="field.enter-area"
          value={area}
          name="title.areas"
          onChange={setArea}
          error={getErrorItem("areas")}
          disableSubmit
          onEnter={addArea}
          disabled={areas.length >= 5}
        />
        <FormButtons
          disabled={loading || areas.length >= 5}
          submitContent="action.add"
          icon="plus"
          onSubmit={addArea}
          noSubmit
          second
          radial
        />
      </div>
      <WorkSamplesCard
        updateLocationWorkSample={updateLocationWorkSample}
        samples={samples}
        getErrorItem={getErrorItem}
      />
      <FormButtons
        disabled={loading}
        onCancel={() => setCancelModal(true)}
        submitContent="action.save"
        icon="save"
      />
      <ConfirmModal
        open={cancelModal}
        setOpen={setCancelModal}
        title="confirm.cancel-editing"
        talk="confirm.cancel-editing-effect"
        onAgree={() => history.goBack()}
      />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(EditShowreel);
