import React from "react";
// Packages
import { LinearProgress } from "@material-ui/core";

const TopLoader = ({ height }) => {
  return (
    <div
      style={{ height: height ?? 4 }}
      className="top-loader absolute full-width top-zero-abs left-zero-abs index"
    >
      <LinearProgress />
    </div>
  );
};

export default TopLoader;
