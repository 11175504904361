import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { message as AntMessage } from "antd";

// Get language properties
export function LanguageHook() {
  const { i18n, t } = useTranslation();
  return {
    isRtl: i18n.language === "ar",
    language: i18n.language,
    t,
  };
}

// Form Hooks
export function HandleApiHook(isFetch) {
  // Hooks
  const { showAlert } = AlertHook();
  // Hooks
  const [loading, setLoading] = useState(isFetch ? true : false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);

  // Get error for field
  const getErrorItem = useCallback(
    (name) => {
      let errorObject = errors.find((error) => error.path === name);
      if (!errorObject) return null;
      return errorObject.error;
    },
    [errors]
  );

  // Reset Errors
  const resetErrors = () => setErrors([]);

  // Get endpoint api data
  const getEndpointApi = ({
    body,
    query,
    headers,
    successMessage,
    params,
    onSuccess,
    onFail,
  }) => {
    return {
      body: body ?? {},
      query: query ?? {},
      headers: headers ?? {},
      success: (data) => {
        setLoading(false);
        if (successMessage)
          showAlert({
            success: true,
            message: successMessage,
            params,
          });
        if (onSuccess) onSuccess(data);
      },
      fail: (data) => {
        if (data.errors)
          setErrors(
            Object.values(data.errors).map((error) => {
              return { error: error.message, path: error.path };
            })
          );
        if (data.error && data.description !== "expired-token")
          showAlert({
            success: false,
            message: data.error,
            params: data.params,
          });
        if (onFail) onFail(data);
        setLoading(false);
        if (isFetch) setError(true);
      },
      error: () => {
        if (!isFetch)
          showAlert({
            success: false,
            message: "error",
          });
        setLoading(false);
        if (isFetch) setError(true);
      },
    };
  };

  // Submit Form
  const submit = ({
    service,
    body,
    query,
    headers,
    successMessage,
    params,
    onSuccess,
    onFail,
  }) => {
    resetErrors();
    setLoading(true);
    service(
      getEndpointApi({
        body,
        query,
        headers,
        successMessage,
        params,
        onSuccess,
        onFail,
      })
    );
  };

  return {
    loading,
    error,
    setError,
    getEndpointApi,
    getErrorItem,
    resetErrors,
    submit,
  };
}

// Alert Helper
export const AlertHook = () => {
  // Hooks
  const { t, isRtl } = LanguageHook();

  const showAlert = ({ success, message, params, duration }) => {
    const translatedParams = {};
    if (params) {
      const paramsKeys = Object.keys(params);
      paramsKeys.forEach((paramKey) => {
        translatedParams[paramKey] = t(params[paramKey]);
      });
    }
    AntMessage[success ? "success" : "error"]({
      content: t(`${success ? "success" : "error"}.${message}`, {
        ...translatedParams,
      }),
      duration: duration ? duration : success ? 2 : 5,
      style: {
        fontFamily: isRtl ? "Almarai" : "Avenir",
      },
    });
  };

  return { showAlert };
};
