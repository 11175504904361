import { apiHelper } from "../../utils/apiHelper";
const endPoint = "/breakdown";

export const breakdown = {
  getMyAssigns
};

// Exec
function execPost(route, data) {
  apiHelper.post(endPoint + route, data);
}
function execPut(route, data) {
  apiHelper.put(endPoint + route, data);
}
function execGet(route, data) {
  apiHelper.get(endPoint + route, data);
}

// Functions
function getMyAssigns(data) {
  execGet("/get-my-assigns", data);
}
