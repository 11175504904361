import React, { useState } from "react";
// Packages
import { Checkbox } from "antd";
// Components
import TextItem from "../text/TextItem";

const CheckboxItem = ({ onChange, content, classes, font }) => {
  const [value, setValue] = useState(false);

  const handleChange = () => {
    if (onChange) onChange(!value);
    setValue(!value);
  };

  return (
    <div className={`checkbox-item flex-nowrap align-start ${classes}`}>
      <Checkbox checked={value} onChange={handleChange} />
      <TextItem
        onClick={handleChange}
        classes={`${font} pointer side-sm-padd text-start`}
        content={content}
      />
    </div>
  );
};

export default CheckboxItem;
