import React from "react";
import AvatarSkeleton from "../../common/loaders/skeleton/avatarSkeleton";
import LineSkeleton from "../../common/loaders/skeleton/lineSkeleton";

const ProjectCastingSkeleton = () => {
  return (
    <div className="single-grid bottom-extend top-extend-fill">
      <div className="card all-med-padd">
        <LineSkeleton rows={2} />
        <div className="flex-nowrap justify-start full-width">
          <AvatarSkeleton shape="circle" size="med2" classes="side-marg" />
          <div style={{ width: "50%" }}>
            <LineSkeleton rows={2} />
          </div>
        </div>
      </div>
      <div className="card all-med-padd">
        <LineSkeleton rows={2} />
        <div className="flex-nowrap justify-start full-width">
          <AvatarSkeleton shape="circle" size="med2" classes="side-marg" />
          <div style={{ width: "50%" }}>
            <LineSkeleton rows={2} />
          </div>
        </div>
      </div>
      <div className="card all-med-padd">
        <LineSkeleton rows={2} />
        <div className="flex-nowrap justify-start full-width">
          <AvatarSkeleton shape="circle" size="med2" classes="side-marg" />
          <div style={{ width: "50%" }}>
            <LineSkeleton rows={2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCastingSkeleton;
