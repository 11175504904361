import React from "react";
// Packages
import { Image } from "antd";

const ImageSource = ({ source, loaded, setLoaded, resize, preview }) => {
  // Previe enabled
  if (preview)
    return (
      <Image
        src={source}
        onLoad={() => setLoaded(true)}
        className={`ant-image-${resize ? resize : "cover"} ${
          !loaded ? "none-imp" : ""
        } full-width-imp full-height-imp pointer`}
      />
    );

  // Normal image render
  return (
    <img
      src={source}
      onLoad={() => setLoaded(true)}
      className={`fit-${resize ?? "cover"} ${
        !loaded ? "none" : ""
      } absolute full-width full-height`}
    />
  );
};

export default ImageSource;
