import React, { useEffect } from "react";
// Helpers
import history from "../../../utils/history";
import { connect } from "react-redux";
import { apiServices } from "../../../services/apiServices";
import { reduxActions } from "../../../redux/actions";
import { HandleApiHook, LanguageHook } from "../../../utils/hooks";
// Components
import LinkButton from "../../common/buttons/linkButton";
import TopLoader from "../../common/loaders/topLoader";
import TextItem from "../../common/text/TextItem";
import { GetIcon } from "../../../assets/utils/IconGenerator";

const RecoveryPass = ({ email, clearEmail }) => {
  // Hooks
  const { loading, submit } = HandleApiHook();
  const { language } = LanguageHook();

  useEffect(() => {
    if (!email) {
      clearEmail();
      history.goBack();
    }
  }, []);

  // Resend Forget
  const submitForgot = () => {
    submit({
      service: apiServices.auth.forgotPass,
      query: {
        email,
        language,
      },
      successMessage: "sent",
      params: { field: "title.recovery-email" },
    });
  };

  return (
    <div className="single-grid">
      {loading && <TopLoader />}
      <TextItem content="title.recover-sent" classes="shead-hev text-center" />
      <GetIcon name="send" size="giant" classes="margin-auto" />
      <TextItem content="desc.recover-sent" classes="micro-lit text-center" />
      <div>
        <TextItem content="desc.ver-email-sent" classes="micro-lit" />
        <TextItem content={email} classes="sparag-hev" normal />
      </div>
      <div>
        <LinkButton
          content="desc.resend-recover"
          classes="tiny-hev"
          disabled={loading}
          line
          onClick={submitForgot}
        />
        <TextItem content="desc.junk-check" classes="micro-lit" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    email: state.email.email,
  };
};
const mapDispatchToProps = {
  clearEmail: reduxActions.emailActions.clearEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPass);
