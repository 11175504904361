import React from "react";
import AvatarSkeleton from "../../common/loaders/skeleton/avatarSkeleton";

const CollaboratorsSkeleton = () => {
  return (
    <div className="fifth-grid">
      <AvatarSkeleton shape="circle" size="big" classes="justify-self-center" />
      <AvatarSkeleton shape="circle" size="big" classes="justify-self-center" />
      <AvatarSkeleton shape="circle" size="big" classes="justify-self-center" />
      <AvatarSkeleton shape="circle" size="big" classes="justify-self-center" />
      <AvatarSkeleton shape="circle" size="big" classes="justify-self-center" />
    </div>
  );
};

export default CollaboratorsSkeleton;
