import { Layout } from "antd";
import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { reduxActions } from "../../redux/actions";
import { apiServices } from "../../services/apiServices";
import history from "../../utils/history";
import { HandleApiHook } from "../../utils/hooks";
import FetchUiData from "../common/layouts/fetchUiData";
import CircleLoader from "../common/loaders/circleLoader";
import NavbarComponent from "../leap/navbar/navbar";
import SideMenu from "../leap/navbar/sideMenu";
import ProjectProfile from "../leap/project/projectProfile";
import CreateRequest from "../leap/project/createRequest";
import ProjectCasting from "../leap/project/projectCasting";
import FullGallery from "../leap/profile/fullGallery";
import ThirdPartyProfile from "../leap/profile/thirdProfile";
import ProjectCastingExplore from "../leap/project/projectCastingExplore";

const ProjectHome = ({
  match,
  location,
  token,
  changeTab,
  type,
  accId,
  admin,
  organizationId,
}) => {
  // Destruct params
  const { projectId } = match.params ?? {};
  // Hooks
  const { loading, error, setError, submit } = HandleApiHook(true);
  const [projectLayout, setProjectLayout] = useState(null);

  // Fetch project layout
  const fetchProjectLayout = () => {
    submit({
      service: apiServices.proj.getProjectLayout,
      query: { token, projectId },
      onSuccess: (projectProfileData) => {
        if (!validateProjectAccess(projectProfileData)) history.replace("/");
        changeTab(projectProfileData.name);
        setProjectLayout(projectProfileData);
      },
    });
  };

  // Validate project access
  const validateProjectAccess = (project) => {
    // Destruct project
    const { collabs, creator, casting } = project ?? {};
    const {
      casting: standByCasting,
      dir,
      dop,
      cast,
      photographer,
      artDirector,
      hair,
      makup,
      woredrope,
      food,
    } = casting ?? {};

    if (type === "acc" && creator.org === organizationId) {
      return admin || creator._id === accId || collabs.includes(accId);
    } else {
      return (
        dir === accId ||
        dop === accId ||
        cast.includes(accId) ||
        photographer.includes(accId) ||
        artDirector.includes(accId) ||
        hair.includes(accId) ||
        makup.includes(accId) ||
        woredrope.includes(accId) ||
        food.includes(accId) ||
        standByCasting.find(
          (standBy) =>
            standBy.hero.third === accId || standBy.back.third === accId
        )
      );
    }
  };

  return (
    <FetchUiData
      service={fetchProjectLayout}
      observer={projectId}
      loading={loading}
      dataFetched={projectLayout}
      error={error}
      setError={setError}
      Loader={() => (
        <CircleLoader size="large" classes="margin-auto top-padd" />
      )}
      noWait
    >
      <Layout className="layout-container">
        <SideMenu project={projectLayout} />
        <Layout>
          <NavbarComponent project={projectLayout} location={location} />
          <div className="flow-cont">
            <div className="content-container full-width">
              <Switch>
                <Route
                  path="/project/:projectId/casting/third-profile/full-gallery"
                  component={() => (
                    <div className="side-med-padd">
                      <FullGallery location={location} />
                    </div>
                  )}
                />
                <Route
                  path="/project/:projectId/casting/third-profile"
                  component={() => (
                    <div className="side-med-padd">
                      <ThirdPartyProfile
                        location={location}
                        fullGalleryUrl={`/project/${projectLayout._id}/casting/third-profile/full-gallery`}
                        limited
                      />
                    </div>
                  )}
                />
                <Route
                  path="/project/:projectId/casting/explore"
                  component={() => (
                    <div className="side-med-padd">
                      <ProjectCastingExplore location={location} />
                    </div>
                  )}
                />
                <Route
                  path="/project/:projectId/casting"
                  component={() => (
                    <div className="side-med-padd">
                      <ProjectCasting castingId={projectLayout?.casting?._id} />
                    </div>
                  )}
                />
                <Route
                  path="/project/:projectId/edit"
                  component={() => (
                    <div className="side-med-padd">
                      <CreateRequest editMode location={location} />
                    </div>
                  )}
                />
                <Route
                  path="/project/:projectId"
                  component={() => (
                    <div className="side-med-padd">
                      <ProjectProfile match={match} />
                    </div>
                  )}
                />
              </Switch>
            </div>
          </div>
        </Layout>
      </Layout>
    </FetchUiData>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    type: state.auth.type,
    accId: state.auth.accId,
    admin: state.auth.admin,
    organizationId: state.auth.org,
  };
};

const mapDispatchToProps = {
  changeTab: reduxActions.tabTitleActions.changeTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHome);
