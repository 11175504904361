import React from "react";

const ImageContainer = ({
  fill,
  size,
  shape,
  children,
  background,
  minHeight,
  minWidth,
  classes,
  onClick,
}) => {
  return (
    <div
      onClick={onClick ?? null}
      style={{
        minHeight: minHeight,
        minWidth: minWidth,
      }}
      className={`image-component ${
        fill ? "full-height full-width" : "fit-content"
      } icon-${fill ? "" : size ? size : "tiny"} ${
        shape !== "square" && "radial"
      } ${onClick && "pointer scale-hover"} ${
        background ?? "grey3"
      }-back relative overflow-hidden flex ${classes}`}
    >
      {children}
    </div>
  );
};

export default ImageContainer;
