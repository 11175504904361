import { apiHelper } from "../../utils/apiHelper";
const endPoint = "/subAccount";

export const subAcc = {
  addSubAcc,
  resendNewSub,
  registerFirst,
  addProfilePic,
  getCollaborators,
  getOrgTeam,
  changeAdmin,
  getSubAccountProfile,
};

// Exec
function execPost(route, data) {
  apiHelper.post(endPoint + route, data);
}
function execPut(route, data) {
  apiHelper.put(endPoint + route, data);
}
function execGet(route, data) {
  apiHelper.get(endPoint + route, data);
}

// Functions
function addSubAcc(data) {
  execPost("/", data);
}
function resendNewSub(data) {
  execPost("/resend", data);
}
function registerFirst(data) {
  execPost("/first-acc", data);
}
function addProfilePic(data) {
  execPost("/profile-pic", data);
}
function getCollaborators(data) {
  execGet("/collab", data);
}
function getOrgTeam(data) {
  execGet("/team", data);
}
function changeAdmin(data) {
  execPut("/change-admin", data);
}
function getSubAccountProfile(data) {
  execGet("/sub-account-profile", data);
}
