import React, { useState } from "react";
// Packages
import { connect } from "react-redux";
// Helpers
import { reduxActions } from "../../../../redux/actions";
import { apiServices } from "../../../../services/apiServices";
import history from "../../../../utils/history";
import { HandleApiHook } from "../../../../utils/hooks";
import { clear, getItem, setItem } from "../../../../utils/StorageHelpers";
// Components
import ImageComponent from "../../../common/image/image";
import IconMenu from "../../../common/menu/iconMenu";
import ConfirmModal from "../../../common/modals/confirmModal";
import NotificationBell from "./notificationBell";

const NavbarActions = ({ logout, type, image, approved, token, id, location }) => {
  // Hooks
  const { loading, submit } = HandleApiHook();
  const [logoutModal, setLogoutModal] = useState(false);

  // Main navigation url
  const navigationUrl = () => {
    if (type === "acc") return "/sub-account/explore";
    else if (type === "3rd") {
      if (approved) return "/third/explore";
      return "/third/approve";
    }
  };

  // Main navbar menu items
  const navbarMenuItems = [
    {
      text: "title.settings",
      icon: "settings",
      iconColor: "brand",
      onClick: () => history.push(navigationUrl() + "/settings"),
    },
    {
      text: "action.log-out",
      icon: "logout",
      iconColor: "second",
      onClick: () => setLogoutModal(true),
    },
  ];

  return (
    <div className="flex-nowrap align-center">
      <NotificationBell />
      <ImageComponent
        size="med"
        classes="side-med-marg"
        image={image}
        onClick={() => history.push(navigationUrl() + "/profile")}
      />
      <IconMenu
        icon="down-arrow"
        color="white"
        size="tiny"
        menuItems={navbarMenuItems}
        placement="right"
      />
      <ConfirmModal
        disabled={loading}
        open={logoutModal}
        setOpen={setLogoutModal}
        title="confirm.logout"
        onAgree={() => {
          submit({
            service: apiServices.auth.logout,
            query: {
              token,
              id,
            },
            onSuccess: () => {
              const language = getItem("i18nextLng");
              clear();
              setItem("i18nextLng", language);
              logout();
            },
          });
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    id: state.auth.id,
    image: state.auth.image,
    type: state.auth.type,
    approved: state.auth.approved,
  };
};

const mapDispathToProps = {
  logout: reduxActions.authActions.logout,
};

export default connect(mapStateToProps, mapDispathToProps)(NavbarActions);
