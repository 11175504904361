import { auth } from "./models/auth";
import { org } from "./models/org";
import { subAcc } from "./models/subAcc";
import { third } from "./models/third";
import { cast } from "./models/cast";
import { proj } from "./models/proj";
import { casting } from "./models/casting";
import { contact } from "./models/contact";
import { callsheet } from "./models/callsheet";
import { notification } from "./models/notification";
import { breakdown } from "./models/breakdown";

export const apiServices = {
  auth,
  org,
  callsheet,
  subAcc,
  third,
  notification,
  cast,
  proj,
  casting,
  contact,
  breakdown,
};
