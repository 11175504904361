import React from "react";
import TextItem from "../../common/text/TextItem";
import ImageComponent from "../../common/image/image";

const UserCard = ({
  title,
  image,
  imageIcon,
  onClick,
  name,
  UtilsRender,
  OptionsRender,
}) => {
  return (
    <div className="single-grid">
      {title && <TextItem content={title} classes="sparag-hev" />}
      <div className="flex-nowrap justify-space-between">
        <ImageComponent
          size="big2"
          image={image}
          onClick={onClick}
          emptyState={{
            type: "icon",
            icon: imageIcon ?? "user",
            color: "black",
            classes: "opacity",
          }}
        />
        <div className="side-med-padd flex-one">
          {name && (
            <>
              <TextItem
                onClick={onClick}
                content={name}
                classes="sparag-med"
                normal
              />
              <div className="top-sm-padd"></div>
            </>
          )}
          {UtilsRender && <UtilsRender />}
        </div>
        <div className="flex-one">{OptionsRender && <OptionsRender />}</div>
      </div>
    </div>
  );
};

export default UserCard;
