import React, { useState } from "react";
// Helpers
import { HandleApiHook, LanguageHook } from "../../../../utils/hooks";
import { apiServices } from "../../../../services/apiServices";
// Components
import TopLoader from "../../../common/loaders/topLoader";
import SettingItem from "../helpers/settingItem";
import HeadTextField from "../../../common/fields/headTextField";
import FormButtons from "../../../common/buttons/FormButtons";
import TextItem from "../../../common/text/TextItem";
import ConfirmModal from "../../../common/modals/confirmModal";

const EmailSettingCard = ({ email, logout, token }) => {
  // Hooks
  const { language } = LanguageHook();
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [modal, setModal] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [confirmChange, setConfirmChange] = useState(false);

  // Submit email change
  const submitEmailChange = () => {
    submit({
      service: apiServices.auth.changeEmail,
      body: {
        email: newEmail,
        language,
      },
      query: {
        token,
      },
      onSuccess: () => logout(),
    });
  };

  // Modal Content
  const modalContent = () => {
    return (
      <div className="single-grid all-med-padd">
        {loading && <TopLoader />}
        <TextItem content="desc.change-email" classes="micro-hev second" />
        <HeadTextField
          name="email"
          place="field.email"
          innerPlace="field.enter-mail"
          value={newEmail}
          error={getErrorItem("email")}
          autoFocus
          type="email"
          onChange={setNewEmail}
        />
        <FormButtons
          disabled={loading}
          onCancel={() => {
            setNewEmail("");
            setModal(false);
          }}
          onSubmit={() => setConfirmChange(true)}
          submitContent="action.changeL"
          classes="top-sm-padd"
          noSubmit
        />
        <ConfirmModal
          open={confirmChange}
          setOpen={setConfirmChange}
          title="confirm.change-email"
          talk="desc.change-email"
          onAgree={submitEmailChange}
        />
      </div>
    );
  };

  return (
    <SettingItem
      icon="mail"
      title="setting.mail"
      talkValue={email}
      modal={modal}
      setModal={setModal}
      modalContent={modalContent}
      setValue={setNewEmail}
    />
  );
};

export default EmailSettingCard;
