import React, { useEffect } from "react";
import { Router, Switch } from "react-router-dom";
// Packages
import { ConfigProvider } from "antd";
import arEG from "antd/es/locale/ar_EG";
import enUS from "antd/es/locale/en_US";
// Helpers
import { LanguageHook } from "../utils/hooks";
import { setAppDirection } from "../utils/helperFunctions";
import history from "../utils/history";
// Components
import SplashScreen from "./common/splash/splashScreen";
import AuthHome from "./auth";
import SubAccountHome from "./subAccount";
import OrganizationHome from "./organization";
import ThirdHome from "./third";
// Routes
import OrganizationRoute from "../routes/OrganizationRoute";
import RememberRoute from "../routes/rememberRoute";
import PrivateRoute from "../routes/privateRoute";
import ThirdRoute from "../routes/thirdRoute";
import AccountRoute from "../routes/AccountRoute";
import ProjectHome from "./project";

const Home = () => {
  // Language Hook
  let { isRtl } = LanguageHook();

  // Set app direction according to language
  useEffect(() => setAppDirection(isRtl), []);

  return (
    <ConfigProvider locale={isRtl ? arEG : enUS}>
      <Router history={history}>
        <Switch>
          {/* Organization routes */}
          <PrivateRoute
            path="/organization"
            component={OrganizationHome}
            navigationKey="auth"
          />
          <OrganizationRoute path="/org" />

          {/* Third types routes */}
          <PrivateRoute
            path="/third"
            component={ThirdHome}
            navigationKey="auth"
          />
          <ThirdRoute path="/3rd" />

          {/* Sub accounts routes */}
          <PrivateRoute
            path="/sub-account"
            component={SubAccountHome}
            navigationKey="auth"
          />
          <AccountRoute path="/acc" />

          {/* Project Route */}
          <PrivateRoute
            path="/project/:projectId"
            component={ProjectHome}
            navigationKey="project"
          />

          {/* Remember route */}
          <RememberRoute path="/" component={AuthHome} />
        </Switch>
      </Router>
    </ConfigProvider>
  );
};

export default SplashScreen(Home);
