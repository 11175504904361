import React, { useState, useRef } from "react";
// Components
import LoadingItems from "./helpers/loadingItems";
import SearchBox from "../search/searchBox";
import TagsRender from "../tags/tags";
import TableHeader from "./table/tableHeader";
import TableData from "./table/tableData";
import TextItem from "../text/TextItem";
import IconTitle from "./IconTitle";
import TableSkeleton from "../../leap/skeleton/tableSkeleton";
import { getObject, setObject } from "../../../utils/StorageHelpers";

const ExploreTable = ({
  title,
  titleIcon,
  filterGroups,
  service,
  query,
  emptyTitle,
  classes,
  info,
  setInfo,
  titleProp,
  tableType,
  filterName,
}) => {
  // Hooks
  const [search, setSearch] = useState(
    () => getObject(filterName)?.search || ""
  );
  const [searching, setSearching] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const [filters, setFilters] = useState(
    () => getObject(filterName)?.filters || {}
  );
  const [appliedFilters, setAppliedFilters] = useState([]);
  const firstRender = useRef(true);

  // Table result
  const result = () => {
    return <TableData type={tableType} data={info} />;
  };

  // Add when scroll
  const addToPage = (data) => {
    let infoData = [...info, ...data[tableType]];
    setInfo(infoData);
  };

  const header = () => {
    return (
      <div className={`single-grid ${classes}`}>
        <div className="noncard-container double-grid">
          <div className="flex justify-start align">
            <IconTitle
              content={title}
              icon={titleIcon}
              iconSize="tiny2"
              textClasses="parag-hev text-upper dark"
              classes="align-center"
            />
            {titleProp && titleProp()}
          </div>
          <SearchBox
            action={() => {
              if (firstRender.current) firstRender.current = false;
              else setSearching(!searching);
            }}
            onChange={setSearch}
            value={search}
            loading={searchLoad}
            filterGroups={filterGroups}
            filters={filters}
            setFilters={setFilters}
            setAppliedFilters={setAppliedFilters}
            filterName={filterName}
          />
        </div>
        {appliedFilters.length !== 0 && (
          <TagsRender
            dark
            tags={appliedFilters}
            onRemove={(tag) => {
              let filtersSample = { ...filters };
              filtersSample[tag.name] = "";
              setObject(filterName, {
                search,
                filters: filtersSample,
              });
              setFilters(filtersSample);
            }}
            format={(tag) => (
              <TextItem content={tag.label} classes="sparag-med white" />
            )}
          />
        )}
        {info.length !== 0 && <TableHeader type={tableType} />}
      </div>
    );
  };
  const content = () => {
    return (
      <LoadingItems
        searching={searching}
        setSearchLoad={setSearchLoad}
        service={service}
        query={{
          ...query,
          filters,
          search,
        }}
        succ={(data) => setInfo(data[tableType])}
        loader={() => <TableSkeleton type="sub" />}
        isEmpty={info.length === 0}
        emptyTitle={emptyTitle}
        result={result}
        addToPage={addToPage}
        loadMore={true}
        classes=""
        noWait
      />
    );
  };

  return (
    <div className="noncard-container">
      {header()}
      {content()}
    </div>
  );
};

export default ExploreTable;
