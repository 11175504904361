import React, { useState, useEffect } from "react";
// Packages
import { connect } from "react-redux";
// Helpers
import { GetBrandLogo } from "../../../assets/utils/IconGenerator";

function LoadingBrand() {
  // Classes
  const splashScreen =
    "splash-screen fixed white-back full-width full-height index flex";
  const splashContainer = "sp-container flex fit-content";

  return (
    <div className={splashScreen}>
      <div className={splashContainer}>
        <GetBrandLogo name="logo-sm-blk" classes="sp-brand-logo" />
        <GetBrandLogo name="logo-talk2" classes="sp-brand-talk" />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    logging: state.account.logging,
  };
};

function SplashScreen(WrappedComponent) {
  return connect(mapStateToProps)(function SplashWrapper({ logging, ...rest }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (logging === false) {
        setTimeout(() => {
          setLoading(false);
        }, 2500);
      }
    }, [logging]);

    if (loading) return LoadingBrand();
    return <WrappedComponent {...rest} />;
  });
}

export default SplashScreen;
