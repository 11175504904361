import React from "react";
// Helpers
import { getCountryCity, getNationality } from "../../../../utils/constHelper";
import { LanguageHook } from "../../../../utils/hooks";
import { getDateOfBirthWithAge } from "../../../../utils/momentHelper";
// Components
import IconTitle from "../../../common/layouts/IconTitle";

const IdentificationContact = ({ profileData, userData, limited }) => {
  // Hooks
  const { language, t, isRtl } = LanguageHook();

  return (
    <div className="double-grid grid-sm-gap top-med-padd">
      {!limited && (
        <>
          <IconTitle
            content={`${userData.number}${
              userData.otherNumber ? `, ${userData.otherNumber}` : ""
            }`}
            icon="phone"
            textClasses={`tiny-lit not-dir ${
              isRtl ? "text-end" : "text-start"
            }`}
          />
          <IconTitle
            content={profileData.email}
            icon="mail"
            textClasses={`tiny-lit not-dir ${
              isRtl ? "text-end" : "text-start"
            }`}
          />
        </>
      )}
      <IconTitle
        content={getCountryCity(userData.country, userData.city, language)}
        icon="location"
        textClasses={`tiny-lit not-dir ${isRtl ? "text-end" : "text-start"}`}
      />
      <IconTitle
        content={getDateOfBirthWithAge(userData.dateOfBirth, language, t)}
        icon="calendar"
        textClasses="tiny-lit text-start"
      />
      {userData.nationality && (
        <IconTitle
          content={getNationality(userData.nationality, language)}
          icon="globe-line"
          textClasses={`tiny-lit not-dir ${isRtl ? "text-end" : "text-start"}`}
        />
      )}
    </div>
  );
};

export default IdentificationContact;
