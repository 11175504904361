import React from "react";
import AvatarSkeleton from "../../common/loaders/skeleton/avatarSkeleton";

const FullGallerySkeleton = () => {
  return (
    <div className="four-grid bottom-extend top-extend-fill">
      <AvatarSkeleton fill shape="square" minHeight={160} />
      <AvatarSkeleton fill shape="square" minHeight={160} />
      <AvatarSkeleton fill shape="square" minHeight={160} />
      <AvatarSkeleton fill shape="square" minHeight={160} />
      <AvatarSkeleton fill shape="square" minHeight={160} />
      <AvatarSkeleton fill shape="square" minHeight={160} />
      <AvatarSkeleton fill shape="square" minHeight={160} />
      <AvatarSkeleton fill shape="square" minHeight={160} />
    </div>
  );
};

export default FullGallerySkeleton;
