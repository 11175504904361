import React from "react";
// Components
import TextItem from "../../../common/text/TextItem";

const MeasurementsList = ({ measurements, gender }) => {
  // Measure item render
  const RenderMeasureItem = ({ measure }) => {
    if (measure !== "type")
      return (
        <div
          className="grid align-self-center index"
          style={{ gridTemplateColumns: "1fr 1fr" }}
        >
          <TextItem
            content={`measure.${
              measure !== "thobe"
                ? measure
                : gender === "Male"
                ? "thobe"
                : "abaya"
            }`}
            classes="side-sm-padd micro-med text-upper dark text-end"
          />
          <TextItem
            content={measurements[measure]}
            classes="side-sm-padd opacity micro-med"
          />
        </div>
      );
    return null;
  };

  return (
    <div className="double-grid">
      {Object.keys(measurements).map((measure) => {
        return <RenderMeasureItem measure={measure} />;
      })}
    </div>
  );
};

export default MeasurementsList;
