import React from "react";
// Hooks
import { LanguageHook } from "../../../../utils/hooks";
import LinkButton from "../../buttons/linkButton";
// Components
import TextItem from "../../text/TextItem";

const FieldFooter = ({
  fieldName,
  error,
  disabled,
  maxLength,
  link,
  onLinkPress,
  value,
  classes,
}) => {
  // Hooks
  const { t } = LanguageHook();

  return (
    <div
      className={`absolute flex-nowrap justify-space-between full-width ${classes}`}
    >
      {error ? (
        <TextItem
          classes="error micro-lit text-start top-tiny-padd"
          content={`error.${error}`}
          params={{ field: t(fieldName) }}
        />
      ) : (
        <div />
      )}
      {link ? (
        <LinkButton
          onClick={onLinkPress}
          classes="fit-content brand pointer transition tiny-med"
          content={link}
          line
        />
      ) : (
        maxLength && (
          <TextItem
            classes={`micro-lit opacity ${disabled && "disabled"} `}
            content={`${value.length ?? 0}/${maxLength}`}
            normal
          />
        )
      )}
    </div>
  );
};

export default FieldFooter;
