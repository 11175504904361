// Icon Generator Util

// Logos / Images / Flags require Contexts
const logos = require.context("../logos", true);
const images = require.context("../images", true);
const flags = require.context("../flags", true);

// Get Brand Logos
export function GetBrandLogo({ name, size, onClick, classes }) {
  return (
    <img
      alt="brand-logo"
      onClick={onClick ?? null}
      src={logos(`./brand/${name ?? "logo-main"}.png`).default}
      className={`icon-${size} fit-contain ${classes}`}
    />
  );
}

// Get Images
export function GetImage({ name, resize, onClick, classes }) {
  return (
    <img
      alt="image"
      onClick={onClick ?? null}
      src={images(`./${name ?? "no-logo.png"}`).default}
      className={`fit-${resize ?? "cover"} ${classes}`}
    />
  );
}

// Get Flags
export function GetFlag({ country, size, classes }) {
  return (
    <img
      alt="flag"
      src={flags(`./${country ?? "no-logo"}.png`).default}
      className={`icon-${size ?? "tiny"} ${classes}`}
    />
  );
}

// Get Logos
export function GetLogo({ name, size, onClick, disabled, classes }) {
  return (
    <img
      alt="logo"
      onClick={onClick ?? null}
      src={logos(`./${name ?? "no-logo"}.png`).default}
      className={`icon-${size ?? "tiny"} ${
        disabled && "icon-disabled"
      } ${classes}`}
    />
  );
}

// Get Icons
export function GetIcon({ name, size, color, onClick, disabled, classes }) {
  return (
    <div
      className={`icon-${size ?? "tiny"} ${
        color ? color : name ? "brand" : "second"
      }-back ${disabled && "icon-disabled"} ${classes}`}
      onClick={onClick ?? null}
      style={{
        WebkitMask: `url(/assets/icons/${
          name ?? "error"
        }.svg) no-repeat center`,
        mask: `url(/assets/icons/${name ?? "error"}.svg) no-repeat center`,
      }}
    ></div>
  );
}
