import React, { useState, useEffect } from "react";
// Packages
import { connect } from "react-redux";
// Components
import getCroppedImg, { dataURLtoFile } from "../../../utils/cropImage";
import AvatarSkeleton from "../loaders/skeleton/avatarSkeleton";
import ImageContainer from "./helpers/imageContainer";
import ImageCropper from "./helpers/imageCropper";
import ImageDeleteButton from "./helpers/imageDeleteButton";
import ImageEmptyState from "./helpers/imageEmptyState";
import ImageSource from "./helpers/imageSource";
import ImageTag from "./helpers/imageTag";
import ImageUploadButton from "./helpers/imageUploadButton";
// Helpers
import { generateFormData } from "../../../utils/helperFunctions";
// Hooks
import { HandleApiHook } from "../../../utils/hooks";
import TooltipContainer from "../tooltip/Tooltip";

const ImageComponent = ({
  name,
  image,
  imageFlag,
  fill,
  size,
  shape,
  tag,
  emptyState,
  upload,
  exportImage,
  token,
  onClick,
  resize,
  aspect,
  background,
  minHeight,
  minWidth,
  preview,
  param,
  onDelete,
  tooltip,
  placement,
  classes,
}) => {
  // Hooks
  const { loading, submit } = HandleApiHook();
  const [source, setSource] = useState("");
  // Image loading hook
  const [loaded, setLoaded] = useState(false);
  // Cropper properties
  const [fileName, setFileName] = useState("");
  const [croppingImage, setCroppingImage] = useState(null);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  // Set source on change
  useEffect(() => {
    setSource(image ? `${process.env.REACT_APP_CDN_URL}/${image}` : "");
  }, [imageFlag, image]);

  // Cropper manipulation
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const resetCropper = () => {
    setCroppingImage(null);
    setCroppedArea(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setFileName("");
  };

  // Submit image upload
  const submitUpload = async () => {
    setLoaded(false);
    const dataUrl = await getCroppedImg(croppingImage, croppedArea);
    const file = dataURLtoFile(dataUrl, fileName);
    submit({
      service: upload,
      body: generateFormData("image", file),
      query: {
        token,
        param,
        oldKey: source.split(process.env.REACT_APP_CDN_URL + "/").pop(),
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onSuccess: (data) => {
        resetCropper();
        setSource(`${process.env.REACT_APP_CDN_URL}/${data}`);
        if (exportImage) exportImage(data);
      },
      successMessage: "upload",
      params: { field: name },
    });
  };

  // Loader
  const ImageLoader = () => (
    <AvatarSkeleton fill={fill} size={size} shape={shape ?? "circle"} />
  );

  // Loading State
  if (loading)
    return (
      <div
        className={`${
          fill ? "full-height full-width" : "fit-content"
        } relative ${classes}`}
      >
        <ImageLoader />
        <ImageTag tag={tag} shape={shape ?? "circle"} />
      </div>
    );

  const content = () => {
    return (
      <div
        className={`${
          fill ? "full-height full-width" : "fit-content"
        } relative ${classes}`}
      >
        <ImageContainer
          background={background}
          minHeight={minHeight}
          minWidth={minWidth}
          onClick={!preview ? onClick : null}
          shape={shape ?? "circle"}
          size={size}
          fill={fill}
        >
          {!source ? (
            <ImageEmptyState size={size} emptyState={emptyState} />
          ) : (
            <>
              <ImageSource
                source={source}
                loaded={loaded}
                setLoaded={setLoaded}
                resize={resize}
                preview={preview}
              />
              {!loaded && <ImageLoader />}
            </>
          )}
        </ImageContainer>
        <ImageTag tag={tag} shape={shape ?? "circle"} />
        <ImageDeleteButton onDelete={onDelete} shape={shape ?? "circle"} />
        <ImageUploadButton
          upload={upload}
          setFileName={setFileName}
          setCroppingImage={setCroppingImage}
          shape={shape ?? "circle"}
        />
        <ImageCropper
          croppingImage={croppingImage}
          onCropComplete={onCropComplete}
          resetCropper={resetCropper}
          submitUpload={submitUpload}
          cropProp={{ crop, setCrop }}
          zoomProp={{ zoom, setZoom }}
          aspect={aspect}
        />
      </div>
    );
  };

  if (!tooltip) return content();
  return (
    <TooltipContainer
      tooltip={tooltip}
      placement={placement}
      content={content}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(ImageComponent);
