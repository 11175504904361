import React from "react";
// Components
import ImageComponent from "../../../common/image/image";
import IdentificationContact from "../helpers/identificationContact";
import IdentificationHeader from "../helpers/identificationHeader";
import IdentificationSubHeader from "../helpers/identificationSubHeader";

const IdentificationCard = ({
  profileData,
  imageEmptyIcon,
  upload,
  editUrl,
  other,
  menuItems,
  limited,
}) => {
  // Desctruct profile data
  const { id: userData } = profileData.data;

  return (
    <div className="card one-third-grid-resp grid-zero-gap">
      <ImageComponent
        shape="square"
        fill
        minHeight={200}
        preview
        size="big2"
        image={userData.image}
        resize="contain"
        upload={upload}
        emptyState={{
          type: "icon",
          icon: imageEmptyIcon,
          color: "black",
          classes: "opacity",
        }}
        name="field.profile-picture"
      />
      <div className="all-med-padd">
        <IdentificationHeader
          userData={userData}
          editUrl={editUrl}
          other={other}
          menuItems={menuItems}
          limited={limited}
        />
        {!limited && (
          <IdentificationSubHeader
            profileData={profileData}
            userData={userData}
          />
        )}
        <IdentificationContact
          profileData={profileData}
          userData={userData}
          limited={limited}
        />
        {/* <IdentificationSocial userData={userData} /> */}
      </div>
    </div>
  );
};

export default IdentificationCard;
