import React, { useState, useEffect } from "react";
// Components
import LinkButton from "../buttons/linkButton";
import CustomModal from "../modals/customModal";
import ContactUs from "../../leap/contactUs/contactUs";
import ReactMarkdown from "react-markdown";
import privacyPolicyEn from "../../../assets/docs/privacyPolicyEn.md";
import privacyPolicyAr from "../../../assets/docs/privacyPolicyAr.md";
import termsOfUseEn from "../../../assets/docs/termsOfUseEn.md";
import termsOfUseAr from "../../../assets/docs/termsOfUseAr.md";
import { LanguageHook } from "../../../utils/hooks";

const CorporateInfo = () => {
  // State Hooks
  const { language } = LanguageHook();
  const [modalType, setModalType] = useState("");
  const [open, setOpen] = useState(false);
  const [modalIcon, setModalIcon] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [docs, setDocs] = useState({
    privacy_en: "",
    privacy_ar: "",
    terms_en: "",
    terms_ar: "",
  });

  useEffect(() => {
    fetch(privacyPolicyEn)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setDocs((prev) => ({ ...prev, privacy_en: text }));
      });
    fetch(privacyPolicyAr)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setDocs((prev) => ({ ...prev, privacy_ar: text }));
      });
    fetch(termsOfUseEn)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setDocs((prev) => ({ ...prev, terms_en: text }));
      });
    fetch(termsOfUseAr)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setDocs((prev) => ({ ...prev, terms_ar: text }));
      });
  }, []);

  const resetModal = () => {
    setModalType("");
    setModalIcon("");
    setModalTitle("");
    setOpen(false);
  };

  return (
    <div className="flex">
      {/* <LinkButton disabled content="title.about-us" classes="tiny-lit" /> */}
      <LinkButton
        content="title.contact-us"
        classes="tiny-lit"
        onClick={() => {
          setModalType("contact");
          setModalIcon("phone");
          setModalTitle("title.contact-us");
          setOpen(true);
        }}
      />
      <LinkButton
        content="title.terms-of-use"
        classes="tiny-lit"
        onClick={() => {
          setModalType("terms");
          setModalIcon("text");
          setModalTitle("title.terms-of-use");
          setOpen(true);
        }}
      />
      <LinkButton
        content="title.privacy-policy"
        classes="tiny-lit"
        onClick={() => {
          setModalType("privacy");
          setModalIcon("text");
          setModalTitle("title.privacy-policy");
          setOpen(true);
        }}
      />
      <CustomModal
        icon={modalIcon}
        title={modalTitle}
        open={open}
        setOpen={setOpen}
      >
        {modalType === "contact" && <ContactUs closeModal={resetModal} />}
        {modalType === "privacy" && (
          <div className="all-med-padd">
            <ReactMarkdown>{docs[`privacy_${language}`]}</ReactMarkdown>
          </div>
        )}
        {modalType === "terms" && (
          <div className="box-med-padd">
            <ReactMarkdown>{docs[`terms_${language}`]}</ReactMarkdown>
          </div>
        )}
      </CustomModal>
    </div>
  );
};

export default CorporateInfo;
