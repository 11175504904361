import { country } from "../assets/const/country";

export const constHelper = {
  getCountries,
  getCities,
  getCountCity,
  getPhone,
};
function getCountries() {
  let final = [];
  country.map((count) => {
    return final.push({
      value: count.code,
      t: {
        en: count.en,
        ar: count.ar,
      },
    });
  });
  return final;
}
function getCities(cnt) {
  let count = country.find((x) => x.code === cnt);
  let final = [];
  count.cities.map((city) => {
    return final.push({
      value: city.shortCode,
      t: {
        en: city.en,
        ar: city.ar,
      },
    });
  });
  return final;
}
function getCountCity(cnt, city, lang) {
  let count = country.find((x) => x.code === cnt);
  let cit = count.cities.find((x) => x.shortCode === city);
  return `${lang === "ar" ? cit.ar : cit.en}, ${
    lang === "ar" ? count.ar : count.en
  }`;
}
function getPhone(phone) {
  let count = country.find((x) => x.code === phone.code);
  return count.dial + phone.num;
}

// Get country city data
export function getCountryCity(countryCode, cityCode, language) {
  let countryFound = country.find((x) => x.code === countryCode);
  let cityFound = countryFound.cities.find((x) => x.shortCode === cityCode);
  return `${countryFound[language]}, ${cityFound[language]}`;
}

// Get Nationality
export function getNationality(nationality, language) {
  let nationalityFound = country.find((x) => x.code === nationality);
  return nationality ? nationalityFound[language] : null;
}

// Get phone dial
export function getPhoneDial(code) {
  return country.find((x) => x.code === code).dial.replace(" ", "");
}
