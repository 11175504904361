import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { apiServices } from "../../../services/apiServices";
import {
  appendArrayToFormData,
  appendObjectToFormData,
  appendToFormData,
  generateFormData,
} from "../../../utils/helperFunctions";
import history from "../../../utils/history";
import { HandleApiHook } from "../../../utils/hooks";
// Components
import FormButtons from "../../common/buttons/FormButtons";
import FileField from "../../common/fields/fileField";
import HeadTextField from "../../common/fields/headTextField";
import TextAreaField from "../../common/fields/TextAreaField";
import IconTitle from "../../common/layouts/IconTitle";
import TopLoader from "../../common/loaders/topLoader";
import ConfirmModal from "../../common/modals/confirmModal";
import TagsRender from "../../common/tags/tags";
import TextItem from "../../common/text/TextItem";

const EditCastingShot = ({ location, token }) => {
  // Destruct Edit data
  const { editData } = location.state ?? {};
  const {
    castDesc: initCastDesc,
    castLinks: initCastLinks,
    castShoot: initCastShoot,
  } = editData ?? {};
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [castDesc, setCastDesc] = useState(initCastDesc ?? "");
  const [castLinks, setCastLinks] = useState(initCastLinks ?? []);
  const [castLink, setCastLink] = useState("");
  const [castShoot, setCastShoot] = useState(initCastShoot ?? null);
  const [castShootOldKey, setCastShootOldKey] = useState(
    initCastShoot ? initCastShoot.url : null
  );
  const [cancelModal, setCancelModal] = useState(false);

  // Add casting sample
  const addCastingSample = () => {
    if (castLink) {
      let castingSamples = [...castLinks];
      let isExists = castingSamples.find((x) => x === castLink);
      if (!isExists) {
        castingSamples.push(castLink);
        setCastLinks(castingSamples);
      }
      setCastLink("");
    }
  };

  // Remove casting sample
  const removeCastingSample = (link) => {
    let castingSample = [...castLinks];
    castingSample = castingSample.filter((obj) => {
      return obj !== link;
    });
    setCastLinks(castingSample);
  };

  // Submit casting shot edit
  const submitCastingShotEdit = (e) => {
    e.preventDefault();
    let formData = generateFormData("castDesc", castDesc);
    formData = castShoot.type
      ? appendToFormData(formData, "castShoot", castShoot)
      : appendObjectToFormData(formData, castShoot);
    formData = appendToFormData(formData, "oldKey", castShootOldKey);
    formData = appendArrayToFormData(formData, "castLinks", castLinks);
    submit({
      service: apiServices.cast.updateCastShoot,
      body: formData,
      query: {
        token,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onSuccess: () => history.goBack(),
      successMessage: "update",
      params: { field: "title.cast-shot" },
    });
  };

  return (
    <form
      onSubmit={submitCastingShotEdit}
      className="noncard-container single-grid bottom-extend top-extend"
      noValidate
    >
      {loading && <TopLoader />}
      <IconTitle
        content="title.edit-casting"
        icon="shot"
        iconSize="tiny1"
        textClasses="parag-med text-upper"
        classes="align-center"
      />
      <div className="card single-grid all-med-padd height-fit-content align-start">
        <TextItem
          content="title.cast-samples"
          classes="tiny-med text-upper full-width justify-start"
        />
        <TextItem
          content="title.edit-casting-info"
          classes="micro-lit dark full-width justify-start"
        />
        <TagsRender tags={castLinks} onRemove={removeCastingSample} />
        <HeadTextField
          innerPlace="field.enter-link"
          value={castLink}
          name="title.cast-samples"
          onChange={setCastLink}
          error={getErrorItem("castLinks")}
          disableSubmit
          onEnter={addCastingSample}
          disabled={castLinks.length >= 5}
        />
        <FormButtons
          disabled={loading || castLinks.length >= 5}
          submitContent="action.add"
          icon="plus"
          onSubmit={addCastingSample}
          noSubmit
          second
          radial
        />
      </div>
      <FileField
        place="title.cast-vid"
        accept="ext.vid"
        type="video"
        asset={castShoot}
        error={getErrorItem("castShoot")}
        onChange={setCastShoot}
        maxSize={50}
      />
      <TextAreaField
        place="field.desc"
        innerPlace="field.desc-casting"
        value={castDesc}
        error={getErrorItem("castDesc")}
        onChange={setCastDesc}
        rows={3}
        maxLength={500}
      />
      <FormButtons
        disabled={loading}
        onCancel={() => setCancelModal(true)}
        submitContent="action.save"
        icon="save"
        classes="top-med-marg"
      />
      <ConfirmModal
        open={cancelModal}
        setOpen={setCancelModal}
        title="confirm.cancel-editing"
        talk="confirm.cancel-editing-effect"
        onAgree={() => history.goBack()}
      />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(EditCastingShot);
