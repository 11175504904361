import React from "react";
// Helpers
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "../../routes/privateRoute";
// Components
import OrganizationAuth from "./auth";
import IssueHome from "./issue";
import OrganizationStart from "./start";

const OrganizationHome = () => {
  return (
    <div className="full-container full-height-vh flex top-padd">
      <Switch>
        <PrivateRoute
          path="/organization/auth"
          component={OrganizationAuth}
          navigationKey="explore"
        />
        <PrivateRoute
          path="/organization/start"
          component={OrganizationStart}
          navigationKey="start"
        />
        <Route path="/organization/issue" component={IssueHome} />
      </Switch>
    </div>
  );
};

export default OrganizationHome;
