import React from "react";

const LineDivider = ({ height, classes, lineClasses }) => {
  return (
    <div className={classes}>
      <div
        className={`grey2-back ${
          height ? "vertical-line" : "horizontal-line"
        } ${lineClasses}`}
        style={{ height: height }}
      ></div>
    </div>
  );
};

export default LineDivider;
