import React, { useState, useEffect } from "react";
import HeadTextField from "../../common/fields/headTextField";
import NormalButton from "../../common/buttons/NormalButton";
import history from "../../../utils/history";
import TopLoader from "../../common/loaders/topLoader";
import { connect } from "react-redux";
import { reduxActions } from "../../../redux/actions";
import { apiServices } from "../../../services/apiServices";
import { HandleApiHook } from "../../../utils/hooks";
import TextItem from "../../common/text/TextItem";

const ResetPassword = ({ match, clearEmail }) => {
  // Destruct params
  const { auth, token } = match.params ?? {};
  // Hooks
  const { loading, getErrorItem, submit } = HandleApiHook();
  const [password, setPassword] = useState("");
  const [rePass, setRePass] = useState("");

  const [passErr, setPassErr] = useState(false);
  const [repassErr, setRepassErr] = useState(false);

  // Check session validity
  useEffect(() => {
    if (!auth || !token) history.replace("/");
  }, []);

  // Submit reset password
  const submitResetPassword = (e) => {
    e.preventDefault();
    submit({
      service: apiServices.auth.resetPass,
      body: {
        password,
        rePass,
        auth,
        token,
      },
      onSuccess: () => {
        clearEmail();
        history.replace("/issue/pass-changed");
      },
    });
  };

  return (
    <form onSubmit={submitResetPassword} className="single-grid grid-high-gap">
      {loading && <TopLoader />}
      <TextItem content="action.reset-pass" classes="label-hev" />
      <TextItem content="desc.reset-pass" classes="tiny-lit" />
      <HeadTextField
        place="field.new-pass"
        innerPlace="field.enter-pass"
        value={password}
        error={getErrorItem("password")}
        onChange={setPassword}
        password
        autoFocus
      />
      <HeadTextField
        place="field.conf-new-pass"
        innerPlace="field.enter-pass"
        value={rePass}
        error={getErrorItem("rePass")}
        password
        onChange={setRePass}
      />
      <NormalButton
        content="action.reset-pass"
        disabled={loading}
        classes="margin-auto"
      />
    </form>
  );
};

const mapDispatchToProps = {
  clearEmail: reduxActions.emailActions.clearEmail,
};

export default connect(null, mapDispatchToProps)(ResetPassword);
