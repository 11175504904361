import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export const hist = {
  push,
  replace,
};
function push(route, data) {
  history.push({
    pathname: route,
    state: data,
  });
}
function replace(route, data) {
  history.replace({
    pathname: route,
    state: data,
  });
}

export default history;
