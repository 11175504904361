import React from "react";
// Components
import LoginCanvas from "./LoginCanvas";
import LogoIntro from "./LogoIntro";

const LoginStack = () => {
  return (
    <div className="single-canvas grid-gap full-width margin-auto">
      <LogoIntro />
      <LoginCanvas />
    </div>
  );
};

export default LoginStack;
