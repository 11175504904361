import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { apiServices } from "../../../../services/apiServices";
import { HandleApiHook } from "../../../../utils/hooks";
// Components
import FloatIconButton from "../../../common/buttons/floatIconButton";
import TopLoader from "../../../common/loaders/topLoader";
import ConfirmModal from "../../../common/modals/confirmModal";
import TextItem from "../../../common/text/TextItem";

const WorkSampleCard = ({ sample, token, removeSample }) => {
  // Hooks
  const { loading, submit } = HandleApiHook();
  const [removeSampleConfirm, setRemoveSampleConfirm] = useState(false);

  // Remove sample
  const submitRemoveSample = () => {
    submit({
      service: apiServices.third.removeWorkSample,
      body: {
        sampleId: sample._id,
        assetKey: sample.asset.url,
      },
      query: {
        token,
      },
      onSuccess: () => removeSample(sample._id),
      successMessage: "removed",
      params: { field: "title.work-sample" },
    });
  };

  return (
    <div>
      {loading && <TopLoader />}
      <div className="flex justify-space-between full-width">
        <TextItem content={sample.title} classes="parag-hev" normal />
        {!loading && (
          <FloatIconButton
            name="trash"
            size="tiny1"
            color="second"
            onClick={() => setRemoveSampleConfirm(true)}
            tooltip="action.remove"
          />
        )}
      </div>
      <TextItem content={sample.desc} classes="tiny-lit" normal />
      <div className="flex justify-start full-width">
        <TextItem
          content={sample.asset.name}
          classes="micro-lit end-med-padd"
          normal
        />
        <TextItem
          content={sample.asset.size}
          classes="micro-lit opacity"
          normal
        />
        <TextItem content="size.mb" classes="micro-lit opacity" />
      </div>
      <ConfirmModal
        open={removeSampleConfirm}
        setOpen={setRemoveSampleConfirm}
        title="confirm.remove-work-sample"
        talk="confirm.remove-work-sample"
        onAgree={submitRemoveSample}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(WorkSampleCard);
