import { getFirebaseToken } from "../../../utils/FirebaseHelper";
import { authActions } from "../auth/authActions";

const { apiServices } = require("../../../services/apiServices");
const { accountTypes } = require("./accountTypes.js");

export const accountActions = {
  logging,
};

function logging(status) {
  return { type: accountTypes.LOGGING, status };
}

export function setAccount(token, id, notificationToken) {
  return async (dispatch) => {
    dispatch(logging(true));
    const newNotificationToken = await getFirebaseToken();
    apiServices.auth.getLoggedInAccount({
      query: { token, id, notificationToken: notificationToken ?? newNotificationToken },
      success: (result) => {
        if (result !== "not-loggedIn") {
          dispatch(authActions.login(token, result, notificationToken));
        }
        dispatch(logging(false));
      },
      fail: () => dispatch(logging(false)),
      error: () => dispatch(logging(false)),
    });
  };
}
