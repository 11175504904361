import React, { useState } from "react";
// Helpers
import { Layout } from "antd";
// Components
import FloatIconButton from "../../common/buttons/floatIconButton";
import SideMenuNavigation from "./helpers/sideMenuNavigation";

const { Sider } = Layout;

const SideMenu = ({ project }) => {
  // Hooks
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Sider
      collapsed={collapsed}
      className={`${!collapsed && "side-menu-not-collapsed"} side-menu index2`}
      width={300}
    >
      <FloatIconButton
        name={collapsed ? "text" : "close"}
        color="black"
        size="small2"
        classes="absolute top-right-med-abs"
        onClick={() => setCollapsed(!collapsed)}
      />
      <SideMenuNavigation
        project={project}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
    </Sider>
  );
};

export default SideMenu;
