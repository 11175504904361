import React from "react";
// Packages
import { Select } from "antd";
// Components
import FieldHeader from "./components/FieldHeader";
import { LanguageHook } from "../../../utils/hooks";
import FieldFooter from "./components/FieldFooter";
// SubImports
const { Option } = Select;

const SelectField = ({
  place,
  innerPlace,
  label,
  value,
  error,
  disabled,
  onChange,
  options,
  classes,
  allowClear,
  onClear,
}) => {
  // Hooks
  const { isRtl, language, t } = LanguageHook();

  return (
    <div className="full-width relative">
      {(place || label) && (
        <FieldHeader
          head={place}
          label={label}
          disabled={disabled}
          error={error}
        />
      )}
      <div
        className={`select-field ${
          disabled ? "select-disabled-field" : ""
        } ${classes}`}
      >
        <Select
          className={`${isRtl ? "select-ar" : "select-en"} ${
            error && !disabled ? "select-field-error" : ""
          }`}
          placeholder={t(innerPlace)}
          showSearch
          disabled={disabled}
          onChange={onChange}
          defaultValue={value}
          value={value}
          allowClear={allowClear}
          onClear={onClear}
          style={{ fontFamily: isRtl ? "Almarai" : "Avenir" }}
          filterOption={(input, option) =>
            option.children.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {options &&
            options.map((option) => {
              return (
                <Option key={option.value} value={option.value}>
                  <span style={{ fontFamily: isRtl ? "Almarai" : "Avenir" }}>
                    {option.label
                      ? option.localize
                        ? t(option.label)
                        : option.label
                      : option.t
                      ? option.t[language]
                      : option.value}
                  </span>
                </Option>
              );
            })}
        </Select>
      </div>
      {error && (
        <FieldFooter
          disabled={disabled}
          error={error}
          fieldName={place}
          value={value}
        />
      )}
    </div>
  );
};

export default SelectField;
