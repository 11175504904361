import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageHook } from "../../../../utils/hooks";
import SelectField from "../../../common/fields/selectField";
import TextItem from "../../../common/text/TextItem";
import SettingItem from "../helpers/settingItem";

const LanguageSettingCard = () => {
  const { i18n } = useTranslation();
  // Hooks
  const { language } = LanguageHook();
  const [modal, setModal] = useState(false);

  // Change language
  const changeAppLanguage = (lng) => {
    if (lng !== language) {
      window.location.reload();
      i18n.changeLanguage(lng);
    }
  };

  // Modal content
  const modalContent = () => {
    return (
      <div className="single-grid all-med-padd">
        <SelectField
          place="field.lang"
          value={language}
          onChange={(val) => {
            changeAppLanguage(val);
            setModal(false);
          }}
          options={[
            {
              value: "en",
              t: {
                en: "English",
                ar: "English",
              },
            },
            {
              value: "ar",
              t: {
                en: "العربية",
                ar: "العربية",
              },
            },
          ]}
        />
      </div>
    );
  };

  return (
    <SettingItem
      icon="globe"
      title="setting.lang"
      talk="setting.talk-lang"
      iconSize="tiny"
      decorator={
        <TextItem
          content={language}
          classes="tiny-hev brand text-upper side-med-padd"
          normal
        />
      }
      modal={modal}
      setModal={setModal}
      modalContent={modalContent}
    />
  );
};

export default LanguageSettingCard;
