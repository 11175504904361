import React, { useState, useEffect, useRef } from "react";
import EmptyForm from "../../error/empty";
import ErrorConnection from "../../error/error";
import VisSensor from "../../visibility/visSensor";

const LoadingItems = ({
  service,
  query,
  succ,
  fail,
  err,
  loader,
  isEmpty,
  emptyTitle,
  result,
  addToPage,
  loadMore,
  searching,
  setSearchLoad,
  card,
  classes,
  observer,
  wait,
  noWait,
}) => {
  const [more, setMore] = useState(false);
  const [paging, setPaging] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const firstRender = useRef(true);

  useEffect(() => {
    setLoading(true);
    if (wait || noWait) service(getApiData);
  }, [error, wait, observer]);
  useEffect(() => {
    if (paging && more) {
      setPageLoad(true);
      service(getApiDataPaging);
    }
  }, [page]);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setSearchLoad(true);
      service(getApiData);
    }
  }, [searching]);

  const getApiData = {
    query,
    success: (data) => {
      setMore(data.more);
      if (succ) succ(data);
      setPage(1);
      setLoading(false);
      if (setSearchLoad) setSearchLoad(false);
    },
    fail: (data) => {
      if (fail) fail(data);
      setError(true);
      setLoading(false);
      if (setSearchLoad) setSearchLoad(false);
    },
    error: () => {
      if (err) err();
      setError(true);
      setLoading(false);
      if (setSearchLoad) setSearchLoad(false);
    },
  };
  const getApiDataPaging = {
    query: {
      ...query,
      page: paging ? page : 1,
    },
    success: (data) => {
      setMore(data.more);
      if (addToPage) addToPage(data);
      setPaging(false);
      setPageLoad(false);
    },
    fail: (data) => {
      if (fail) fail(data);
      setError(true);
      setPageLoad(false);
    },
    error: () => {
      if (err) err();
      setError(true);
      setPageLoad(false);
    },
  };

  const nextPage = (isVisible) => {
    if (isVisible && more) {
      setPaging(true);
      setPage(page + 1);
    }
  };

  if (loading) {
    return loader();
  } else if (error) {
    return (
      <ErrorConnection
        classes={card ? "card" : ""}
        onRefresh={() => setError(false)}
      />
    );
  } else if (isEmpty) {
    return <EmptyForm classes={card ? "card" : ""} title={emptyTitle} />;
  } else {
    return (
      <div className={classes}>
        {result()}
        {loadMore && <VisSensor nextPage={nextPage} pageLoad={pageLoad} />}
      </div>
    );
  }
};

export default LoadingItems;
