import React from "react";
// Packages
import { Upload } from "antd";
// Components
import FieldHeader from "./components/FieldHeader";
import FieldFooter from "./components/FieldFooter";
import TextItem from "../text/TextItem";
import { AlertHook, LanguageHook } from "../../../utils/hooks";
import { assetTypes } from "../../../utils/constants";

const FileField = ({
  place,
  error,
  disabled,
  onChange,
  accept,
  type,
  asset,
  maxSize,
}) => {
  // Hooks
  const { t, isRtl } = LanguageHook();
  const { showAlert } = AlertHook();

  // Upload Props
  const props = {
    accept: t(accept),
    showUploadList: false,
    beforeUpload: (file) => {
      if (!assetTypes[type].includes(file.type.split("/")[1])) {
        showAlert({
          message: "not-valid",
          params: {
            field: "title.asset",
          },
        });
      } else if (maxSize) {
        if (file.size / 1000000 < maxSize) onChange(file);
        else
          showAlert({
            success: false,
            message: "max-size",
            params: { maxSize: maxSize },
          });
      } else onChange(file);
      return false;
    },
  };

  return (
    <div className="full-width relative">
      {(place || accept) && (
        <FieldHeader
          head={place}
          label={accept}
          disabled={disabled}
          error={error}
        />
      )}
      <div
        className={`border-dark trans-back single-grid relative ${
          error && !disabled && "border-alert"
        } ${disabled && "border-grey2"}`}
      >
        <TextItem
          content={asset ? asset.name : "field.no-file"}
          classes={`sparag-med max-width box-sm-padd ellipsis ${
            asset ? "" : "opacity"
          }`}
          style={{ lineHeight: isRtl ? 1 : 1.4 }}
        />
        <Upload
          className={`absolute ${isRtl ? "left-zero-abs" : "right-zero-abs"}`}
          disabled={disabled}
          {...props}
        >
          <TextItem
            content="action.browse"
            classes={`parag-hev box-sm-padd align-self-center ${
              disabled ? "dark opacity" : "brand pointer"
            }`}
          />
        </Upload>
      </div>
      {error && (
        <FieldFooter disabled={disabled} error={error} fieldName={place} />
      )}
    </div>
  );
};

export default FileField;
