import React, { useState, useEffect } from "react";
// Helpers
import { Input } from "antd";
import { LanguageHook } from "../../../utils/hooks";
// Components
import { GetIcon } from "../../../assets/utils/IconGenerator";
import CircleLoader from "../loaders/circleLoader";
import FiltersModal from "./helpers/filtersModal";
import { getObject, setObject } from "../../../utils/StorageHelpers";

const SearchBox = ({
  action,
  value,
  onChange,
  loading,
  filterGroups,
  filters,
  setFilters,
  setAppliedFilters,
  filterName,
}) => {
  // Hooks
  const { isRtl, t } = LanguageHook();
  const [filtersHolder, setFiltersHolder] = useState({});

  // Set Applied Filters (That showed as tags)
  useEffect(() => {
    if (filterGroups) {
      setFiltersHolder(filters);
      const appliedFilters = [];
      filterGroups.forEach((filterGroup) => {
        if (filterGroup && filters[filterGroup.name])
          appliedFilters.push({
            name: filterGroup.name,
            value: filters[filterGroup.name],
            label: filterGroup.values.find(
              ({ value }) => value === filters[filterGroup.name]
            ).label,
          });
      });
      setAppliedFilters(appliedFilters);
    }

    // Clear useEffect
    const timeId = setTimeout(() => {
      if (action) action();
    }, 500);
    return () => clearTimeout(timeId);
  }, [value, filters]);

  // Set Filters (sent to backend)
  useEffect(() => {
    const localFilters = getObject(filterName)?.filters;
    if (filterGroups && !localFilters) {
      let filtersSample = { ...filters };
      filterGroups.forEach((filterGroup) => {
        if (filterGroup && filterGroup.name)
          filtersSample[filterGroup.name] = filterGroup.defVal;
      });
      setFilters(filtersSample);
    }
  }, []);

  return (
    <div className="flex-nowrap align">
      {filterGroups && (
        <FiltersModal
          filters={filters}
          filtersHolder={filtersHolder}
          setFiltersHolder={setFiltersHolder}
          filterGroups={filterGroups}
          submitFilters={() => {
            if (filterName) {
              setObject(filterName, {
                search: value,
                filters: filtersHolder,
              });
            }
            setFilters(filtersHolder);
          }}
        />
      )}
      <div className={`relative search-box full-width`}>
        <div className={`search-ico ${isRtl ? "search-en" : "search-ar"}`}>
          <GetIcon name="search" />
        </div>
        <Input
          onChange={(e) => {
            if (filterName) {
              setObject(filterName, {
                search: e.target.value,
                filters: filtersHolder,
              });
            }
            onChange(e.target.value);
          }}
          placeholder={t("field.search")}
          value={value}
        />
        {loading && (
          <div className={`search-ico ${isRtl ? "search-ar" : "search-en"}`}>
            <CircleLoader size="tiny" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBox;
