import React, { useEffect, useState } from "react";
// Packages
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { titleRouteKeys } from "../../utils/routes";
// Helpers
import history from "../../utils/history";
import { connect } from "react-redux";
import { reduxActions } from "../../redux/actions";

const HeaderProps = ({ tabValue, clearTab }) => {
  // Language Hook
  const { t } = useTranslation();

  // Constants
  const pathKeys = Object.keys(titleRouteKeys);
  const pathValues = Object.values(titleRouteKeys);

  // State managing tabs title
  const [tabTitle, setTabTitle] = useState(null);

  // First run
  useEffect(() => {
    setTabTitle(null);
    clearTab();
    pathValues.forEach((value, index) => {
      if (window.location.pathname.includes(value))
        setTabTitle(pathKeys[index]);
    });
  }, []);

  // Changing tab title upon history change
  history.listen(({ pathname }) => {
    setTabTitle(null);
    if (!pathname.includes("project")) clearTab();
    pathValues.forEach((value, index) => {
      if (pathname.includes(value)) setTabTitle(pathKeys[index]);
    });
  });

  return (
    <Helmet>
      {tabTitle && (
        <title>{tabTitle ? t(`routes.${tabTitle}`, { tabValue }) : ""}</title>
      )}
    </Helmet>
  );
};

const mapStateToProps = (state) => {
  return {
    tabValue: state.tabTitle.tabValue,
  };
};

const mapDispatchToProps = {
  clearTab: reduxActions.tabTitleActions.clearTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderProps);
