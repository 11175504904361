import React from "react";
import AvatarSkeleton from "../../common/loaders/skeleton/avatarSkeleton";
import LineSkeleton from "../../common/loaders/skeleton/lineSkeleton";

const TableSkeleton = ({ type }) => {
  const renderSubSkel = () => {
    return (
      <div className="list-divider sub-row grid vertical-med-padd">
        <AvatarSkeleton shape="circle" size="med" />
        <LineSkeleton rows={1} classes="name align-self-center" />
        <LineSkeleton rows={1} classes="phone align-self-center phone-skel" />
        <LineSkeleton rows={1} classes="email align-self-center" />
        <LineSkeleton rows={1} classes="status align-self-center" />
      </div>
    );
  };
  if (type === "sub") {
    return (
      <>
        {renderSubSkel()}
        {renderSubSkel()}
        {renderSubSkel()}
        {renderSubSkel()}
        {renderSubSkel()}
        {renderSubSkel()}
      </>
    );
  } else return null;
};

export default TableSkeleton;
