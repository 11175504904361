import React from "react";
import AvatarSkeleton from "../../common/loaders/skeleton/avatarSkeleton";
import LineSkeleton from "../../common/loaders/skeleton/lineSkeleton";

const ProjectCardSkeleton = () => {
  return (
    <div className="single-grid">
      <AvatarSkeleton fill shape="square" minHeight={200} />
      <div className="one-third-grid side-padd">
        <AvatarSkeleton size="med2" classes="justify-self-center" />
        <LineSkeleton rows={2} />
      </div>
    </div>
  );
};

export default ProjectCardSkeleton;
