import React from "react";
// Components
import CheckboxItem from "../../../common/checkbox/checkbox";
import TextItem from "../../../common/text/TextItem";

const ChooseDirectorsCard = ({ setDir, setDop }) => {
  return (
    <div className="single-grid all-med-padd">
      <TextItem content="title.directors" classes="parag-hev text-upper" />
      <CheckboxItem
        classes="full-width justify-start side-med-padd"
        content="title.dir"
        font="parag-lit"
        onChange={setDir}
      />
      <CheckboxItem
        classes="full-width justify-start side-med-padd"
        content="title.dop-lng"
        font="parag-lit"
        onChange={setDop}
      />
    </div>
  );
};

export default ChooseDirectorsCard;
