import React, { useState } from "react";
// Helpers
import { connect } from "react-redux";
import { apiServices } from "../../../services/apiServices";
import { HandleApiHook } from "../../../utils/hooks";
// Components
import FetchUiData from "../../common/layouts/fetchUiData";
import ImageComponent from "../../common/image/image";
import OrganizationCardSkeleton from "../skeleton/organizationCardSkeleton";
import TextItem from "../../common/text/TextItem";
import IconTitle from "../../common/layouts/IconTitle";

const OrganizationCard = ({ organizationId, token }) => {
  // Hooks
  const { loading, error, setError, submit } = HandleApiHook(true);
  const [organization, setOrganization] = useState(null);
  // Destruct organization data
  const { image, name, type, auth, accounts } = organization ?? {};
  const { email, status } = auth ?? {};

  // Fetch organization data
  const fetchOrganization = async () => {
    submit({
      service: apiServices.org.getOrgCard,
      query: {
        token,
        org: organizationId,
      },
      onSuccess: (organizationData) => setOrganization(organizationData),
    });
  };

  return (
    <FetchUiData
      service={fetchOrganization}
      observer={organizationId}
      loading={loading}
      dataFetched={organization}
      error={error}
      setError={setError}
      Loader={OrganizationCardSkeleton}
      classes="card one-third-grid-resp grid-zero-gap"
      noWait
    >
      <ImageComponent
        shape="square"
        fill
        minHeight={100}
        preview
        size="big2"
        image={image}
        resize="contain"
        emptyState={{
          type: "icon",
          icon: "building",
          color: "black",
          classes: "opacity",
        }}
      />
      <div className="all-med-padd double-grid">
        <div className="single-grid">
          <TextItem classes="slabel-hev" content={name} normal />
          <div>
            <TextItem
              content={`org.${type}`}
              classes="tiny-hev brand text-start"
            />
            <TextItem
              content={email}
              classes="micro-lit opacity text-start"
              normal
            />
          </div>
        </div>
        <div className="single-grid grid-sm-gap">
          <IconTitle
            content={accounts && accounts.length}
            label="title.employees"
            icon="team"
            iconSize="tiny1"
            textClasses="sparag-med text-upper text-left"
            classes="align-center"
          />
          <IconTitle
            content={`status.${status ? "active" : "deac"}`}
            icon="status"
            iconSize="tiny1"
            textClasses={`tiny-hev text-upper text-left ${
              status ? "active" : "deactivated"
            }`}
            classes="align-center"
          />
        </div>
      </div>
    </FetchUiData>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    organizationId: state.auth.org,
  };
};

export default connect(mapStateToProps)(OrganizationCard);
