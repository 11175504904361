import { apiHelper } from "../../utils/apiHelper";
const endPoint = "/cast";

export const cast = {
  addShowcasePic,
  addGalleryPic,
  removeGalleryPic,
  getShowcase,
  getGallery,
  getCastShoot,
  uploadCastVideo,
  updateCastShoot,
  updateMeasures,
};

// Exec
function execPost(route, data) {
  apiHelper.post(endPoint + route, data);
}
function execPut(route, data) {
  apiHelper.put(endPoint + route, data);
}
function execGet(route, data) {
  apiHelper.get(endPoint + route, data);
}

function addShowcasePic(data) {
  execPost("/showcase-pic", data);
}
function addGalleryPic(data) {
  execPost("/gallery-pic", data);
}
function removeGalleryPic(data) {
  execPut("/remove-gallery-pic", data);
}
function getShowcase(data) {
  execGet("/showcase", data);
}
function getGallery(data) {
  execGet("/gallery", data);
}
function getCastShoot(data) {
  execGet("/cast-shoot", data);
}
function uploadCastVideo(data) {
  execPost("/cast-video", data);
}
function updateCastShoot(data) {
  execPut("/cast-shoot", data);
}
function updateMeasures(data) {
  execPut("/measures", data);
}
