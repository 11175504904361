import React, { useState } from "react";
// Components
import FloatIconButton from "../../buttons/floatIconButton";
import FormButtons from "../../buttons/FormButtons";
import CustomModal from "../../modals/customModal";
import RadioGroup from "../../radio/radioGroup";
import TextItem from "../../text/TextItem";

const FiltersModal = ({
  filters,
  filtersHolder,
  setFiltersHolder,
  filterGroups,
  submitFilters,
}) => {
  // Hooks
  const [applyFilters, setApplyFilters] = useState(false);

  // Set Filter Holder (which controls the state of filters modal)
  const applyFilter = (value, name) => {
    let filtersHolderSample = { ...filtersHolder };
    filtersHolderSample[name] = value;
    setFiltersHolder(filtersHolderSample);
  };

  return (
    <>
      <FloatIconButton
        name="adjustments"
        size="small"
        classes="end-med-padd"
        onClick={() => setApplyFilters(true)}
        tooltip="title.filters"
        placement="bottom"
      />
      <CustomModal
        icon="adjustments"
        title="title.filters"
        open={applyFilters}
        setOpen={setApplyFilters}
      >
        <div className="single-grid all-med-padd">
          {filterGroups.map((filterGroup) => {
            if (filterGroup)
              return (
                <div key={filterGroup.title} className="single-grid">
                  <TextItem
                    content={filterGroup.title}
                    classes="tiny-hev text-upper"
                  />
                  <RadioGroup
                    group={filterGroup.values}
                    onChange={(value) => applyFilter(value, filterGroup.name)}
                    defVal={filters[filterGroup.name]}
                  />
                </div>
              );
            return null;
          })}
          <FormButtons
            onSubmit={() => {
              submitFilters();
              setApplyFilters(false);
            }}
            onCancel={() => setApplyFilters(false)}
            submitContent="action.submit"
            classes="top-sm-marg"
          />
        </div>
      </CustomModal>
    </>
  );
};

export default FiltersModal;
