import React from "react";
// Helpers
import { Switch } from "react-router-dom";
import history from "../../../utils/history";
// Components
import MainLogo from "../../common/logo/mainLogo";
import PrivateRoute from "../../../routes/privateRoute";
import CopyRight from "../../common/footer/CopyRight";
import WelcomeStart from "../../leap/start/welcomeStart";
import PersonalStart from "../../leap/start/personalStart";

const ThirdStart = () => {
  return (
    <div className="full-container full-height-vh flex top-padd">
      <div className="single-canvas grid-gap">
        <div className="single-grid grid-center">
          <MainLogo onClick={() => history.push("/")} />
        </div>
        <div className="padding-card shadow flex">
          <div className="card-content margin-auto">
            <Switch>
              <PrivateRoute
                path="/third/start/welcome"
                component={WelcomeStart}
                navigationKey="startWelcome"
              />
              <PrivateRoute
                path="/third/start/personal"
                component={PersonalStart}
                navigationKey="startPersonal"
              />
            </Switch>
          </div>
        </div>
        <CopyRight classes="text-center vertical-med-padd" />
      </div>
    </div>
  );
};

export default ThirdStart;
