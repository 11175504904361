const { authTypes } = require("./authTypes");

export const authActions = {
  login,
  changeUsername,
  endInit,
  incStage,
  approveProf,
  addImage,
  logout,
};
function login(token, auth, notificationToken) {
  return { type: authTypes.LOGIN, token, auth, notificationToken };
}
function changeUsername(username) {
  return { type: authTypes.CHANGE_USERNAME, username };
}
function addImage(image) {
  return { type: authTypes.ADD_IMAGE, image };
}
function endInit() {
  return { type: authTypes.END_INIT };
}
function incStage() {
  return { type: authTypes.INC_STAGE };
}
function approveProf() {
  return { type: authTypes.APPROVE_PROF };
}
function logout() {
  return { type: authTypes.LOGOUT };
}
