const { accountTypes } = require("./accountTypes.js");

export function account(
  state = {
    logging: true,
  },
  { type, status }
) {
  switch (type) {
    case accountTypes.LOGGING:
      return { logging: status };
    default:
      return state;
  }
}
