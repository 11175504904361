import React, { useState, useEffect } from "react";
// Packages
import Cropper from "react-easy-crop";
import { Slider } from "@material-ui/core";
// Components
import CustomModal from "../../modals/customModal";
import FormButtons from "../../buttons/FormButtons";

const ImageCropper = ({
  croppingImage,
  submitUpload,
  resetCropper,
  onCropComplete,
  cropProp,
  zoomProp,
  aspect,
}) => {
  // Destruct hooks
  const { crop, setCrop } = cropProp;
  const { zoom, setZoom } = zoomProp;
  const [cropper, setCropper] = useState(false);

  // Toggle modal when change the selected image
  useEffect(() => {
    setCropper(croppingImage === null ? false : true);
  }, [croppingImage]);

  return (
    <CustomModal
      icon="camera"
      title="title.edit-image"
      open={cropper}
      setOpen={setCropper}
      classes="cropper-modal"
      onClose={resetCropper}
      disableClear
    >
      <div className="relative cropper-container margin-auto">
        <Cropper
          image={croppingImage}
          crop={crop}
          zoom={zoom}
          aspect={aspect ? aspect : 1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className="all-med-padd">
        <div className="side-padd">
          <Slider
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={(e, zoom) => setZoom(zoom)}
            color="primary"
            className="vertical-sm-marg"
          />
        </div>
        <FormButtons onCancel={resetCropper} onSubmit={submitUpload} />
      </div>
    </CustomModal>
  );
};

export default ImageCropper;
