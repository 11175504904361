import React, { useEffect } from "react";
import EmptyForm from "../error/empty";
import ErrorConnection from "../error/error";

const FetchUiData = ({
  service,
  loading,
  Loader,
  error,
  setError,
  children,
  observer,
  classes,
  dataFetched,
  emptyMessage,
  wait,
  noWait,
}) => {
  // Execute Service
  useEffect(async () => {
    if (wait || noWait) await service();
  }, [error, wait, observer]);

  if (loading || (!dataFetched && !error)) {
    return <Loader />;
  } else if (error) {
    return <ErrorConnection onRefresh={() => setError(false)} />;
  } else if (emptyMessage && dataFetched.length === 0) {
    return <EmptyForm title={emptyMessage} />;
  } else return <div className={classes}>{children}</div>;
};

export default FetchUiData;
