import React from "react";
// Components
import TextItem from "../text/TextItem";

const LinkText = ({ text, link, onClick, classes }) => {
  return (
    <div className={`tiny-lit ${classes}`}>
      <TextItem content={text} span />
      <TextItem
        content={link}
        classes="side-sm-marg brand line-hover pointer tiny-med"
        span
        onClick={onClick}
      />
    </div>
  );
};

export default LinkText;
