import React from "react";
// Helpers
import history from "../../../utils/history";
import { Switch, Route } from "react-router-dom";
// Components
import MainLogo from "../../common/logo/mainLogo";
import AccountActivated from "../../leap/issue/accActive";
import AccountAlreadyActive from "../../leap/issue/accAlreadyActive";
import PasswordChanged from "../../leap/issue/passChanged";
import ResetPassword from "../../leap/issue/resetPass";

const IssueStack = () => {
  return (
    <div className="single-canvas grid-gap full-width margin-auto">
      <div className="single-grid grid-center">
        <MainLogo onClick={() => history.push("/")} />
      </div>
      <div className="padding-card shadow flex">
        <div className="card-content margin-auto">
          <Switch>
            <Route
              path="/issue/reset-pass/:auth/:token"
              component={ResetPassword}
            />
            <Route path="/issue/pass-changed" component={PasswordChanged} />
            <Route path="/issue/acc-activated" component={AccountActivated} />
            <Route
              path="/issue/already-activated"
              component={AccountAlreadyActive}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default IssueStack;
