import React, { useState } from "react";
// Helpers
import { GetIcon } from "../../../../assets/utils/IconGenerator";
import { LanguageHook } from "../../../../utils/hooks";
// Components
import EmptyForm from "../../../common/error/empty";
import IconTitle from "../../../common/layouts/IconTitle";
import CardUtilities from "../../../common/helpers/cardUtilities";
import EditMeasurementsModal from "../helpers/editMeasurementsModal";
import MeasurementsList from "../helpers/measurementsList";

const MeasurementCard = ({
  profileData,
  currentStage,
  other,
  updateAccountData,
}) => {
  // Desctruct profile data
  const { id: userData } = profileData.data ?? {};
  const { id: accountData } = userData.accData ?? {};
  const { measurements: initMeasurements } = accountData ?? {};
  // Hooks
  const { isRtl } = LanguageHook();
  const [measurements, setMeasurements] = useState(initMeasurements ?? null);
  const [editMeasures, setEditMeasures] = useState(false);

  if (
    userData.type === "cast" &&
    profileData.initial &&
    (!currentStage || currentStage >= 2)
  ) {
    if (!measurements && other) return null;
    if (!measurements)
      return (
        <div className="card">
          <EmptyForm
            title="empty.measures"
            button="action.insert"
            buttonIcon="edit"
            emptyIcon="ruler"
            onClick={() => setEditMeasures(true)}
          />
          <EditMeasurementsModal
            editMeasures={editMeasures}
            setEditMeasures={setEditMeasures}
            measurements={measurements}
            gender={userData.gender}
            setMeasurements={(measureResults) => {
              setMeasurements(measureResults);
              updateAccountData({
                ...accountData,
                measurements: measureResults,
              });
            }}
          />
        </div>
      );
    return (
      <div className="card all-med-padd single-grid overflow-hidden relative">
        <div className="flex justify-space-between full-width">
          <IconTitle
            content="title.measures"
            icon="ruler"
            iconSize="tiny"
            textClasses="sparag-hev text-upper dark"
            classes="align-center index"
          />
          <CardUtilities
            edit={!other}
            onEditClick={() => setEditMeasures(true)}
          />
        </div>
        <MeasurementsList
          measurements={measurements}
          gender={userData.gender}
        />
        <GetIcon
          name="human"
          size="monster"
          color="grey"
          classes={`justify-self-center align-self-center opacity absolute top-${
            isRtl ? "left" : "right"
          }-abs`}
        />
        {editMeasures && (
          <EditMeasurementsModal
            editMeasures={editMeasures}
            setEditMeasures={setEditMeasures}
            measurements={measurements}
            gender={userData.gender}
            setMeasurements={setMeasurements}
          />
        )}
      </div>
    );
  }
  return null;
};

export default MeasurementCard;
