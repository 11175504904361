import { apiHelper } from "../../utils/apiHelper";
const endPoint = "/organization";

export const org = {
  addProfilePic,
  getOrgCard,
  organizationWelcome,
};

// Exec
function execPost(route, data) {
  apiHelper.post(endPoint + route, data);
}
function execPut(route, data) {
  apiHelper.put(endPoint + route, data);
}
function execGet(route, data) {
  apiHelper.get(endPoint + route, data);
}

// Functions
function addProfilePic(data) {
  execPost("/profile-pic", data);
}
function getOrgCard(data) {
  execGet("/org-card", data);
}
function organizationWelcome(data) {
  execPut("/organization-welcome", data);
}
