import React, { useEffect, useState, useRef } from "react";
import { GetIcon } from "../../../../assets/utils/IconGenerator";
import { Badge } from "antd";
import { HandleApiHook } from "../../../../utils/hooks";
import { apiServices } from "../../../../services/apiServices";
import { connect } from "react-redux";
import { onMessageListener } from "../../../../utils/FirebaseHelper";
import { getNotificationSound } from "../../../../utils/SoundsHelper";
import history from "../../../../utils/history";

const NotificationBell = ({ token, type, approved }) => {
  const { submit } = HandleApiHook(true);
  const [count, setCount] = useState(0);
  const notificationAudio = getNotificationSound();
  const initialRender = useRef(true);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      fetchCount();
    }
  };

  const playNotificationAudio = () => {
    notificationAudio
      .play()
      .catch(() => console.log("Notification sound unavailable"));
  };

  const increaseCount = () => setCount((prevCount) => prevCount + 1);

  onMessageListener(increaseCount);

  useEffect(() => {
    fetchCount();
  }, []);

  useEffect(() => {
    if (initialRender.current) return (initialRender.current = false);
    playNotificationAudio();
  }, [count]);

  const fetchCount = () => {
    submit({
      service: apiServices.notification.getUnreadCount,
      query: {
        token,
      },
      onSuccess: (countResult) => setCount(countResult.count),
    });
  };

  return (
    <div
      onClick={() => {
        switch (type) {
          case "acc":
            history.push("/sub-account/explore/notifications");
            break;
          case "3rd":
          default:
            history.push(
              approved
                ? "/third/explore/notifications"
                : "/third/approve/notifications"
            );
            break;
        }
      }}
      className="side-med-padd flex pointer"
    >
      <Badge size="small" count={count}>
        <GetIcon name="bell" color="white" />
      </Badge>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    type: state.auth.type,
    approved: state.auth.approved,
  };
};

export default connect(mapStateToProps)(NotificationBell);
