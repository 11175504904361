import React, { useState } from "react";
// Packages
import { Radio } from "antd";
// Components
import TextItem from "../text/TextItem";

const RadioGroup = ({ group, defVal, onChange }) => {
  // Hooks
  const [value, setValue] = useState(defVal);

  // Handle radio check
  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) onChange(e.target.value);
  };

  return (
    <Radio.Group
      className="flex-imp justify-start"
      onChange={handleChange}
      value={value}
    >
      {group.map((groupItem) => {
        return (
          <Radio
            key={groupItem.value}
            className="flex bottom-sm-marg"
            value={groupItem.value}
          >
            {
              <TextItem
                classes={`tiny-lit ${
                  groupItem.value === value ? "transition tiny-med brand" : ""
                }`}
                content={groupItem.label}
              />
            }
          </Radio>
        );
      })}
    </Radio.Group>
  );
};

export default RadioGroup;
