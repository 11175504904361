import { apiHelper } from "../../utils/apiHelper";
const endPoint = "/notification";

export const notification = {
  getUnreadCount,
  listNotifications,
  markAsRead
};

// Exec
function execPost(route, data) {
  apiHelper.post(endPoint + route, data);
}
function execPut(route, data) {
  apiHelper.put(endPoint + route, data);
}
function execGet(route, data) {
  apiHelper.get(endPoint + route, data);
}

// Functions
function getUnreadCount(data) {
  execGet("/unread-count", data);
}
function listNotifications(data) {
  execGet("/list-notifications", data);
}
function markAsRead(data) {
  execPut("/mark-as-read", data);
}
