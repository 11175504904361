import React from "react";
// Components
import TextItem from "../../text/TextItem";

const TableHeader = ({ type }) => {
  if (type === "sub") {
    return (
      <div className="sub-row grid side-sm-padd grid-hide">
        <div />
        <TextItem content="field.name" classes="name micro-hev text-upper" />
        <div className="phone">
          <TextItem content="title.phone" classes="micro-hev text-upper" />
        </div>
        <div className="email">
          <TextItem content="field.email" classes="micro-hev text-upper" />
        </div>
        <TextItem
          content="title.status"
          classes="status micro-hev text-upper"
        />
      </div>
    );
  } else return null;
};

export default TableHeader;
