import React, { useEffect, useState } from "react";
// Helpers
import history from "../../../../utils/history";
// Components
import { GetIcon } from "../../../../assets/utils/IconGenerator";
import TextItem from "../../../common/text/TextItem";

const WideScreenNavigationItem = ({ navigationItem }) => {
  // Hooks
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(window.location.pathname === navigationItem.url);
  }, []);
  history.listen(() => {
    setActive(window.location.pathname === navigationItem.url);
  });

  return (
    <div
      className={`flex-nowrap pointer side-med-padd full-height ${
        active && "wide-screen-navigation-item-active"
      }`}
      onClick={() => {
        history.push(navigationItem.url);
      }}
    >
      <GetIcon
        name={navigationItem.icon}
        color={active ? "brand" : "white"}
        size="micro2"
        classes="end-sm-marg"
      />
      <TextItem
        content={navigationItem.text}
        classes={`tiny-lit white ${active && "tiny-hev"}`}
      />
    </div>
  );
};

export default WideScreenNavigationItem;
