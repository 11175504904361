import React from "react";
// Helpers
import { Switch } from "react-router-dom";
import PrivateRoute from "../../../routes/privateRoute";
import history from "../../../utils/history";
// Components
import MainLogo from "../../common/logo/mainLogo";
import OrganizationPasswordImageStart from "../../leap/start/organizationPasswordStart";
import CopyRight from "../../common/footer/CopyRight";
import OrganizationFirstSubAccount from "../../leap/start/organizationFirstSubAccount";

const OrganizationStart = () => {
  return (
    <div className="single-canvas grid-gap full-width margin-auto">
      <div className="single-grid grid-center">
        <MainLogo onClick={() => history.push("/")} />
      </div>
      <div className="padding-card shadow flex">
        <div className="card-content margin-auto">
          <Switch>
            <PrivateRoute
              path="/organization/start/welcome"
              component={OrganizationPasswordImageStart}
              navigationKey="startWelcome"
            />
            <PrivateRoute
              path="/organization/start/sub-account"
              component={OrganizationFirstSubAccount}
              navigationKey="startPersonal"
            />
          </Switch>
        </div>
      </div>
      <CopyRight classes="text-center vertical-med-padd" />
    </div>
  );
};

export default OrganizationStart;
