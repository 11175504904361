import React from "react";
// Helpers
import { momentHelper } from "../../../../utils/momentHelper";
import { LanguageHook } from "../../../../utils/hooks";
import history from "../../../../utils/history";
// Components
import { GetLogo } from "../../../../assets/utils/IconGenerator";
import ImageComponent from "../../../common/image/image";
import TextItem from "../../../common/text/TextItem";

const ProjectCard = ({ project, subAccount }) => {
  // Destruct Project
  const { _id, createdAt, image, name, status, org, creator } = project ?? {};
  // Hooks
  const { isRtl, language } = LanguageHook();

  return (
    <div
      onClick={() => {
        history.push(`/project/${_id}`);
      }}
      className="project-card card pointer align-self-start relative overflow-hidden scale-hover"
    >
      {momentHelper.getHours(createdAt) < 48 && (
        <GetLogo
          name="new-tag"
          size="big"
          classes="absolute index top-zero-abs right-zero-abs"
        />
      )}
      <ImageComponent
        shape="square"
        fill
        minHeight={250}
        image={image}
        size="monster"
        emptyState={{
          type: "icon",
          icon: "project",
          color: "black",
          classes: "opacity",
        }}
        classes="radial-edges"
      />
      <div className="absolute one-third-grid all-med-padd full-width black-gradient-back bottom-zero-abs full-height">
        <div
          className={`${
            isRtl ? "audi-imgs-ar" : "audi-imgs"
          } fit-content align-self-end`}
        >
          {!subAccount && <ImageComponent image={org.image} size="big" />}
          {subAccount && <ImageComponent image={creator.image} size="big" />}
        </div>
        <div className="align-self-end vertical-sm-padd">
          <TextItem
            content={name}
            classes="sparag-hev white text-start"
            normal
          />
          <div className="flex flex-center justify-start">
            <TextItem
              content={momentHelper.getFrom(createdAt, language)}
              classes="micro-lit white"
              normal
            />
            <div className="padd-dot-white"></div>
            <TextItem
              content={`status.${status}`}
              classes={`micro-hev ${status}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
