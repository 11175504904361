import React, { useState } from "react";
import IconTitle from "../../../common/layouts/IconTitle";
import ProjectExplore from "../../project/projectExplore";

const ProjectsTabs = ({ subId }) => {
  // Hooks
  const [activeType, setActiveType] = useState("proj");

  // Is projects
  const isProject = () => activeType === "proj";

  if (subId)
    return (
      <div className="single-grid">
        <div className="double-grid noncard-container">
          <IconTitle
            content="title.projects"
            icon="project"
            iconSize="tiny"
            iconColor={isProject() ? "brand" : "dark"}
            textClasses={`sparag-${
              isProject() ? "hev white" : "lit dark"
            } text-upper`}
            classes={`card all-med-padd radial-edges full-width justify-center-imp pointer ${
              isProject() ? "black-back" : "white-back"
            }`}
            onClick={async () => {
              await setActiveType(null);
              setActiveType("proj");
            }}
          />
          <IconTitle
            content="title.requests"
            icon="writing"
            iconSize="tiny"
            iconColor={!isProject() ? "brand" : "dark"}
            textClasses={`sparag-${
              !isProject() ? "hev white" : "lit dark"
            } text-upper`}
            classes={`card all-med-padd radial-edges full-width justify-center-imp pointer ${
              !isProject() ? "black-back" : "white-back"
            }`}
            onClick={async () => {
              await setActiveType(null);
              setActiveType("req");
            }}
          />
        </div>
        {activeType && (
          <ProjectExplore
            filterName={
              activeType === "proj"
                ? "sub-profile-projects-filters"
                : "sub-profile-requests-filters"
            }
            subAccount
            type={activeType}
            otherProfileId={subId}
          />
        )}
      </div>
    );
  return null;
};

export default ProjectsTabs;
