import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxActions } from "../../../redux/actions";
// Helpers
import { apiServices } from "../../../services/apiServices";
import { HandleApiHook } from "../../../utils/hooks";
// Components
import FetchUiData from "../../common/layouts/fetchUiData";
import OrganizationCard from "../cards/organizationCard";
import SubAccountProfileSkeleton from "../skeleton/subAccountProfileSkeleton";
import IdentificationCard from "./cards/identificationCard";
import ProjectsTabs from "./cards/projectsTabs";

const SubAccountProfile = ({
  token,
  location,
  menuItems,
  imageUploadService,
  identificationCardEditUrl,
  changeTab,
}) => {
  // Hooks
  const { loading, error, setError, submit } = HandleApiHook(true);
  const [profileData, setProfileData] = useState(null);
  const [otherProfile, setOtherProfile] = useState(
    location.state ? location.state.authId : undefined
  );
  const [subId, setSubId] = useState(
    location.state ? location.state.subId : undefined
  );

  // Fetch profile data
  const fetchProfile = async () => {
    await setOtherProfile(location.state ? location.state.authId : undefined);
    submit({
      service: apiServices.subAcc.getSubAccountProfile,
      query: {
        token,
        authId: otherProfile,
      },
      onSuccess: (profileDataResult) => {
        changeTab(profileDataResult.username.trim());
        setProfileData(profileDataResult);
      },
    });
  };

  return (
    <FetchUiData
      service={fetchProfile}
      observer={location.state}
      loading={loading}
      dataFetched={profileData}
      error={error}
      setError={setError}
      Loader={SubAccountProfileSkeleton}
      classes="single-grid bottom-extend top-extend-fill"
      noWait
    >
      <IdentificationCard
        profileData={profileData}
        imageEmptyIcon="user"
        upload={imageUploadService}
        editUrl={identificationCardEditUrl}
        other={otherProfile}
        menuItems={menuItems}
      />
      <OrganizationCard />
      <ProjectsTabs subId={subId} />
    </FetchUiData>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

const mapDispatchToProps = {
  changeTab: reduxActions.tabTitleActions.changeTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubAccountProfile);
