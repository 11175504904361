import React from "react";
import IdentificationHeader from "../../profile/helpers/identificationHeader";
import ImageComponent from "../../../common/image/image";
import IdentificationContact from "../../profile/helpers/identificationContact";
import { hist } from "../../../../utils/history";

const ConfirmedCard = ({ confirmedData, project }) => {
  return (
    <div
      onClick={() => {
        hist.push(`/project/${project._id}/casting/third-profile`, {
          authId: confirmedData.auth?._id,
        });
      }}
      className="flex-nowrap justify-start pointer"
    >
      <ImageComponent
        shape="circle"
        preview
        size="monster"
        image={confirmedData.image}
      />
      <div className="all-med-padd">
        <IdentificationHeader userData={confirmedData} other limited />
        <IdentificationContact userData={confirmedData} limited />
      </div>
    </div>
  );
};

export default ConfirmedCard;
